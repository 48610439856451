import React from 'react'
import classes from './MultiPageStyle.module.scss'
import { COMPLETE_SIGNUP_PAGES, languages } from '../../../../../assets/data/SelectOptions'
import { TFAText, TFASimpleInput, TFAInputText, TFASelectBox, TFAButton } from '../../../../../components/TFAComponents'
import { Avatar, IconButton } from '@mui/material'
import { AddImg } from '../../../../../assets/icons/Auth/AuthIcons'
import { useLazyQuery, useQuery } from '@apollo/client'
import validatePage from '../../../../../utils/validatePage'
import { GET_PRESIGNED_UPLOAD_URL, GET_VISITOR_TOKEN } from '../../../../../services/apiQuery'
import axios from 'axios'

const AboutYou = ({ formik, setCurrentPage }) => {
	const { data } = useQuery(GET_VISITOR_TOKEN)
	const [getUploadURL] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)

	if (data) {
		localStorage.setItem('TFAToken', data.GetVisitorToken)
		localStorage.setItem('TFATokenType', 'VisitorToken')
	}

	const uploadAvatar = async ({ target }) => {
		const file = target.files[0]
		const uploadImageURL = URL.createObjectURL(target.files[0])
		try {
			const { GetPresignedUploadUrl } = await callGetUploadURL(file)
			if (!GetPresignedUploadUrl) return
			const url = GetPresignedUploadUrl.link
			const uploading = await axios.put(url, file, {
				headers: {
					'Content-Type': file.type,
				},
			})
			if (uploading.status !== 200) return
			formik.setFieldValue((formik.values.avatar = GetPresignedUploadUrl.key))
			formik.setFieldValue((formik.values.image = uploadImageURL))
		} catch (error) {
			console.log(error)
		}
	}

	const callGetUploadURL = async (file) => {
		const { data } = await getUploadURL({
			variables: {
				filename: file.name,
				folder: 'avatar',
			},
		})
		return data
	}

	const handleSubmit = async () => {
		const { firstLanguage, experienceLevel } = formik.values
		const isPageValid = await validatePage({ firstLanguage, experienceLevel }, formik)
		if (!isPageValid) return
		setCurrentPage(COMPLETE_SIGNUP_PAGES.AVAILABILITY)
	}

	return (
		<>
			<TFAText
				primary_title='About You'
				secondary_title='Hey There! Let’s get you set up.'
				description='Let’s get to know each other. We just need a few details from you to create you account.'
				style1={classes}
			/>
			<div className={classes.avatar_box}>
				<Avatar
					src={formik && formik.values.image}
					alt='professional avatar'
					className={classes.avatar_box__avatar}
					sx={{ width: 220, height: 220, bgcolor: '#2F68FA' }}
				/>
				<IconButton
					component='span'
					sx={{ background: '#2F68FA', padding: '0.5em' }}
					className={classes.avatar_box__camera}
					name='image'
					onChange={uploadAvatar}
				>
					<input type='file' className={classes.avatar_box__camera__avatar_input} accept={'image/*'} />
					<AddImg />
				</IconButton>
			</div>
			<div className={classes.bio_box}>
				<TFASimpleInput
					box_width='100%'
					box_margin='0'
					width='100% !important'
					label='BIO'
					rows={5}
					variant='outlined'
					name='bio'
					text_field='100%'
					formik={formik}
				/>
			</div>
			<div className={classes.title_box}>
				<p className={classes.title_box__primary}>First Language</p>
				<p className={classes.title_box__secondary}>
					Select your first or prefered language. This allows us to connect you to a professional who specializes in your languages.
				</p>
				<TFASelectBox
					selectArray={languages}
					label='FIRST LANGUAGE'
					name='firstLanguage'
					width='100%'
					required={true}
					formik={formik}
					error={formik?.errors?.firstLanguage ?? null}
				/>
			</div>
			<div className={classes.title_box}>
				<p className={classes.title_box__primary}>Second Language</p>
				<p className={classes.title_box__secondary}>
					Select your prefered second language. This is a fallback from your first language selection (This is not mandatory).
				</p>
				<TFASelectBox selectArray={languages} label='SECOND LANGUAGE' name='secondLanguage' width='100%' formik={formik} />
			</div>
			<div className={classes.title_box}>
				<p className={classes.title_box__primary}>Experience Level</p>
				<p className={classes.title_box__secondary}>Enter how many years of experience you have in your migration profession.</p>
				<TFAInputText
					label='EXPERIENCE LEVEL'
					type='text'
					name='experienceLevel'
					form_width='100%'
					placeholder='2 years'
					formik={formik}
					error={formik?.errors?.experienceLevel ?? null}
				/>
			</div>
			<div className={classes.btn_box}>
				<TFAButton text='NEXT' onClick={handleSubmit} style1={classes} />
			</div>
		</>
	)
}

export default AboutYou
