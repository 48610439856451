import React, { useEffect, useState } from 'react'
import classes from './Modals.module.scss'
import moment from 'moment'
import { TFAButton, TFAModal, TFASkeleton } from '../../../../../components/TFAComponents'
import { CloseBtn, TimeArrow } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useQuery } from '@apollo/client'
import { GET_AVAILABLE_TIME } from '../../../../../services/apiQuery'
import { CREATE_APPOINTMENT } from '../../../../../assets/data/SelectOptions'

const SelectAppointmentTime = ({
    formik,
    selectedDay,
    isAppointmentTimeOpen,
    setIsAppointmentTimeOpen,
    setCurrentPage,
}) => {
    const [ allAvailability, setAllAvailability ] = useState([])
    const [ selectedTime, setSelectedTime ] = useState('')

    const formattedSelectedDay = selectedDay && moment(selectedDay).format('DD/MM/YYYY')
    const startDay = selectedDay && moment(selectedDay).format('YYYY-MM-DD')
    const endDay = selectedDay && moment(selectedDay).add(1, 'day').format('YYYY-MM-DD')
    const timezone = moment.tz.guess()
    const diff = moment().tz(timezone).format('Z')
    const { loading: availableLoading, data: availableData } = useQuery(GET_AVAILABLE_TIME, {
        variables: {
            professionalId: formik.values.professional?.id,
            dayStart: `${startDay}T00:00:00${diff}`,
            dayEnd:   `${endDay}T00:00:00${diff}`,
        }
    })

    useEffect(() => {
        availableData && setAllAvailability(availableData.GetAvailableTime)
    },[availableData])

    const timeFormatter = (date, timing) => {
        const startTime = moment(date).format('hh:mm a')
        const endTime = moment(date).add(30, 'minutes').format('hh:mm a')
        switch (timing) {
            case 'start':   return startTime
            case 'end':     return endTime
            default:        break;
        }
    }

    const confirmAppointmentTime = () => {
        if (!selectedTime) return
        formik.setFieldValue(formik.values.appointmentDate = selectedTime)
        setCurrentPage(CREATE_APPOINTMENT.DETAILS)
    }

    return (
        <TFAModal 
            open={isAppointmentTimeOpen}
            handleClose={() => setIsAppointmentTimeOpen(false)}
            width='30em'
            bg='#FFFFFF'
        >
            <div className={classes.modal_title_box}>
                <CloseBtn 
                    className={classes.modal_title_box__icon}
                    onClick={() => setIsAppointmentTimeOpen(false)}
                />
                <p className={classes.modal_title_box__title}>
                    Select Appointment Time
                </p>
            </div>
            <p className={classes.modal_desc_box}>
                Prefer a professional? Enter their professional code below to send this question directly to them.
            </p>
            <div className={classes.selected_date_box}>
                <p className={classes.selected_date_box__title}>
                    Date Selected:
                </p>
                <p className={classes.selected_date_box__date}>
                    {formattedSelectedDay}
                </p>
            </div>
            <div className={classes.available_box}>
                {availableLoading
                ? <TFASkeleton type='appointment_time' count={5}/>
                : allAvailability.map((time, index) => (
                    <div 
                        className={selectedTime === time
                        ?   classes.available_box__time_card_selected
                        :   classes.available_box__time_card
                        }
                        key={index}
                        onClick={() => setSelectedTime(time)}
                    >
                        <p>{timeFormatter(time, 'start')}</p>
                        <TimeArrow />
                        <p>{timeFormatter(time, 'end')}</p>
                    </div>
                ))
                }
            </div>
            <div className={classes.btn_box}>
                <TFAButton 
                    text='CONFIRM'
                    onClick={confirmAppointmentTime}
                    style1={classes}
                />
            </div>
        </TFAModal>
    )
}

export default SelectAppointmentTime