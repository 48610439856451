import React from 'react'
import classes from './Modals.module.scss'
import { TFAButton, TFAModal, TFASelectBox, TFASimpleInput } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import SearchCountryInput from '../../../../../components/Register/SearchCountryInput/SearchCountryInput'
import { categories } from '../../../../../assets/data/SelectOptions'

const QuestionDetailsModal = ({ 
    formik,
    isDetailModalOpen, 
    setIsDetailModalOpen 
}) => {
    return (
        <TFAModal
            open={isDetailModalOpen}
            setIsDetailModalOpen={setIsDetailModalOpen}
            width='30em'
        >
            <div className={classes.modal_title_box}>
                <CloseBtn 
                    className={classes.modal_title_box__icon}
                    onClick={() => setIsDetailModalOpen(false)}
                />
                <p className={classes.modal_title_box__title}>
                    Edit Details
                </p>
            </div>
            <div className={classes.scroll_box}>
                <div className={classes.title_box}>
                    <p className={classes.title_box__primary}>
                        Destination
                    </p>
                    <p className={classes.title_box__secondary}>
                        Select the country of destination relating to your question.
                    </p>
                    <div className={classes.title_box__simple_input}>
                        <SearchCountryInput 
                            name='countryOfDestination'
                            placeholder='COUNTRY OF DESTINATION'
                            formik={formik}
                            error={formik?.errors?.countryOfDestination ?? null}
                        />
                    </div>
                </div>
                <div className={classes.title_box}>
                    <p className={classes.title_box__primary}>
                        Category
                    </p>
                    <p className={classes.title_box__secondary}>
                        Select a category for your question.
                    </p>
                    <div className={classes.title_box__simple_input}>
                    <TFASelectBox 
                        selectArray={categories}
                        label='CATEGORY'
                        name='category'
                        width='100%'
                        required={true}
                        formik={formik}
                        error={formik?.errors?.category ?? null}
                        />
                    </div>
                </div>
                <div className={classes.title_box}>
                    <p className={classes.title_box__primary}>
                        Question Title
                    </p>
                    <p className={classes.title_box__secondary}>
                        Your <b>Question Title</b> is the headline for your question.
                    </p>
                    <div className={classes.title_box__simple_input}>
                        <TFASimpleInput 
                            box_width='100%'
                            box_margin='0'
                            width='100% !important'
                            label='QUESTION TITLE'
                            rows={5}
                            variant='outlined'
                            name='questionTitle'
                            text_field='100%'
                            formik={formik}
                            error={formik.errors.questionTitle ?? null}
                        />
                    </div>
                </div>
                <div className={classes.title_box}>
                    <p className={classes.title_box__primary}>
                        Question Context
                    </p>
                    <p className={classes.title_box__secondary}>
                        Provide some context to your question. The more information you can provide, the better professionals can answer your question.
                    </p>
                    <div className={classes.title_box__simple_input}>
                        <TFASimpleInput 
                            box_width='100%'
                            box_margin='0'
                            width='100% !important'
                            label='QUESTION CONTEXT'
                            rows={5}
                            variant='outlined'
                            name='questionContext'
                            text_field='100%'
                            formik={formik}
                            error={formik.errors.questionContext ?? null}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.btn_box}>
                <TFAButton 
                    text='CONFIRM'
                    onClick={() => setIsDetailModalOpen()}
                    style1={classes}
                />
            </div>
        </TFAModal>
    )
}

export default QuestionDetailsModal