import React from 'react'
import classes from './DashboardSubTitle.module.scss'

const DashboardSubTitle = ({ 
  Icon, 
  title, 
  color, 
  fontSize, 
  margnTop,
  iconSize,
  style
}) => {
  return (
    <div className={margnTop
        ? classes.subtitle_box_top
        : classes.subtitle_box}>
        {Icon &&
        <Icon 
          className={classes.subtitle_icon} 
          style={{fill: color, transform: iconSize}}
        />}
        <p 
          className={classes.subtitle_title}
          style={{fontSize: fontSize}}
        >
          {title}
        </p>
    </div>
  )
}

export default DashboardSubTitle