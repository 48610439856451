import React from 'react'
import classes from './DetailsImage.module.scss'
import { InstallFile } from '../../../assets/icons/Dashboard/DashboardIcons'
import { downloadFile } from '../../../utils/downloadFiles'

const DetailsImage = ({ questionDetails }) => {
    return (
        <div className={classes.container}>
            {questionDetails.questionimages.map((image, index) => (
            <div 
                className={classes.container__image_box}
                key={index}
            >
                <img 
                    src={image.link}
                    alt='question document'
                    className={classes.container__image_box__image}
                />
                <InstallFile 
                    className={classes.container__image_box__icon}
                    onClick={() => downloadFile(image)}
                />
            </div>
            ))}
        </div>
    )
}

export default DetailsImage