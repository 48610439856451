import React, { useEffect, useState } from 'react'
import classes from './Modals.module.scss'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAButton, TFAModal, TFASkeleton } from '../../../../../components/TFAComponents'
import { Searchbar } from '../../../../../components/Dashboard/DashboardComponents'
import ProfessionalCard from '../../../../../components/Dashboard/ProfessionalCard/ProfessionalCard'
import { useQuery } from '@apollo/client'
import { GET_PROFESSIONALS } from '../../../../../services/apiQuery'

const SelectProfessionalsModal = ({ formik, isProModalOpen, proModalClose }) => {
	const [searchText, setSearchText] = useState('')
	const [professionals, setProfessionals] = useState([])
	const { loading: professionalsLoading, data: professionalsData } = useQuery(GET_PROFESSIONALS, {
		variables: { professionalCode: searchText },
	})

	console.log(professionalsData)

	useEffect(() => {
		professionalsData && setProfessionals(professionalsData.GetProfessionals.objectList)
	}, [professionalsData])

	const selectProfessional = (professional) => {
		formik.setFieldValue((formik.values.professional = professional))
	}

	return (
		<TFAModal open={isProModalOpen} handleClose={proModalClose} width='30em' bg='#FFFFFF'>
			<div className={classes.modal_title_box}>
				<CloseBtn className={classes.modal_title_box__icon} onClick={proModalClose} />
				<p className={classes.modal_title_box__title}>Select Professional</p>
			</div>
			<p className={classes.modal_desc_box}>
				Prefer a professional? Enter their professional code below to send this question directly to them.
			</p>
			<Searchbar setSearchText={setSearchText} />
			<div className={classes.professional_list_box}>
				{professionalsLoading ? (
					<TFASkeleton type='professionals' count={4} />
				) : professionals.length === 0 ? (
					<div className={classes.empty_professional_box}>No Professionals to select</div>
				) : (
					<div className={classes.professional_list}>
						{professionals.map((professional, index) => (
							<div
								className={
									formik.values.professional?.id === professional.id ? classes.professional_list__card_clicked : classes.professional_list__card
								}
								key={index}
							>
								<ProfessionalCard professionalDetails={professional} clickProfessional={() => selectProfessional(professional)} type='question' />
							</div>
						))}
					</div>
				)}
			</div>
			<div className={classes.pro_select_btn_box}>
				<TFAButton text='SELECT' onClick={proModalClose} style1={classes} />
			</div>
		</TFAModal>
	)
}

export default SelectProfessionalsModal
