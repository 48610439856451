import React, { useEffect, useState } from 'react'
import classes from './MultiPageStyle.module.scss'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { FormControl, InputAdornment } from '@mui/material'
import TimePicker from '../../../../../components/Register/TimePicker/TimePicker'
import { TFAFormControlLabel, TFACheckbox } from '../../../../../components/Register/TimePicker/MuiStyle'
import { TFAInputText, TFAButton } from '../../../../../components/TFAComponents'
import timezone from 'moment-timezone'
import validatePage from '../../../../../utils/validatePage'
import { dateFormatter } from '../../../../../utils/appointmentFormatter'
import { Alert } from '@mui/material'
import { COMPLETE_SIGNUP_PAGES } from '../../../../../assets/data/SelectOptions'

const Availability = ({ formik, setCurrentPage, isUnavailable, setIsUnavailable }) => {
	const [location, setLocation] = useState('')
	const [errorDay, setErrorDay] = useState('')

	useEffect(() => {
		const zone_name = timezone.tz.guess()
		const time_zone = timezone.tz(zone_name).zoneAbbr()
		setLocation(`${time_zone} (${zone_name})`)
	}, [])

	useEffect(() => {
		setErrorDay('')
		const formattedDates = dateFormatter(formik.values.availability)
		formattedDates.forEach((value, index) => value === 0 && setErrorDay(index))
	}, [formik])

	const handleSubmit = async () => {
		const { halfHourRate, minRate } = formik.values
		const isPageValid = await validatePage({ halfHourRate, minRate }, formik)
		if (!isPageValid) return
		setCurrentPage(COMPLETE_SIGNUP_PAGES.ADDRESS_PAYMENT)
	}

	return (
		<>
			<div className={classes.backBtn_box} onClick={() => setCurrentPage(COMPLETE_SIGNUP_PAGES.ABOUT_YOU)}>
				<LeftArrow className={classes.backBtn_box__arrow} />
				<p className={classes.backBtn_box__txt}>RETURN TO ABOUT YOU</p>
			</div>
			<div className={classes.section_title_box}>
				<p className={classes.section_title_box__main}>When are you free for meetings?</p>
				<p className={classes.section_title_box__desc}>
					Complete your available times so users can schedule meetings with you. You can set your availability between 00:00pm and 00:00am.
				</p>
			</div>
			<div className={classes.calendar_box}>
				{formik.values.availability.map((week, index) => (
					<TimePicker
						title={week.day}
						key={index}
						clickCheckbox={() => formik.setFieldValue(week, (week.isClicked = !week.isClicked))}
						isClicked={week.isClicked}
						unavailable={isUnavailable}
						formik={formik}
						week={week}
						error={index === errorDay && true}
					/>
				))}
			</div>
			{errorDay === 0 && (
				<Alert severity='error' sx={{ padding: '0 1em', marginTop: '0.2em' }}>
					Start time must be before finish time
				</Alert>
			)}
			<div className={classes.unavailable_box}>
				<TFAFormControlLabel
					label='Mark yourself as unavailable.'
					className={classes.unavailable_box__title_checkbox}
					control={<TFACheckbox onChange={() => setIsUnavailable(!isUnavailable)} />}
				/>
			</div>
			<div className={classes.timezone_box}>
				<p>
					You’re timezone is set to <span>{location}</span>.
				</p>
			</div>

			<div className={classes.section_title_box}>
				<p className={classes.section_title_box__main}>How much are your appointments?</p>
				<p className={classes.section_title_box__desc}>Select your half-hourly rate for your appointments ($25 minimum).</p>
				<FormControl fullWidth sx={{ mt: 3, mb: 5 }}>
					<TFAInputText
						label='APPOINTMENT PRICE'
						type='text'
						name='halfHourRate'
						placeholder='25'
						value={formik.values.halfHourRate}
						formik={formik}
						error={formik?.errors?.halfHourRate ?? null}
						InputProps={{
							startAdornment: <InputAdornment position='start'>AUD $</InputAdornment>,
						}}
					/>
				</FormControl>
			</div>

			<div className={classes.section_title_box}>
				<p className={classes.section_title_box__main}>What is your minimum question price?</p>
				<p className={classes.section_title_box__desc}>Select your minimum question price for your questions ($25 minimum).</p>
				<FormControl fullWidth sx={{ mt: 3 }}>
					<TFAInputText
						label='QUESTION PRICE'
						type='text'
						name='minRate'
						placeholder='25'
						value={formik.values.minRate}
						formik={formik}
						error={formik?.errors?.minRate ?? null}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start' sx={{ fontFamily: "'Space Grotesk', sans-serif" }}>
									AUD $
								</InputAdornment>
							),
						}}
					/>
				</FormControl>
			</div>
			<div className={classes.btn_box}>
				<TFAButton text='SAVE SCHEDULE AND RATE' onClick={handleSubmit} style1={classes} />
			</div>
		</>
	)
}

export default Availability
