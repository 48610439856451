import React from 'react'
import classes from './Modals.module.scss'
import { TFAButton, TFAModal, TFAText } from '../../../../../components/TFAComponents'
import { TaskCompleted } from '../../../../../assets/icons/Dashboard/DashboardIcons'

const SavedModal = ({ savedModalOpen, setSavedModalOpen }) => {
    return (
        <TFAModal
            open={savedModalOpen}
            handleClose={() => setSavedModalOpen(false)}
            width='25em'
        >
            <TaskCompleted className={classes.modal_icon}/>
            <div className={classes.modal_text_box}>
                <TFAText 
                    secondary_title='Your Account Updated'
                    description='You account has been updated successfully'
                    style1={classes}
                />
            </div>
            <div className={classes.confirm_btn_box}>
                <TFAButton 
                    text='GREAT!'
                    onClick={() => setSavedModalOpen(false)}
                    style1={classes}
                />
            </div>
        </TFAModal>
    )
}

export default SavedModal