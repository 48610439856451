import React, { useEffect, useState } from 'react'
import classes from './Modal.module.scss'
import moment from 'moment'
import { TFAButton, TFAModal, TFASkeleton } from '../../../../../components/TFAComponents'
import { CloseBtn, EditIcon, TimeArrow } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useQuery } from '@apollo/client'
import { GET_AVAILABLE_TIME } from '../../../../../services/apiQuery'
import { IconButton } from '@mui/material'

const UpdateTimeModal = ({
  formik,
  isTimeModalOpen,
  setIsTimeModalOpen,
  setIsDateModalOpen,
}) => {
  const [ allAvailability, setAllAvailability ] = useState([])
  const [ initialTime, setInitialTime ] = useState('')
  const [ selectedTime, setSelectedTime ] = useState('')

  const formattedSelectedDay = moment(formik.values.selectedNewDate 
    ? formik.values.selectedNewDate
    : formik.values.timeStart
  ).format('DD/MM/YYYY')
  const startDay = moment(formik.values.timeStart).format('YYYY-MM-DD')
  const endDay = moment(formik.values.timeStart).add(1, 'day').format('YYYY-MM-DD')
  const timezone = moment.tz.guess()
  const diff = moment().tz(timezone).format('Z')
  const { loading: availableLoading, data: availableData } = useQuery(GET_AVAILABLE_TIME, {
    variables: {
      professionalId: formik.values.professional?.id,
      dayStart: `${startDay}T00:00:00${diff}`,
      dayEnd:   `${endDay}T00:00:00${diff}`,
    }
  })

  useEffect(() => {
    if (availableData) {
      setAllAvailability(availableData.GetAvailableTime)
      setInitialTime(formik.values.timeStart)
    }
  },[availableData])

  const editAppointmentDate = () => {
    setIsDateModalOpen(true)
    setIsTimeModalOpen(false)
  }

  const timeFormatter = (date, timing) => {
    const startTime = moment(date).format('hh:mm a')
    const endTime = moment(date).add(30, 'minutes').format('hh:mm a')
    switch (timing) {
        case 'start':   return startTime
        case 'end':     return endTime
        default:        break;
    }
  }

  const confirmAppointmentTime = () => {
    const { timeStart, selectedNewDate } = formik.values
    const applyNewDate = (selectedNewDate, startTime) => {
      const selectedDate = moment(selectedNewDate).format('YYYY-MM-DD')
      const time = moment(startTime).format()
      const timeStart = time.slice(10)
      const newDate = `${selectedDate}${timeStart}`
      return newDate
    }
    if (!selectedTime) {
      if (!selectedNewDate) {
        formik.setFieldValue(formik.values.modifiedDate = timeStart)
      } else {
        const newDate = applyNewDate(selectedNewDate, timeStart)
        formik.setFieldValue(formik.values.modifiedDate = newDate)
      }
    } else {
      if (!selectedNewDate) {
        formik.setFieldValue(formik.values.modifiedDate = selectedTime)
      } else {
        const newDate = applyNewDate(selectedNewDate, selectedTime)
        formik.setFieldValue(formik.values.modifiedDate = newDate)
      }
    }
    setIsTimeModalOpen(false)
  }

  return (
    <TFAModal
      open={isTimeModalOpen}
      handleClose={() => setIsTimeModalOpen(false)}
      width='30em'
      bg='#FFFFFF'
    >
      <div className={classes.modal_title_box}>
        <CloseBtn 
          className={classes.modal_title_box__icon}
          onClick={() => setIsTimeModalOpen(false)}
        />
        <p className={classes.modal_title_box__title}>
          Select Appointment Time
        </p>
      </div>
      <p className={classes.modal_desc_box}>
        Select a time that works for your appointment from the options below.
      </p>
      <div className={classes.editable_box}>
        <div className={classes.selected_date_box}>
          <p className={classes.selected_date_box__title}>
              Date Selected:
          </p>
          <p className={classes.selected_date_box__date}>
              {formattedSelectedDay}
          </p>
        </div>
        <div className={classes.edit_icon_box}>
          <IconButton
            className={classes.edit_icon_box__icon}
            onClick={editAppointmentDate}
          >
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.available_box}>
        {availableLoading
        ? <TFASkeleton type='appointment_time' count={5}/>
        : allAvailability.map((time, index) => (
            <div 
                className={initialTime === time
                ? classes.available_box__time_card_intial
                :  selectedTime === time
                  ?   classes.available_box__time_card_selected
                  :   classes.available_box__time_card
                }
                key={index}
                onClick={() => setSelectedTime(time)}
            >
                <p>{timeFormatter(time, 'start')}</p>
                <TimeArrow />
                <p>{timeFormatter(time, 'end')}</p>
            </div>
        ))
        }
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='CONFIRM'
          onClick={confirmAppointmentTime}
          style1={classes}
        />
      </div>
    </TFAModal>
  )
}

export default UpdateTimeModal