import React from 'react'
import classes from './CategoryState.module.scss'
import { Country, Category } from '../../../assets/icons/Dashboard/DashboardIcons' 
import { reverseCategoryConverter, reverseSpecialtyConverter } from '../../../utils/specialtyConverter'

const CategoryState = ({ destinationCountry, category}) => {

  return (
    <div className={classes.category_state_box}>
        <div className={classes.category_state_box__country_box}>
            <Country className={classes.category_state_box__icons}/>
            <p>{destinationCountry}</p>
        </div>
        <div className={classes.category_state_box__category_box}>
            <Category className={classes.category_state_box__icons}/>
            <p>{reverseCategoryConverter(category)}</p>
        </div>
    </div>
  )
}

export default CategoryState