import React, { useEffect, useState } from 'react'
import classes from './PAppointments.module.scss'
import { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import Appointments from './Sections/Appointments'
import Details from './Sections/Details'
import { useLocation } from 'react-router-dom'

const PAppointments = () => {
  const [ appointmentId, setAppointmentId ] = useState('')
  const location = useLocation()
  
  useEffect(() => {
    location.state && setAppointmentId(location.state)
  }, [location])

  return (
    <div className={classes.wrapper}>
        <Sidebar pageSide='professional' tabName='Appointments' />
        <div className={classes.main_content_box}>
          <Appointments
            appointmentId={appointmentId}
            setAppointmentId={setAppointmentId}
          />
          <Details 
            appointmentId={appointmentId}
          />
      </div>
    </div>
  )
}

export default PAppointments