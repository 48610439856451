import React, { useEffect, useState } from 'react'
import classes from '../Questions.module.scss'
import { DashboardTitle, DashboardSubTitle, QuestionCard } from '../../../../../components/Dashboard/DashboardComponents'
import { JobSearch, QuestionBox } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useNavigate } from 'react-router-dom'
import useClientContext from '../../../../../states/Client/useClientContext'
import { useQuery } from '@apollo/client'
import { GET_QUESTIONS, GET_CARD_DETAIL } from '../../../../../services/apiQuery'
import { TFASkeleton } from '../../../../../components/TFAComponents'

const MyQuestions = ({ questionId, setQuestionId }) => {
	const { client } = useClientContext().clientState
	const [activeQuestions, setActiveQuestions] = useState([])
	const [completedQuestions, setCompletedQuestions] = useState([])
	const navigate = useNavigate()
	const { loading: cardLoading, data: cardData } = useQuery(GET_CARD_DETAIL)

	const { loading: activeQuestionsLoading, data: activeQuestionsData } = useQuery(GET_QUESTIONS, {
		variables: {
			progress: ['Posted', 'Pick_Up', 'Working', 'Answered'],
			clientId: client?.id,
		},
	})
	const { loading: completedQuestionsLoading, data: completedQuestionsData } = useQuery(GET_QUESTIONS, {
		variables: {
			progress: ['Complete'],
			clientId: client?.id,
		},
	})

	const navigateToCreateQuestion = () => {
		if (cardLoading) return

		if (cardData.Stripe_ListAllCards.length === 0) navigate('/payment')
		else navigate('/create_question')
	}

	useEffect(() => {
		activeQuestionsData && setActiveQuestions(activeQuestionsData.GetQuestions.objectList)
		if (completedQuestionsData) {
			const allCompletedQuestions = completedQuestionsData.GetQuestions.objectList
			const sortedCompletedQuestions = allCompletedQuestions.slice().sort((a, b) => b.createdAt - a.createdAt)
			setCompletedQuestions(sortedCompletedQuestions)
		}
	}, [activeQuestionsData, completedQuestionsData])

	return (
		<div className={classes.questions_container}>
			<DashboardTitle title='My Questions' Icon={JobSearch} iconClick={() => navigateToCreateQuestion()} />
			{!activeQuestionsLoading && !completedQuestionsLoading && activeQuestions.length === 0 && completedQuestions.length === 0 && (
				<div className={classes.no_question_box}>
					<div className={classes.no_question_box__text}>
						<p className={classes.no_question_box__text__title}>No Questions Here Yet.</p>
						<p className={classes.no_question_box__text__desc}>
							Start your advice-seeking journey on Time For Advice by creating your first question.
							<br />
							<br />
							Create a question by tapping the Plus icon in the top right corner.
						</p>
					</div>
					<QuestionBox className={classes.question_box_icon} />
				</div>
			)}
			{(activeQuestions.length > 0 || completedQuestions.length > 0) && (
				<div className={classes.question_list_container}>
					<div className={classes.job_container__active_job_box}>
						<DashboardSubTitle title='Active:' margnTop={true} />
						{activeQuestionsLoading ? (
							<TFASkeleton type='question_card' count={2} />
						) : activeQuestions?.length === 0 ? (
							<div className={classes.empty_question_box}>No Active Jobs</div>
						) : (
							activeQuestions.map((question, index) => (
								<QuestionCard
									question={question}
									key={index}
									questionId={questionId}
									clickQuestionCard={() => setQuestionId(question.id)}
									tabName='My Questions'
								/>
							))
						)}
					</div>
					<div className={classes.job_container__completed_job_box}>
						<DashboardSubTitle title='Complete:' />
						{completedQuestionsLoading ? (
							<TFASkeleton type='question_card' count={2} />
						) : completedQuestions?.length === 0 ? (
							<div className={classes.empty_question_box}>No Active Jobs</div>
						) : (
							completedQuestions.map((question, index) => (
								<QuestionCard
									question={question}
									key={index}
									questionId={questionId}
									clickQuestionCard={() => setQuestionId(question.id)}
									tabName='My Questions'
								/>
							))
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default MyQuestions
