import React, { useEffect, useState } from 'react'
import classes from './Sections.module.scss'
import { DashboardTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { useQuery } from '@apollo/client'
import { GET_PAYMENTS } from '../../../../../services/apiQuery'
import useClientContext from '../../../../../states/Client/useClientContext'
import { TFASkeleton } from '../../../../../components/TFAComponents'
import moment from 'moment'

const TransactionHistory = () => {
  const { client } = useClientContext().clientState
  const [ allHistories, setAllHistories ] = useState([])
  const { loading: historyLoading, data: historyData } = useQuery(GET_PAYMENTS, {
    variables: { clientId: client?.id}
  })

  useEffect(() => {
    if (historyData) {
      const allHistoryData = historyData.GetPayments.objectList
      const sortedHistoryData = allHistoryData.slice().sort((a, b) => b.createdAt - a.createdAt)
      setAllHistories(sortedHistoryData)
    }
  },[historyData])

  const findTransactionTitle = (history) => {
    if (history.question)       return history.question.title
    if (history.appointment)    return history.appointment.title
    if (history.kit)            return history.kit.title
  }

  const createdDateConverter = (createdAt) => {
    const formattedDate = moment.unix(createdAt/1000).format('D MMM YYYY')
    return formattedDate
  }

  return (
    <div className={classes.section_container}>
      <DashboardTitle title='Transaction history'/>
      {historyLoading
      ? <TFASkeleton />
      : <div className={classes.transaction_box}>
          {allHistories.map((history, index) => (
          <div 
            className={classes.transaction_box__history_card}  
            key={index}
          >
            <div className={classes.history_top}>
              <div className={classes.history_top__price_box}>
                <p className={classes.history_top__price_box__title}>
                  Purchase
                </p>
                <p className={classes.history_top__price_box__price}>
                  &#36;{parseFloat(history.amount / 100).toFixed(2)}
                </p>
              </div>
              <div className={classes.history_top__category}>
                {history.question && 'Question'}
                {history.appointment && 'Appointment'}
                {history.kit && 'Kit'}
              </div>
            </div>
            <p className={classes.history_title}>
              {findTransactionTitle(history)}
            </p>
            <div className={classes.history_state_box}>
              <p className={classes.history_state_box__date}>
                {createdDateConverter(history.createdAt)}
              </p>
              {history.question &&
              <p className={classes.history_state_box__detail}>
                {history.question.professional?.firstName}&nbsp;
                {history.question.professional?.lastName}&nbsp;
              </p>
              }
              {history.appointment &&
              <p className={classes.history_state_box__detail}>
                {history.appointment.professional?.firstName}&nbsp;
                {history.appointment.professional?.lastName}&nbsp;
              </p>
              }
            </div>
          </div>
          ))}
        </div>
      }
    </div>
  )
}

export default TransactionHistory