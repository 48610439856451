import React, { useEffect, useState } from 'react'
import classes from '../WorkingArea.module.scss'
import { AnswerInput, DashboardTitle, ProfessionalQuestionLog } from '../../../../../components/Dashboard/DashboardComponents'
import { UploadFileClip } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import Modal from './Modal'
import { Chip } from '@mui/material'
import { useFormik } from 'formik'
import { AnswerQuestionValues } from '../../../../../assets/data/FormikInitialValues'
import { useMutation, useQuery } from '@apollo/client'
import {
	GET_QUESTION,
	GET_QUESTION_ASKED,
	GET_QUESTION_COUNT,
	GET_QUESTION_REPLYS,
	SINGLE_DOWNLOAD,
} from '../../../../../services/apiQuery'
import { CREATE_QUESTION_REPLY, QUESTION_UPDATE } from '../../../../../services/apiMutation'
import { TFASkeleton, TFASnackBar } from '../../../../../components/TFAComponents'
import DetailsProgress from '../../../../../components/Dashboard/DetailsProgress/DetailsProgress'
import DetailsProfile from '../../../../../components/Dashboard/DetailsProfile/DetailsProfile'
import DetailsQuestion from '../../../../../components/Dashboard/DetailsQuestion/DetailsQuestion'
import DetailsImage from '../../../../../components/Dashboard/DetailsImage/DetailsImage'
import DetailsFile from '../../../../../components/Dashboard/DetailsFile/DetailsFile'

const Details = ({ questionId }) => {
	const [avatar, setAvatar] = useState('')
	const [questionDetails, setQuestionDetails] = useState({})
	const [questionLogs, setQuestionLogs] = useState([])
	const [numberOfAsked, setNumberOfAsked] = useState(0)

	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const [isTemplateOpen, setIsTemplateOpen] = useState(false)
	const [isAnswerOpen, setIsAnswerOpen] = useState(false)
	const templateModalOpen = () => setIsTemplateOpen(true)
	const templateModalClose = () => setIsTemplateOpen(false)
	const answerModalOpen = () => setIsAnswerOpen(true)
	const answerModalClose = () => setIsAnswerOpen(false)

	const {
		loading: questionLoading,
		data: questionData,
		refetch: refetchQuestion,
	} = useQuery(GET_QUESTION, {
		variables: { getQuestionId: questionId },
	})
	const {
		loading: replyLoading,
		data: replyData,
		refetch: refetchReply,
	} = useQuery(GET_QUESTION_REPLYS, {
		variables: { questionId: questionDetails?.id },
	})
	const { loading: avatarLoading, data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
		variables: { filename: questionDetails?.client?.avatar },
	})
	const { loading: countLoading, data: countData } = useQuery(GET_QUESTION_COUNT, {
		variables: { clientId: questionDetails?.client?.id },
	})
	const [createQuestionReply] = useMutation(CREATE_QUESTION_REPLY)
	const [updateQuestion] = useMutation(QUESTION_UPDATE)

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: AnswerQuestionValues,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { QuestionreplyCreate } = await callCreateQuestionReply(values)
				if (!QuestionreplyCreate) {
					setIsSnackbarOpen(true)
					return
				}
				refetchQuestion()
				refetchReply()
				answerModalClose()
			} catch (error) {
				console.log(error)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		questionData && setQuestionDetails(questionData.GetQuestion)
		replyData && setQuestionLogs(replyData.GetQuestionreplys.objectList)
		avatarData && setAvatar(avatarData.SingleDownload)
		countData && setNumberOfAsked(countData.CountQuestions)
	}, [questionData, replyData, avatarData, countData])

	useEffect(() => {
		questionId && resetFormikForm(formik)
	}, [questionId])

	const callCreateQuestionReply = async (values) => {
		const stage = questionLogs.length === 0 ? 'professionalReplyOne' : 'professionalReplyTwo'
		const fileKeys = values.attachedFiles.map((file) => file.key)
		const { data } = await createQuestionReply({
			variables: {
				questionreply: {
					professionalId: questionDetails.professional.id,
					questionId: questionDetails.id,
					content: values.inputText,
					stage: stage,
				},
				questionReplyFileKeys: fileKeys,
			},
		})
		return data
	}

	const callUpdateQuestion = async (question) => {
		const { data } = await updateQuestion({
			variables: {
				question: {
					id: question?.id,
					progress: 'Answered',
				},
			},
		})
		return data
	}

	const deleteAttachedFile = (file) => {
		const newFiles = formik.values.attachedFiles.filter((attached) => attached.filename !== file.filename)
		formik.setFieldValue((formik.values.attachedFiles = newFiles))
	}

	const checkQuestionLogStage = (logs) => {
		const stages = logs.map((log) => log.stage)
		const lastStage = stages.pop()
		const result = lastStage !== 'professionalReplyOne' && lastStage !== 'professionalReplyTwo'
		return result
	}

	const resetFormikForm = (formik) => {
		formik.setFieldValue((formik.values.inputText = ''), (formik.values.attachedFiles = []), (formik.values.selectedTemplate = {}))
	}

	const changeReplyState = (length, progress, index) => {
		if (length - 1 !== index) return ''
		switch (progress) {
			case 'Answered':
				return 'Pending'
			case 'Complete':
				return 'Accepted'
			case 'Working':
				return 'Info Required'
			default:
				return
		}
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.details_container}>
			<DashboardTitle title='Details' />
			{Object.entries(questionDetails).length === 0 ? (
				<div className={classes.details_container__detail_box}>
					<div className={classes.details_container__detail_box__empty_txt}>Select a job to see details</div>
				</div>
			) : (
				<>
					<div className={classes.details}>
						{questionLoading && replyLoading && avatarLoading && countLoading ? (
							<TFASkeleton type='detail' />
						) : (
							<>
								<DetailsProgress questionDetails={questionDetails} style />
								<DetailsProfile numberOfAsked={numberOfAsked} questionDetails={questionDetails} avatar={avatar} page='professional' />
								<DetailsQuestion questionDetails={questionDetails} />
								{questionDetails.questionimages.length !== 0 && <DetailsImage questionDetails={questionDetails} />}
								<DetailsFile questionDetails={questionDetails} />
								{questionLogs.length !== 0 && (
									<>
										<p className={classes.log_title}>Question log:</p>
										<div className={classes.log_box}>
											{questionLogs.map((log, index) => (
												<ProfessionalQuestionLog
													log={log}
													progress={changeReplyState(questionLogs.length, questionDetails.progress, index)}
													client={questionDetails.client}
													key={index}
												/>
											))}
										</div>
									</>
								)}
								{checkQuestionLogStage(questionLogs) && (
									<div className={classes.answer_bg}>
										{formik.values.attachedFiles.length !== 0 && (
											<div className={classes.attached_file_box}>
												{formik.values.attachedFiles.map((file, index) => (
													<Chip
														label={file.filename}
														onDelete={() => deleteAttachedFile(file)}
														className={classes.attached_file_box__file_chip}
														key={index}
														icon={<UploadFileClip className={classes.attached_file_box__clip_icon} />}
													/>
												))}
											</div>
										)}
										<AnswerInput formik={formik} templateSubmit={templateModalOpen} answerSubmit={answerModalOpen} />
									</div>
								)}
							</>
						)}
					</div>
				</>
			)}
			<Modal
				formik={formik}
				isTemplateOpen={isTemplateOpen}
				isAnswerOpen={isAnswerOpen}
				templateModalClose={templateModalClose}
				answerModalClose={answerModalClose}
			/>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to send your answer to the client, Please try again.'
			/>
		</div>
	)
}

export default Details
