import React from 'react'
import classes from './TFAButton.module.scss'
import { CircularProgress } from '@mui/material'
import { TFACustomButton } from './MuiStyle'

export default function TFAButton(props) {
    return (
        <TFACustomButton 
            className={`${classes.tfa_button_container} ${props.style1?.tfa_button_container1 ?? ''} ${props.style2?.tfa_button_container2 ?? ''} ${props.style3?.tfa_button_container3 ?? ''} `}
            variant={props.variant ?? 'contained' }
            onClick={props.onClick}
            disabled={props.disabled ?? false}
        >
        {props.isLoading 
            ?   <CircularProgress 
                    className={props.disabled
                        ? classes.disabled_circular_progress
                        : classes.circular_progress}
                /> 
            :   props.text
        }
        </TFACustomButton>
    )  
}