import React, { useEffect, useState } from 'react'
import classes from './WorkingArea.module.scss'
import  { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import Questions from './Sections/Questions'
import Details from './Sections/Details'
import { useLocation } from 'react-router-dom'

const WorkingArea = () => {
  const [ questionId, setQuestionId ] = useState('')
  const location = useLocation()

  useEffect(() => {
    location.state && setQuestionId(location.state)
  },[location])

  return (
    <div className={classes.wrapper}>
      <Sidebar pageSide='professional' tabName='Working Area'/>
      <div className={classes.main_content_box}>
        <Questions 
          questionId={questionId}
          setQuestionId={setQuestionId} 
        />
        <Details questionId={questionId} />
      </div>
    </div>
  )
}

export default WorkingArea