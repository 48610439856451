import React, { useEffect, useState } from 'react'
import classes from './ClientQuestionLog.module.scss'
import moment from 'moment'
import useClientContext from '../../../states/Client/useClientContext'
import { QUESTION_LOG_STATE } from '../../../assets/data/SelectOptions'
import { InstallFile } from '../../../assets/icons/Dashboard/DashboardIcons'
import { downloadFile } from '../../../utils/downloadFiles'

const ClientQuestionLog = ({ log, length, progress, inputFieldOpen, moreQuestionOpen, acceptQuestionOpen }) => {
	const { client } = useClientContext().clientState
	const [createdTime, setCreatedTime] = useState('')

	useEffect(() => {
		if (log) {
			const createdAt = parseInt(log.createdAt)
			const result = moment(createdAt).format('k:mm D/MMM/YYYY')
			setCreatedTime(result)
		}
	}, [log])

	const getQuestionLogState = () => {
		if (log.stage === 'professionalReplyOne') {
			switch (progress) {
				case 'Working':
					return renderLogState(QUESTION_LOG_STATE.CLARIFICATION_NEEDED)
				case 'Answered': {
					if (length > 1) return renderLogState(QUESTION_LOG_STATE.CLARIFICATION_NEEDED)
					else return renderLogState(QUESTION_LOG_STATE.YOUR_ANSWER)
				}
				case 'Complete': {
					if (length > 1) return renderLogState(QUESTION_LOG_STATE.CLARIFICATION_NEEDED)
					else return renderLogState(QUESTION_LOG_STATE.ANSWER_ACCEPTED1)
				}
				default:
					return
			}
		}
		if (log.stage === 'professionalReplyTwo') {
			switch (progress) {
				case 'Answered':
					return renderLogState(QUESTION_LOG_STATE.REPLY_RECIEVED)
				case 'Complete':
					return renderLogState(QUESTION_LOG_STATE.ANSWER_ACCEPTED2)
				default:
					break
			}
		}
	}

	const stateColorGenerator = (state) => {
		switch (state) {
			case 'Accept Answer':
				return `${classes.state_green_btn}`
			case 'Reply':
				return `${classes.state_yellow_btn}`
			case 'More Questions?':
				return `${classes.state_yellow_btn}`
			case 'Answer Accepted':
				return `${classes.state_green}`
			case 'Clarification Needed':
				return `${classes.state_yellow}`
			case 'Question Flagged':
				return `${classes.state_red}`
			default:
				break
		}
	}

	const optionBtnAction = async (option) => {
		switch (option) {
			case 'Accept Answer':
				acceptQuestionOpen()
				break
			case 'Reply':
				inputFieldOpen()
				break
			case 'More Questions?':
				moreQuestionOpen()
				break
			default:
				break
		}
	}

	const renderLogState = (logState) => {
		return (
			<div className={classes.log_state_box}>
				<p className={classes.log_state_box__title}>{logState.title}</p>
				<p className={classes.log_state_box__body}>{logState.body}</p>
				{logState.options !== [] && (
					<div className={classes.log_state_box__option_box}>
						{logState.options.map((option) => (
							<div className={`${stateColorGenerator(option)}`} key={option} onClick={() => optionBtnAction(option)}>
								{option}
							</div>
						))}
					</div>
				)}
				{logState.state !== '' && (
					<div className={classes.log_state_box__option_box}>
						<div className={`${stateColorGenerator(logState.state)}`}>{logState.state}</div>
					</div>
				)}
			</div>
		)
	}

	return (
		<div className={classes.log_wrapper}>
			<div className={classes.log_wrapper__log_name}>
				<p className={classes.log_wrapper__log_name__user}>
					{log.stage === 'clientReplyOne'
						? `${client.firstName} ${client.lastName}`
						: `${log.professional?.firstName} ${log.professional?.lastName}`}
					<span> ・ </span>
					{log.stage === 'clientReplyOne' ? 'You' : 'Professional'}
				</p>
				<p className={classes.log_wrapper__log_name__time}>{createdTime}</p>
			</div>
			<div className={log.questionreplyfiles.length !== 0 ? classes.log_wrapper__with_file : classes.log_wrapper__without_file}>
				<p className={classes.content_text_style}>{log.content}</p>
			</div>
			{log.questionreplyfiles.length !== 0 && (
				<div className={classes.log_wrapper__log_file}>
					{log.questionreplyfiles.map((file, index) => (
						<div className={classes.log_wrapper__log_file__file} key={index}>
							<img src={file.link} alt='upload file' className={classes.log_wrapper__log_file__file__image} />
							<InstallFile className={classes.log_wrapper__log_file__file__install} onClick={() => downloadFile(file)} />
						</div>
					))}
				</div>
			)}
			{getQuestionLogState()}
		</div>
	)
}

export default ClientQuestionLog
