import React, { useEffect, useState } from 'react'
import classes from './MultiPages.module.scss'
import { CREATE_APPOINTMENT } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { ProfessionalCard, ProgressState, Searchbar } from '../../../../../components/Dashboard/DashboardComponents'
import { TFASkeleton } from '../../../../../components/TFAComponents'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PROFESSIONALS } from '../../../../../services/apiQuery'

const Professional = ({ formik, currentPage, setCurrentPage }) => {
  const [ searchText, setSearchText ] = useState('')
  const [ allProfessionals, setAllProfessionals ] = useState([])
  const navigate = useNavigate()
  const { loading: professionalsLoading, data: professionalsData } = useQuery(GET_PROFESSIONALS, {
    variables: { professionalCode: searchText }
  })

  useEffect(() => {
    professionalsData && setAllProfessionals(professionalsData.GetProfessionals.objectList)
  },[professionalsData])

  const selectProfessional = (professional) => {
    formik.setFieldValue(formik.values.professional = professional)
    setCurrentPage(CREATE_APPOINTMENT.TIME)
  }

  return (
    <>
      <div
        className={classes.backBtn_box}
        onClick={() => navigate('/c_dashboard/c_appointments')}
      >
        <LeftArrow className={classes.backBtn_box__arrow}/>
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.progress_box}>
        <p className={classes.progress_box__title}>
          Progress:
        </p>
        <div className={classes.progress_box__state}>
          <ProgressState 
            stateName='create_appointment'
            status={currentPage}
            variant='list'
            style={classes}
          />
        </div>
      </div>
      <div className={classes.search_box}>
        <p className={classes.search_box__title}>
          Find A Professional
        </p>
        <Searchbar 
          setSearchText={setSearchText} 
          bg={true}
        />
      </div>
      <div className={classes.professional_list_box}>
        {professionalsLoading
        ? <TFASkeleton 
            type='professionals'
            count='5'
          />
        : Object.entries(allProfessionals).length === 0
          ? <div className={classes.empty_professional_box}>
            No professional is available at this moment
          </div>
          : allProfessionals.map((professional, index) => (
            <div className={classes.professional_card_container} key={index}>
              <ProfessionalCard 
                professionalDetails={professional}
                clickProfessional={() => selectProfessional(professional)}
                clickedId={formik.values.professional.id}
                key={index}
              />
            </div>
          ))
        }
      </div>
    </>
  )
}

export default Professional