import React, { useState } from 'react'
import { TFAFormControl, TFATextInput } from './MuiStyle'
import { MdOutlineVisibilityOff, MdOutlineVisibility } from 'react-icons/md'
import Alert from '@mui/material/Alert'

export default function TFAInputText({ formik, name, ...props}) {
  const [showPassword, setShowPassword] = useState(false)

  const styles = {
    visibility: {
      position: 'absolute',
      top: props.error ? '22%' : '35%',
      right: '5%',
      transform: 'scale(1.5)',
      cursor: 'pointer',
    },
  }

  const typeSwitch = () => {
    if (props.type === 'password') return showPassword ? "text" : "password"
    else return props.type
  }

  return (
    <TFAFormControl 
      form_width={props.form_width} 
      sx={{margin: props.margin ?? '0.5em 0'}}
    >
      <TFATextInput
        sx={{ height: props.height }}
        required={props.required ?? true}
        label={props.label}
        type={typeSwitch()}
        name={name}
        placeholder={props.placeholder ?? ''}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name] ?? ''}
        InputProps={props.InputProps}
      >
        {props.children}
      </TFATextInput>
      {(name === 'password' || name === 'newPassword' || name === 'confirmNewPassword') && showPassword && <MdOutlineVisibility style={styles.visibility} onClick={() => setShowPassword(false)} />}
      {(name === 'password' || name === 'newPassword' || name === 'confirmNewPassword') && !showPassword && <MdOutlineVisibilityOff style={styles.visibility} onClick={() => setShowPassword(true)} />}
      {formik.touched[name] && props.error && 
        <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
          {props.error}
        </Alert>
      }
    </TFAFormControl>
  )
}