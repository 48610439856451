import React from 'react'
import classes from './Modals.module.scss'
import { TFAButton, TFAModal, TFAText } from '../../../../../components/TFAComponents'
import { UpdatePassword } from '../../../../../assets/icons/Dashboard/DashboardIcons'

const PasswordModal = ({ passwordModalOpen, setPasswordModalOpen }) => {
    return (
        <TFAModal
            open={passwordModalOpen}
            handleClose={() => setPasswordModalOpen(false)}
            width='25em'
        >
            <UpdatePassword className={classes.modal_icon}/>
            <div className={classes.modal_text_box}>
                <TFAText 
                    secondary_title='Password Updated'
                    description='Your password has been updated successfully.'
                    style1={classes}
                />
            </div>
            <div className={classes.confirm_btn_box}>
                <TFAButton 
                    text='GREAT!'
                    onClick={() => setPasswordModalOpen(false)}
                    style1={classes}
                />
            </div>
        </TFAModal>
    )
}

export default PasswordModal