import React, { ChangeEvent, SyntheticEvent, useState } from 'react'
// @ts-ignore
import classes from './MultiPages.module.scss'
import { Avatar, IconButton } from '@mui/material'
import { AddImg } from '../../../../../assets/icons/Auth/AuthIcons'
import {
	TFAButton,
	TFAInputText,
	TFASubText,
	TFAText,
	TFAPhoneInput,
	TFAAccordion,
	TFASnackBar,
} from '../../../../../components/TFAComponents'
import { TFAFormControlLabel, TFACheckbox } from '../../../../../components/Register/TimePicker/MuiStyle'

import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import SearchCountryInput from '../../../../../components/Register/SearchCountryInput/SearchCountryInput'
import { GET_PRESIGNED_UPLOAD_URL, GET_VISITOR_TOKEN } from '../../../../../services/apiQuery'
import { CLIENT_SIGNUP_STEP2 } from '../../../../../services/apiMutation'
import axios from 'axios'
import { TnCInfo } from '../../../../../assets/icons/Auth/AuthIcons'
import { useFormik } from 'formik'
import { clientSignupSchema } from '../../../../../utils/validation'
import mobileParser from '../../../../../utils/mobileParser'
import useClientContext from '../../../../../states/Client/useClientContext'
import { useNavigate } from 'react-router-dom'

type Props = {
	email: string
	code: string
}

function AboutYou({ email, code }: Props) {
	const { data } = useQuery(GET_VISITOR_TOKEN)
	const [getUploadURL] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)
	const [clientSignup] = useMutation(CLIENT_SIGNUP_STEP2)

	const [showPassword, setShowPassword] = useState(false)
	const [isTermsAgreed, setIsTermsAgreed] = useState(false)
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const navigate = useNavigate()
	const { clientDispatch } = useClientContext()

	const formik = useFormik({
		initialValues: {
			phoneCountry: {
				countryCode: 'au',
				dialCode: '61',
			},
			phoneNumber: '',
			firstName: '',
			lastName: '',
			countryOfResidence: '',
			image: '',
			avatar: '',
			password: '',
			confirmNewPassword: '',
		},
		validationSchema: clientSignupSchema,
		onSubmit: (values) => {
			signupUser()
		},
	})

	if (data) {
		localStorage.setItem('TFAToken', data.GetVisitorToken)
		localStorage.setItem('TFATokenType', 'VisitorToken')
	}

	const openTnC = () => {
		window.open(`https://www.timeforadvice.com/terms/`, '_blank')
	}

	const callGetUploadURL = async (file: File) => {
		const { data } = await getUploadURL({
			variables: {
				filename: file.name,
				folder: 'avatar',
			},
		})
		return data
	}

	const uploadAvatar = async ({ target }: ChangeEvent<HTMLInputElement>) => {
		if (!target.files || target.files?.length === 0) return
		const file = target.files[0]
		const uploadImageURL = URL.createObjectURL(file)
		try {
			const { GetPresignedUploadUrl } = await callGetUploadURL(file)
			if (!GetPresignedUploadUrl) return
			const url = GetPresignedUploadUrl.link
			const uploading = await axios.put(url, file, {
				headers: {
					'Content-Type': file.type,
				},
			})
			if (uploading.status !== 200) return
			formik.setFieldValue('avatar', GetPresignedUploadUrl.key)
			formik.setFieldValue('image', uploadImageURL)
		} catch (error) {
			console.log(error)
		}
	}

	const signupUser = async () => {
		const { values } = formik

		try {
			formik.setSubmitting(true)
			const { data } = await clientSignup({
				variables: {
					verifyCode: code,
					client: {
						firstName: values.firstName,
						lastName: values.lastName,
						email,
						mobile: mobileParser(values.phoneNumber, values.phoneCountry.dialCode ?? '61', values.phoneCountry.countryCode ?? 'AU'),
						avatar: values.avatar,
						countryOfOrigin: values.countryOfResidence,
						password: values.password,
					},
				},
			})

			if (!data.ClientSignupStep2) {
				setIsSnackbarOpen(true)
				return
			}
			localStorage.setItem('TFAToken', data.ClientSignupStep2.JWT)
			localStorage.setItem('TFATokenType', 'ClientToken')
			clientDispatch({ type: 'setClient', data: data.ClientSignupStep2 })
			setTimeout(() => {
				navigate('/languages')
			}, 200)
		} catch (error) {
			setIsSnackbarOpen(true)
		} finally {
			formik.setSubmitting(false)
		}
	}

	const handleSnackbarClose = (event: SyntheticEvent<Element, Event>, reason: string) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.content_box}>
			<TFAText primary_title='About You' secondary_title='Hey There! Let’s get you set up.' style1={classes} />
			<TFASubText description='Let’s get to know each other. We just need a few details from you to create you account.' style1={classes} />
			<TFAInputText label='FIRST NAME' type='text' name='firstName' formik={formik} error={formik.errors.firstName ?? null} />
			<TFAInputText label='LAST NAME' type='text' name='lastName' formik={formik} error={formik.errors.lastName ?? null} />
			<TFAPhoneInput name='phoneNumber' bg={null} formik={formik} error={formik.errors.phoneNumber} prefix={''} />
			<div style={{ marginBottom: '.5rem' }} />
			<SearchCountryInput
				name='countryOfResidence'
				placeholder='COUNTRY OF RESIDENCE *'
				formik={formik}
				error={formik.errors.countryOfResidence ?? null}
			/>
			<TFASubText primary_title='Profile Picture' description='Personalise your account with a profile picture.' style1={classes} />
			<div className={classes.avatar_box}>
				<Avatar
					src={formik?.values?.image}
					alt='client avatar'
					className={classes.avatar_box__avatar}
					sx={{ width: 220, height: 220, bgcolor: '#2F68FA' }}
				/>
				<IconButton sx={{ background: '#2F68FA', padding: '0.5em' }} className={classes.avatar_box__camera} name='image'>
					<input type='file' onChange={uploadAvatar} className={classes.avatar_box__camera__avatar_input} accept={'image/*'} />
					<AddImg />
				</IconButton>
			</div>

			<div>
				<TFASubText
					primary_title='Password'
					description='Create a strong password that will protect your Time For Advice account.'
					style1={classes}
				/>
				<TFAInputText
					label='PASSWORD'
					type='password'
					name='password'
					form_width='100%'
					showPassword={showPassword}
					visiblePassword={setShowPassword}
					formik={formik}
					error={formik?.errors?.password ?? null}
				/>
				<TFAInputText
					label='CONFIRM PASSWORD'
					type='password'
					name='confirmNewPassword'
					form_width='100%'
					showPassword={showPassword}
					visiblePassword={setShowPassword}
					formik={formik}
					error={formik?.errors?.confirmNewPassword ?? null}
				/>
				<div className={classes.passward_suggestion_box}>
					<TFAAccordion title='Our Password Advice'>
						<ul className={classes.bullet_points}>
							<li>Minimum length of 8 characters.</li>
							<li>At least 1 number.</li>
							<li>At least 1 uppercase and lowercase letter.</li>
						</ul>
					</TFAAccordion>
				</div>
				<div className={classes.tnc_box}>
					<TFAFormControlLabel
						label="I AGREE TO THE T&amp;C'S"
						className={classes.title_checkbox}
						control={<TFACheckbox onChange={() => setIsTermsAgreed(!isTermsAgreed)} />}
					/>
					<TnCInfo className={classes.tnc_icon} onClick={openTnC} />
				</div>
			</div>

			<div className={classes.btn_box}>
				<TFAButton text='NEXT' onClick={formik.handleSubmit} style1={classes} disabled={!isTermsAgreed} />
			</div>

			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to signup, Please try again later.'
			/>
		</div>
	)
}

export default AboutYou
