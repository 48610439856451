import React, { useEffect, useState } from 'react'
import classes from './RetrieveClientAccount.module.scss'
import { TFALink, TFAText, TFAInputText, TFAButton, TFASnackBar } from '../../../../components/TFAComponents'
import { RetrievePasswordValues } from '../../../../assets/data/FormikInitialValues'
import { retrievePwdSchema } from '../../../../utils/validation'
import { TFALogo } from '../../../../assets/icons/Auth/AuthIcons'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import TwoFactorVerificationForm from '../../../../components/Register/TwoFactorVerificationForm/TwoFactorVerificationForm'
import { useMutation, useQuery } from '@apollo/client'
import { CLIENT_RESET_PASSWORD_STEP1, CLIENT_RESET_PASSWORD_STEP2, UPDATE_CLIENT_PROFILE } from '../../../../services/apiMutation'
import TFAPhoneInput from '../../../../components/Register/TFAPhoneInput/TFAPhoneInput'
import { GET_VISITOR_TOKEN } from '../../../../services/apiQuery'

const RetrieveClientAccount = () => {
	const [mobileLoading, setIsEmailLoading] = useState(false)
	const [verifyLoading, setVerifyLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [authCode, setAuthCode] = useState('')
	const [isSubmit, setIsSubmit] = useState({
		isEmail: false,
		isAuthCode: false,
		isPassword: false,
	})
	const navigate = useNavigate()
	const { data: visitorData } = useQuery(GET_VISITOR_TOKEN)
	const [ClientResetPassword1] = useMutation(CLIENT_RESET_PASSWORD_STEP1)
	const [ClientResetPassword2] = useMutation(CLIENT_RESET_PASSWORD_STEP2)
	const [updateClientPassword] = useMutation(UPDATE_CLIENT_PROFILE)
	const [isMobileSnackbarOpen, setIsMobileSnackbarOpen] = useState(false)
	const [isCodeSnackbarOpen, setIsCodeSnackbarOpen] = useState(false)
	const [isUpdateSnackbarOpen, setIsUpdateSnackbarOpen] = useState(false)
	const formik = useFormik({
		initialValues: RetrievePasswordValues,
		validationSchema: retrievePwdSchema,
	})

	useEffect(() => {
		if (visitorData) {
			localStorage.setItem('TFAToken', visitorData.GetVisitorToken)
			localStorage.setItem('TFATokenType', 'VisitorToken')
		}
	}, [visitorData])

	const sendVerificationCode = async () => {
		try {
			setIsEmailLoading(true)
			const { data } = await ClientResetPassword1({
				variables: { email: formik.values.email },
			})
			if (data.ClientResetPasswordStep1 !== 'success') return
			setIsSubmit({ ...isSubmit, isEmail: true })
		} catch (error) {
			console.log({ error })
			setIsMobileSnackbarOpen(true)
		} finally {
			setIsEmailLoading(false)
		}
	}

	const verifyCode = async () => {
		try {
			setVerifyLoading(true)
			const { data } = await ClientResetPassword2({
				variables: {
					email: formik.values.email,
					verifyCode: authCode,
				},
			})
			if (!data.ClientResetPasswordStep2) return
			formik.setFieldValue((formik.values.id = data.ClientResetPasswordStep2.id))
			localStorage.setItem('TFAToken', data.ClientResetPasswordStep2.JWT)
			localStorage.setItem('TFATokenType', 'ClientToken')
			setIsSubmit({ ...isSubmit, isAuthCode: true })
		} catch (error) {
			setIsCodeSnackbarOpen(true)
		} finally {
			setVerifyLoading(false)
		}
	}

	const handleSubmit = async () => {
		try {
			formik.setSubmitting(true)
			const { ClientUpdate } = await callUpdateClientPassword(formik.values)
			if (!ClientUpdate) return
			navigate('/login')
		} catch (error) {
			setIsUpdateSnackbarOpen(true)
		} finally {
			formik.setSubmitting(false)
		}
	}

	const callUpdateClientPassword = async (values) => {
		const { data } = await updateClientPassword({
			variables: {
				client: {
					id: values.id,
					password: values.newPassword,
				},
			},
		})
		return data
	}

	const switchPageBack = () => {
		!isSubmit.isEmail && navigate('/login')
		isSubmit.isEmail && !isSubmit.isAuthCode && setIsSubmit({ ...isSubmit, isEmail: false })
		isSubmit.isEmail && isSubmit.isAuthCode && setIsSubmit({ ...isSubmit, isAuthCode: false })
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsMobileSnackbarOpen(false)
		setIsCodeSnackbarOpen(false)
		setIsUpdateSnackbarOpen(false)
	}

	return (
		<div className={classes.wrapper}>
			<TFALogo className={classes.logo} />
			<div className={classes.wrapper__main_container}>
				<TFALink
					text='RETURN'
					backward={true}
					to={!isSubmit.isEmail ? 'auth' : 'retrieve_client_account'}
					onClick={switchPageBack}
					style1={classes}
				/>
				<div className={classes.wrapper__main_container__tfa_text}>
					{!isSubmit.isEmail && <TFAText primary_title='Retrieve Account' secondary_title='Let’s get you back in action!' style1={classes} />}
					{isSubmit.isEmail && !isSubmit.isAuthCode && <TFAText primary_title='Retrieve Account' secondary_title='Your code' style1={classes} />}
					{isSubmit.isAuthCode && !isSubmit.isPassword && (
						<TFAText primary_title='Retrieve Account' secondary_title='Create New Password' style1={classes} />
					)}
				</div>
				<div className={classes.text_wrapper}>
					<div className={classes.text_wrapper__title}>
						{!isSubmit.isEmail && 'Account Email'}
						{isSubmit.isEmail && !isSubmit.isAuthCode && 'Verification Code'}
						{isSubmit.isAuthCode && !isSubmit.isPassword && 'New Password'}
					</div>
					<div className={classes.text_wrapper__description}>
						{!isSubmit.isEmail &&
							'Enter the email address associated with your account. If this email address exists on our platform you will receive a 6 digital code in your inbox.'}
						{isSubmit.isEmail && !isSubmit.isAuthCode && `We sent a message to ${formik.values.email} enter the 6 digit code below.`}
						{isSubmit.isAuthCode && !isSubmit.isPassword && 'Create a strong password that will protect your Time For Advice account.'}
					</div>
				</div>
				{!isSubmit.isEmail && (
					<TFAInputText
						label='EMAIL'
						type='email'
						name='email'
						placeholder='sample@timeforadvice.com'
						formik={formik}
						error={formik.errors.email ?? null}
					/>
				)}
				{isSubmit.isEmail && !isSubmit.isAuthCode && (
					<TwoFactorVerificationForm authCode={authCode} onChangeText={setAuthCode} verifyCode={verifyCode} />
				)}
				{isSubmit.isAuthCode && !isSubmit.isPassword && (
					<div>
						<TFAInputText
							label='NEW PASSWORD'
							type='password'
							name='newPassword'
							placeholder='Enter New Password'
							width='30em'
							showPassword={showPassword}
							visiblePassword={setShowPassword}
							formik={formik}
							error={formik.errors.newPassword ?? null}
						/>
						<TFAInputText
							label='CONFIRM NEW PASSWORD'
							type='password'
							name='confirmNewPassword'
							placeholder='Enter Confirm New Password'
							width='30em'
							showPassword={showPassword}
							visiblePassword={setShowPassword}
							formik={formik}
							error={formik.errors.confirmNewPassword ?? null}
						/>
					</div>
				)}

				<TFAButton
					text='NEXT'
					onClick={
						(!isSubmit.isEmail && sendVerificationCode) ||
						(isSubmit.isEmail && !isSubmit.isAuthCode && verifyCode) ||
						(isSubmit.isEmail && isSubmit.isAuthCode && !isSubmit.isPassword && handleSubmit)
					}
					isLoading={mobileLoading || verifyLoading || formik.isSubmitting}
					disabled={mobileLoading || verifyLoading || formik.isSubmitting}
					style1={classes}
				/>
			</div>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isMobileSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='This phone number does not exist. Please try again.'
			/>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isCodeSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='This verifacation code is not valid. Please try again.'
			/>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isUpdateSnackbarOpen}
				handleSnackbarClose={setIsUpdateSnackbarOpen}
				message='Failed to update new password. Please try again.'
			/>
		</div>
	)
}

export default RetrieveClientAccount
