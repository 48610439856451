import React from 'react'
import classes from './TFALink.module.scss'
import { Link } from 'react-router-dom'
import { LeftArrow, RightArrow, DoubleArrowRight } from '../../assets/icons/Auth/AuthIcons'

export default function TFALinkExternal(props) {
	return (
		<div>
			<a
				href={props.to}
				onClick={props.onClick}
				className={`${props?.style1?.link_container1 ?? ''} ${props?.style2?.link_container2 ?? ''} ${classes.link_container}`}
			>
				{props.backward ? <LeftArrow className={`${props?.style1?.backward1 ?? ''} ${classes.backward}`} /> : null}
				{props.text}
				{props.forward ? <DoubleArrowRight className={`${props?.style1?.forward1 ?? ''} ${classes.forward}`} /> : null}
				{props.aiforward ? <RightArrow className={`${props?.style1?.aiforward1 ?? ''} ${classes.backward}`} /> : null}
			</a>
		</div>
	)
}
