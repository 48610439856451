import React, { useState } from 'react'
import classes from './ProSignup.module.scss'
import { TFALogo, CompleteUpload } from '../../../../assets/icons/Auth/AuthIcons'
import { specialties } from '../../../../assets/data/SelectOptions'
import { useFormik } from 'formik'
import { proSignupSchema } from '../../../../utils/validation'
import { specialtyConverter } from '../../../../utils/specialtyConverter'
import { GET_VISITOR_TOKEN, GET_PRESIGNED_UPLOAD_URL } from '../../../../services/apiQuery'
import { PROFESSIONAL_SIGNUP_STEP1 } from '../../../../services/apiMutation'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import UploadFileInput from '../../../../components/Register/UploadFileInput/UploadFileInput'
import { useNavigate } from 'react-router-dom'
import {
	TFALink,
	TFAText,
	TFAInputText,
	TFASubText,
	TFASelectBox,
	TFAButton,
	TFAModal,
	TFASnackBar,
	TFATabs,
} from '../../../../components/TFAComponents'
import TFAPhoneInput from '../../../../components/Register/TFAPhoneInput/TFAPhoneInput'
import { InitialProSignupValues } from '../../../../assets/data/FormikInitialValues'
import TFADateInputField from '../../../../components/TFADateInputField/TFADateInputField'
import UploadIdentityInput from '../../../../components/Register/UploadIdentityInput/UploadIdentityInput'
import mobileParser from '../../../../utils/mobileParser'
import dateParser from '../../../../utils/dateParser'

const ProSignup = () => {
	const [tabName, setTabName] = useState('MARA Number')
	const [isSignupError, setIsSignupError] = useState(false)
	const [isEmailError, setIsEmailError] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const handleModalOpen = () => setIsModalOpen(true)
	const handleModalClose = () => setIsModalOpen(false)
	const navigate = useNavigate()

	const { data } = useQuery(GET_VISITOR_TOKEN)
	const [getUploadURL] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)
	const [signupMutation] = useMutation(PROFESSIONAL_SIGNUP_STEP1)

	const formik = useFormik({
		initialValues: InitialProSignupValues,
		validationSchema: proSignupSchema,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { ProfessionalSignupStep1 } = await callSignupMutation(values)
				if (!ProfessionalSignupStep1) return
				if (ProfessionalSignupStep1 === 'emailIsRegistered') {
					setIsEmailError(true)
					return
				}
				handleModalOpen()
			} catch (error) {
				setIsSignupError(true)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	// Step1: When this component is mounted, it generates visitor token
	if (data) {
		localStorage.setItem('TFAToken', data.GetVisitorToken)
		localStorage.setItem('TFATokenType', 'VisitorToken')
	}

	// Step2: Upload files with temp token
	const callUploadFileQuery = async (file, type) => {
		const result = await getUploadURL({
			variables: {
				filename: file.name,
				folder: type === 'MARA' ? 'MARA_document' : 'prof_supportingdocument',
			},
		})
		return result
	}

	// Step3: Submit after files are all uploaded
	const callSignupMutation = async (values) => {
		const specialties = values.specialty.map((s) => specialtyConverter(s))
		const { data } = await signupMutation({
			variables: {
				professional: {
					firstName: values.firstName,
					lastName: values.lastName,
					businessProfile: values.website ?? 'https://www.timeforadvice.com',
					email: values.email,
					mobile: mobileParser(values.phoneNumber, values.phoneCountry.dialCode ?? '61', values.phoneCountry.countryCode ?? 'AU'),
					dayOfBirth: values.day,
					monthOfBirth: values.month,
					yearOfBirth: values.year,
					MARANumber: values.MARANumber,
					MARAexpiryDate: dateParser(values.expiryYear, values.expiryMonth, values.expiryDay),
					LPNNumber: values.LPNNumber,
					lpn_expiry_date: dateParser(values.lpnExpiryYear, values.lpnExpiryMonth, values.lpnExpiryDay),
					frontId: values.frontId,
					backId: values.backId,
				},
				documents: values.files,
				maraDocuments: values.MARAFiles,
				lpnDocuments: values.LPNFiles,
				professionalspecialties: specialties,
			},
		})
		return data
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSignupError(false)
		setIsEmailError(false)
	}

	return (
		<div className={classes.wrapper}>
			<TFALogo className={classes.logo} />
			<div className={classes.wrapper__main_container}>
				<TFALink to='p_login' backward={true} text='RETURN' style1={classes} />
				<TFAText
					secondary_title='Looking to become a professional?'
					description='Let’s get to know each other. We just need a few details to process your application.'
					style1={classes}
				/>
				<TFAInputText label='FIRST NAME' type='text' name='firstName' formik={formik} error={formik.errors.firstName ?? null} />
				<TFAInputText label='LAST NAME' type='text' name='lastName' formik={formik} error={formik.errors.lastName ?? null} />
				<TFAInputText
					label='WEBSITE'
					type='text'
					name='website'
					placeholder='https://www.timeforadvice.com/'
					formik={formik}
					error={formik.errors.website ?? null}
				/>
				<TFAInputText
					label='EMAIL'
					type='email'
					name='email'
					placeholder='sample@timeforadvice.com'
					formik={formik}
					error={formik.errors.email ?? null}
				/>
				<TFAPhoneInput
					name='phoneNumber'
					formik={formik}
					error={formik.errors.phoneNumber ?? null}
					handleBlur={formik.handleBlur}
					prefix={''}
				/>
				<TFASubText primary_title='Date Of Birth' description='Enter your date of birth.' style1={classes} />
				<TFADateInputField type='dob' formik={formik} required={true} />
				<TFASubText
					primary_title='What Is Your Specialty?'
					description='Select the migration specialty that you are most rehearsed in.'
					style1={classes}
				/>
				<TFASelectBox
					selectArray={specialties}
					label='SPECIALTY'
					name='specialty'
					formik={formik}
					error={formik.errors.specialty ?? null}
					multiple={true}
				/>
				<TFATabs
					tabName={tabName}
					setTabName={setTabName}
					isMARAEmpty={formik.values.MARANumber === '' ? true : false}
					isLPNEmpty={formik.values.LPNNumber === '' ? true : false}
				>
					{tabName === 'MARA Number' && (
						<>
							<TFASubText
								primary_title='MARA Number'
								description={
									<p>
										Enter your <b>MARA Number</b> below.
									</p>
								}
								style1={classes}
							/>
							<TFAInputText
								label='MARA NUMBER'
								type='text'
								name='MARANumber'
								placeholder='1234567'
								required={false}
								formik={formik}
								error={formik.errors.MARANumber ?? null}
							/>
							<p className={classes.mara_title_vontainer}>
								Enter your <b>MARA Expiry</b> date or <b>Practising Certificate Expiry</b> date.
							</p>
							<TFADateInputField type='marn_expiry' formik={formik} required={false} />
							<p className={classes.mara_title_vontainer}>
								Attach your <b>Practising Certificate</b> or <b>MARA Registration and PI Insurance Certificate</b>
							</p>
							<UploadFileInput
								formik={formik}
								callUploadFileQuery={callUploadFileQuery}
								files={'MARAFiles'}
								uploadedFiles={'MARAUploadedFiles'}
								type={'MARA'}
								error={formik.errors.MARAFiles ?? null}
							/>
						</>
					)}
					{tabName === 'LPN Number' && (
						<>
							<TFASubText
								primary_title='LPN Number'
								description={
									<p>
										Enter your <b>LPN Number</b> below.
									</p>
								}
								style1={classes}
							/>
							<TFAInputText
								label='LPN NUMBER'
								type='text'
								name='LPNNumber'
								placeholder='1234567'
								required={false}
								formik={formik}
								error={formik.errors.LPNNumber ?? null}
							/>
							<p className={classes.mara_title_vontainer}>
								Enter your <b>LPN Expiry</b> date or <b>Practising Certificate Expiry</b> date.
							</p>
							<TFADateInputField type='lpn_expiry' formik={formik} required={false} />
							<p className={classes.mara_title_vontainer}>
								Attach your <b>Practising Certificate</b>
							</p>
							<UploadFileInput
								formik={formik}
								callUploadFileQuery={callUploadFileQuery}
								files={'LPNFiles'}
								uploadedFiles={'LPNUploadedFiles'}
								type={'MARA'}
								error={formik.errors.LPNFiles ?? null}
							/>
						</>
					)}
				</TFATabs>
				<TFASubText
					primary_title='Identity Documents'
					description='Attach a front and back image of a valid identity type (e.g. driver’s licence or passport) (accept pdf / jpg / png).'
					style1={classes}
				/>
				<UploadIdentityInput formik={formik} frontError={formik.errors.frontId ?? null} backError={formik.errors.backId ?? null} />
				<TFASubText
					primary_title='Supporting Documents'
					description='Attach any supporting documents that relate to your profession (certificates &amp; degrees).'
					style1={classes}
				/>
				<UploadFileInput
					formik={formik}
					callUploadFileQuery={callUploadFileQuery}
					files={'files'}
					uploadedFiles={'uploadedFiles'}
					type={'support'}
					error={formik.errors.files ?? null}
				/>
				<TFAButton
					text='APPLY AS A PROFESSIONAL'
					onClick={formik.handleSubmit}
					isLoading={formik.isSubmitting}
					disabled={formik.isSubmitting}
					style1={classes}
				/>
			</div>
			<TFAModal open={isModalOpen} handleClose={handleModalClose} width='25em'>
				<CompleteUpload className={classes.modal_icon} />
				<div className={classes.modal_text_box}>
					<TFAText secondary_title='Completed Signup' style1={classes} />
				</div>
				<div className={classes.confirm_btn_box}>
					<TFAButton text='DONE' onClick={() => navigate('/top')} style1={classes} />
				</div>
			</TFAModal>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSignupError}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to signup. Please try again.'
			/>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isEmailError}
				handleSnackbarClose={handleSnackbarClose}
				message='This email is already in use'
			/>
		</div>
	)
}

export default ProSignup
