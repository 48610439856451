import React, { useState } from 'react'
import classes from './Sidebar.module.scss'
import { Avatar, Badge, Drawer, List } from '@mui/material'
import { 
  SidebarItem, 
  SidebarItemIcon, 
  SidebarItemText,
  MinSidebarItem,
  MinSidebarItemIcon,
} from './MuiStyle'
import { Notification, TFA_Traditional } from '../../../assets/icons/Dashboard/DashboardIcons'
import { MenuIcon } from '../../../assets/icons/Dashboard/DashboardIcons'
import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { CLIENT_SIDEBAR_LIST, PRO_SIDEBAR_LIST } from '../../../assets/data/SidebarOptions'
import useProfessionalContext from '../../../states/Professional/useProfessionalContext'
import useClientContext from '../../../states/Client/useClientContext'
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer'

const Sidebar = ({ pageSide, tabName }) => {
  const { professional } = useProfessionalContext().professionalState
  const { client } = useClientContext().clientState
  const [ isNotificationOpen, setIsNotificationOpen ] = useState(false)
  const [ isNewNotifications, setIsNewNotifications ] = useState(0)
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)

  const renderAvatar = (avatar) => {
    if (avatar === 'false') return null
    else return avatar
  }

  const sideBarMenu = () => {
    return (
      <div className={classes.sidebar_box_width}>
        <div className={classes.logo_box}>
          <Link to='/top'>
            <img 
              src={TFA_Traditional} 
              alt='tfa-traditional' 
              className={classes.logo} 
            />
          </Link>

          {isNewNotifications === 0
          ?
          <Notification 
            className={classes.notification_log} 
            onClick={() => setIsNotificationOpen(true)}
          />
          :
          <Badge 
            className={classes.notification_badge}
            color="secondary"
            badgeContent=''
            variant="dot"
            overlap='rectangular'
          >
            <Notification 
              className={classes.notification_badge_box} 
              onClick={() => setIsNotificationOpen(true)}
            />
          </Badge>
          }

          <NotificationDrawer 
            isNotificationOpen={isNotificationOpen}
            setIsNotificationOpen={setIsNotificationOpen}
            setIsNewNotifications={setIsNewNotifications}
          />
        </div>
        <div className={classes.user_box}>
          <Avatar 
            alt='your-avatar' 
            src={professional
              ? renderAvatar(professional?.avatarLink)
              : renderAvatar(client?.avatarLink)
            }
            className={classes.avator} 
            sx={{width: '2.5em', height: '2.5em'}}
          />
          <div className={classes.user_name_box}>
            <div>
              <span className={classes.user_name_box__avatar_name}>
                {professional?.firstName ?? client?.firstName}&nbsp;
                {professional?.lastName ?? client?.lastName}
              </span> 
              {professional &&
              <>
                &nbsp;-&nbsp;
                <span className={classes.user_name_box__avatar_number}>
                  {professional.professionalCode}
                </span>
              </>
              }
            </div>
            <div className={classes.user_name_box__avatar_title}>
              {professional
              ? 
                <p>
                  {professional.professionalspecialties?.length}&nbsp;
                  {professional.professionalspecialties?.length > 1 
                  ? 'specialties'
                  : 'speciality'
                  }
                </p>
              : 'Personal Account'
              }
            </div>
          </div>
        </div>
        <div className={classes.menu_tab_box}>
          <List>
            {(pageSide === 'professional' 
            ? PRO_SIDEBAR_LIST 
            : CLIENT_SIDEBAR_LIST).map((listItem, index) => (
              <Link to={listItem.link} key={index} >
                <SidebarItem 
                  button 
                  selected={listItem.listText === tabName ?? false}
                >
                  <SidebarItemIcon>
                    {listItem.listIcon}
                  </SidebarItemIcon>
                  <SidebarItemText>
                    {listItem.listText}
                  </SidebarItemText>
                </SidebarItem>
              </Link>
            ))}
          </List>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.max_width}>
        {sideBarMenu()}
      </div>
      <div className={classes.min_width}>
        <div className={classes.min_logo_box}>
          <Notification 
            className={classes.min_logo_box__min_notification_log} 
            onClick={() => setIsNotificationOpen(true)}
          />
          <NotificationDrawer 
            isNotificationOpen={isNotificationOpen}
            setIsNotificationOpen={setIsNotificationOpen}
          />
        </div>
        <div className={classes.min_user_box}>
          <Avatar 
            alt='your-avatar' 
            src={professional?.avatarLink ?? client?.avatarLink}
            className={classes.min_user_box__min_avator} 
            sx={{width: '2.5em', height: '2.5em'}}
          />
        </div>
        <div className={classes.menu_tab_box}>
          <List>
            {(pageSide === 'professional' 
            ? PRO_SIDEBAR_LIST 
            : CLIENT_SIDEBAR_LIST).map((listItem, index) => (
              <Link to={listItem.link} key={index} >
                <MinSidebarItem 
                  button 
                  selected={listItem.listText === tabName ?? false}
                >
                  <MinSidebarItemIcon>
                    {listItem.listIcon}
                  </MinSidebarItemIcon>
                </MinSidebarItem>
              </Link>
            ))}
          </List>
        </div>
      </div>
      <div className={classes.min_width}>
        <IconButton onClick={() => setIsDrawerOpen(true)}>
          <MenuIcon />
        </IconButton>
        <Drawer
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          {sideBarMenu()}
        </Drawer>
      </div>
    </div>
  )
}

export default Sidebar