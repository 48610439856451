import moment from 'moment'

const finishTimeConverter = (finishTime) => {
    const endTime = moment.parseZone(finishTime).local().format()
    return endTime
}

const remainingTimeConverter = (finishTime) => {
    const endTime = finishTimeConverter(finishTime)
    const now = moment().local().format()
    const finish = moment(endTime)
    const seconds = finish.diff(now, 'seconds')
    const formattedHours = moment.utc(seconds*1000).format('HH:mm')
    return formattedHours
}

const expiryDateConverter = (finishTime) => {
    const endTime = finishTimeConverter(finishTime)
    const formattedDate = moment(endTime).format('DD MMMM YYYY')
    return formattedDate
}

const appointmentTimeConverter = (startTime, finishTime) => {
    const start = moment.parseZone(startTime).local().format('hh:mm')
    const end = moment.parseZone(finishTime).local().format('LT')
    const date = moment.parseZone(startTime).local().format('DD-MMM')
    return `${date} ${start} - ${end}`
}

const appointmentDateFormatter = (finishTime) => {
    const appointmentDate = moment.parseZone(finishTime).local().format('DD/MM/YYYY')
    return appointmentDate
}

const completeQuestionTimeConverter = () => {
    const now = moment().local().format('DD MMMM YYYY')
    return now
}

export {
    remainingTimeConverter,
    expiryDateConverter,
    appointmentTimeConverter,
    appointmentDateFormatter,
    completeQuestionTimeConverter,
}