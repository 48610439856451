import React, { useEffect, useState } from 'react'
import classes from '../WorkingArea.module.scss'
import {
    DashboardTitle,
    Searchbar,
    DashboardSubTitle,
    QuestionCard,
} from '../../../../../components/Dashboard/DashboardComponents'
import { 
    ActiveJobs, 
    CompletedJobs 
} from '../../../../../assets/icons/Dashboard/DashboardIcons'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'
import { useMutation, useQuery } from '@apollo/client'
import { QUESTION_UPDATE } from '../../../../../services/apiMutation'
import { GET_QUESTIONS } from '../../../../../services/apiQuery'
import { TFASkeleton } from '../../../../../components/TFAComponents'

const Questions = ({ questionId, setQuestionId }) => {
  const { professional } = useProfessionalContext().professionalState
  const [ searchText, setSearchText ] = useState('')
  const [ activeQuestions, setActiveQuestions ] = useState([])
  const [ completedQuestions, setCompletedQuestions ] = useState([])
  const [ questionUpdate ] = useMutation(QUESTION_UPDATE)
  const { loading: activeJobLoading, data: activeQuestionData } = useQuery(GET_QUESTIONS, {
    variables: {
      progress: ['Posted', 'Pick_Up', 'Working', 'Answered'],
      professionalId: professional?.id,
      title: searchText,
    }
  })
  const { loading: completedJobLoading, data: completedQuestionData } = useQuery(GET_QUESTIONS, {
    variables: {
      progress: ['Complete'],
      professionalId: professional?.id,
      title: searchText,
    }
  })

  useEffect(() => {
    activeQuestionData && setActiveQuestions(activeQuestionData.GetQuestions.objectList)
    if (completedQuestionData) {
      const allCompletedQuestions = completedQuestionData.GetQuestions.objectList
      const sortedCompletedQuestions = allCompletedQuestions.slice().sort((a, b) => b.createdAt - a.createdAt)
      setCompletedQuestions(sortedCompletedQuestions)
    }
  },[activeQuestionData, completedQuestionData])
    
  const clickActiveQuestion = async(question) => {
    try {
      if (question.progress === 'Posted' || question.progress === 'Pick Up') {
        const { QuestionUpdate } = await callUpdateQuestion(question)
        QuestionUpdate === 'success' && setQuestionId(question.id)
      } else {
        setQuestionId(question.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const callUpdateQuestion = async(question) => {
    const { data } = await questionUpdate({
      variables: {
        question: {
          id: question.id,
          progress: 'Working',
        }
      }
    })
    return data
  }

  return (
    <div className={classes.questions_container}>
      <DashboardTitle title='Questions' />
      <Searchbar setSearchText={setSearchText}/>
      <div className={classes.job_container}>
        <div className={classes.job_container__active_job_box}>
          <DashboardSubTitle Icon={ActiveJobs} title='Active Jobs' />
          {activeJobLoading
          ? <TFASkeleton 
              type='question_card'
              count={2}
            />
          :
          activeQuestions?.length === 0
          ? <div className={classes.empty_question_box}>
              No Active Jobs
            </div>
          : activeQuestions.map((question, index) => (
              <QuestionCard 
                question={question}
                key={index}
                questionId={questionId}
                clickQuestionCard={() => clickActiveQuestion(question)}
                tabName='Working Area'
              />
            ))
          }
        </div>
        <div className={classes.job_container__completed_job_box}>
          <DashboardSubTitle Icon={CompletedJobs} title='Completed Jobs' />
          {completedJobLoading
          ? <TFASkeleton 
              type='question_card'
              count={2}
            />
          :
            completedQuestions?.length === 0
            ? <div className={classes.empty_question_box}>
                No Completed Jobs
              </div>
            : completedQuestions?.map((question, index) => (
              <QuestionCard 
                question={question}
                key={index}
                questionId={questionId}
                clickQuestionCard={() => setQuestionId(question.id)}
                tabName='Working Area'
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Questions