import React, { useEffect, useState } from 'react'
import classes from '../PAppointments.module.scss'
import Modal from './Modal'
import { DashboardTitle, ProgressState } from '../../../../../components/Dashboard/DashboardComponents'
import { CancelAppointment, LinkInfo } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAButton, TFASkeleton } from '../../../../../components/TFAComponents'
import { Avatar } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_APPOINTMENT, GET_APPOINTMENT_COUNT, SINGLE_DOWNLOAD } from '../../../../../services/apiQuery'
import { appointmentDateFormatter, appointmentTimeConverter } from '../../../../../utils/finishTimeConverter'
import moment from 'moment'

const Details = ({ appointmentId }) => {
  const [ avatar, setAvatar ] = useState('')
  const [ appointmentDetails, setAppointmentDetails ] = useState({})
  const [ createdTime, setCreatedTime ] = useState('')
  const [ numberOfAsked, setNumberOfAsked ] = useState(0)
  const now = moment().format()
  const openTime = moment(appointmentDetails?.timeStart).subtract(15, "minutes").format()
  const isJoinChatAvailable = (now < openTime)

  const [ isCancelOpen, setIsCancelOpen ] = useState(false)
  const [ isLinkOpen, setIsLinkOpen ] = useState(false)
  const cancelModalOpen = () => setIsCancelOpen(true)
  const cancelModalClose = () => setIsCancelOpen(false)
  const addLinkModalOpen = () => setIsLinkOpen(true)
  const addLinkModalClose = () => setIsLinkOpen(false)

  const { 
    loading: appointmentLoading, 
    data: appointmentData,
    refetch: appointmentRefetch,
  } = useQuery(GET_APPOINTMENT, { 
    variables: { getAppointmentId: appointmentId }
  })
  const { loading: avatarLoading, data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
    variables: { filename: appointmentDetails?.client?.avatar}
  })
  const { loading: countLoading, data: countData } = useQuery(GET_APPOINTMENT_COUNT,{
    variables: { clientId: appointmentDetails?.client?.id }
  })

  useEffect(() => {
    appointmentData && setAppointmentDetails(appointmentData.GetAppointment)
    avatarData && setAvatar(avatarData.SingleDownload)
    countData && setNumberOfAsked(countData.CountAppointments)
  },[appointmentData, avatarData, countData])

  useEffect(() => {
    if (appointmentDetails) {
      const updatedAt = parseInt(appointmentDetails.updatedAt)
      const result = moment(updatedAt).format('k:mm D/MMM/YYYY')
      setCreatedTime(result)
    }
  },[appointmentDetails])

  const formatCompleteDate = (date) => {
    const formattedDate = moment.utc(date).local().format('D MMMM YYYY')
    return formattedDate
  }

  const beginMeeting = () => {
    window.open(
      `${appointmentDetails.link}`,
      '_blank'
    )
  }

  return (
    <div className={classes.details_container}>
      <DashboardTitle title='Details' marginBottom='0.5em'/>
      {Object.entries(appointmentDetails).length === 0
      ? <div className={classes.details_container__detail_box}>
          <div className={classes.details_container__detail_box__empty_txt}>
              Select an appointment to see details
          </div>
        </div>

      : <>
          {Object.entries(appointmentDetails).length !== 0 &&
           appointmentDetails.progress === 'Meeting' &&
           isJoinChatAvailable &&
          <CancelAppointment 
            className={classes.details_container__cancel_icon}
            onClick={cancelModalOpen}
          />
          }
          <Modal
            appointmentId={appointmentId}
            link={appointmentDetails.link}
            isCancelOpen={isCancelOpen}
            isLinkOpen={isLinkOpen}
            cancelModalClose={cancelModalClose}
            addLinkModalClose={addLinkModalClose}
            appointmentRefetch={appointmentRefetch}
          />
          <div className={classes.details_box}>
            {(appointmentLoading && avatarLoading && countLoading)
            ? <TFASkeleton type='detail'/>
            : <>
                <p className={classes.details_box__progress_title}>Progress</p>
                <div className={classes.progress}>
                  <div className={classes.progress__progress_state}>
                    <ProgressState 
                      stateName='meeting'
                      status={appointmentDetails.progress !== 'Cancelled'
                        ? appointmentDetails.progress
                        : 'Meeting'
                      }
                      cancelled={appointmentDetails.progress === 'Cancelled'}
                      variant='list'
                      style={classes}
                    />
                  </div>
                </div>

                {appointmentDetails.progress === 'Cancelled' &&
                <div className={classes.cancel_box}>
                  <p className={classes.cancel_box__title}>
                    Appointment Cancelled
                  </p>
                  <p className={classes.cancel_box__content}>
                    This appointment has been cancelled. Sorry for the inconvenience.
                  </p>
                </div>
                }

                <p className={classes.details_box__detail_title}>Details</p>
                <div className={classes.question_box}>
                  <p className={classes.question_box__title}>
                    {appointmentDetails.title}
                  </p>
                  <p className={classes.question_box__content}>
                    {appointmentDetails.context}
                  </p>
                  <p className={classes.question_box__complete_time}>
                    {`Complete・ ${formatCompleteDate(appointmentDetails.timeFinish)}`}
                  </p>
                </div>

                <div className={classes.time_slot_box}>
                  <p className={classes.time_slot_box__title}>
                    Time Slot:
                  </p>
                  <p className={classes.time_slot_box__time}>
                    {appointmentTimeConverter(appointmentDetails.timeStart, appointmentDetails.timeFinish)}
                  </p>
                  <p className={classes.time_slot_box__date}>
                    {appointmentDateFormatter(appointmentDetails.timeFinish)}
                  </p>
                </div>

                <div className={classes.link_box} onClick={addLinkModalOpen}>
                  <p className={classes.link_box__title}>
                    Appointment Link:
                  </p>
                  <p className={classes.link_box__link}>
                    {appointmentDetails.link ?? 'Add Link'}
                  </p>
                  <LinkInfo className={classes.link_box__icon}/>
                </div>

                <div className={classes.chat_btn_box}>
                {(appointmentDetails.progress === 'Sent' ||
                appointmentDetails.progress === 'Meeting') &&
                  <TFAButton 
                    text='JOIN CHAT'
                    style2={!isJoinChatAvailable ? classes : null}
                    style3={isJoinChatAvailable ? classes : null}
                    onClick={!isJoinChatAvailable ? beginMeeting : null}
                  />
                }
                {appointmentDetails.progress === 'Complete' &&
                  <TFAButton
                    variant='outlined'
                    text='APPOINTMENT COMPLETE'
                    style1={classes}
                  />
                }
                {appointmentDetails.progress === 'Cancelled' &&
                  <TFAButton
                    variant='outlined'
                    text='APPOINTMENT CANCELLED'
                    style1={classes}
                  />
                }
                </div>

                {
                  <div className={classes.button_text_box}>
                    <p className={classes.button_text_box__text}>
                      {appointmentDetails.progress !== 'Cancelled' &&
                      appointmentDetails.progress !== 'Complete' &&
                        `Your link will be ready 15 minutes before the allocated time.`
                      }
                    </p>
                  </div>
                }

                <p className={classes.details_box__detail_title}>Client</p>
                <div className={classes.client_box}>
                  <Avatar 
                    alt='client-avatar' 
                    src={avatar} 
                    className={classes.client_box__avator} 
                    sx={{width: '2.5em', height: '2.5em'}}
                  />
                  <div className={classes.client_box__client_info}>
                    <p className={classes.client_box__client_info__client_name}>
                      {appointmentDetails.client.firstName} {appointmentDetails.client.lastName}
                    </p>
                    <p className={classes.client_box__client_info__question_count}>
                      {numberOfAsked}&nbsp;
                      {numberOfAsked <= 1
                      ? 'Appointment Made'
                      : 'Appointments Made'
                      }
                    </p>
                  </div>
                </div>
                {appointmentDetails.cancelDetail &&
                <div className={classes.cancel_detail_box}>
                  <div className={classes.cancel_detail_box__name_box}>
                    <p className={classes.cancel_detail_box__name_box__user}>
                      You
                      <span> ・ </span>
                      Professional
                    </p>
                    <p className={classes.cancel_detail_box__name_box__time}>
                      {createdTime}
                    </p>
                  </div>
                  <div>
                    <p>
                      {appointmentDetails.cancelDetail}
                    </p>
                  </div>
                </div>
                }
              </>
            }
          </div>
        </>
      }
    </div>
  )
}

export default Details