import React, { useEffect, useState } from 'react'
import classes from '../JobSearch.module.scss'
import { CategoryState } from '../../../../../components/Dashboard/DashboardComponents'
import { ArrowBack, InstallFile, VisaForm } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { Avatar } from '@mui/material'
import { TFAButton, TFASkeleton } from '../../../../../components/TFAComponents'
import { expiryDateConverter } from '../../../../../utils/finishTimeConverter'
import { downloadFile } from '../../../../../utils/downloadFiles'
import { useQuery } from '@apollo/client'
import { SINGLE_DOWNLOAD } from '../../../../../services/apiQuery'
import { formatFileInfo } from '../../../../../utils/formatFileInfo'

const Details = ({
    clickedQuestion,
    setClickedQuestion,
    handleAdviceOpen,
    handleOfferOpen,
}) => {
    const [ avatar, setAvatar ] = useState('')
    const { loading: avatarLoading, data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
        variables: { filename: clickedQuestion?.client?.avatar}
    })

    useEffect(() => {
        avatarData && setAvatar(avatarData.SingleDownload)
    },[avatarData])

    return (
        <div className={classes.job_selected_box__detail_box}>
            <div className={classes.job_detail}>
                {(Object.entries(clickedQuestion).length === 0 && avatarLoading)
                ? <TFASkeleton type='detail' />
                : 
                <>
                    <div
                        className={classes.job_detail__back}
                        onClick={() => setClickedQuestion({})}
                    >
                        <ArrowBack className={classes.job_detail__back__arrow} />
                        <p className={classes.job_detail__back__title}>CLOSE</p>
                    </div>
                    <div className={classes.job_detail__client}>
                        <Avatar
                            alt='client-avatar'
                            src={avatar}
                            className={classes.job_detail__client__avator}
                            sx={{ width: '2.5em', height: '2.5em' }}
                        />
                        <div className={classes.job_detail__client__text}>
                            <p className={classes.job_detail__client__text__name}>
                                {clickedQuestion?.client?.firstName}&nbsp;
                                {clickedQuestion?.client?.lastName}
                            </p>
                            <p className={classes.job_detail__client__text__question_count}>
                                {clickedQuestion?.client?.questionsAsked} Questions Asked
                            </p>
                        </div>
                    </div>
                    <div className={classes.job_detail__question_box}>
                        <p className={classes.job_detail__question_box__title}>{clickedQuestion?.title}</p>
                        <CategoryState
                            destinationCountry={clickedQuestion?.destinationCountry}
                            category={clickedQuestion?.category}
                        />
                        <div>
                            <p className={classes.job_detail__question_box__content}>{clickedQuestion?.content}</p>
                        </div>
                        <div className={classes.job_detail__question_box__expiryDateConSve_box}>
                            <p className={classes.job_detail__question_box__expiry_box__txt}>
                                Expiry・ <span>{expiryDateConverter(clickedQuestion?.finishTime)}</span>
                            </p>
                        </div>
                    </div>
                    <div className={classes.job_detail__image_box}>
                        {clickedQuestion?.questionimages.map((image, index) => (
                            image.link
                            ?
                            <div 
                                className={classes.job_detail__image_box__container}
                                key={index}
                            >
                                <img 
                                    src={image.link} 
                                    alt='visa documents' 
                                    className={classes.job_detail__image_box__container__img} 
                                />
                                <InstallFile
                                    className={classes.job_detail__image_box__container__install_icon}
                                    onClick={() => downloadFile(image)}
                                />
                            </div>
                            : <TFASkeleton type='image' key={index}/>
                        ))}
                    </div>
                    <div className={classes.job_detail__document_box}>
                        {clickedQuestion?.questionfiles.map((file, index) => {
                            return(
                            file.link
                            ?
                            <div 
                                className={classes.job_detail__document_box__container} 
                                key={index}
                            >
                                <div className={classes.job_detail__document_box__container__title_box}>
                                    <VisaForm className={classes.job_detail__document_box__container__title_box__icon}/>
                                    <div className={classes.job_detail__document_box__container__title_box__text_box}>
                                        <p className={classes.job_detail__document_box__container__title_box__text_box__top}>
                                            {formatFileInfo(file.key, 'name')}
                                        </p>
                                        <p className={classes.job_detail__document_box__container__title_box__text_box__bottom}>
                                            {formatFileInfo(file.key, 'type')}
                                        </p>
                                    </div>
                                </div>
                                <InstallFile 
                                    className={classes.job_detail__document_box__container__install}
                                    onClick={() => downloadFile(file)}
                                />
                            </div>
                            : <TFASkeleton type='document'/>
                            )
                        })}
                    </div>
                    <div className={classes.job_detail__submit_box}>
                        <div className={classes.job_detail__submit_box__price}>
                            <p className={classes.job_detail__submit_box__price__price_title}>
                                Price:
                                <span className={classes.job_detail__submit_box__price__price_txt}>
                                    &#36;{parseFloat(clickedQuestion?.price / 100).toFixed(2)}
                                </span>
                            </p>
                            <p className={classes.job_detail__submit_box__price__price_desc}>
                                You will have 24 hours to complete the listed task or this listing will be relisted on the Time For Advice search.
                            </p>
                        </div>
                        <div className={classes.job_detail__submit_box__submit}>
                            <TFAButton
                                text='GIVE ADVICE'
                                onClick={() => handleAdviceOpen()}
                                style1={classes}
                            />
                            <div
                                onClick={() => handleOfferOpen()}
                                className={classes.job_detail__submit_box__submit__offer_btn}
                            >
                                <p className={classes.job_detail__submit_box__submit__offer_btn__txt}>
                                    PROPOSE NEW OFFER
                                </p>
                            </div>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default Details