import React from 'react'
import classes from '../PProfile.module.scss'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { 
    ProfileSetting,
    LogoutProfile,
    CardArrow,
} from '../../../../../assets/icons/Dashboard/DashboardIcons'

const Settings = ({
    professionalLogout,
    accountModalOpen,
    availabilityModalOpen,
    passwordModalOpen,
}) => {
  return (
    <div className={classes.setting_container__settings}>
      <div className={classes.setting_title_box}>
        <DashboardSubTitle 
          Icon={ProfileSetting}
          title='Settings'
          fontSize='1.4em'
        />
        <LogoutProfile 
          className={classes.setting_title_box__icon}
          onClick={professionalLogout}
        />
      </div>
      <div className={classes.setting_list}>
        <div 
          className={classes.setting_list__box}
          onClick={accountModalOpen}
        >
          <p className={classes.setting_list__box__title}>
            Account Details
          </p>
          <CardArrow 
            className={classes.setting_list__box__arrow}
          />
        </div>
        <div 
          className={classes.setting_list__second_box}
          onClick={availabilityModalOpen}
        >
          <p className={classes.setting_list__box__title}>
            Availability
          </p>
          <CardArrow 
            className={classes.setting_list__box__arrow}
          />
        </div>
        <div 
          className={classes.setting_list__box}
          onClick={passwordModalOpen}
        >
          <p className={classes.setting_list__box__title}>
            Security
          </p>
          <CardArrow 
            className={classes.setting_list__box__arrow}
          />
        </div>
      </div>
    </div>
  )
}

export default Settings