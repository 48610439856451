import React, { useEffect, useState } from 'react'
import classes from '../Questions.module.scss'
import { CloseBtn, ConfirmAnswer } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAButton, TFAModal, TFASkeleton, TFAText } from '../../../../../components/TFAComponents'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { ACCEPT_QUESTION_OFFER, QUESTION_UPDATE, UPDATE_QUESTION_OFFER } from '../../../../../services/apiMutation'
import { GET_QUESTION_OFFER, SINGLE_DOWNLOAD } from '../../../../../services/apiQuery'
import { reverseSpecialtyConverter } from '../../../../../utils/specialtyConverter'
import { Avatar } from '@mui/material'
import { PaymentMethodCard } from '../../../../../components/Dashboard/DashboardComponents'
import PayPalButton from '../../../../../components/Dashboard/PayPalButton/PayPalButton'
import useClientContext from '../../../../../states/Client/useClientContext'

const Modal = ({
	questionId,
	formik,
	questionPrice,
	isAcceptOfferOpen,
	isDeclineOfferOpen,
	isAnswerOpen,
	isMoreQuestionOpen,
	isAcceptQuestionOpen,
	paymentModalOpen,
	acceptOfferClose,
	declineOfferClose,
	inputFieldClose,
	answerModalClose,
	moreQuestionClose,
	acceptQuestionClose,
	refetchQuestion,
	refetchReply,
}) => {
	const { client } = useClientContext().clientState
	const [avatar, setAvatar] = useState('')
	const [isAcceptOfferLoading, setIsAcceptOfferLoading] = useState(false)
	const [isDeclineOfferLoading, setIsDelineOfferLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [offer, setOffer] = useState({})
	const navigate = useNavigate()
	const { loading: avatarLoading, data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
		variables: { filename: offer.professional?.avatar },
	})
	const { loading: offerLoading, data: offerData } = useQuery(GET_QUESTION_OFFER, {
		variables: { getQuestionofferId: formik.values.offerId },
	})
	const [acceptQuestionOffer] = useMutation(ACCEPT_QUESTION_OFFER)
	const [updateQuestionOffer] = useMutation(UPDATE_QUESTION_OFFER)
	const [acceptQuestion] = useMutation(QUESTION_UPDATE)

	useEffect(() => {
		offerData && setOffer(offerData.GetQuestionoffer)
	}, [offerData])

	useEffect(() => {
		avatarData && setAvatar(avatarData.SingleDownload)
	}, [avatarData])

	useEffect(() => {
		if (client) {
			formik.setFieldValue((formik.values.paymentPreference = client.paymentPreference))
			formik.setFieldValue((formik.values.email = client.email))
		}
	}, [client])

	const acceptOffer = async () => {
		try {
			setIsAcceptOfferLoading(true)
			const { QuestionofferAcceptOffer } = await callAcceptQuestionOffer(formik.values)
			if (!QuestionofferAcceptOffer) return
			acceptOfferClose()
			refetchQuestion()
			refetchReply()
			window.location.reload(false)
		} catch (error) {
			console.log(error)
		} finally {
			setIsAcceptOfferLoading(false)
		}
	}

	const declineOffer = async () => {
		try {
			setIsDelineOfferLoading(true)
			const { QuestionofferUpdate } = await callDeclineQuestionOffer()
			if (!QuestionofferUpdate) return
			declineOfferClose()
			refetchQuestion()
			refetchReply()
			window.location.reload(false)
		} catch (error) {
			console.log(error)
		} finally {
			setIsDelineOfferLoading(false)
		}
	}

	const confirmAcceptQuestion = async () => {
		try {
			setIsLoading(true)
			const { QuestionUpdate } = await callAcceptQuestion(questionId)
			if (QuestionUpdate !== 'success') return
			inputFieldClose()
			refetchQuestion()
			refetchReply()
			acceptQuestionClose()
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const callAcceptQuestionOffer = async (values) => {
		const { data } = await acceptQuestionOffer({
			variables: {
				questionofferId: formik.values.offerId,
				paypalOfferId: values.paymentPreference === 'PayPal' ? values.paypalOrderId : null,
				sourceId: values.paymentPreference === 'Card' ? values.defaultCard.id : '',
			},
		})
		return data
	}

	const callDeclineQuestionOffer = async () => {
		const { data } = await updateQuestionOffer({
			variables: {
				questionoffer: {
					id: formik.values.offerId,
					status: 'denied',
				},
			},
		})
		return data
	}

	const callAcceptQuestion = async (id) => {
		const { data } = await acceptQuestion({
			variables: {
				question: {
					id,
					progress: 'Complete',
				},
			},
		})
		return data
	}

	const getOfferPriceDifference = (offerPrice) => {
		const difference = offerPrice - questionPrice
		return parseFloat(difference / 100).toFixed(2)
	}

	return (
		<>
			<TFAModal open={isAcceptOfferOpen} handleClose={acceptOfferClose} width={'30em'}>
				<div className={classes.modal_title_box}>
					<CloseBtn className={classes.modal_title_box__icon} onClick={acceptOfferClose} />
					<p className={classes.modal_title_box__title}>Accept Offer</p>
				</div>
				<p className={classes.title_description}>
					By accepting this offer. You will be debited the difference in order to match the offered price.
				</p>
				{offerLoading && avatarLoading && offer ? (
					<TFASkeleton type='professionals' count={1} />
				) : (
					<div className={classes.offer_detail_box}>
						<div className={classes.professional_modal_box}>
							<Avatar
								src={avatar}
								className={classes.professional_modal_box__avatar}
								sx={{ width: '2.5em', height: '2.5em' }}
								alt='offer-professional-avatar'
							/>
							<div className={classes.professional_modal_box__profile}>
								<p className={classes.professional_modal_box__profile__name}>
									{offer.professional?.firstName}&nbsp;
									{offer.professional?.lastName}&nbsp;
									<span className={classes.professional_modal_box__profile__name__code}>- {offer.professional?.professionalCode}</span>
								</p>
								<p className={classes.professional_modal_box__profile__detail}>{reverseSpecialtyConverter(offer.professional?.specialty)}</p>
							</div>
						</div>
						<div className={classes.offer_content}>
							<p className={classes.offer_content__message}>{offer.message}</p>
							<div className={classes.offer_content__price_box}>
								<p className={classes.offer_content__price_box__title}>Offer Price:</p>
								<p className={classes.offer_content__price_box__price}>&#36;{parseFloat(offer.price / 100).toFixed(2)}</p>
							</div>
						</div>
					</div>
				)}
				<div className={classes.title_box}>
					<p className={classes.title_box__primary}>Difference</p>
					<p className={classes.title_box__secondary}>In order to accept this offer you will be debited the difference outlined below.</p>
					<div className={classes.title_box__difference_box}>
						<p className={classes.title_box__difference_box__title}>Difference Price:</p>
						<p className={classes.title_box__difference_box__price}>&#36;{getOfferPriceDifference(offer.price)}</p>
					</div>
				</div>
				<div className={classes.title_box}>
					<p className={classes.title_box__primary}>Payment Method</p>
					<p className={classes.title_box__secondary}>Select how you would like to pay for your difference.</p>
					<PaymentMethodCard formik={formik} handleClick={paymentModalOpen} isPaymentSelected={true} method={formik.values.paymentPreference} />
				</div>
				<div className={classes.btn_box}>
					<TFAButton text='ACCEPT OFFER' onClick={acceptOffer} isLoading={isAcceptOfferLoading} style1={classes} />
				</div>
			</TFAModal>
			<TFAModal open={isDeclineOfferOpen} handleClose={declineOfferClose} width={'30em'}>
				<div className={classes.modal_title_box}>
					<CloseBtn className={classes.modal_title_box__icon} onClick={declineOfferClose} />
					<p className={classes.modal_title_box__title}>Decline Offer</p>
				</div>
				<p className={classes.title_description}>
					Are you sure you want to decline this offer? You will not be able to retrieve it once declined.
				</p>
				{offerLoading && avatarLoading && offer ? (
					<TFASkeleton type='professionals' count={1} />
				) : (
					<div className={classes.offer_detail_box}>
						<div className={classes.professional_modal_box}>
							<Avatar
								src={avatar}
								className={classes.professional_modal_box__avatar}
								sx={{ width: '2.5em', height: '2.5em' }}
								alt='offer-professional-avatar'
							/>
							<div className={classes.professional_modal_box__profile}>
								<p className={classes.professional_modal_box__profile__name}>
									{offer.professional?.firstName}&nbsp;
									{offer.professional?.lastName}&nbsp;
									<span className={classes.professional_modal_box__profile__name__code}>- {offer.professional?.professionalCode}</span>
								</p>
								<p className={classes.professional_modal_box__profile__detail}>{reverseSpecialtyConverter(offer.professional?.specialty)}</p>
							</div>
						</div>
						<div className={classes.offer_content}>
							<p className={classes.offer_content__message}>{offer.message}</p>
							<div className={classes.offer_content__price_box}>
								<p className={classes.offer_content__price_box__title}>Offer Price:</p>
								<p className={classes.offer_content__price_box__price}>&#36;{parseFloat(offer.price / 100).toFixed(2)}</p>
							</div>
						</div>
					</div>
				)}
				<div className={classes.btn_box}>
					<TFAButton text='DECLINE OFFER' onClick={declineOffer} isLoading={isDeclineOfferLoading} style2={classes} />
				</div>
			</TFAModal>
			<TFAModal open={isAnswerOpen} handleClose={answerModalClose} width={'25em'}>
				<ConfirmAnswer className={classes.modal_icon} />
				<div className={classes.modal_text_box}>
					<TFAText
						secondary_title='Confirm Your Question'
						description={
							<>
								<p>Need some clarity?</p>
								<p>All questions on Time For Advice get a single follow up clarification reply. </p>
								<p>Once this is sent, you cannot edit or undo your question.</p>
							</>
						}
						style2={classes}
					/>
				</div>
				<div className={classes.confirm_btn_box}>
					<TFAButton text='CONFIRM' isLoading={formik.isSubmitting} onClick={formik.handleSubmit} style2={classes} />
					<div className={classes.confirm_btn_box__go_back_box} onClick={answerModalClose}>
						<p className={classes.confirm_btn_box__go_back_box__txt}>GO BACK</p>
					</div>
				</div>
			</TFAModal>
			<TFAModal open={isMoreQuestionOpen} handleClose={moreQuestionClose} width={'25em'}>
				<ConfirmAnswer className={classes.modal_icon} />
				<div className={classes.modal_text_box}>
					<TFAText
						secondary_title='Got More Questions?'
						description={
							<>
								<p>If you need more clarification on a topic you can always ask another question on Time For Advice.</p>
								<br />
								<p>If you feel this question was not responded to correctly, you can let our team know by flagging this question. </p>
							</>
						}
						style2={classes}
					/>
				</div>
				<div className={classes.confirm_btn_box}>
					<TFAButton text='CONFIRM' onClick={() => navigate('/create_question')} style2={classes} />
					<div className={classes.confirm_btn_box__go_back_box} onClick={moreQuestionClose}>
						<p className={classes.confirm_btn_box__go_back_box__txt}>GO BACK</p>
					</div>
				</div>
			</TFAModal>
			<TFAModal open={isAcceptQuestionOpen} handleClose={acceptQuestionClose} width={'25em'}>
				<ConfirmAnswer className={classes.modal_icon} />
				<div className={classes.modal_text_box}>
					<TFAText
						secondary_title='Accept Answer'
						description={'Are you happy with the reply you received? Accept the reply to close this question.'}
						style2={classes}
					/>
				</div>
				<div className={classes.confirm_btn_box}>
					<TFAButton text='CONFIRM' onClick={confirmAcceptQuestion} isLoading={isLoading} style2={classes} />
					<div className={classes.confirm_btn_box__go_back_box} onClick={acceptQuestionClose}>
						<p className={classes.confirm_btn_box__go_back_box__txt}>GO BACK</p>
					</div>
				</div>
			</TFAModal>
		</>
	)
}

export default Modal
