import React, { useEffect, useState } from 'react'
import classes from './Sections.module.scss'
import CreatePaymentMethod from '../Modals/CreatePaymentMethod'
import RemovePaymentMethod from '../Modals/RemovePaymentMethod'
import { DashboardTitle, PaymentMethodCard } from '../../../../../components/Dashboard/DashboardComponents'
import { useFormik } from 'formik'
import { TFAButton, TFASkeleton } from '../../../../../components/TFAComponents'
import { useMutation, useQuery } from '@apollo/client'
import { GET_CARD_DETAIL } from '../../../../../services/apiQuery'
import { UpdateClientPaymentValues } from '../../../../../assets/data/FormikInitialValues'
import useClientContext from '../../../../../states/Client/useClientContext'
import { UPDATE_DEFAULT_CARD } from '../../../../../services/apiMutation'

const Payment = () => {
  const { client } = useClientContext().clientState
  const { loading: cardLoading, data: cardData, refetch: cardRefetch } = useQuery(GET_CARD_DETAIL)
  const [ updateDefaultCard ] = useMutation(UPDATE_DEFAULT_CARD)
  
  const [ createPaymentModalOpen, setCreatePaymentModalOpen ] = useState(false)
  const [ removePaymentModalOpen, setRemovePaymentModalOpen ] = useState(false)

  const formik = useFormik({
    initialValues: UpdateClientPaymentValues,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { Stripe_UpdateDefaultCard } = await callUpdateDefualtCard(values)
        if (!Stripe_UpdateDefaultCard) return
        cardRefetch()
      } catch (error) {
        console.log(error)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    if (cardData && client) {
      if (cardData.Stripe_ListAllCards.length === 0) return
      formik.setFieldValue(formik.values.allCards = cardData.Stripe_ListAllCards)
      formik.setFieldValue(formik.values.originalDefaultCard = cardData.Stripe_ListAllCards[0])
      formik.setFieldValue(formik.values.defaultCard = cardData.Stripe_ListAllCards[0])
    }
  },[cardData, client])

  const clickPaymentMethod = (card) => { 
    formik.setFieldValue(formik.values.defaultCard = card)
  }

  const callUpdateDefualtCard = async (values) => {
    const { data } = await updateDefaultCard({
      variables: {
        clientId: client?.id,
        sourceId: values.defaultCard.id
      }
    })
    return data
  }

  return (
    <div className={classes.section_container}>
      <DashboardTitle title='Payment'/>
      <div className={classes.section_box}>
        <p className={classes.section_box__title}>
          Payment Methods
        </p>
        <p className={classes.section_box__desc}>
          Update your payment details.
        </p>
      </div>
      <div className={classes.payment_method_box}>
      {cardLoading || !formik.values.allCards
      ? <TFASkeleton />
      : formik.values.allCards.map((card, index) => (
        <div
          className={classes.payment_method_box__card}
          key={index}
        >
          <PaymentMethodCard 
            cardDetails={card}
            defaulCard={formik.values.allCards[0]}
            method='Card'
            handleClick={() => clickPaymentMethod(card)}
            isPaymentSelected={card.id === formik.values.defaultCard.id}
            iconType='edit'
            onClick={() => setRemovePaymentModalOpen(true)}
          />
        </div>
      ))
      }
        <div className={classes.btn_box}>
          <TFAButton 
            text='CREATE PAYMENT METHOD'
            onClick={() => setCreatePaymentModalOpen(true)}
            style3={classes}
          />
        </div>
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='SAVE'
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          style1={classes}
        />
      </div>
      <CreatePaymentMethod 
        formik={formik}
        clientId={client.id}
        createPaymentModalOpen={createPaymentModalOpen}
        setCreatePaymentModalOpen={setCreatePaymentModalOpen}
        cardRefetch={cardRefetch}
      />
      <RemovePaymentMethod 
        formik={formik}
        clientId={client?.id}
        removePaymentModalOpen={removePaymentModalOpen}
        setRemovePaymentModalOpen={setRemovePaymentModalOpen}
      />
    </div>
  )
}

export default Payment