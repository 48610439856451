import React from 'react'
import classes from './DashboardTitle.module.scss'
import { IconButton } from '@mui/material'

const DashboardTitle = ({ title, Icon, iconClick, marginBottom, scale }) => {
  return (
    <div className={classes.title_box} style={{marginBottom: marginBottom}}>
        <p className={classes.title}>{title}</p>
        {Icon && 
        <IconButton
          className={classes.icon} 
          style={{transform: scale}}
          onClick={iconClick}
        >
          <Icon />
        </IconButton>
        }
    </div>
  )
}

export default DashboardTitle