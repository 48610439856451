import React from 'react'
import classes from './DetailsFile.module.scss'
import { InstallFile, VisaForm } from '../../../assets/icons/Dashboard/DashboardIcons'
import { formatFileInfo } from '../../../utils/formatFileInfo'
import { downloadFile } from '../../../utils/downloadFiles'

const DetailsFile = ({ questionDetails }) => {
    return (
        <div className={classes.container}>
            {questionDetails.questionfiles.map((file, index) => (
            <div
                className={classes.container__file_box}
                key={index}
            >
                <div className={classes.container__file_box__title_box}>
                    <VisaForm 
                        className={classes.container__file_box__title_box__icon}
                    />
                    <div className={classes.container__file_box__title_box__info_box}>
                        <p className={classes.container__file_box__title_box__info_box__title}>
                            {formatFileInfo(file.key, 'name')}
                        </p>
                        <p className={classes.container__file_box__title_box__info_box__type}>
                            {formatFileInfo(file.key, 'type')}
                        </p>
                    </div>
                </div>
                <InstallFile 
                    className={classes.container__file_box__install}
                    onClick={() => downloadFile(file)}
                />
            </div>
            ))}
        </div>
    )
}

export default DetailsFile