import React, { useEffect } from 'react'
import classes from './Sections.module.scss'
import useClientContext from '../../../../../states/Client/useClientContext'
import { useMutation } from '@apollo/client'
import { UPDATE_CLIENT_PROFILE } from '../../../../../services/apiMutation'
import { useFormik } from 'formik'
import { UpdateClientLanguageValues } from '../../../../../assets/data/FormikInitialValues'
import { updateClientLanguageSchema } from '../../../../../utils/validation'
import { DashboardTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAButton, TFASelectBox } from '../../../../../components/TFAComponents'
import { languages } from '../../../../../assets/data/SelectOptions'

const Languages = ({ setSavedModalOpen }) => {
	const { client } = useClientContext().clientState
	const [updateProfile] = useMutation(UPDATE_CLIENT_PROFILE)
	const formik = useFormik({
		initialValues: UpdateClientLanguageValues,
		validationSchema: updateClientLanguageSchema,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { ClientUpdate } = await callUpdateProfile(values)
				if (ClientUpdate !== 'success') return
				setSavedModalOpen(true)
			} catch (error) {
				console.log(error)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (client) {
			formik.setFieldValue((formik.values.firstLanguage = client.firstLanguage))
			formik.setFieldValue((formik.values.secondLanguage = client.secondLanguage))
		}
	}, [client])

	const callUpdateProfile = async (values) => {
		const { data } = await updateProfile({
			variables: {
				client: {
					id: client?.id,
					firstLanguage: values.firstLanguage,
					secondLanguage: values.secondLanguage,
				},
			},
		})
		return data
	}

	return (
		<div className={classes.section_container}>
			<DashboardTitle title='Language' />
			<div className={classes.section_box}>
				<p className={classes.section_box__title}>First Language</p>
				<p className={classes.section_box__desc}>
					Select your first or prefered language. This allows us to connect you to a professional who specializes in your languages.
				</p>
				<TFASelectBox
					selectArray={languages.filter((language) => language !== formik.values.secondLanguage)}
					label='FIRST LANGUAGE'
					name='firstLanguage'
					width='100%'
					required={true}
					formik={formik}
					error={formik?.errors?.firstLanguage ?? null}
				/>
			</div>
			<div className={classes.second_section_box}>
				<p className={classes.second_section_box__title}>Second Language</p>
				<p className={classes.second_section_box__desc}>
					Select your prefered second language. This is a fallback from your first language selection (This is not mandatory).
				</p>
				<TFASelectBox
					selectArray={languages.filter((language) => language !== formik.values.firstLanguage)}
					label='SECOND LANGUAGE'
					name='secondLanguage'
					width='100%'
					formik={formik}
				/>
			</div>
			<div className={classes.btn_box}>
				<TFAButton text='SAVE' onClick={formik.handleSubmit} isLoading={formik.isSubmitting} disabled={formik.isSubmitting} style1={classes} />
			</div>
		</div>
	)
}

export default Languages
