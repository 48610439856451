import React, { useState } from 'react'
import classes from './Modals.module.scss'
import { TFAButton, TFAModal } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js'
import TFAStripeInput from '../../../../../components/Register/TFAStripeInput/TFAStripeInput'
import { useMutation } from '@apollo/client'
import { CREATE_STRIPE_CARD } from '../../../../../services/apiMutation'

const CreatePaymentMethod = ({ 
  formik, 
  clientId,
  createPaymentModalOpen, 
  setCreatePaymentModalOpen,
  cardRefetch,
}) => {
  const [ isStripeValidating, setIsStripeValidating ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ cardDetail, setCardDetail ] = useState('')
  const stripe = useStripe()
  const elements = useElements()
  const [ createStripeCard ] = useMutation(CREATE_STRIPE_CARD)

  const handleSubmit = async () => {
    try {
      await getStripeId()
      if (cardDetail?.complete === true) {
        const { Stripe_CreateCard } = await callCreateStripeCard(clientId, formik.values.stripeId)
        if (!Stripe_CreateCard) return
        setCreatePaymentModalOpen(false)
        cardRefetch()
      }
    } catch (error) {
      console.log({error})
    }
  }

  const getStripeId = async () => {
    try {
      setErrorMessage('')
      setIsStripeValidating(true)
      const stripeToken = await stripeCreatePaymentMethod()
      if (!stripeToken) return
      formik.setFieldValue(formik.values.stripeId = stripeToken)
      setIsStripeValidating(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsStripeValidating(false)
    }
  }

  const stripeCreatePaymentMethod = async () => {
    if (!stripe || !elements) return
    const cardElement = elements.getElement(CardElement)
    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: formik.values.cardName,
      }
    })
    const stripeToken = await stripe.createToken(cardElement)
    console.log({stripeToken})
    if (error) {
      setErrorMessage('This is not valid card details. Please check your details and try again')
      return null
    }
    return stripeToken
  }

  const callCreateStripeCard = async (clientId, stripeId) => {
    const { data } = await createStripeCard({
      variables: {
        clientId,
        sourceId: stripeId?.token?.id
      }
    })
    return data
  }

  return (
    <TFAModal
      open={createPaymentModalOpen}
      handleClose={() => setCreatePaymentModalOpen(false)}
      width='30em'
    >
      <div className={classes.modal_title_box}>
        <CloseBtn 
          className={classes.modal_title_box__icon}
          onClick={() => setCreatePaymentModalOpen(false)}
        />
        <p className={classes.modal_title_box__title}>
          Add Payment Method
        </p>
      </div>
      <p className={classes.modal_desc_box}>
        Create a new payment method.
      </p>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Debit/Credit Card Setup
        </p>
        <p className={classes.title_box__secondary}>
          Enter valid debit or credit card details below.
        </p>
        <div className={classes.title_box__simple_input}>
          <TFAStripeInput 
            formik={formik}
            errorMessage={errorMessage}
            setCardDetail={setCardDetail}
          />
        </div>
      </div>
      <div className={classes.btn_box}>
        <TFAButton
          text='ADD'
          onClick={handleSubmit}
          style1={classes}
          disabled={!cardDetail}
          isLoading={isStripeValidating}
        />
      </div>
    </TFAModal>
  )
}

export default CreatePaymentMethod