import React, { useState } from 'react'
import { 
    TFAAccordionBox,
    TFAAccordionSummary,
    TFAAccordionDetails
} from './MuiStyle'

const TFAAccordion = ({ title, children }) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <div>
            <TFAAccordionBox 
                expanded={expanded} 
                onChange={() => setExpanded(!expanded)}
            >
                <TFAAccordionSummary>
                    <p>{title}</p>
                </TFAAccordionSummary>
                <TFAAccordionDetails>
                    { children }
                </TFAAccordionDetails>
            </TFAAccordionBox>
        </div>
    )
}

export default TFAAccordion