import React from 'react'
import { SpecialitiesBudge } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import classes from '../PProfile.module.scss'

const Specialities = ({ specialities }) => {
    return (
        <div className={classes.specialities_container}>
            <DashboardSubTitle 
                Icon={SpecialitiesBudge}
                title='Specialities'
                fontSize={'1.4em'}
                iconSize='scale(1.7)'
                color='#2F68FA'
            />
            <div className={classes.specialities_box}>
                <SpecialitiesBudge 
                    className={classes.specialities_box__details__status_icon_big}
                />
                <div className={classes.specialities_box__details__text}>
                    {specialities?.map((specialityObj, index) => (
                    <p 
                        className={classes.specialities_box__details__text__title}
                        key={index}
                    >
                        • {specialityObj.specialty}
                    </p>
                    ))
                    }
                </div>
            </div>
        </div>
        
    )
}

export default Specialities