import React from 'react'
import classes from './TimePicker.module.scss'
import { Select, MenuItem } from '@mui/material'
import { TFAFormControl } from '../../TFASelectBox/MuiStyle'
import { SELECT_NAMES } from '../../../assets/data/SelectOptions'
import { timeTable, meridiemTable } from '../../../assets/data/SelectOptions'
import { TimePickerMenuProps } from '../../../assets/styles/GlobalStyle/GlobalMuiStyle'
import { TFAFormControlLabel, TFACheckbox } from './MuiStyle'

const TimePicker = ({
  title,
  clickCheckbox,
  isClicked,
  unavailable,
  formik,
  week,
  error,
}) => {

  const handleChange = (select) => (e) => {
    formik.setFieldValue(week[select] = e.target.value)
  }

  return (
    <div className={classes.time_picker_box}>
      <TFAFormControlLabel
        label={title}
        disabled={unavailable}
        className={classes.title_checkbox}
        control={
          <TFACheckbox
            onChange={clickCheckbox}
            checked={isClicked ? false : true}
          />}
      />
      <div className={classes.schedule_box}>
        <div className={classes.schedule_box__start_box}>
          {/* Start Time */}
          <TFAFormControl
            className={classes.time_form_control}
            disabled={unavailable ? unavailable : isClicked}
            error={error}
          >
            <Select
              value={week[SELECT_NAMES.START_TIME] ?? ''}
              onChange={handleChange(SELECT_NAMES.START_TIME)}
              MenuProps={TimePickerMenuProps}
            >
              {timeTable.map(time => (
                <MenuItem
                  key={time}
                  value={time}
                >
                  {time}
                </MenuItem>
              ))}
            </Select>
          </TFAFormControl>
                  
          {/* Start Meridiem */}
          <TFAFormControl
            className={classes.form_control}
            disabled={unavailable ? unavailable : isClicked}
            error={error}
          >
            <Select
              value={week[SELECT_NAMES.START_MERIDIEM] ?? ''}
              onChange={handleChange(SELECT_NAMES.START_MERIDIEM)}
              MenuProps={TimePickerMenuProps}
            >
              {meridiemTable.map(meridiem => (
                <MenuItem
                  key={meridiem}
                  value={meridiem}
                >
                  {meridiem}
                </MenuItem>
              ))}
            </Select>
          </TFAFormControl>
        </div>

          <p className={classes.divider}>-</p>

        <div className={classes.schedule_box__end_box}>
            {/* End Time */}
          <TFAFormControl
            className={classes.time_form_control}
            disabled={unavailable ? unavailable : isClicked}
            error={error}
          >
            <Select
              value={week[SELECT_NAMES.END_TIME] ?? ''}
              onChange={handleChange(SELECT_NAMES.END_TIME)}
              MenuProps={TimePickerMenuProps}
            >
              {timeTable.map(time => (
                <MenuItem
                  key={time}
                  value={time}
                >
                  {time}
                </MenuItem>
              ))}
            </Select>
          </TFAFormControl>

          {/* End Meridiem */}
          <TFAFormControl
            className={classes.form_control}
            disabled={unavailable ? unavailable : isClicked}
            error={error}
          >
            <Select
              value={week[SELECT_NAMES.END_MERIDIEM] ?? ''}
              onChange={handleChange(SELECT_NAMES.END_MERIDIEM)}
              MenuProps={TimePickerMenuProps}
            >
              {meridiemTable.map(meridiem => (
                <MenuItem
                  key={meridiem}
                  value={meridiem}
                >
                  {meridiem}
                </MenuItem>
              ))}
            </Select>
          </TFAFormControl>
        </div>
      </div>
    </div>
  )
}

export default TimePicker