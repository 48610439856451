import React, { useEffect, useState } from 'react'
import classes from '../PAppointments.module.scss'
import { TFAButton, TFAModal, TFASimpleInput } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFATextInput } from '../../../../../components/TFAInputText/MuiStyle'
import { useMutation } from '@apollo/client'
import { UPDATE_APPOINTMENT } from '../../../../../services/apiMutation'

const Modal = ({
  appointmentId,
  link,
  isCancelOpen,
  isLinkOpen,
  cancelModalClose,
  addLinkModalClose,
  appointmentRefetch,
}) => {
  const [ cancelDetail, setCancelDetail ] = useState('')
  const [ appointmentLink, setAppointmentLink ] = useState('')
  const [ isCancelLoading, setIsCancelLoading ] = useState(false)
  const [ isAddLinkLoading, setIsAddLinkLoading ] = useState(false)
  const [ updateAppointment ] = useMutation(UPDATE_APPOINTMENT)

  useEffect(() => {
    setAppointmentLink(link)
  },[link])

  const cancelAppointment = async () => {
    try {
      setIsCancelLoading(true)
      const { AppointmentUpdate } = await callCancelAppointment(cancelDetail)
      if (!AppointmentUpdate) return
      cancelModalClose()
      appointmentRefetch()
    } catch (error) {
      console.log(error)
    } finally {
      setIsCancelLoading(false)
    }
  }

  const addAppointmentLink = async () => {
    try {
      setIsAddLinkLoading(true)
      const { AppointmentUpdate } = await callUpdateAppointment()
      if (!AppointmentUpdate) return
      addLinkModalClose()
      appointmentRefetch()
    } catch (error) {
      console.log(error)
    } finally {
      setIsAddLinkLoading(false)
    }
  }

  const callCancelAppointment = async (cancelDetail) => {
    const { data } = await updateAppointment({
      variables: {
        appointment: {
          id: appointmentId,
          progress: 'Cancelled',
          cancelDetail,
        }
      }
    })
    return data
  }

  const callUpdateAppointment = async () => {
    const { data } = await updateAppointment({
      variables: {
        appointment: {
          id: appointmentId,
          link: appointmentLink,
        }
      }
    })
    return data
  }

  return (
    <>
      <TFAModal
        open={isCancelOpen} 
        handleClose={cancelModalClose} 
        width={'35em'}
      >
        <div className={classes.modal_title_box}>
          <CloseBtn 
            className={classes.modal_title_box__icon} 
            onClick={cancelModalClose}
          />
          <p className={classes.modal_title_box__title}>
            Cancel Appointment
          </p>
        </div>
        <div className={classes.modal_content_box}>
          <p className={classes.modal_content_box__top_desc}>
            Provide your client with some details as to why you are cancelling this appointment.
          </p>
          <TFASimpleInput 
            box_width='95%'
            box_margin='0 auto'
            width='100% !important'
            label='ABOUT CANCELLATION'
            rows={5}
            variant='outlined'
            text_field='100%'
            setValues={setCancelDetail}
            value={cancelDetail}
          />
          <p className={classes.modal_content_box__bottom_desc}>
            By cancelling this appointment you will be forfeiting the payment and permanently cancelling this appointment. This action can not be undone.
          </p>
          <TFAButton 
            text='CANCEL APPOINTMENT'
            isLoading={isCancelLoading}
            onClick={cancelAppointment}
            style1={classes}
          />
        </div>
      </TFAModal>

      <TFAModal
        open={isLinkOpen} 
        handleClose={addLinkModalClose} 
        width={'35em'}
      >
        <div className={classes.modal_title_box}>
          <CloseBtn 
            className={classes.modal_title_box__icon} 
            onClick={addLinkModalClose}
          />
          <p className={classes.modal_title_box__title}>
            Appointment Link
          </p>
        </div>
        <div className={classes.modal_content_box}>
          <div className={classes.modal_content_box__main}>
            <p className={classes.modal_content_box__main__title}>
              Add Link
            </p>
            <p className={classes.modal_content_box__main__desc}>
              Add the appointment link below. This appointment link is used to access the virtual appointment.
            </p>
          </div>
          <TFATextInput 
            sx={{ width: '90%', margin: '1em 0 3.5em 0'}}
            label='APPOINTMENT LINK'
            type='text'
            onChange={e => setAppointmentLink(e.target.value)}
            value={appointmentLink}
          />
          <TFAButton 
            text='ADD'
            isLoading={isAddLinkLoading}
            onClick={addAppointmentLink}
            style1={classes}
          />
        </div>
      </TFAModal>
    </>
  )
}

export default Modal