import React, { useEffect, useState } from 'react'
import classes from '../Templates.module.scss'
import { DashboardSubTitle, RichTextField } from '../../../../../components/Dashboard/DashboardComponents'
import { ActiveJobs } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAButton, TFASkeleton, TFASnackBar } from '../../../../../components/TFAComponents'
import { TemplateField } from '../../../../../components/TFAInputText/MuiStyle'
import { useMutation, useQuery } from '@apollo/client'
import { GET_TEMPLATE } from '../../../../../services/apiQuery'
import { CREATE_TEMPLATE, DELETE_TEMPLATE, UPDATE_TEMPLATE } from '../../../../../services/apiMutation'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'

const EditTemplates = ({ 
  clickedTemplateId,
  setClickedTemplateId,
  isNewClicked,
  setIsNewClicked,
}) => {
  const { professional } = useProfessionalContext().professionalState
  const [ templateDetails, setTemplateDetails ] = useState({})
  const [ newTemplate, setNewTemplate ] = useState({
    title: 'New Template',
    content: 'Template Information'
  })
  const [ isLoading, setIsLoading ] = useState(false)
  const { loading: templateLoading, data: templateData, refetch: templateRefetch } = useQuery(GET_TEMPLATE, {
    variables: { getTemplateId: clickedTemplateId}
  })
  const [ createTemplate ] = useMutation(CREATE_TEMPLATE)
  const [ updateTemplate ] = useMutation(UPDATE_TEMPLATE)
  const [ deleteTemplate ] = useMutation(DELETE_TEMPLATE)
  const [ isErrorbarOpen, setIsErrorbarOpen ] = useState(false)
  const [ isCreateSuccessbarOpen, setIsCreateSuccessbarOpen ] = useState(false)
  const [ isUpdateSuccessbarOpen, setIsUpdateSuccessbarOpen ] = useState(false)

  useEffect(() => {
    templateData && setTemplateDetails(templateData.GetTemplate)
  },[templateData])

  const handleTemplateTitle = ({ target }) => {
    isNewClicked && setNewTemplate({
      ...newTemplate,
      title: target.value
    })
    !isNewClicked && setTemplateDetails({
      ...templateDetails,
      title: target.value
    })
  }

  const handleTemplateBody = ({ target }) => {
    isNewClicked && setNewTemplate({
      ...newTemplate,
      content: target.value
    })
    !isNewClicked && setTemplateDetails({
      ...templateDetails,
      content: target.value
    })
  }
  // const handleTemplateBody = (value) => {
  //   isNewClicked && setNewTemplate({
  //     ...newTemplate,
  //     content: value
  //   })
  //   !isNewClicked && setTemplateDetails({
  //     ...templateDetails,
  //     content: value
  //   })
  // }

  const submitTemplate = async () => {
    try {
      setIsLoading(true)
      if (isNewClicked) {
        const { TemplateCreate } = await callCreateTemplate()
        if (!TemplateCreate) return
        templateRefetch()
        setIsCreateSuccessbarOpen(true)
      } else {
        const { TemplateUpdate } = await callUpdateTemplate()
        if (!TemplateUpdate) return
        templateRefetch()
        setIsUpdateSuccessbarOpen(true)
      }
    } catch (error) {
      console.log(error)
      setIsErrorbarOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteTemplate = async () => {
    const { TemplateDelete } = await callDeleteTemplate()
    if (TemplateDelete) {
      setClickedTemplateId('')
      setIsNewClicked(false)
      setTemplateDetails({})
    }
  }

  const callCreateTemplate = async () => {
    const { data } = await createTemplate({
      variables: {
        template: {
          professionalId: professional.id,
          title: newTemplate.title,
          content: newTemplate.content,
        }
      }
    })
    return data
  }

  const callUpdateTemplate = async () => {
    const { data } = await updateTemplate({
      variables: {
        template: {
          id: clickedTemplateId,
          title: templateDetails.title,
          content: templateDetails.content,
        }
      }
    })
    return data
  }

  const callDeleteTemplate = async () => {
    const { data } = await deleteTemplate({
      variables: {
        templateDeleteId: clickedTemplateId,
      }
    })
    return data
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return
    setIsErrorbarOpen(false)
    setIsCreateSuccessbarOpen(false)
    setIsUpdateSuccessbarOpen(false)
  }

  return (
    <div className={classes.edit_container}>
      <DashboardSubTitle 
        Icon={ActiveJobs}
        title='Edit Templates'
      />
      <div className={classes.edit_box}>
      {(!isNewClicked && 
        Object.entries(templateDetails).length === 0) &&
        <p className={classes.empty_box_title}>
          Select a template to see details
        </p>
      } 
      {(isNewClicked || 
        Object.entries(templateDetails).length !== 0) &&
        <div className={classes.template_box}>
        {templateLoading
        ? <TFASkeleton type='template_detail' />
        :
        <>
          <div className={classes.template_box__title_box}>
            <p className={classes.template_box__title_box__label}>
              Title:
            </p>
            <input 
              className={classes.template_box__title_box__title}
              value={isNewClicked
                ? newTemplate.title
                : templateDetails.title
              }
              onChange={handleTemplateTitle}
            />
          </div>
          <div className={classes.template_box__hr_divider}></div>
          <div 
            className={classes.template_box__body_box}
          >
            <TemplateField 
              type='text'
              className={classes.template_box__body_box__body}
              value={isNewClicked
                ? newTemplate.content
                : templateDetails.content
              }
              multiline={true}
              onChange={handleTemplateBody}
            />
            {/* <RichTextField 
              value={isNewClicked
                ? newTemplate.content
                : templateDetails.content
              }
              onChange={handleTemplateBody}
            /> */}
            <TFAButton
              text='SAVE'
              onClick={submitTemplate}
              isLoading={isLoading}
              style2={classes}
            />
            <TFAButton
              text='DELETE'
              onClick={handleDeleteTemplate}
              isLoading={isLoading}
              style3={classes}
            />
          </div>
        </>
        }
        </div>
      }
      </div>
      <TFASnackBar 
        variant='error'
        isSnackbarOpen={isErrorbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message='Failed to save this template. Please try again.'
      />
      <TFASnackBar 
        variant='success'
        isSnackbarOpen={isCreateSuccessbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message='New template has been successfully created.'
      />
      <TFASnackBar 
        variant='success'
        isSnackbarOpen={isUpdateSuccessbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message='The template has been successfully updated.'
      />
    </div>
  )
}

export default EditTemplates