import React from 'react'
import classes from '../PProfile.module.scss'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { Certificate, Documents } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import { SINGLE_DOWNLOAD } from '../../../../../services/apiQuery'

const LPNStatus = ({ professional, updateMaraModalOpen }) => {
	const [singleDownload] = useLazyQuery(SINGLE_DOWNLOAD)
	const today = moment()
	const lpnExpiry = moment(professional?.lpn_expiry_date)
	const isExpired = moment(lpnExpiry).isAfter(today, 'day')

	const callSingleDownload = async (filename) => {
		const { data } = await singleDownload({
			variables: { filename },
		})
		window.open(data?.SingleDownload)
	}

	console.log(professional)

	return (
		<div className={classes.mara_status_container}>
			<DashboardSubTitle Icon={Certificate} title='LPN Certificate Status' fontSize='1.4em' color='#2F68FA' iconSize='scale(1.8)' />
			<div className={classes.mara_box}>
				<div className={classes.mara_box__contents}>
					<Certificate className={classes.mara_box__status_icon_big} />
					<div className={classes.mara_box__text_box}>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>LPN Number:</p>
							<p className={classes.mara_box__text_box__container__detail}>{professional?.LPNNumber}</p>
						</div>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>Expiry Date:</p>
							<p className={classes.mara_box__text_box__container__detail}>{moment(professional?.lpn_expiry_date).format('DD-MMM-YYYY')}</p>
						</div>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>Certificate:</p>
							<p className={classes.mara_box__text_box__container__detail}>
								{professional?.lpn_documents?.map((document, index) => (
									<Documents
										className={classes.mara_box__text_box__container__detail__document}
										onClick={() => callSingleDownload(document.key)}
										key={index}
									/>
								))}
							</p>
						</div>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>Status:</p>
							<p
								className={
									isExpired ? classes.mara_box__text_box__container__detail_active : classes.mara_box__text_box__container__detail_inactive
								}
							>
								{isExpired ? 'Active' : 'Inactive'}
							</p>
						</div>
						<p className={classes.mara_contact_text}>
							{isExpired && 'Please contact support to upload LPN certificate'}
							<a href='mailto:admin@timeforadvice.com' className={classes.mara_contact_text__address}>
								&nbsp;Send Email
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LPNStatus
