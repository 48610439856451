import React from 'react'
import classes from './MultiPages.module.scss'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { CREATE_QUESTIONS } from '../../../../../assets/data/SelectOptions'
import { ProgressState } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAText, TFAButton } from '../../../../../components/TFAComponents'
import { useLazyQuery } from '@apollo/client'
import { GET_PRESIGNED_UPLOAD_URL } from '../../../../../services/apiQuery'
import UploadImagesFiles from '../../../../../components/Dashboard/UploadImagesFiles/UploadImagesFiles'

const Media = ({ formik, currentPage, setCurrentPage }) => {
  const [ getUploadURL ] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)

  const callgetUploadURL = async (file) => {
    const { data } = await getUploadURL({
      variables: {
        filename: file.name,
        folder: 'question'
      }
    })
    return data
  }

  return (
    <>
      <div
        className={classes.backBtn_box}
        onClick={() => setCurrentPage(CREATE_QUESTIONS.DETAILS)}
      >
        <LeftArrow className={classes.backBtn_box__arrow} />
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.progress_box}>
        <p className={classes.progress_box__title}>
          Progress:
        </p>
        <div className={classes.progress_box__state}>
          <ProgressState 
            stateName='create_question'
            status={currentPage}
            variant='list'
            style={classes}
          />
        </div>
      </div>
      <div className={classes.main_content_container}>
        <TFAText 
          primary_title='Images &amp; Files'
          secondary_title='Some extras'
          style1={classes}
        />
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Add Images
        </p>
        <p className={classes.title_box__secondary}>
          Attach images to help your professional understand your situation.
        </p>
      </div>
      <UploadImagesFiles 
        formik={formik}
        callgetUploadURL={callgetUploadURL}
        type='images'
      />
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Attach Files
        </p>
        <p className={classes.title_box__secondary}>
          Attach files to help your professional understand your situation.
        </p>
      </div>
      <UploadImagesFiles
        formik={formik}
        callgetUploadURL={callgetUploadURL}
        type='files'
      />
      <div className={classes.btn_box}>
        <TFAButton 
          text='NEXT'
          onClick={() => setCurrentPage(CREATE_QUESTIONS.PRICE)}
          style1={classes}
        />
      </div>
    </>
  )
}

export default Media