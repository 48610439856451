import React from 'react'
import classes from './MultiPages.module.scss'
import { CREATE_APPOINTMENT } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { ProgressState } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAText, TFASimpleInput, TFAButton } from '../../../../../components/TFAComponents'
import validatePage from '../../../../../utils/validatePage'

const Details = ({ formik, currentPage, setCurrentPage }) => {
  const { title, context } = formik.values

  const handleSubmit = async () => {
    const isPageValid = await validatePage({title, context}, formik)
    if (!isPageValid) return
    setCurrentPage(CREATE_APPOINTMENT.CONFIRM)
  }

  return (
    <>
      <div 
        className={classes.backBtn_box}
        onClick={() => setCurrentPage(CREATE_APPOINTMENT.TIME)}
      >
        <LeftArrow className={classes.backBtn_box__arrow}/>
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.progress_box}>
        <p className={classes.progress_box__title}>
          Progress:
        </p>
        <div className={classes.progress_box__state}>
          <ProgressState 
            stateName='create_appointment'
            status={currentPage}
            variant='list'
            style={classes}
          />
        </div>
      </div>
      <div className={classes.main_content_container}>
        <TFAText 
          primary_title='Appointment Details'
          secondary_title='What do you need help with?'
          style1={classes}
        />
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Appointment Title
        </p>
        <p className={classes.title_box__secondary}>
          Your 
          <span className={classes.title_box__secondary__bold}>
          &nbsp;Appointment Title&nbsp;
          </span>
          gives an outline of what you want to discuss.
        </p>
        <div className={classes.title_box__simple_input}>
          <TFASimpleInput 
            box_width='100%'
            box_margin='0'
            width='100% !important'
            label='APPOINTMENT TITLE'
            rows={5}
            variant='outlined'
            name='title'
            text_field='100%'
            formik={formik}
            error={formik.errors.title ?? null}
          />
        </div>
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Appointment Context
        </p>
        <p className={classes.title_box__secondary}>
          Provide some context to your appointment. The more information you can provide, the better professionals can prepare for you appointment.
        </p>
        <div className={classes.title_box__simple_input}>
          <TFASimpleInput 
            box_width='100%'
            box_margin='0'
            width='100% !important'
            label='APPOINTMENT CONTEXT'
            rows={5}
            variant='outlined'
            name='context'
            text_field='100%'
            formik={formik}
            error={formik.errors.context ?? null}
          />
        </div>
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='NEXT'
          onClick={handleSubmit}
          style1={classes}
          disabled={title === '' || context === ''}
        />
      </div>
    </>
  )
}

export default Details