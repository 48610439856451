import React, { useState } from 'react'
import classes from '../Auth.module.scss'
import login_class from './Login.module.scss'
import { LoginImg } from '../../../../assets/images/Auth/AuthImages'
import { User } from '../../../../assets/icons/Auth/AuthIcons'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { clientLoginSchema } from '../../../../utils/validation'
import { TFALink, TFAText, TFAButton, TFAInputText, TFASnackBar } from '../../../../components/TFAComponents'
import useClientContext from '../../../../states/Client/useClientContext'
import { useMutation } from '@apollo/client'
import { CLIENT_LOGIN } from '../../../../services/apiMutation'

const Login = () => {
	const { clientDispatch } = useClientContext()
	const [clientLogin] = useMutation(CLIENT_LOGIN)
	const navigate = useNavigate()
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: clientLoginSchema,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { ClientLogin } = await callClientLogin(values)
				if (!ClientLogin) return
				localStorage.setItem('TFAToken', ClientLogin.JWT)
				localStorage.setItem('TFATokenType', 'ClientToken')
				clientDispatch({ type: 'setClient', data: ClientLogin })
				navigate('/c_dashboard/questions')
			} catch (error) {
				setIsSnackbarOpen(true)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	const callClientLogin = async (values) => {
		const { data } = await clientLogin({
			variables: {
				email: values.email,
				password: values.password,
			},
		})
		return data
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.auth_wrapper}>
			<div className={classes.left_container}>
				<img src={LoginImg} className={classes.left_container__img} alt='login' />
			</div>
			<div className={classes.right_container}>
				<div className={classes.center_box}>
					<TFALink text='RETURN' backward={true} to='auth' style1={login_class} />
					<div className={login_class.right_container__title}>
						<TFAText primary_title='Welcome back' secondary_title='Sign back into your account' style1={login_class} />
						<User className={login_class.user_icon} />
					</div>
					<div className={login_class.input_wrapper}>
						<TFAInputText
							label='EMAIL'
							type='email'
							name='email'
							placeholder='sample@timeforadvice.com'
							formik={formik}
							error={formik.errors.email ?? null}
						/>
						<TFAInputText
							label='PASSWORD'
							type='password'
							name='password'
							placeholder='Enter Password'
							formik={formik}
							error={formik.errors.password ?? null}
						/>
					</div>
					<div className={login_class.auth_btn_wrapper}>
						<TFAButton text='LOGIN' isLoading={formik.isSubmitting} onClick={formik.handleSubmit} style1={login_class} />
					</div>
					<div className={login_class.forgotPwd_text}>Forgotten your Time For Advice account password?</div>
					<TFALink text='RETRIEVE ACCOUNT' aiforward={true} to='retrieve_client_account' style1={login_class} />
				</div>
			</div>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to login, Please try again.'
			/>
		</div>
	)
}

export default Login
