import React from 'react'
import classes from './TFAStripeInput.module.scss'
import { CardElement } from '@stripe/react-stripe-js'
import { TFAInputText } from '../../TFAComponents'
import { Alert } from '@mui/material'

const TFAStripeInput = ({ 
  formik,
  errorMessage,
  setCardDetail
}) => {
  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        fontFamily: "'Space Grotesk', sans-serif",
        fontSmoothing: "antialiased",
        fontSize: '1.05em',
        margin: 'auto 0',
        '::placeholder': {
          fontFamily: "'Space Grotesk', sans-serif",
          color: "#022873",
          fontWeight: '700',
          fontSize: '1.05em',
          textTransform: 'uppercase'
        },
        ':-webkit-autofill': {
          color: "#022873",
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    }
  }
  
  return (
    <div className={classes.stripe_container}>
      <TFAInputText 
        label='ACCOUNT NAME'
        type='text'
        name='cardName'
        formik={formik}
        error={formik?.errors?.cardName ?? null}
      />
      <CardElement 
        options={CARD_ELEMENT_OPTIONS} 
        placeholder='test'
        className={classes.StripeElement}
        onChange={e => setCardDetail(e)}
      />
      {errorMessage && 
        <Alert 
          severity='error' 
          sx={{
            padding: '0 1em', 
            marginTop: '0.3em'
          }}
        >
            {errorMessage}
        </Alert>
      }
    </div>
  )
}

export default TFAStripeInput