import React, { useEffect, useState } from 'react'
import classes from '../PAppointments.module.scss'
import {
  AppointmentCard,
  DashboardSubTitle,
  DashboardTitle,
  Searchbar
} from '../../../../../components/Dashboard/DashboardComponents'
import {
  UpcomingAppointment,
  CompletedAppointment
} from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFASkeleton } from '../../../../../components/TFAComponents'
import { useMutation, useQuery } from '@apollo/client'
import { GET_APPOINTMENTS } from '../../../../../services/apiQuery'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'
import { UPDATE_APPOINTMENT } from '../../../../../services/apiMutation'
import moment from 'moment'

const Appointments = ({ appointmentId, setAppointmentId }) => {
  const { professional } = useProfessionalContext().professionalState
  const [ searchText, setSearchText ] = useState('')
  const [ upcomingMeetings, setUpcomingMeetings ] = useState([])
  const [ completedMeetings, setCompletedMeetings ] = useState([])
  const { loading: upcomingMeetingLoading, data: upcomingMeetingData } = useQuery(GET_APPOINTMENTS, {
    variables: { 
      professionalId: professional?.id,
      progress: [ 'Sent', 'Meeting' ],
      title: searchText,
    }
  })
  const { loading: completedMeetingLoading, data: completedMeetingData } = useQuery(GET_APPOINTMENTS, {
    variables: { 
      professionalId: professional?.id,
      progress: [ 'Cancelled', 'Complete' ]
    }
  })
  const [ updateAppointment ] = useMutation(UPDATE_APPOINTMENT)

  useEffect(() => {
    if (upcomingMeetingData) {
      const allUpcomingMeetings = upcomingMeetingData.GetAppointments.objectList
      const sortedUpcomingMeetings = allUpcomingMeetings.slice().sort((a, b) => moment(a.timeStart) - moment(b.timeStart))
      setUpcomingMeetings(sortedUpcomingMeetings)
    }
    completedMeetingData && setCompletedMeetings(completedMeetingData.GetAppointments.objectList)
  },[upcomingMeetingData, completedMeetingData])

  const clickUpcomingAppointment = async (appointment) => {
    try {
      if (appointment.progress === 'Sent') {
        const { AppointmentUpdate } = await callUpdateAppointment(appointment.id)
        if (!AppointmentUpdate === 'success') return 
        setAppointmentId(appointment.id)
      } else {
        setAppointmentId(appointment.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const callUpdateAppointment = async (id) => {
    const { data } = await updateAppointment({
      variables: {
        appointment: {
          id,
          progress: 'Meeting'
        }
      }
    })
    return data
  }

  return (
    <div className={classes.appointment_container}>
      <DashboardTitle title='Appointments' />
      <Searchbar 
        bg={true}
        setSearchText={setSearchText}
      />
      <div className={classes.appointment_box}>
        <div className={classes.appointment_box__upcoming}>
          <DashboardSubTitle 
            Icon={UpcomingAppointment} 
            title='Upcoming Appointments'
          />
          {upcomingMeetingLoading
          ? <TFASkeleton 
              type='question_card'
              count={2}
            />
          :
          upcomingMeetings.length === 0
          ? <div className={classes.empty_appointment_box}>
              No Upcoming Meetings
            </div>
          : upcomingMeetings.map((appointment, index) => (
              <AppointmentCard
                appointment={appointment}
                key={index}
                clickedMeetingId={appointmentId}
                clickAppointmentCard={() => clickUpcomingAppointment(appointment)}
                page='professional'
              />
            ))
          }
        </div>
        <div className={classes.appointment_box__completed}>
          <DashboardSubTitle 
            Icon={CompletedAppointment} 
            title='Completed Appointments' 
          />
          {completedMeetingLoading
          ? <TFASkeleton 
              type='question_card'
              count={2}
            />
          : 
          completedMeetings.length === 0
          ? <div className={classes.empty_appointment_box}>
              No Completed Appointments
            </div>
          : completedMeetings.map((appointment, index) => (
              <AppointmentCard
                appointment={appointment}
                key={index}
                clickedMeetingId={appointmentId}
                clickAppointmentCard={() => setAppointmentId(appointment.id)}
                page='professional'
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Appointments