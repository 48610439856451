import React from 'react'
import classes from './ProgressState.module.scss'
import {
    SignupProgressState,
    QuestionProgressState,
    ReplyLogState,
    AppointmentProgressState,
    MeetingProgressState,
    CreateQuestionsProgressState,
    CreateAppointmentProgressState,
} from '../../../assets/data/ProgressState'

const ProgressState = ({ 
    stateName, 
    status,
    cancelled,
    variant,
    style
}) => {
    const StateName = () => {
        switch (stateName) {
            case 'signup': return SignupProgressState
            case 'question': return QuestionProgressState
            case 'reply_log': return ReplyLogState
            case 'appointment': return AppointmentProgressState
            case 'meeting': return MeetingProgressState
            case 'create_question': return CreateQuestionsProgressState
            case 'create_appointment': return CreateAppointmentProgressState
            default: break;
        }
    }

    const createMultiProgressStates = () => {
        let completed = [], active = [], uncompleted = []
        const initialStates = StateName()
        const activeIndex = initialStates?.findIndex( s => s.title === status)
        initialStates?.forEach((state, index) => {
            if (index < activeIndex) 
                completed = [...completed, {...state, status: 0}]
            if (index === activeIndex) 
                cancelled
                ? active = [...active, {...state, status: 3}]
                : active = [...active, {...state, status: 1}]
            if (index > activeIndex) 
                uncompleted = [...uncompleted, {...state, status: 2}]
        })
        const progressStates = completed.concat(active, uncompleted)
        return progressStates
    }

    const createSingleProgressState = () => {
        const initialStates = StateName()
        const currentState = initialStates?.find(s => s.title === status)
        if (stateName === 'appointment') 
            return [{...currentState}]
        if (currentState?.title === 'Complete' || 
            currentState?.title === 'Answered' || 
            currentState?.title === 'Accepted'
        )   return [{...currentState, status: 0}]
        else 
            return [{...currentState, status: 1}]
    }

    const stateColor = (status) => {
        switch (status) {
            case 0: return `${classes.completed_state}`
            case 1: return `${classes.active_state}`
            case 2: return `${classes.uncompleted_state}`
            case 3: return `${classes.cancelled_state}`
            default: break;
        }
    }

    return (
    <div className={`${classes.state_box} ${style && style.state_container}`}>
        {variant === 'list' &&
            createMultiProgressStates().map(({ title, status }, index) => (
                <p 
                    className={`${stateColor(status)} ${classes.state} ${style && style.state_icon}`} 
                    key={index}
                >
                    {title}
                </p>
            ))
        }
        {variant === 'card' &&
            createSingleProgressState().map(({ title, status }, index) => (
                <p 
                    className={`${stateColor(status)} ${classes.single_state}`} 
                    key={index}
                >
                    {title}
                </p>
            ))
        }
    </div>
    )
}

export default ProgressState