import React from 'react'
import classes from './TFADirectDebitInput.module.scss'
import { TFAInputText } from '../../TFAComponents'
import { Alert } from '@mui/material'

const TFADirectDebitInput = ({ formik, bg }) => {

    const addBSBDash = (number) => {
        if (number.length >= 4) {
            const firstThree = number.slice(0,3)
            const lastThree = number.slice(3)
            return `${firstThree}-${lastThree}`
        }
        if (number.length < 4) {
            const firstThree = number.slice(0,3)
            return firstThree
        }
        return number
    }

    const handleBSBChange = ({ target }) => {
        const splitByDash = target.value.split('-').join('')
        formik.setFieldValue(formik.values.bsb = splitByDash)
    }

    return (
        <div className={classes.stripe_container}>
            <TFAInputText 
                label='ACCOUNT NAME'
                type='text'
                name='accountName'
                formik={formik}
                error={formik?.errors?.accountName ?? null}
            />
            <div className={classes.bank_account_box}>
                <input 
                    name='bsb'
                    value={addBSBDash(formik.values.bsb)}
                    placeholder='BSB'
                    className={bg
                        ? classes.bank_account_box__bsb_dashboard
                        : classes.bank_account_box__bsb
                    }
                    maxLength='7'
                    onChange={handleBSBChange}
                />
                 <input 
                    name='accountNumber'
                    value={formik.values.accountNumber}
                    type='text'
                    placeholder='ACCOUNT NUMBER'
                    className={bg
                        ? classes.bank_account_box__account_number_dashboard
                        : classes.bank_account_box__account_number
                    }
                    onChange={formik.handleChange}
                />
            </div>
            {formik.touched.accountNumber && formik.errors.accountNumber && 
                <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {formik.errors.accountNumber}
                </Alert>
            }
            {formik.touched.bsb && formik.errors.bsb && 
                <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {formik.errors.bsb}
                </Alert>
            }
        </div>
    )
}

export default TFADirectDebitInput