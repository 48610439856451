import React, { useState } from 'react'
import classes from '../Kits.module.scss'
import { DashboardTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { useQuery } from '@apollo/client'
import { GET_KIT } from '../../../../../services/apiQuery'
import { useEffect } from 'react'
import { TFASkeleton } from '../../../../../components/TFAComponents'
import moment from 'moment'
import { CardArrow } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { downloadFile } from '../../../../../utils/downloadFiles'

const KitDetails = ({ purchasedKitId, setPurchasedKitId }) => {
  const [ kitDetails, setKitDetails ] = useState({})
  const { loading: kitLoading, data: kitData } = useQuery(GET_KIT, {
    variables: { getKitId: purchasedKitId }
  })
  const purchasedDate = kitDetails && moment(kitDetails.date).format('D MMMM YYYY')

  useEffect(() => {
    kitData && setKitDetails(kitData.GetKit)
  },[kitData])
  return (
    <div className={classes.details_container}>
    {Object.entries(kitDetails).length === 0
    ? <div className={classes.details_container__empty_box}>
        <p className={classes.details_container__empty_box__text}>
          Purchase a kit to see details
        </p>
      </div>
    : <>
        <DashboardTitle 
          title={`Kit ${kitDetails.subclass}`}
        />
        {kitLoading
        ? <TFASkeleton type='detail' />
        : <div className={classes.details}>
            <div className={classes.your_kit_container}>
              <p className={classes.your_kit_container__title}>
                Your Kit
              </p>
              <div className={classes.your_kit_container__details}>
                <p className={classes.your_kit_container__details__title}>
                  {kitDetails.title}
                </p>
                <p className={classes.your_kit_container__details__content}>
                  {kitDetails.content}
                </p>
                <div className={classes.your_kit_container__details__state}>
                  <p className={classes.your_kit_container__details__state__progress}>
                    Purchased
                  </p>
                  <p className={classes.your_kit_container__details__state__date}>
                    {purchasedDate}
                  </p>
                </div>
              </div>
            </div>
            <div 
              className={classes.view_kit_box}
              onClick={() => downloadFile(kitDetails)}
            >
              <p className={classes.view_kit_box__title}>
                View Kit
              </p>
              <div className={classes.view_kit_box__arrow_box}>
                <CardArrow />
              </div>
            </div>
            <div className={classes.kit_detail_box}>
              <p className={classes.kit_detail_box__title}>
                Kit Details
              </p>
              <p>
                This kit is designed to give you a solid starting point in the preparation and submission of your own visa application.<br/><br/> 
                No assessment has been made of your personal circumstances and the information contained within the kit is supplied only as a comprehensive compilation of necessary information and tools (otherwise available from other sources) required to complete a visa application.<br/><br/> 
                The kit is prepared and supplied by an independent expert and Time For Advice accepts no responsibility for the representations made by the expert in this kit.<br/><br/> 
                Time For Advice recommends obtaining an independent assessment of your migration options and possible outcomes before undertaking any visa application. This kit is designed to be used in conjunction with other Time For Advice tools in order to complete your application.
              </p>
            </div>
          </div>
        }
      </>
      
    }
    </div>
  )
}

export default KitDetails