import React, { useEffect, useState } from 'react'
import classes from './CAppointments.module.scss'
import { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import Appointments from './Sections/Appointment'
import Details from './Sections/Details'
import EditAppointment from '../EditAppointment/EditAppointment'
import { useLocation } from 'react-router-dom'

const CAppointments = () => {
  const [ appointmentId, setAppointmentId ] = useState('')
  const [ isUpdateAppointmentOpen, setIsUpdateAppointmentOpen ] = useState(false)
  const location = useLocation()

  useEffect(() => {
    location.state && setAppointmentId(location.state)
  },[location])

  return (
    <>
      {isUpdateAppointmentOpen
      ? <EditAppointment 
          setIsUpdateAppointmentOpen={setIsUpdateAppointmentOpen}
          appointmentId={appointmentId}
        />
      : <div className={classes.wrapper}>
          <Sidebar pageSide='client' tabName='Appointments' />
          <div className={classes.main_content_box}>
            <Appointments
              appointmentId={appointmentId}
              setAppointmentId={setAppointmentId}
            />
            <Details 
              appointmentId={appointmentId}
              setIsUpdateAppointmentOpen={setIsUpdateAppointmentOpen}
            />
          </div>
        </div>
      }
    </>
  )
}

export default CAppointments