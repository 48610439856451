import React, { useEffect, useState } from 'react'
import classes from './Questions.module.scss'
import { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import MyQuestions from './Sections/MyQuestions'
import Details from './Sections/Details'
import EditQuestion from '../EditQuestion/EditQuestion'
import { useLocation } from 'react-router-dom'

const Questions = () => {
  const [ questionId, setQuestionId ] = useState('') 
  const [ isUpdateQuestionOpen, setIsUpdateQuestionOpen ] = useState(false)
  const location = useLocation()

  useEffect(() => {
    location.state && setQuestionId(location.state)
  },[location])

  return (
    <>
      {isUpdateQuestionOpen
      ? <EditQuestion 
          setIsUpdateQuestionOpen={setIsUpdateQuestionOpen}
          questionId={questionId}
        />
      :
      <div className={classes.wrapper}>
        <Sidebar pageSide='client' tabName='Questions' />
        <div className={classes.main_content_box}>
          <MyQuestions 
            questionId={questionId}
            setQuestionId={setQuestionId} 
          />
          <Details 
            questionId={questionId}
            setIsUpdateQuestionOpen={setIsUpdateQuestionOpen}
          />
        </div>
      </div>
      }
    </>
  )
}

export default Questions