import { gql } from '@apollo/client'

// Professional Signup
const PROFESSIONAL_SIGNUP_STEP1 = gql`
	mutation ProfessionalSignupStep1(
		$professional: ProfessionalInput
		$documents: [ProfsupportingdocumentInput]
		$maraDocuments: [MARADocumentInput]
		$lpnDocuments: [LpnDocumentInput]
		$professionalspecialties: [ProfessionalSpecialtyEnumType]
	) {
		ProfessionalSignupStep1(
			professional: $professional
			documents: $documents
			MARADocuments: $maraDocuments
			lpnDocuments: $lpnDocuments
			professionalspecialties: $professionalspecialties
		)
	}
`

const AVATAR_UPLOAD_MUTATION = gql`
	mutation SingleUpload($file: Upload, $folder: S3FoldersEnum!, $isAsync: Boolean) {
		SingleUpload(file: $file, folder: $folder, isAsync: $isAsync) {
			filename
			key
			link
		}
	}
`

const PROFESSIONAL_SIGNUP_STEP2 = gql`
	mutation ProfessionalSignupStep2(
		$stripeAccount: StripeAccountType!
		$professional: ProfessionalInput
		$meetingSchedules: [MeetingscheduleInput]
	) {
		ProfessionalSignupStep2(StripeAccount: $stripeAccount, professional: $professional, meetingSchedules: $meetingSchedules) {
			JWT
			id
			firstName
			lastName
			email
			mobile
			professionalspecialties {
				id
				specialty
			}
			MARANumber
			MARAexpiryDate
			LPNNumber
			avatar
			bio
			firstLanguage
			secondLanguage
			experienceLevel
			stripe_accountId
			streetNumber
			streetName
			suburb
			postcode
			state
			country
			askingPrice
			questionPrice
		}
	}
`

// Professional Login
const PROFESSIONAL_LOGIN = gql`
	mutation ProfessionalLogin($email: String, $password: String) {
		ProfessionalLogin(email: $email, password: $password) {
			JWT
			id
			firstName
			lastName
			email
			mobile
			professionalspecialties {
				id
				specialty
			}
			dayOfBirth
			monthOfBirth
			yearOfBirth
			MARANumber
			MARAexpiryDate
			LPNNumber
			avatar
			bio
			firstLanguage
			secondLanguage
			experienceLevel
			stripe_accountId
			streetNumber
			streetName
			suburb
			postcode
			state
			country
			askingPrice
			questionPrice
		}
	}
`

// Retrieve Password
const RESET_PASSWORD_STEP1 = gql`
	mutation ResetPasswordStep1($email: String) {
		ResetPasswordStep1(email: $email)
	}
`

const RESET_PASSWORD_STEP2 = gql`
	mutation ResetPasswordStep2($email: String, $verifyCode: String) {
		ResetPasswordStep2(email: $email, verifyCode: $verifyCode) {
			id
			JWT
		}
	}
`

const CLIENT_RESET_PASSWORD_STEP1 = gql`
	mutation ClientResetPasswordStep1($email: String) {
		ClientResetPasswordStep1(email: $email)
	}
`

const CLIENT_RESET_PASSWORD_STEP2 = gql`
	mutation ClientResetPasswordStep2($email: String, $verifyCode: String) {
		ClientResetPasswordStep2(email: $email, verifyCode: $verifyCode) {
			id
			JWT
		}
	}
`

// Questions
const QUESTION_UPDATE = gql`
	mutation QuestionUpdate($question: QuestionInput) {
		QuestionUpdate(question: $question)
	}
`

const EDIT_QUESTION = gql`
	mutation QuestionUpdate($question: QuestionInput, $imageKeys: [String], $fileKeys: [String]) {
		QuestionUpdate(question: $question, imageKeys: $imageKeys, fileKeys: $fileKeys)
	}
`

const CREATE_QUESTION_REPLY = gql`
	mutation Root_mutation_type_v1($questionreply: QuestionreplyInput, $questionReplyFileKeys: [String]) {
		QuestionreplyCreate(questionreply: $questionreply, questionReplyFileKeys: $questionReplyFileKeys) {
			id
			content
			stage
			createdAt
			professional {
				id
				firstName
				lastName
			}
			questionreplyfiles {
				id
				key
				link
			}
		}
	}
`

const GIVE_QUESTION_ADVICE = gql`
	mutation Root_mutation_type_v1($questionpoolId: String) {
		QuestionofferGiveAdvice(questionpoolId: $questionpoolId) {
			id
		}
	}
`

const CREATE_QUESTION = gql`
	mutation QuestionCreate($question: QuestionInput, $paypalOfferId: String, $imageKeys: [String], $fileKeys: [String], $isPool: Boolean) {
		QuestionCreate(question: $question, paypalOfferId: $paypalOfferId, imageKeys: $imageKeys, fileKeys: $fileKeys, isPool: $isPool) {
			id
		}
	}
`

const CREATE_QUESTION_OFFER = gql`
	mutation QuestionofferCreate($questionoffer: QuestionofferInput) {
		QuestionofferCreate(questionoffer: $questionoffer) {
			id
			price
			message
		}
	}
`

const ACCEPT_QUESTION_OFFER = gql`
	mutation QuestionofferAcceptOffer($questionofferId: String, $paypalOfferId: String, $sourceId: String) {
		QuestionofferAcceptOffer(questionofferId: $questionofferId, paypalOfferId: $paypalOfferId, sourceId: $sourceId) {
			id
		}
	}
`

const UPDATE_QUESTION_OFFER = gql`
	mutation QuestionofferUpdate($questionoffer: QuestionofferInput) {
		QuestionofferUpdate(questionoffer: $questionoffer)
	}
`

const DELETE_QUESTION = gql`
	mutation Root_mutation_type_v1($questionDeleteId: String) {
		QuestionDelete(id: $questionDeleteId)
	}
`

// Appointments
const UPDATE_APPOINTMENT = gql`
	mutation AppointmentUpdate($appointment: AppointmentInput) {
		AppointmentUpdate(appointment: $appointment)
	}
`

const CREATE_CLIENT_APPOINTMENT = gql`
	mutation AppointmentCreate($appointment: AppointmentInput) {
		AppointmentCreate(appointment: $appointment) {
			id
		}
	}
`

// Templates
const CREATE_TEMPLATE = gql`
	mutation TemplateCreate($template: TemplateInput) {
		TemplateCreate(template: $template) {
			id
			title
			content
		}
	}
`

const UPDATE_TEMPLATE = gql`
	mutation TemplateUpdate($template: TemplateInput) {
		TemplateUpdate(template: $template)
	}
`

const DELETE_TEMPLATE = gql`
	mutation TemplateDelete($templateDeleteId: String) {
		TemplateDelete(id: $templateDeleteId)
	}
`

// Profile
const CHECK_CURRENT_PASSWORD = gql`
	mutation ProfessionalLogin($password: String, $email: String) {
		ProfessionalLogin(password: $password, email: $email) {
			id
		}
	}
`

const CHECK_CLIENT_PASSOWORD = gql`
	mutation ClientLogin($email: String, $password: String) {
		ClientLogin(email: $email, password: $password) {
			id
		}
	}
`

const UPDATE_PROFILE = gql`
	mutation ProfessionalUpdate($professional: ProfessionalInput, $account: StripeExternalAccountType) {
		ProfessionalUpdate(professional: $professional, account: $account)
	}
`

const UPDATE_AVAILABILITY = gql`
	mutation MeetingScheduleUpdate($meetingSchedules: [MeetingscheduleInput]) {
		MeetingScheduleUpdate(meetingSchedules: $meetingSchedules) {
			id
			startTime
			endTime
		}
	}
`

const UPDATE_CLIENT_PROFILE = gql`
	mutation ClientUpdate($client: ClientInput) {
		ClientUpdate(client: $client)
	}
`

const CREATE_REPORT = gql`
	mutation ReportCreate($report: ReportInput) {
		ReportCreate(report: $report) {
			id
		}
	}
`

const CREATE_STRIPE_CARD = gql`
	mutation Stripe_CreateCard($clientId: String!, $sourceId: String!) {
		Stripe_CreateCard(clientId: $clientId, sourceId: $sourceId)
	}
`

const DELETE_STRIPE_CARD = gql`
	mutation Stripe_DeleteCard($clientId: String!, $sourceId: String!) {
		Stripe_DeleteCard(clientId: $clientId, sourceId: $sourceId)
	}
`

const UPDATE_DEFAULT_CARD = gql`
	mutation Stripe_UpdateDefaultCard($clientId: String!, $sourceId: String!) {
		Stripe_UpdateDefaultCard(clientId: $clientId, sourceId: $sourceId)
	}
`

// Client Signup
const CLIENT_SIGNUP_STEP1 = gql`
	mutation ClientSignupStep1($email: String) {
		ClientSignupStep1(email: $email)
	}
`

const CLIENT_VERIFY_CODE = gql`
	mutation ClientVerifyCode($verifyCode: String, $email: String) {
		ClientVerifyCode(verifyCode: $verifyCode, email: $email) {
			result
		}
	}
`

const CLIENT_SIGNUP_STEP2 = gql`
	mutation ClientSignupStep2($verifyCode: String, $client: ClientInput) {
		ClientSignupStep2(verifyCode: $verifyCode, client: $client) {
			JWT
			id
			firstName
			lastName
			email
			mobile
			avatarLink
			countryOfOrigin
			firstLanguage
			secondLanguage
			paymentPreference
			stripe_customId
			status
			questionsAsked
		}
	}
`

// Client Login
const CLIENT_LOGIN = gql`
	mutation ClientLogin($email: String, $password: String) {
		ClientLogin(email: $email, password: $password) {
			JWT
			id
			firstName
			lastName
			email
			mobile
			avatarLink
			countryOfOrigin
			firstLanguage
			secondLanguage
			paymentPreference
			stripe_customId
			status
			questionsAsked
		}
	}
`

// Kits
const KIT_PURCHASE = gql`
	mutation Root_mutation_type_v1($kitId: String, $sourceId: String, $paypalOfferId: String, $clientId: String) {
		KitPurchase(kitId: $kitId, sourceId: $sourceId, paypalOfferId: $paypalOfferId, clientId: $clientId)
	}
`

// Notification
const UPDATE_NOTIFICATION = gql`
	mutation NotificationUpdate($notification: NotificationInput) {
		NotificationUpdate(notification: $notification)
	}
`

export {
	PROFESSIONAL_SIGNUP_STEP1,
	AVATAR_UPLOAD_MUTATION,
	PROFESSIONAL_SIGNUP_STEP2,
	PROFESSIONAL_LOGIN,
	RESET_PASSWORD_STEP1,
	RESET_PASSWORD_STEP2,
	CLIENT_RESET_PASSWORD_STEP1,
	CLIENT_RESET_PASSWORD_STEP2,
	QUESTION_UPDATE,
	EDIT_QUESTION,
	CREATE_QUESTION_REPLY,
	GIVE_QUESTION_ADVICE,
	CREATE_QUESTION,
	CREATE_QUESTION_OFFER,
	ACCEPT_QUESTION_OFFER,
	UPDATE_QUESTION_OFFER,
	DELETE_QUESTION,
	UPDATE_APPOINTMENT,
	CREATE_CLIENT_APPOINTMENT,
	CREATE_TEMPLATE,
	UPDATE_TEMPLATE,
	DELETE_TEMPLATE,
	CHECK_CURRENT_PASSWORD,
	CHECK_CLIENT_PASSOWORD,
	UPDATE_PROFILE,
	UPDATE_AVAILABILITY,
	UPDATE_CLIENT_PROFILE,
	CREATE_REPORT,
	CREATE_STRIPE_CARD,
	DELETE_STRIPE_CARD,
	UPDATE_DEFAULT_CARD,
	CLIENT_SIGNUP_STEP1,
	CLIENT_VERIFY_CODE,
	CLIENT_SIGNUP_STEP2,
	CLIENT_LOGIN,
	KIT_PURCHASE,
	UPDATE_NOTIFICATION,
}
