import React from 'react'
import classes from './MultiPageStyle.module.scss'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { TFAInputText } from '../../../../../components/TFAComponents'
import { COMPLETE_SIGNUP_PAGES } from '../../../../../assets/data/SelectOptions'
import AutoAddressComplete from '../../../../../components/Register/AutoAddressComplete/AutoAddressComplete'

const Address = ({ formik, setCurrentPage }) => {
	return (
		<div>
			<div className={classes.backBtn_box} onClick={() => setCurrentPage(COMPLETE_SIGNUP_PAGES.AVAILABILITY)}>
				<LeftArrow className={classes.backBtn_box__arrow} />
				<p className={classes.backBtn_box__txt}>RETURN TO SCHEDULE AND RATE</p>
			</div>
			<div className={classes.section_title_box}>
				<p className={classes.section_title_box__main}>Your Address</p>
				<p className={classes.section_title_box__desc}>
					Enter your residential address below. We need these details to successfully transact payments to you.
				</p>
			</div>
			<div className={classes.search_address_box}>
				<AutoAddressComplete formik={formik} />
			</div>
			<div className={classes.address_box}>
				<TFAInputText
					label='STREET NUMBER'
					type='text'
					name='streetNumber'
					form_width='100%'
					formik={formik}
					error={formik?.errors?.streetNumber ?? null}
				/>
				<TFAInputText
					label='STREET NAME'
					type='text'
					name='streetName'
					form_width='100%'
					formik={formik}
					error={formik?.errors?.streetName ?? null}
				/>
				<TFAInputText label='SUBURB' type='text' name='suburb' form_width='100%' formik={formik} error={formik?.errors?.suburb ?? null} />
				<TFAInputText label='POSTCODE' type='text' name='postcode' form_width='100%' formik={formik} error={formik?.errors?.postcode ?? null} />
				<TFAInputText label='STATE' type='text' name='state' form_width='100%' formik={formik} error={formik?.errors?.state ?? null} />
				<TFAInputText label='COUNTRY' type='text' name='country' form_width='100%' formik={formik} error={formik?.errors?.country ?? null} />
			</div>
		</div>
	)
}

export default Address
