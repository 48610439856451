import React from 'react'
import classes from './TFAText.module.scss'

export default function TFAText(props) {
    return (
        <div className={`${props?.style1?.tfa_text_wrapper1 ?? ''} ${props?.style2?.tfa_text_wrapper2 ?? ''} ${classes.tfa_text_wrapper}`}>
            <div className={`${props?.style1?.primary_txt1 ?? ''} ${props?.style2?.primary_txt2 ?? ''} ${classes.primary_txt}`}>
                {props.primary_title}
            </div>
            <div className={`${props?.style1?.secondary_txt1 ?? ''} ${props?.style2?.secondary_txt2 ?? ''} ${classes.secondary_txt}`}>
                {props.secondary_title}
            </div>
            <div className={`${props?.style1?.description_txt1 ?? ''} ${props?.style2?.description_txt2 ?? ''} ${classes.description_txt}`}>
                {props.description}
            </div>
        </div>
    )  
}