import React from 'react'
import classes from '../PProfile.module.scss'
import moment from 'moment'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { PiggyBank } from '../../../../../assets/icons/Dashboard/DashboardIcons'

const Earnings = ({ allHistories }) => {
  const thisMonth = moment().format('MMMM')
  const now = moment()

  const totalCalculator = (histories) => {
    const sameMonth = histories.filter(histroy => moment(now).isSame(histroy?.payment?.created, 'month'))
    const total = sameMonth.reduce((prev, curr) => prev + curr.amount, 0)
    const formattedTotal = parseFloat(total / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return formattedTotal
  } 
  
  return (
    <div className={classes.setting_container__earnings}>
        <DashboardSubTitle 
          Icon={PiggyBank}
          title='Earnings'
          fontSize='1.4em'
        />
        <div className={classes.main_content}>
          <p className={classes.main_content__month}>
            {thisMonth}
          </p>
          <p className={classes.main_content__revenue}>
            &#36; {totalCalculator(allHistories)}
          </p>
        </div>
    </div>
  )
}

export default Earnings