import * as Yup from 'yup'
import validUrl from 'valid-url'
import validator from 'validator'
import { PhoneNumberUtil } from 'google-libphonenumber'

const validatePhoneNumber = (value, countryCode) => {
	let result = false
	try {
		const phoneUtil = PhoneNumberUtil.getInstance()
		result = phoneUtil.isValidNumber(phoneUtil.parse(value, countryCode))
	} catch (e) {
		result = false
	}
	return result
}

const passwordValidator = Yup.string()
	.required('New Password is required')
	.min(8, 'Your passward needs to be at least 8 characters long')
	.matches(
		new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
		'Must Contain One Uppercase, One Lowercase, One Number, and One Symbol'
	)

export const proLoginSchema = Yup.object({
	email: Yup.string().required('Email is required').email('Invalid email address'),
	password: Yup.string().required('Password is required'),
})

export const proSignupSchema = Yup.object({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	website: Yup.string()
		.required('Website is required')
		.test('isWebsiteValid', 'Invalid URL. Website must start with https://', (url) => url && validUrl.isUri(url)),
	email: Yup.string()
		.required('Email is required')
		.email('Invalid email address')
		.test('isEmailvalid', 'Invalid Email Address', (email) => email && validator.isEmail(email)),
	phoneNumber: Yup.string()
		.required('Phone Number is required')
		.test('isPhoneValid', 'Invalid Phone Number', (value, schema) => {
			const countryCode = schema?.parent?.phoneCountry?.countryCode
			return validatePhoneNumber(value, countryCode)
		}),
	specialty: Yup.array().min(1).required('Speciality is required'),
	day: Yup.number()
		.required('Day is required')
		.typeError('Day must be number')
		.min(1, 'Day must be between 1 to 31')
		.max(31, 'Day must be between 1 to 31'),
	month: Yup.number()
		.required('Month is required')
		.typeError('Month must be number')
		.min(1, 'Month must be between 1 to 12')
		.max(12, 'Month must be between 1 to 12'),
	year: Yup.number()
		.required('Year is required')
		.typeError('Year must be number')
		.min(1900, 'Year must be between 1900 to 2030')
		.max(2030, 'Year must be between 1900 to 2030'),
	frontId: Yup.string().required('A front image of a valid identity is required'),
	backId: Yup.string().required('A back image of a valid identity is required'),
}).shape(
	{
		MARANumber: Yup.string().when('LPNNumber', {
			is: (LPNNumber) => !LPNNumber || LPNNumber.length === 0,
			then: Yup.string().required('Either MARA number or LPN Number is required'),
		}),
		LPNNumber: Yup.string().when('MARANumber', {
			is: (MARNNumber) => !MARNNumber || MARNNumber.length === 0,
			then: Yup.string()
				.required('Either LPN Number or MARA number is required')
				.test('LPNNumber', 'LPN Number must be exactly 7 digits', (val) => val?.toString().length === 7),
		}),
		expiryDay: Yup.number().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Day must be number')
				.required('Expiry Day is required')
				.min(1, 'Expiry Day must be between 1 to 31')
				.max(31, 'Expiry Day must be between 1 to 31'),
		}),
		expiryMonth: Yup.number().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Month must be number')
				.required('Expiry Month is required')
				.min(1, 'Expiry Month must be between 1 to 12')
				.max(12, 'Expiry Month must be between 1 to 12'),
		}),
		expiryYear: Yup.number().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Year must be number')
				.required('Expiry Year is required')
				.min(2022, 'Expiry Year must be greater than 2022')
				.max(2100, 'Invalid Expiry Year'),
		}),
		MARAFiles: Yup.array().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.array().min(1).required('Practising Certificate or MARA Registration and PI Insurance Certificate needs to be uploaded'),
		}),
		lpnExpiryDay: Yup.number().when('LPNNumber', {
			is: (LPNNumber) => LPNNumber && LPNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Day must be number')
				.required('Expiry Day is required')
				.min(1, 'Expiry Day must be between 1 to 31')
				.max(31, 'Expiry Day must be between 1 to 31'),
		}),
		lpnExpiryMonth: Yup.number().when('LPNNumber', {
			is: (LPNNumber) => LPNNumber && LPNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Month must be number')
				.required('Expiry Month is required')
				.min(1, 'Expiry Month must be between 1 to 12')
				.max(12, 'Expiry Month must be between 1 to 12'),
		}),
		lpnExpiryYear: Yup.number().when('LPNNumber', {
			is: (LPNNumber) => LPNNumber && LPNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Year must be number')
				.required('Expiry Year is required')
				.min(2022, 'Expiry Year must be greater than 2022')
				.max(2100, 'Invalid Expiry Year'),
		}),
		LPNFiles: Yup.array().when('LPNNumber', {
			is: (LPNNumber) => LPNNumber && LPNNumber.length > 0,
			then: Yup.array().min(1).required('Practising Certificate needs to be uploaded'),
		}),
	},
	[
		'MARANumber',
		'LPNNumber',
		'expiryDay',
		'expiryMonth',
		'expiryYear',
		'MARAFiles',
		'lpnExpiryDay',
		'lpnExpiryMonth',
		'lpnExpiryYear',
		'LPNFiles',
	]
)

const digitsOnly = (value) => /^\d+$/.test(value)
export const proCompleteSignupSchema = Yup.object({
	firstLanguage: Yup.string().required('Please select your first language'),
	experienceLevel: Yup.string().required('Please add your experience'),
	halfHourRate: Yup.number()
		.typeError('Appointment Price must be numbers')
		.required('Please set your half hour rate')
		.min(25, 'Minimum half hour rate is $25'),
	minRate: Yup.number()
		.typeError('Question Price must be numbers')
		.required('Please set your minimum rate')
		.min(25, 'Minimum question rate is $25'),
	streetNumber: Yup.string().required('Please enter street number'),
	streetName: Yup.string().required('Please enter street name'),
	suburb: Yup.string().required('Please enter suburb'),
	postcode: Yup.string().required('Please enter postcode'),
	state: Yup.string().required('Please enter state'),
	country: Yup.string().required('Please enter country'),
	accountName: Yup.string().required('Please enter your bank account name'),
	accountNumber: Yup.number().typeError('Account Number must be numbers').required('Please enter your bank account number'),
	bsb: Yup.string()
		.required('Please enter your bank BSB')
		.test('Digits only', 'BSB must be 6 digits number', digitsOnly)
		.test('bsb', 'BSB must be exactly 6 digits', (val) => val?.toString().length === 6),
	password: passwordValidator,
	confirmNewPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('password')], 'Passwords must match'),
})

export const clientLoginSchema = Yup.object({
	email: Yup.string().required('Email is required'),
	password: Yup.string().required('Password is required'),
})

export const verifyEmailSchema = Yup.object({
	email: Yup.string().required('Email is required').email('Invalid email address'),
})

export const clientSignupSchema = Yup.object({
	phoneNumber: Yup.string()
		.required('Phone Number is required')
		.test('isPhoneValid', 'Invalid Phone Number', (value, schema) => {
			const countryCode = schema?.parent?.phoneCountry?.countryCode
			return validatePhoneNumber(value, countryCode)
		}),
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	countryOfResidence: Yup.string().required('Country of residence is required'),
	password: passwordValidator,
	confirmNewPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('password')], 'Passwords must match'),
})

export const clientLanguagesSchema = Yup.object({
	firstLanguage: Yup.string().required('Please select your first language'),
	secondLanguage: Yup.string(),
})

export const clientPaymentSchema = Yup.object({
	cardName: Yup.string().required('Card Name is Required'),
})

export const retrievePwdSchema = Yup.object({
	email: Yup.string().required('Email is required').email('Invalid email address'),
	newPassword: passwordValidator,
	confirmNewPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

export const createQuestionsSchema = Yup.object({
	countryOfDestination: Yup.string().required('Country of Destination is required'),
	category: Yup.string().required('Category is required'),
	questionTitle: Yup.string().required('Question Title is required'),
	questionContext: Yup.string().required('Question Context is required'),
	askingPrice: Yup.number()
		.typeError('Asking price must be numbers')
		.required('Please set your asking price')
		.min(25, 'Minimum asking price must be $25 or higher'),
})

export const CreateAppointmentSchema = Yup.object({
	professional: Yup.object().required('Please select a professional'),
	appointmentDate: Yup.string().required('Please select an appointment date and time'),
	title: Yup.string().required('Appointment title is required'),
	context: Yup.string().required('Appointment context is required'),
})

export const updateProAccountSchema = Yup.object({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string()
		.required('Email is required')
		.email('Invalid email address')
		.test('isEmailvalid', 'Invalid Email Address', (email) => email && validator.isEmail(email)),
	phoneNumber: Yup.string()
		.required('Phone Number is required')
		.test('isPhoneValid', 'Invalid Phone Number', (value, schema) => {
			const countryCode = schema?.parent?.phoneCountry?.countryCode
			return validatePhoneNumber(value, countryCode)
		}),
})

export const updateMeetingAvailabilitySchema = Yup.object({
	halfHourRate: Yup.number().typeError('Appointment Price must be numbers').min(25, 'Minimum half hour rate is $25'),
	minRate: Yup.number().typeError('Question Price must be numbers').min(25, 'Minimum question rate is $25'),
})

export const updatePasswordSchema = Yup.object({
	password: Yup.string().required('Password is required'),
	newPassword: passwordValidator,
	confirmNewPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

export const updateMARACertificateSchema = Yup.object().shape(
	{
		MARANumber: Yup.string().required('MARA number is required'),
		expiryDay: Yup.number().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Day must be number')
				.required('Expiry Day is required')
				.min(1, 'Expiry Day must be between 1 to 31')
				.max(31, 'Expiry Day must be between 1 to 31'),
		}),
		expiryMonth: Yup.number().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Month must be number')
				.required('Expiry Month is required')
				.min(1, 'Expiry Month must be between 1 to 12')
				.max(12, 'Expiry Month must be between 1 to 12'),
		}),
		expiryYear: Yup.number().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.number()
				.typeError('Expiry Year must be number')
				.required('Expiry Year is required')
				.min(2022, 'Expiry Year must be greater than 2022')
				.max(2100, 'Invalid Expiry Year'),
		}),
		MARAFiles: Yup.array().when('MARANumber', {
			is: (MARNNumber) => MARNNumber && MARNNumber.length > 0,
			then: Yup.array().min(1).required('Practising Certificate or MARA Registration and PI Insurance Certificate needs to be uploaded'),
		}),
	},
	['MARANumber', 'expiryDay', 'expiryMonth', 'expiryYear', 'MARAFiles']
)

export const updateClientAccountSchema = Yup.object({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string().required('Email is required').email('Invalid email address'),
})

export const updateClientLanguageSchema = Yup.object({
	firstLanguage: Yup.string().required('Please select your first language'),
})
