import React from 'react'
import { TFAMuiTab, TFAMuiTabs } from './MuiStyle'

const TFATabs = ({ 
    children, 
    tabName, 
    setTabName,
    isMARAEmpty,
    isLPNEmpty,
}) => {
    return (
        <div>
            <TFAMuiTabs
                value={tabName}
                onChange={(e, name) => setTabName(name)}
            >
                <TFAMuiTab 
                    value='MARA Number' 
                    label='MARA Number'
                    disabled={!isLPNEmpty}
                />
                <TFAMuiTab 
                    value='LPN Number' 
                    label='LPN Number' 
                    disabled={!isMARAEmpty}
                />
            </TFAMuiTabs>
            { children }
        </div>
    )
}

export default TFATabs