import { FormControlLabel, Checkbox, styled } from '@mui/material'

const TFAFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        fontFamily: "'Space Grotesk', sans-serif",
        fontWeight: '500',
        color: '#022873',
    },
}))

const TFACheckbox = styled(Checkbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
        color: '#2F68FA',
    },
    '&.Mui-checked': {
        color: '#2F68FA',
    },
}))

export { TFAFormControlLabel, TFACheckbox }