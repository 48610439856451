import React from 'react'
import classes from './DetailsQuestion.module.scss'
import { expiryDateConverter } from '../../../utils/finishTimeConverter'
import { CategoryState } from '../DashboardComponents'

const DetailsQuestion = ({ 
    questionDetails,
    title,
}) => {
    return (
        <>
            {title &&
                <p className={classes.question_title}>
                    {title}
                </p>
            }
            <div className={classes.container}>
                <p className={classes.container__title}>
                    {questionDetails.title}
                </p>
                <CategoryState 
                    destinationCountry={questionDetails.destinationCountry}
                    category={questionDetails.category}
                />
                <p className={classes.container__content}>
                    {questionDetails.content}
                </p>
                <div className={classes.container__expiry_box}>
                    <p className={classes.container__expiry_box__expiry}>
                        Expiry・ 
                        <span>
                            {expiryDateConverter(questionDetails.finishTime)}
                        </span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default DetailsQuestion