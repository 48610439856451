import React, { useEffect, useState } from 'react'
import classes from './NotificationDrawer.module.scss'
import { Drawer } from '@mui/material'
import { AppointmentNotification, ArrowBack, QuestionNotification } from '../../../assets/icons/Dashboard/DashboardIcons'
import { useMutation, useQuery } from '@apollo/client'
import { GET_NOTIFICATIONS } from '../../../services/apiQuery'
import { UPDATE_NOTIFICATION } from '../../../services/apiMutation'
import { TFASkeleton } from '../../TFAComponents'
import { useNavigate } from 'react-router-dom'

const NotificationDrawer = ({ 
    isNotificationOpen, 
    setIsNotificationOpen,
    setIsNewNotifications,
}) => {
    const navigate = useNavigate()
    const [ allNewMessages, setAllNewMessages ] = useState([])
    const [ allPastMessages, setAllPastMessages ] = useState([])
    const { loading: newLoading, data: newData, refetch: newRefetch } = useQuery(GET_NOTIFICATIONS, {
        variables: { isRead: false }
    })
    const { loading: oldLoading, data: oldData, refetch: oldRefetch } = useQuery(GET_NOTIFICATIONS, {
        variables: { isRead: true }
    })
    const [ updateNotification ] = useMutation(UPDATE_NOTIFICATION)

    useEffect(() => {
        if (newData) {
            setAllNewMessages(newData.GetNotifications.objectList)
            // setIsNewNotifications(newData.GetNotifications.count)
        }
        oldData && setAllPastMessages(oldData.GetNotifications.objectList)
    },[newData, oldData])

    const clickNotification = async (appointment, question, notificationId) => {
        const tokenType = localStorage.getItem('TFATokenType')
        try {
            const { NotificationUpdate } = await callUpdateNotification(notificationId)
            if (!NotificationUpdate) return
            if (question) {
                tokenType === 'ProfessionalToken' && navigate('/p_dashboard/working_area', { state: question.id }) 
                tokenType === 'ClientToken' && navigate('/c_dashboard/questions', { state: question.id }) 
            }
            if (appointment) {
                tokenType === 'ProfessionalToken' && navigate('/p_dashboard/p_appointments', { state: appointment.id })
                tokenType === 'ClientToken' && navigate('/c_dashboard/c_appointments', { state: appointment.id })
            }
            newRefetch()
            oldRefetch()
            setIsNotificationOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    const callUpdateNotification = async (id) => {
        const { data } = await updateNotification({
            variables: {
                notification: {
                    id,
                    isRead: true
                }
            }
        })
        return data
    }

    const notificationCard = (message, index, bg) => {
        return(
            <div 
                className={`${classes.card_container} ${bg === 'blue' ? classes.blue_bg : classes.white_bg}`} 
                key={index}
                onClick={() => clickNotification(message.appointment , message.question, message.id)}
            >
                {message.appointment &&
                    <AppointmentNotification className={classes.card_container__icon} />
                }
                {message.question &&
                    <QuestionNotification className={classes.card_container__icon} />
                }
                <div className={classes.notification_content}>
                    <div className={classes.notification_content__type}>
                        <p>
                        {message.appointment && 'Appointment'}
                        {message.question && 'Question'}
                        </p>
                        <p>
                        ・
                        </p>
                        <p className={classes.notification_content__type__status}>
                            {message.title}
                        </p>
                    </div>
                    <p className={classes.notification_content__message}>
                        {message.content}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <Drawer
            open={isNotificationOpen}
            onClose={() => setIsNotificationOpen(false)}
        >
            <div className={classes.drawer_container}>
                <div className={classes.drawer_title}>
                    <ArrowBack 
                        className={classes.drawer_title__icon}
                        onClick={() => setIsNotificationOpen(false)}
                    />
                    <p className={classes.drawer_title__text}>
                        Notifications
                    </p>
                </div>
                <div className={classes.scrollable}>
                    <div className={classes.notification_list_box}>
                        <p className={classes.notification_list_box__title}>New</p>
                        {newLoading
                        ?   <TFASkeleton type='notifications' count={3}/>
                        :   allNewMessages.length === 0
                            ?
                            <div className={classes.notification_list_box__empty_box}>
                                There is no notification
                            </div>
                            :
                            <div className={classes.notification_list_box__list}>
                                {allNewMessages.map((message, index) => (
                                    notificationCard(message, index, 'blue')
                                ))}
                            </div>
                        }
                    </div>
                    <div className={classes.notification_list_box}>
                        <p className={classes.notification_list_box__title}>Past</p>
                        {oldLoading
                        ?   <TFASkeleton type='notifications' count={3} />
                        :   allPastMessages.length === 0
                            ?
                            <div className={classes.notification_list_box__empty_box}>
                                There is no notification
                            </div>
                            :
                            <div className={classes.notification_list_box__list}>
                                {allPastMessages.map((message, index) => (
                                    notificationCard(message, index, 'white')
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default NotificationDrawer