import React from 'react'
import classes from './TFASkeleton.module.scss'
import { Skeleton } from '@mui/material'

const TFASkeleton = ({ type, count }) => {

    const getQuestionCards = () => {
        let skeletonList = []
        const skeleton = (index) => (
            <div className={classes.question_card_container} key={index}>
                <Skeleton variant='text' width='100%' height={25} />
                <div className={classes.card_flex_box}>
                    <Skeleton variant='rectangular' width='70%' height={50} className={classes.margin_right}/>
                    <Skeleton variant='rectangular' width='30%' height={50} />
                </div>
                <Skeleton variant='text' width='100%' height={25} />
            </div>
        )
        for (let i = 0; i < count; i++) {
            skeletonList.push(skeleton(i))
        }
        return skeletonList
    }

    const getDetail = () => {
        return(
            <div className={classes.detail_container}>
                <Skeleton variant='text' width='100%' height={50} animation='wave'/>
                <div className={classes.flex_box}>
                    <Skeleton variant='circular' width={60} height={60} animation='wave'/>
                    <div className={classes.max_width}>
                        <Skeleton variant='text' width='100%' height={30} animation='wave'/>
                        <Skeleton variant='text' width='100%' height={30} animation='wave'/>
                    </div>
                </div>
                <Skeleton variant='rectangular' width='100%' height={200} animation='wave'/>
                <Skeleton variant='rectangular' width='100%' height={150} animation='wave' className={classes.margin_top}/>
                <Skeleton variant='rectangular' width='100%' height={150} animation='wave' className={classes.margin_top}/>
            </div>
        )
    }

    const getImage = () => {
        return(
            <div className={classes.image_container}>
                <Skeleton variant='rectangular' width='100%' height='100%' animation='wave'/>
            </div>
        )
    }

    const getDocument = () => {
        return(
            <div className={classes.document_container}>
                <Skeleton variant='rectangular' width='100%' height='100%' animation='wave'/>
            </div>
        )
    }

    const getTemplate = () => {
        return(
            <div className={classes.template_container}>
                <div className={classes.template_skeleton}>
                    <Skeleton variant='rectangular' width='100%' height='100%' animation='wave'/>
                </div>
                <div className={classes.template_skeleton}>
                    <Skeleton variant='rectangular' width='100%' height='100%' animation='wave'/>
                </div>
                <div className={classes.template_skeleton}>
                    <Skeleton variant='rectangular' width='100%' height='100%' animation='wave'/>
                </div>
            </div>
        )
    }

    const getTemplateCards = () => {
        let skeletonList = []
        const skeleton = (index) => (
            <div className={classes.template_card_container} key={index}>
                <Skeleton variant='text' width='100%' height={70} animation='wave'/>
            </div>
        )
        for (let i = 0; i < count; i++) {
            skeletonList.push(skeleton(i))
        }
        return skeletonList
    }

    const getTemplateDetail = () => {
        return(
            <div className={classes.tempalte_detail_container}>
                <Skeleton variant='text' width='100%' height='5em' animation='wave'/>
                <Skeleton variant='rectangular' width='100%' height={300} animation='wave'/>
            </div>
        )
    }

    const getProfessionals = () => {
        let skeletonList = []
        const skeleton = (index) => (
            <div className={classes.professionals_container} key={index}>
                <div className={classes.professionals_container__icon}>
                    <Skeleton variant='circular' width={60} height={60} animation='wave'/>
                </div>
                <div className={classes.professionals_container__profile}>
                    <Skeleton variant='text' width='100%' height='2em' animation='wave' />
                    <Skeleton variant='text' width='80%' height='2em' animation='wave' />
                </div>
            </div>
        )
        for (let i = 0; i < count; i++) {
            skeletonList.push(skeleton(i))
        }
        return skeletonList
    }

    const getCalendar = () => {
        const skeleton = Array(3).fill(null).map((month, index) => (
            <div className={classes.calendar_container} key={index}>
                <Skeleton variant='text' width='95%' height='4em' animation='wave' />
                <Skeleton variant='rectangular' width='95%' height={300} animation='wave'/>
            </div>
        ))
        return skeleton
    }

    const getAppointmentTime = () => {
        let skeletonList = []
        const skeleton = (index) => (
            <div className={classes.appointment_container} key={index}>
                <Skeleton variant='text' width='95%' height='4em' animation='wave'/>
            </div>
        )
        for (let i = 0; i < count; i++) {
            skeletonList.push(skeleton(i))
        }
        return skeletonList
    }

    const getTransactionHistory = () => {
        let skeletonList = []
        const skeleton = (index) => (
            <div className={classes.history_container} key={index}>
                <Skeleton variant='text' width='95%' height='4em' animation='wave'/>
            </div>
        )
        for (let i = 0; i < count; i++) {
            skeletonList.push(skeleton(i))
        }
        return skeletonList
    }

    const getNotifications = () => {
        let skeletonList = []
        const skeleton = (index) => (
            <div className={classes.history_container} key={index}>
                <Skeleton variant='text' width='95%' height='4em' animation='wave'/>
            </div>
        )
        for (let i = 0; i < count; i++) {
            skeletonList.push(skeleton(i))
        }
        return skeletonList
    }

    const switchSkeletons = () => {
        switch (type) {
            case 'question_card':       return getQuestionCards()
            case 'detail':              return getDetail()
            case 'image':               return getImage()
            case 'document':            return getDocument()
            case 'template':            return getTemplate()
            case 'template_card':       return getTemplateCards()
            case 'template_detail':     return getTemplateDetail()
            case 'professionals':       return getProfessionals()
            case 'calendar':            return getCalendar()
            case 'appointment_time':    return getAppointmentTime()
            case 'transaction_history': return getTransactionHistory()
            case 'notifications':       return getNotifications()
            default: return
        }
    }

    return (
        <>
            {switchSkeletons()}
        </>
    )
}

export default TFASkeleton