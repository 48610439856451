import React from 'react'
import { TFATextInput } from './MuiStyle'
import { Alert } from '@mui/material'

const TFASimpleInput = ({ children, formik, name, ...props }) => {
  const handleChange = ({ target }) => {
    props.setValues(target.value)
  }

  return (
    <div style={{width: props.box_width ?? '90%', margin: props.box_margin ?? '0.5em auto'}}>
      <TFATextInput
          sx={{ 
              width: props.width, 
              height: props.height, 
              maxHeight: props.maxHeight,
              overflowY: props.overflowY,
          }}
          label={props.label}
          type={'text'}
          name={name}
          placeholder={props.placeholder ?? ''}
          onChange={formik ? formik.handleChange : handleChange}
          multiline={true}
          rows={props.rows}
          maxRows={props.maxRows}
          variant={props.variant}
          text_field={props.text_field}
          value={formik ? formik.values[name] : props.value }
      >
          { children }
      </TFATextInput>
      {formik?.touched[name] && props.error && 
      <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
        {props.error}
      </Alert>
      }
    </div>
  )
}

export default TFASimpleInput