import React, { useEffect, useState } from 'react'
import classes from './EditAppointment.module.scss'
import moment from 'moment'
import UpdateAppointment from './Sections/UpdateAppointment'
import UpdateDateModal from './Sections/UpdateDateModal'
import UpdateDetailsModal from './Sections/UpdateDetailsModal'
import UpdateTimeModal from './Sections/UpdateTimeModal'
import CancelAppointmentModal from './Sections/CancelAppointmentModal'
import { TFALogo } from '../../../../assets/icons/Auth/AuthIcons'
import { useFormik } from 'formik'
import { UpdateAppointmentValues } from '../../../../assets/data/FormikInitialValues'
import { useMutation, useQuery } from '@apollo/client'
import { GET_APPOINTMENT } from '../../../../services/apiQuery'
import { UPDATE_APPOINTMENT } from '../../../../services/apiMutation'
import { TFASnackBar } from '../../../../components/TFAComponents'

const EditAppointment = ({ setIsUpdateAppointmentOpen, appointmentId }) => {
    const [ isSnackbarOpen, setIsSnackbarOpen ] = useState(false)
    const [ isTimeModalOpen, setIsTimeModalOpen ] = useState(false)
    const [ isDateModalOpen, setIsDateModalOpen ] = useState(false)
    const [ isDetailModalOpen, setIsDetailModalOpen ] = useState(false)
    const [ isCancelModalOpen, setIsCancelModalOpen ] = useState(false)
    const { loading: appointmentLoading, data: appointmentData } = useQuery(GET_APPOINTMENT, {
        variables: { getAppointmentId: appointmentId }
    })
    const [ updateAppointment ] = useMutation(UPDATE_APPOINTMENT)
    const formik = useFormik({
        initialValues: UpdateAppointmentValues,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                const { AppointmentUpdate } = await callUpdateAppointment(values)
                if (AppointmentUpdate !== 'success') return
                setIsUpdateAppointmentOpen(false)
                window.location.reload(false)
            } catch (error) {
                setIsSnackbarOpen(true)
            } finally {
                formik.setSubmitting(false)
            }
        }
    })

    useEffect(() => {
        if (appointmentData) {
            formik.setFieldValue(formik.values.professional = appointmentData.GetAppointment.professional)
            formik.setFieldValue(formik.values.timeStart = appointmentData.GetAppointment.timeStart)
            formik.setFieldValue(formik.values.timeFinish = appointmentData.GetAppointment.timeFinish)
            formik.setFieldValue(formik.values.title = appointmentData.GetAppointment.title)
            formik.setFieldValue(formik.values.context = appointmentData.GetAppointment.context)
        }
    },[appointmentData])

    const callUpdateAppointment = async (values) => {
        let startTime
        let endTime
        if (values.modifiedDate) {
            startTime = values.modifiedDate
            endTime = moment(values.modifiedDate).add(30, 'minutes').format()
        } else {
            startTime = moment(values.timeStart).format()
            endTime = moment(values.timeStart).add(30, 'minutes').format()
        }
        const { data } = await updateAppointment({
            variables: {
                appointment: {
                    id: appointmentId,
                    title: values.title,
                    context: values.context,
                    timeStart: startTime,
                    timeFinish: endTime,
                },
            }
        })
        return data
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return
        setIsSnackbarOpen(false)
    }

    return (
        <div className={classes.wrapper}>
            <TFALogo className={classes.logo}/>
            <div className={classes.main_container}>
            {!appointmentLoading &&
                <UpdateAppointment 
                    formik={formik}
                    setIsUpdateAppointmentOpen={setIsUpdateAppointmentOpen}
                    setIsTimeModalOpen={setIsTimeModalOpen}
                    setIsDetailModalOpen={setIsDetailModalOpen}
                    setIsCancelModalOpen={setIsCancelModalOpen}
                />
            }
            </div>
            <UpdateTimeModal 
                formik={formik}
                isTimeModalOpen={isTimeModalOpen}
                setIsTimeModalOpen={setIsTimeModalOpen}
                setIsDateModalOpen={setIsDateModalOpen}
            />
            <UpdateDateModal 
                formik={formik}
                isDateModalOpen={isDateModalOpen}
                setIsDateModalOpen={setIsDateModalOpen}
                setIsTimeModalOpen={setIsTimeModalOpen}
            />
            <UpdateDetailsModal 
                formik={formik}
                isDetailModalOpen={isDetailModalOpen}
                setIsDetailModalOpen={setIsDetailModalOpen}
            />
            <CancelAppointmentModal 
                appointmentId={appointmentId}
                isCancelModalOpen={isCancelModalOpen}
                setIsCancelModalOpen={setIsCancelModalOpen}
                setIsUpdateAppointmentOpen={setIsUpdateAppointmentOpen}
            />
            <TFASnackBar 
                variant='error'
                isSnackbarOpen={isSnackbarOpen}
                handleSnackbarClose={handleSnackbarClose}
                message='Failed to edit this appointment. Please try again.'
            />
        </div>
    )
}

export default EditAppointment