import React, { useState } from 'react'
import classes from './Modals.module.scss'
import { TFAButton, TFAModal } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { PaymentMethodCard } from '../../../../../components/Dashboard/DashboardComponents'
import useClientContext from '../../../../../states/Client/useClientContext'
import CreatePaymentMethod from '../../Profile/Modals/CreatePaymentMethod'

const PaymentMethodModal = ({ formik, isPaymentModalOpen, paymentModalClose, onRefresh }) => {
	const [selectedMethod, setSelectedMethod] = useState(formik.values.paymentPreference)
	const [selectedCard, setSelectedCard] = useState({})
	const [createPaymentModalOpen, setCreatePaymentModalOpen] = useState(false)
	const { client } = useClientContext().clientState

	const selectPaymentMethod = () => {
		formik.setFieldValue((formik.values.paymentPreference = selectedMethod))
		formik.setFieldValue((formik.values.defaultCard = selectedCard))
		paymentModalClose()
	}

	const clickPaymentMethod = (card) => {
		setSelectedMethod('Card')
		setSelectedCard(card)
	}

	console.log(client)
	console.log(formik.values)

	return (
		<TFAModal open={isPaymentModalOpen} handleClose={paymentModalClose} width='30em'>
			<div className={classes.modal_title_box}>
				<CloseBtn className={classes.modal_title_box__icon} onClick={paymentModalClose} />
				<p className={classes.modal_title_box__title}>Swap Payment Method</p>
			</div>
			<div className={classes.payment_method_list_box}>
				{formik.values.allCards.length !== 0 ? (
					formik.values.allCards.map((card, index) => (
						<div className={classes.payment_method_list_box__card} key={index}>
							<PaymentMethodCard
								cardDetails={card}
								defaulCard={formik.values.allCards[0]}
								method='Card'
								handleClick={() => clickPaymentMethod(card)}
								isPaymentSelected={'Card' === selectedMethod && card.id === selectedCard.id}
							/>
						</div>
					))
				) : (
					<div
						onClick={() => setCreatePaymentModalOpen(true)}
						style={{
							width: '100%',
							backgroundColor: '#2F68FA',
							color: 'white',
							borderRadius: 10,
							paddingTop: 10,
							cursor: 'pointer',
							paddingBottom: 10,
							marginBottom: 10,
						}}
					>
						Click to add a card
					</div>
				)}
			</div>
			<div className={classes.btn_box}>
				<TFAButton text='SELECT' onClick={selectPaymentMethod} style2={classes} />
			</div>
			<CreatePaymentMethod
				formik={formik}
				clientId={client.id}
				createPaymentModalOpen={createPaymentModalOpen}
				setCreatePaymentModalOpen={setCreatePaymentModalOpen}
				cardRefetch={onRefresh}
			/>
		</TFAModal>
	)
}

export default PaymentMethodModal
