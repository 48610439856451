import React from 'react'
import AsyncSelect from 'react-select/async'
import CustomStyles from './CustomStyles'
import countries from '../../../assets/data/CountryCode'
import { Alert } from '@mui/material'

const SearchCountryInput = ({
  name,
  placeholder,
  formik,
  error,
}) => {

  const getSelectedValue = (country) => {
    country && formik.setFieldValue(formik.values[name] = country.label)
  } 

  const filterCountries = (inputCountries) => {
    const searchResult = countries.filter(country => 
      country.label.toLowerCase().includes(inputCountries.toLowerCase())
    )
    return searchResult
  }

  const countryOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterCountries(inputValue))
    }, 500)
  }

  return (
    <div>
      <AsyncSelect
        name={name}
        cacheOptions
        defaultOptions={countries}
        loadOptions={countryOptions}
        placeholder={placeholder}
        onChange={getSelectedValue}
        styles={CustomStyles}
      />
      {formik.touched[name] && error &&
        <Alert severity='error' sx={{padding: '0.1em', marginTop: '0.2em'}}>
          {error}
        </Alert>
      }
    </div>
  )
}

export default SearchCountryInput