import React, { useEffect, useState } from 'react'
import classes from './RetrieveProfessionalAccount.module.scss'
import { TFASnackBar } from '../../../../components/TFAComponents'
import { RetrievePasswordValues } from '../../../../assets/data/FormikInitialValues'
import { retrievePwdSchema } from '../../../../utils/validation'
import { TFALogo } from '../../../../assets/icons/Auth/AuthIcons'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_PROFILE } from '../../../../services/apiMutation'
import { GET_VISITOR_TOKEN } from '../../../../services/apiQuery'
import { RETRIEVE_PASSWORD_PAGES } from '../../../../assets/data/SelectOptions'
import AccountEmail from './MultiPages/AccountEmail'
import VerifyCode from './MultiPages/VerifyCode'
import ResetPassword from './MultiPages/ResetPassword'

const RetrieveProfessionalAccount = () => {
    const [ currentPage, setCurrentPage ] = useState(RETRIEVE_PASSWORD_PAGES.ACCOUNT_EMAIL)
    const navigate = useNavigate()

    const { data: visitorData } = useQuery(GET_VISITOR_TOKEN)
    const [ updateProfessionalPassword ] = useMutation(UPDATE_PROFILE)

    const [ isEmailSnackbarOpen, setIsEmailSnackbarOpen ] = useState(false)
    const [ isCodeSnackbarOpen, setIsCodeSnackbarOpen ] = useState(false)
    const [ isUpdateSnackbarOpen, setIsUpdateSnackbarOpen ] = useState(false)

    const formik = useFormik({
      initialValues: RetrievePasswordValues,
      validationSchema: retrievePwdSchema,
      onSubmit: async (values) => {
        try {
          formik.setSubmitting(true)
          const { ProfessionalUpdate } = await callUpdateProfessionalPassword(values)
          if (!ProfessionalUpdate) return
          localStorage.setItem('TFATokenType', 'ProfessionalToken')
          navigate('/p_login')
        } catch (error) {
          setIsUpdateSnackbarOpen(true)
        } finally {
          formik.setSubmitting(false)
        }
      }
    })

    useEffect(() => {
      if (visitorData) {
        localStorage.setItem('TFAToken', visitorData.GetVisitorToken)
        localStorage.setItem('TFATokenType', 'VisitorToken')
      }
    },[visitorData])

    useEffect(() => {
      // FIXME: after endter verication code, it rerenders and come back to email page
      console.log('this is called')
    }, [])

    const callUpdateProfessionalPassword = async (values) => {
      const { data } = await updateProfessionalPassword({
        variables: {
          professional: {
            id: values.id,
            password: values.newPassword
          }
        }
      })
      return data
    }

    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') return
      setIsEmailSnackbarOpen(false)
      setIsCodeSnackbarOpen(false)
      setIsUpdateSnackbarOpen(false)
    }

    const renderPage = (currentPage, setCurrentPage, formik) => {
      switch (currentPage) {
        case RETRIEVE_PASSWORD_PAGES.ACCOUNT_EMAIL:
          return <AccountEmail 
            formik={formik}
            setCurrentPage={setCurrentPage}
            setIsEmailSnackbarOpen={setIsEmailSnackbarOpen}
          />
        case RETRIEVE_PASSWORD_PAGES.VERIFY_CODE:
          return <VerifyCode 
            formik={formik}
            setCurrentPage={setCurrentPage}
            setIsCodeSnackbarOpen={setIsCodeSnackbarOpen}
          />
        case RETRIEVE_PASSWORD_PAGES.RESET_PASSWORD:
          return < ResetPassword 
            formik={formik}
            setCurrentPage={setCurrentPage}
          />
        default: return
      }
    }
  
    return (
      <div className={classes.wrapper}>
        <TFALogo className={classes.logo}/>
        <div className={classes.wrapper__main_container}>
          {renderPage(currentPage, setCurrentPage, formik)}
        </div>
        <TFASnackBar 
            variant='error'
            isSnackbarOpen={isEmailSnackbarOpen}
            handleSnackbarClose={handleSnackbarClose}
            message='This email does not exist. Please try again.'
        />
        <TFASnackBar 
            variant='error'
            isSnackbarOpen={isCodeSnackbarOpen}
            handleSnackbarClose={handleSnackbarClose}
            message='This verifacation code is not valid. Please try again.'
        />
        <TFASnackBar 
            variant='error'
            isSnackbarOpen={isUpdateSnackbarOpen}
            handleSnackbarClose={setIsUpdateSnackbarOpen}
            message='Failed to update new password. Please try again.'
        />
      </div>
    )
}

export default RetrieveProfessionalAccount