import React, { useEffect, useState } from 'react'
import classes from './DetailsOffer.module.scss'
import { Avatar } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_CARD_DETAIL, SINGLE_DOWNLOAD } from '../../../services/apiQuery'
import { reverseSpecialtyConverter } from '../../../utils/specialtyConverter'

const DetailsOffer = ({ 
    offer,
    formik,
    acceptOfferOpen,
    declineOfferOpen,
}) => {
    const [ avatar, setAvatar ] = useState('')
    const { loading: avatarLoading, data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
        variables: { filename: offer.professional.avatar}
    })
    const { data: cardData } = useQuery(GET_CARD_DETAIL)

    useEffect(() => {
        avatarData && setAvatar(avatarData.SingleDownload)
    },[avatarData])

    useEffect(() => {
        if (cardData) {
            const values = formik.values
            if (cardData.Stripe_ListAllCards.length === 0) return
            formik.setFieldValue(values.allCards = cardData.Stripe_ListAllCards)
            formik.setFieldValue(values.originalDefaultCard = cardData.Stripe_ListAllCards[0])
            formik.setFieldValue(values.defaultCard = cardData.Stripe_ListAllCards[0])
        }
    },[cardData])

    const acceptOffer = () => {
        formik.setFieldValue(formik.values.offerId = offer.id)
        acceptOfferOpen()
    }
    
    const declineOffer = () => {
        formik.setFieldValue(formik.values.offerId = offer.id)
        declineOfferOpen()
    }

    return (
        <div className={classes.container}>
            {avatarLoading
            ? <></>
            : 
            <>
                <div className={classes.container__professional}>
                    <Avatar 
                        src={avatar}
                        className={classes.container__professional__avatar}
                        sx={{width: '2.5em', height: '2.5em'}}
                        alt='offer-professional-avatar'
                    />
                    <div className={classes.container__professional__profile}>
                        <p className={classes.container__professional__profile__name}>
                            {offer.professional.firstName}&nbsp;
                            {offer.professional.lastName}&nbsp;
                            <span className={classes.container__professional__profile__name__code}>
                                - {offer.professional.professionalCode}
                            </span>
                        </p>
                        <p className={classes.container__professional__profile__detail}>
                            {reverseSpecialtyConverter(offer.professional.specialty)}
                        </p>
                    </div>
                </div>
                <div className={classes.container__content}>
                    <p className={classes.container__content__message}>
                        {offer.message}
                    </p>
                    <div className={classes.container__content__price_box}>
                        <p className={classes.container__content__price_box__title}>
                            Offer Price:
                        </p>
                        <p className={classes.container__content__price_box__price}>
                            &#36;{parseFloat(offer.price / 100).toFixed(2)}
                        </p>
                    </div>
                </div>
                <div className={classes.container__options}>
                    <div 
                        className={classes.container__options__accept_btn}
                        onClick={() => acceptOffer()}
                    >
                        Accept Offer
                    </div>
                    <div 
                        className={classes.container__options__decline_btn}
                        onClick={() => declineOffer()}
                    >
                        Decline Offer
                    </div>
                </div>
            </>
            }
        </div>
    )
}

export default DetailsOffer