import React, { useState } from 'react'
import classes from '../RetrieveProfessionalAccount.module.scss'
import { RETRIEVE_PASSWORD_PAGES } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { TFAButton, TFAInputText, TFAText } from '../../../../../components/TFAComponents'

const ResetPassword = ({ formik, setCurrentPage }) => {
  const [ showPassword, setShowPassword ] = useState(false)
  
  return (
    <>
      <div 
        className={classes.backBtn_box} 
        onClick={() => setCurrentPage(RETRIEVE_PASSWORD_PAGES.VERIFY_CODE)}
      >
        <LeftArrow className={classes.backBtn_box__arrow}/>
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.wrapper__main_container__tfa_text}>
        <TFAText 
          primary_title='Retrieve Account'
          secondary_title='Create New Password'
          style1={classes}
        />
      </div>
      <div className={classes.text_wrapper}>
        <div className={classes.text_wrapper__title}>
          New Password
        </div>
        <div className={classes.text_wrapper__description}>
          Create a strong password that will protect your Time For Advice account.
        </div>
      </div>
      <div>
        <TFAInputText
          label='NEW PASSWORD'
          type='password'
          name='newPassword'
          placeholder='Enter New Password'
          width='30em'
          showPassword={showPassword}
          visiblePassword={setShowPassword}
          formik={formik}
          error={formik.errors.newPassword ?? null}
        />
        <TFAInputText
          label='CONFIRM NEW PASSWORD'
          type='password'
          name='confirmNewPassword'
          placeholder='Enter Confirm New Password'
          width='30em'
          showPassword={showPassword}
          visiblePassword={setShowPassword}
          formik={formik}
          error={formik.errors.confirmNewPassword ?? null}
        />
      </div>
      <TFAButton 
        text='NEXT'
        onClick={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        disabled={formik.isSubmitting}
        style1={classes}
      />
    </>
  )
}

export default ResetPassword