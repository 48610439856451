import React, { useEffect, useState } from 'react'
import classes from './MultiPages.module.scss'
import SelectAppointmentTime from '../Modals/SelectAppointmentTime'
import { CREATE_APPOINTMENT } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { ProfessionalCard, ProgressState, AppointmentCalendar } from '../../../../../components/Dashboard/DashboardComponents'
import { useQuery } from '@apollo/client'
import { GET_MEETING_SCHEDULES } from '../../../../../services/apiQuery'
import { TFASkeleton } from '../../../../../components/TFAComponents'

const Time = ({ formik, currentPage, setCurrentPage }) => {
  const { 
    id,
    firstName,
    askingPrice,
  } = formik.values.professional
  const [ availabilityList, setAvailabilityList ] = useState([])
  const [ selectedDay, setSelectedDay ] = useState('')
  const [ isAppointmentTimeOpen, setIsAppointmentTimeOpen ] = useState(false)
  const { loading: availabilityLoading, data: availabilityData } = useQuery(GET_MEETING_SCHEDULES, {
    variables: { professionalId: id }
  })

  useEffect(() => {
    availabilityData && setAvailabilityList(availabilityData.GetMeetingschedules.objectList)
  },[availabilityData])

  const selectAppointmentDay = (day, month) => {
    setSelectedDay(`${day} ${month}`)
    setIsAppointmentTimeOpen(true)
  }

  return (
    <>
      <div
        className={classes.backBtn_box}
        onClick={() => setCurrentPage(CREATE_APPOINTMENT.PROFESSIONAL)}
      >
        <LeftArrow className={classes.backBtn_box__arrow}/>
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.progress_box}>
        <p className={classes.progress_box__title}>
          Progress:
        </p>
        <div className={classes.progress_box__state}>
          <ProgressState 
            stateName='create_appointment'
            status={currentPage}
            variant='list'
            style={classes}
          />
        </div>
        <div className={classes.appointment_time_box}>
          <div className={classes.professional_detail_box}>
            <p className={classes.professional_detail_box__title}>
              About {firstName}
            </p>
            <div className={classes.professional_detail_box__card}>
              <ProfessionalCard 
                professionalDetails={formik.values.professional}
                bio={true}
              />
            </div>
            <div className={classes.asking_price_box}>
              <p className={classes.asking_price_box__title}>
                Appointment Price:
              </p>
              <p className={classes.asking_price_box__price}>
                &#36;{parseFloat(askingPrice / 100).toFixed(2)}
              </p>
            </div>
          </div>
          <div className={classes.calendar_box}>
            <p className={classes.calendar_box__title}>
            {firstName}’s Availability
            </p>
            <p className={classes.calendar_box__desc}>
              Select a date from the available days below.
            </p>
            {availabilityLoading
            ? <TFASkeleton type='calendar'/>
            : <AppointmentCalendar 
                availabilityList={availabilityList}
                selectedDay={selectedDay}
                selectAppointmentDay={selectAppointmentDay}
              />
            }
          </div>
        </div>
      </div>
      <SelectAppointmentTime 
        formik={formik}
        selectedDay={selectedDay}
        isAppointmentTimeOpen={isAppointmentTimeOpen}
        setIsAppointmentTimeOpen={setIsAppointmentTimeOpen}
        setCurrentPage={setCurrentPage}
      />
    </>
  )
}

export default Time