import moment from 'moment'
import React from 'react'
import { CardArrow } from '../../../assets/icons/Dashboard/DashboardIcons'
import classes from './KitCard.module.scss'

const KitCard = ({
  kit,
  kitId,
  clickKit,
  cardName,
}) => {
  const purchaseDate = moment(kit.date).format('D MMMM YYYY')
  const kitSubclassTitle = kit.subclass.split(' ')[0]
  const kitSubclassNumber = kit.subclass.split(' ')[1]
  return (
    <div
      className={kitId === kit.id
        ? classes.clicked_container
        : classes.card_container
      }
      onClick={clickKit}
    >
      <div className={classes.card_main_box}>
        <div className={classes.subclass_box}>
          {(kitSubclassTitle && kitSubclassNumber) && (
          <>
            <p className={classes.subclass_box__title}>
              {kitSubclassTitle}
            </p>
            <p className={classes.subclass_box__value}>
              {kitSubclassNumber}
            </p>
          </>
          )}
        </div>
        <p className={classes.kit_title}>
          {kit.title}
        </p>
        <div className={classes.state_box}>
          <div className={cardName === 'purchased'
            ? classes.purchased_state
            : classes.available_state
          }>
            {cardName === 'purchased'
            ? 'Purchased'
            : `$${kit.price / 100}`
            }
          </div>
          <div className={classes.state_box__purchased_date}>
            {cardName === 'purchased'
            ? purchaseDate
            : 'Purchase Now'
            }
          </div>
        </div>
      </div>
      <div className={classes.arrow_box}>
        <CardArrow />
      </div>
    </div>
  )
}

export default KitCard