import React, { useEffect, useState } from 'react'
import classes from './EditQuestion.module.scss'
import UpdateQuestion from './Sections/UpdateQuestion'
import { TFALogo } from '../../../../assets/icons/Auth/AuthIcons'
import { TFASnackBar } from '../../../../components/TFAComponents'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { UpdateQuestionValues } from '../../../../assets/data/FormikInitialValues'
import { useMutation, useQuery } from '@apollo/client'
import { GET_QUESTION } from '../../../../services/apiQuery'
import { EDIT_QUESTION } from '../../../../services/apiMutation'
import { categoryConverter } from '../../../../utils/specialtyConverter'

const EditQuestion = ({ setIsUpdateQuestionOpen, questionId }) => {
  const [ isSnackbarOpen, setIsSnackbarOpen ] = useState(false)
  const navigate = useNavigate()

  const { loading: questionLoading, data: questionData } = useQuery(GET_QUESTION, {
    variables: { getQuestionId: questionId }
  })
  const [ editQuestion ] = useMutation(EDIT_QUESTION)

  const formik = useFormik({
    initialValues: UpdateQuestionValues,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { QuestionUpdate } = await callEditQuestion(values)
        if (QuestionUpdate !== 'success') return
        navigate('/c_dashboard/questions')
        window.location.reload(false)
      } catch (error) {
        setIsSnackbarOpen(true)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    if (questionData) {
      const values = formik.values
      const data = questionData.GetQuestion
      formik.setFieldValue(values.questonId = data.id)
      formik.setFieldValue(values.questionTitle = data.title)
      formik.setFieldValue(values.questionContext = data.content)
      formik.setFieldValue(values.countryOfDestination = data.destinationCountry)
      formik.setFieldValue(values.category = data.category)
      formik.setFieldValue(values.initialImages = data.questionimages)
      formik.setFieldValue(values.initialFiles = data.questionfiles)
    }
  },[questionData])

  const callEditQuestion = async (values) => {
    const initImages = values.initialImages.map(img => img.key)
    const uploadedImages = values.images.map(img => img.key)
    const initiFiles = values.initialFiles.map(file => file.key)
    const uploadedFiles = values.files.map(file => file.key)
    const updateImages = [...initImages, ...uploadedImages]
    const updateFiles = [...initiFiles, ...uploadedFiles]
    const { data } = await editQuestion({
      variables: {
        question: {
          id: values.questonId,
          title: values.questionTitle,
          content: values.questionContext,
          destinationCountry: values.countryOfDestination,
          category: categoryConverter(values.category), 
          progress: 'Pick_Up',
        },
        imageKeys: updateImages,
        fileKeys: updateFiles,
      }
    })
    return data
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return
    setIsSnackbarOpen(false)
  }

  return (
    <div className={classes.wrapper}>
      <TFALogo className={classes.logo}/>
      <div className={classes.main_container}>
        {!questionLoading &&
          <UpdateQuestion 
            formik={formik} 
            setIsUpdateQuestionOpen={setIsUpdateQuestionOpen}
          />
        }
      </div>
      <TFASnackBar 
        variant='error'
        isSnackbarOpen={isSnackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message='Failed to edit this question. Please try again.'
      />
    </div>
  )
}

export default EditQuestion