import React, { useState } from 'react'
import classes from './ProLogin.module.scss'
import { User } from '../../../../assets/icons/Auth/AuthIcons'
import { PAuthImg } from '../../../../assets/images/Auth/AuthImages'
import { TFALink, TFAText, TFAButton, TFAInputText, TFASnackBar } from '../../../../components/TFAComponents'
import { useNavigate } from 'react-router-dom'
import { InitialProLoginValues } from '../../../../assets/data/FormikInitialValues'
import { useFormik } from 'formik'
import { proLoginSchema } from '../../../../utils/validation'
import { useMutation } from '@apollo/client'
import { PROFESSIONAL_LOGIN } from '../../../../services/apiMutation'
import useProfessionalContext from '../../../../states/Professional/useProfessionalContext'
import TFALinkExternal from '../../../../components/TFALinkExternal/TFALinkExternal'

const ProLogin = () => {
	const { professionalDispatch } = useProfessionalContext()
	const [professionalLogin] = useMutation(PROFESSIONAL_LOGIN)
	const navigate = useNavigate()
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const formik = useFormik({
		initialValues: InitialProLoginValues,
		validationSchema: proLoginSchema,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { ProfessionalLogin } = await callProfessionalLogin(values)
				if (!ProfessionalLogin) return
				localStorage.setItem('TFAToken', ProfessionalLogin.JWT)
				localStorage.setItem('TFATokenType', 'ProfessionalToken')
				professionalDispatch({ type: 'setProfessional', data: ProfessionalLogin })
				navigate('/p_dashboard/working_area')
			} catch (error) {
				setIsSnackbarOpen(true)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	const callProfessionalLogin = async (values) => {
		const { data } = await professionalLogin({
			variables: {
				email: values.email,
				password: values.password,
			},
		})
		return data
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.auth_wrapper}>
			<div className={classes.left_container}>
				<img src={PAuthImg} className={classes.left_container__img} alt='p_auth' />
			</div>
			<div className={classes.right_container}>
				<div className={classes.center_box}>
					<TFALinkExternal text='RETURN TO WEBSITE' backward={true} to='https://timeforadvice.com' style1={classes} />
					<div className={classes.right_container__title}>
						<TFAText primary_title='Welcome back' secondary_title='Sign back into your account' style1={classes} />
						<User className={classes.user_icon} />
					</div>
					<TFAInputText
						label='EMAIL'
						type='email'
						name='email'
						placeholder='sample@timeforadvice.com'
						formik={formik}
						error={formik.errors.email ?? null}
					/>
					<TFAInputText
						label='PASSWORD'
						type='password'
						name='password'
						placeholder='Enter Password'
						formik={formik}
						error={formik.errors.password ?? null}
					/>
					<div className={classes.forgotPwd_text}>Forgotten your Time For Advice account password?</div>
					<TFALink text='RETRIEVE ACCOUNT' aiforward={true} to='retrieve_professional_account' style1={classes} />
					<div className={classes.auth_btn_wrapper}>
						<TFAButton text='LOGIN' style1={classes} onClick={formik.handleSubmit} isLoading={formik.isSubmitting} />
						<TFAButton
							text='BECOME A PROFESSIONAL'
							onClick={() => {
								navigate('/p_signup')
							}}
							style2={classes}
						/>
					</div>
				</div>
			</div>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to login. Please try again.'
			/>
		</div>
	)
}

export default ProLogin
