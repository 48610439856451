const parseAddressComponents = (addressComponents ) => {
    const subPremiseObj = addressComponents.find(obj => obj.types.includes('subpremise'))
    const subPremise = subPremiseObj?.long_name
    const streetNumberObj = addressComponents.find(obj => obj.types.includes('street_number'))
    let streetNumber = streetNumberObj?.long_name
    if(subPremise){
        streetNumber = `${subPremise}/${streetNumber}`
    }
    const streetNameObj = addressComponents.find(obj => obj.types.includes('route'))
    const streetName = streetNameObj?.long_name
    const suburbObj = addressComponents.find(obj => obj.types.includes('locality'))
    const suburb = suburbObj?.long_name
    const stateObj = addressComponents.find(obj => obj.types.includes('administrative_area_level_1'))
    const state = stateObj?.short_name
    const countryObj = addressComponents.find(obj => obj.types.includes('country'))
    const country = countryObj?.long_name
    const postCodeObj = addressComponents.find(obj => obj.types.includes('postal_code'))
    const postCode = postCodeObj?.long_name
    const presentValues = [subPremise, streetNumber, streetName, suburb, state, country, postCode].filter(value => value !== undefined)
    const fullAddress = presentValues.join(" ")
    return { streetNumber, streetName, suburb, state, country, postCode, fullAddress }
 }
 export default parseAddressComponents