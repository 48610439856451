import React, { useState } from 'react'
import classes from './MultiPages.module.scss'
import { CREATE_QUESTIONS } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { ProgressState, ProfessionalCard } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAButton, TFAInputText, TFAText } from '../../../../../components/TFAComponents'
import { FormControl, InputAdornment } from '@mui/material'
import SelectProfessionalsModal from '../Modals/SelectProfessionalsModal'
import validatePage from '../../../../../utils/validatePage'

const Price = ({ formik, currentPage, setCurrentPage }) => {
  const [ isProModalOpen, setIsProModalOpen ] = useState(false)
  const proModalOpen = () => setIsProModalOpen(true)
  const proModalClose = () => setIsProModalOpen(false)

  const checkMinPrice = (min, input) => {
    if (!min && input) return true
    if (!input) return false
    if (min <= input) return true
    if (min > input) {
      formik.setFieldError(
        'askingPrice', 
        'Asking price must be greater than or equal to professional minimum price'
      )
      return false
    }
  }

  const handleSubmit = async () => {
    const { askingPrice, professional } = formik.values
    const isPageValid = await validatePage({askingPrice}, formik)
    const isPriceValidated = checkMinPrice(professional.questionPrice, (parseFloat(askingPrice) * 100))
    if (!isPageValid || !isPriceValidated) return
    setCurrentPage(CREATE_QUESTIONS.CONFIRM)
  }

  const handleRemoveProfessional = () => {
    formik.setFieldValue(formik.values.professional = {})
  }

  return (
    <>
      <div
        className={classes.backBtn_box}
        onClick={() => setCurrentPage(CREATE_QUESTIONS.MEDIA)}
      >
        <LeftArrow className={classes.backBtn_box__arrow} />
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.progress_box}>
        <p className={classes.progress_box__title}>
          Progress:
        </p>
        <div className={classes.progress_box__state}>
          <ProgressState 
            stateName='create_question'
            status={currentPage}
            variant='list'
            style={classes}
          />
        </div>
      </div>
      <div className={classes.main_content_container}>
        <TFAText 
          primary_title='Premium'
          secondary_title='Set your price'
          style1={classes}
        />
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__secondary}>
          Set the asking price for your question. Set a price you think is fair for a professional to receive after they answer your question ($25 minimum).
        </p>
      </div>
      <FormControl fullWidth>
        <TFAInputText 
          label='ASKING PRICE'
          type='text'
          name='askingPrice'
          placeholder={formik.values.professional.questionPrice ?
            parseFloat(formik.values.professional.questionPrice / 100).toFixed(2).toString() : 
            '25.00'
          }
          value={formik.values.askingPrice}
          formik={formik}
          error={formik.errors.askingPrice ?? null}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                AUD $
              </InputAdornment>
            )
          }}
        />
      </FormControl>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Professional Code
        </p>
        <p className={classes.title_box__secondary}>
          Prefer a professional? Enter their professional code below to send this question directly to them.
        </p>
      </div>
      {(Object.entries(formik.values.professional).length !== 0) &&
      <ProfessionalCard 
        professionalDetails={formik.values.professional}
        type='question'
      />
      }
      <div className={classes.professional_select_btns}>
        <TFAButton 
          text={Object.entries(formik.values.professional).length === 0
            ? 'SELECT PROFESSIONAL'
            : 'CHANGE PROFESSIONAL'
          }
          onClick={proModalOpen}
          style1={classes}
        />
        {(Object.entries(formik.values.professional).length !== 0) &&
        <div
          className={classes.professional_select_btns__remove_btn}
          onClick={handleRemoveProfessional}
        >
          <p className={classes.professional_select_btns__remove_btn__text}>
            REMOVE PROFESSIONAL
          </p>
        </div>
        }
        <TFAButton 
          text='NEXT'
          onClick={handleSubmit}
          style2={classes}
          disabled={formik.values.askingPrice === ''}
        />
      </div>
      <SelectProfessionalsModal 
        formik={formik}
        isProModalOpen={isProModalOpen}
        proModalClose={proModalClose}
      />
    </>
  )
}

export default Price