import React from 'react'
import classes from './TFASubText.module.scss'

const TFASubText = (props) => {
  return (
    <div className={`${props?.style1?.tfa_sub_text_wrapper ?? ''} ${classes.tfa_sub_text_wrapper}`}>
        <div className={`${props?.style1?.tfa_sub_text_primary ?? ''} ${classes.tfa_sub_text_primary}`}>
            {props.primary_title}
        </div>
        <div className={`${props?.style1?.tfa_sub_text_description ?? ''} ${classes.tfa_sub_text_description}`}>
            {props.description}
        </div>
    </div>
  )
}

export default TFASubText