import { Button, styled,  } from '@mui/material'

const TFACustomButton = styled(Button)(({ theme }) => ({
    fontFamily: "'Space Grotesk', sans-serif",
    width: '100%',
    margin: '0',
    fontWeight: 'bold',
    fontSize: '1.2em',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '6px',
    '&:disabled': {
        background: '#F2F6F7',
        cursor: 'not-allowed',
    },
    '&.Mui-disabled': {
        background: '#F2F6F7',
        cursor: 'not-allowed',
    }
}))

const TFAAttachFilesButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    fontFamily: "'Space Grotesk', sans-serif",
    margin: '0',
    fontSize: '1em',
    borderColor: '#2F68FA',
    color: '#2F68FA',
    cursor: 'pointer',
    textTransform: 'inherit',
    borderRadius: '10px',
    marginTop: '1em',
}))

export { TFACustomButton, TFAAttachFilesButton }