import React, { useEffect, useState } from 'react'
import classes from './ProfessionalQuestionLog.module.scss'
import { ProgressState } from '../DashboardComponents'
import moment from 'moment'
import { InstallFile } from '../../../assets/icons/Dashboard/DashboardIcons'
import { downloadFile } from '../../../utils/downloadFiles'

const ProfessionalQuestionLog = ({ 
  log,
  progress,
  client 
}) => {
  const [ createdTime, setCreatedTime ] = useState('')

  useEffect(() => {
    if (log) {
      const createdAt = parseInt(log.createdAt)
      const result = moment(createdAt).format('k:mm D/MMM/YYYY')
      setCreatedTime(result)
    }
  },[log])

  return (
    <div className={classes.log_wrapper}>
      <div className={classes.log_wrapper__log_name}>
        <p className={classes.log_wrapper__log_name__user}>
          {log.stage === 'clientReplyOne' 
            ? `${client.firstName} ${client.lastName}`
            : 'You'
          }
          <span> ・ </span>
          {log.stage === 'clientReplyOne' 
            ? 'Client'
            : 'Professional'
          }
        </p>
        <p className={classes.log_wrapper__log_name__time}>
          {createdTime}
        </p>
      </div>
      <div className={log.questionreplyfiles.length !== 0 
        ? classes.log_wrapper__with_file
        : classes.log_wrapper__without_file
      }>
        <p className={classes.content_text_style}>{log.content}</p>
      </div>
      {log.questionreplyfiles.length !== 0 &&
      <div className={classes.log_wrapper__log_file}>
        {log.questionreplyfiles.map((file, index) => (
          <div
            className={classes.log_wrapper__log_file__file}
            key={index}
          >
            <img 
              src={file.link} 
              alt='upload file' 
              className={classes.log_wrapper__log_file__file__image}
            />
            <InstallFile 
              className={classes.log_wrapper__log_file__file__install}
              onClick={() => downloadFile(file)}
            />
          </div>
        ))}
      </div>
      }
      {progress &&
      <div className={classes.log_wrapper__log_status}>
        <ProgressState 
          stateName='reply_log'
          status={progress}
          variant='card'
        />
      </div>
      }
    </div>
  )
}

export default ProfessionalQuestionLog