import { useMutation, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { CreateReportValues } from '../../../../../assets/data/FormikInitialValues'
import { PROFILE_SETTTING_PAGE, reportTopics } from '../../../../../assets/data/SelectOptions'
import { LeftArrow, TFALogo } from '../../../../../assets/icons/Auth/AuthIcons'
import { TFAButton, TFASelectBox, TFASimpleInput, TFAText } from '../../../../../components/TFAComponents'
import { CREATE_REPORT } from '../../../../../services/apiMutation'
import { GET_ALL_CLIENT_APPOINTMENT, GET_ALL_CLIENT_QUESTION } from '../../../../../services/apiQuery'
import useClientContext from '../../../../../states/Client/useClientContext'
import classes from './Sections.module.scss'

const Report = ({ setClickedPage, setReportModalOpen}) => {
  const { client } = useClientContext().clientState
  const [ selectedReportType, setSelectedReportType ] = useState('')
  const [ allQuestions, setAllQuestions ] = useState([])
  const [ allAppointments, setAllAppointments ] = useState([])
  const { loading: questionsLoading, data: questionsData } = useQuery(GET_ALL_CLIENT_QUESTION, {
    variables: { 
      clientId: client?.id,
      progress: ['Posted', 'Pick_Up', 'Working', 'Answered', 'Complete'],
    }
  })
  const { loading: appointmentsLoading, data: appointmentsData } = useQuery(GET_ALL_CLIENT_APPOINTMENT, {
    variables: { 
      clientId: client?.id,
      progress: ['Sent', 'Meeting', 'Complete', 'Cancelled'],
    }
  })
  const [ createReport ] = useMutation(CREATE_REPORT)

  const formik = useFormik({
    initialValues: CreateReportValues,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { ReportCreate } = await callCreateReport(values)
        console.log({ReportCreate})
        if (!ReportCreate) return
        setReportModalOpen(true)
        setClickedPage(PROFILE_SETTTING_PAGE.ABOUT_YOU)
      } catch (error) {
        console.log(error)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    questionsData && setAllQuestions(questionsData.GetQuestions.objectList)
    appointmentsData && setAllAppointments(appointmentsData.GetAppointments.objectList)
  },[questionsData, appointmentsData])

  const callCreateReport = async (values) => {
    const { data } = await createReport({
      variables: {
        report: {
          clientId: client?.id,
          questionId: selectedReportType === 'question' ? values.label : null,
          appointmentId: selectedReportType === 'appointment' ? values.label : null,
          label: selectedReportType === 'question' ? 'question' : 'appoinment',
          topic: values.topic,
          content: values.content,
        }
      }
    })
    return data
  }
  
  return (
    <div className={classes.wrapper}>
      <TFALogo className={classes.logo}/>
      <div className={classes.main_container}>
        <div
          className={classes.backBtn_box}
          onClick={() => setClickedPage(PROFILE_SETTTING_PAGE.ABOUT_YOU)}
        >
          <LeftArrow className={classes.backBtn_box__arrow}/>
          <p className={classes.backBtn_box__txt}>RETURN</p>
        </div>
        <div className={classes.main_content_container}>
          <TFAText 
            primary_title='Flag'
            secondary_title='Not Had A Great Experience?'
            style1={classes}
          />
        </div>
        <div className={classes.second_section_box}>
          <p className={classes.second_section_box__title}>
            Alert The Time For Advice Team
          </p>
          <p className={classes.second_section_box__desc}>
            It’s never nice having a bad experience. 
            We apologise if you are currently finding it difficult to get an answer on Time For Advice.<br/><br/> 
            You can alert our team below on what you are having difficulties with.
          </p>
        </div>
        <div className={classes.second_section_box}>
          <p className={classes.second_section_box__title}>
            Report Experience
          </p>
          <p className={classes.second_section_box__desc}>
            Select what type of experience you would like to report.
          </p>
          <div className={classes.second_section_box__btn}>
            <TFAButton 
              text='REPORT QUESTION'
              onClick={() => setSelectedReportType('question')}
              style1={selectedReportType === 'question' ? classes : null}
              style2={selectedReportType !== 'question' ? classes : null}
            />
            <TFAButton 
              text='REPORT APPOINTMENT'
              onClick={() => setSelectedReportType('appointment')}
              style1={selectedReportType === 'appointment' ? classes : null}
              style2={selectedReportType !== 'appointment' ? classes : null}
            />
          </div>
        </div>
        {selectedReportType !== '' &&
        !questionsLoading &&
        !appointmentsLoading &&
        <div className={classes.second_section_box}>
          <div className={classes.select_box}>
            <p className={classes.second_section_box__title}>
              {selectedReportType === 'question'
              ? 'Question'
              : 'Appointment'
              }
            </p>
            <TFASelectBox 
              reportArray={selectedReportType === 'question'
                ? allQuestions
                : allAppointments
              }
              label='LABEL'
              name='label'
              width='100%'
              formik={formik}
            />
          </div>
          <div className={classes.select_box}>
            <p className={classes.second_section_box__title}>
              Select Topic
            </p>
            <TFASelectBox 
              selectArray={reportTopics}
              label='TOPIC'
              name='topic'
              width='100%'
              formik={formik}
            />
          </div>
          <div className={classes.select_box}>
            <p className={classes.second_section_box__title}>
              More Information
            </p>
            <p className={classes.second_section_box__desc}>
              Please provide some information on what you are currently experiencing.
            </p>
            <TFASimpleInput 
              box_width='100%'
              box_margin='0'
              width='100% !important'
              label='INFORMATION'
              rows={5}
              variant='outlined'
              name='content'
              text_field='100%'
              formik={formik}
              error={formik.errors.content ?? null}
            />
          </div>
          <div className={classes.btn_box}>
            <TFAButton 
              text='NEXT'
              onClick={formik.handleSubmit}
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
              style2={classes}
            />
          </div>
        </div>
        }
      </div>
    </div>
  )
}

export default Report