import React, { useEffect, useState } from 'react'
import classes from '../Templates.module.scss'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAButton, TFASkeleton } from '../../../../../components/TFAComponents'
import { Templates, CardArrow } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'
import { useQuery } from '@apollo/client'
import { GET_TEMPLATES } from '../../../../../services/apiQuery'

const MyTemplates = ({ 
    clickedTemplateId,
    setClickedTemplateId,
    isNewClicked,
    setIsNewClicked,
 }) => {
    const { professional } = useProfessionalContext().professionalState
    const [ allTemplates, setAllTemplates ] = useState([])
    const { loading: templatesLoading, data: templatesData, refetch: templateRefetch } = useQuery(GET_TEMPLATES,{
        variables: { professionalId: professional?.id }
    })

    useEffect(() => {
        templateRefetch()
    },[isNewClicked, clickedTemplateId])

    useEffect(() => {
        templatesData && setAllTemplates(templatesData?.GetTemplates?.objectList)
    },[templatesData])

    const selectTemplate = (id) => {
        setIsNewClicked(false)
        setClickedTemplateId(id)
    }

    const addNewTemplate = () => {
        setIsNewClicked(!isNewClicked)
        setClickedTemplateId('')
    }

    return (
        <div className={classes.template_container}>
            <DashboardSubTitle 
                Icon={Templates}
                title='My Templates'
                color='#2F68FA'
            />
            <div className={classes.template_list}>
            {templatesLoading
            ?   <TFASkeleton
                    type='template_card'
                    count='3'
                />
            :   allTemplates?.length === 0
                ? <div className={classes.empty_template_box}>
                    No Template    
                </div>
                : allTemplates?.map((template, index) => (
                    <div 
                        className={clickedTemplateId === template.id
                            ? `${classes.template_list__box} ${classes.template_list__selected}` 
                            : `${classes.template_list__box}`
                        }
                        onClick={() => selectTemplate(template.id)}
                        key={index}
                    >
                        <p className={classes.template_list__box__title}>
                            {template.title}
                        </p>
                        <CardArrow 
                            className={classes.template_list__box__arrow}
                        />
                    </div>
                ))
            }
            </div>
            {isNewClicked &&
                <div className={`${classes.template_list__box} ${classes.template_list__selected}`
                }
                >
                <p className={classes.template_list__box__title}>
                    New Template
                </p>
                <CardArrow 
                    className={classes.template_list__box__arrow}
                />
            </div>
            }
            <div className={classes.new_template_box}>
                <TFAButton
                    text={!isNewClicked 
                        ? 'NEW TEMPLATE'
                        : 'CLOSE'
                    }
                    onClick={addNewTemplate}
                    style1={classes}
                />
            </div>
        </div>
    )
}

export default MyTemplates