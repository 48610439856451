import React, { useState } from 'react'
import classes from '../PProfile.module.scss'
import { CloseBtn, UpdateCompleted } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import {
  TFAModal,
  TFAButton,
  TFAText,
} from '../../../../../components/TFAComponents'
import { useFormik } from 'formik'
import { UpdateBankAccount } from '../../../../../assets/data/FormikInitialValues'
import TFADirectDebitInput from '../../../../../components/Register/TFADirectDebitInput/TFADirectDebitInput'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE } from '../../../../../services/apiMutation'

const BankAccountModal = ({ 
  professionalId, 
  isBankAccountOpen, 
  bankAccountModalClose,
}) => {
  const [ isCompleted, setIsCompleted ] = useState(false)
  const [ updateBankAccount ] = useMutation(UPDATE_PROFILE)
  const formik = useFormik({
    initialValues: UpdateBankAccount,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { ProfessionalUpdate } = await callUpdateBankAccount(values)
        if (!ProfessionalUpdate) return
        setIsCompleted(true)
      } catch (error) {
        console.log(error)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const callUpdateBankAccount = async (values) => {
    const { data } = await updateBankAccount({
      variables: {
        professional: {
          id: professionalId,
        },
        account: {
          country: 'AU',
          currency: 'aud',
          routing_number: values.bsb,
          account_number: values.accountNumber,
          account_holder_name: values.accountName,
        }
      }
    })
    return data
  }

  const closeCompletedModal = () => {
    bankAccountModalClose()
    setIsCompleted(false)
  }

  return (
    <>
      {isCompleted
      ?
      <TFAModal
        open={isBankAccountOpen}
        handleClose={bankAccountModalClose}
        width='25em'
      >
        <UpdateCompleted className={classes.modal_title_icon}/>
        <div className={classes.modal_text_box}>
          <TFAText
            secondary_title='Bank Account Updated'
            description='Your bank account detail has been updated successfully.'
            style2={classes}
          />
        </div>
        <TFAButton
          text='GREAT!'
          style2={classes}
          onClick={closeCompletedModal}
        />
      </TFAModal>
      :
      <TFAModal
        open={isBankAccountOpen}
        handleClose={bankAccountModalClose}
        width='30em'
      >
        <div className={classes.modal_title_box}>
          <CloseBtn 
            className={classes.modal_title_box__icon}
            onClick={bankAccountModalClose}
          />
          <p className={classes.modal_title_box__title}>
            Bank Account
          </p>
        </div>
        <TFAText
          primary_title='Bank Account'
          secondary_title='Update Account'
          style1={classes}
        />
        <div className={classes.bank_account}>
          <p className={classes.bank_account__title}>
            New Bank Account
          </p>
          <p className={classes.bank_account__desc}>
            Enter your new bank account details.
          </p>
          <TFADirectDebitInput 
            formik={formik}
            bg='dashboard'
          />
        </div>
        <TFAButton 
          text='SAVE'
          style2={classes}
          isLoading={formik.isSubmitting}
          onClick={formik.handleSubmit}
        />
      </TFAModal>
      }
    </>
  )
}

export default BankAccountModal