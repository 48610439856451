import React from 'react'
import classes from '../PProfile.module.scss'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { Certificate, Documents } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import { SINGLE_DOWNLOAD } from '../../../../../services/apiQuery'

const MARAStatus = ({ professional, updateMaraModalOpen }) => {
	const [singleDownload] = useLazyQuery(SINGLE_DOWNLOAD)
	const today = moment()
	const maraExpiry = moment(professional?.MARAexpiryDate)
	const isExpired = moment(maraExpiry).isAfter(today, 'day')

	const callSigleDownload = async (filename) => {
		const { data } = await singleDownload({
			variables: { filename },
		})
		window.open(data?.SingleDownload)
	}

	return (
		<div className={classes.mara_status_container}>
			<DashboardSubTitle Icon={Certificate} title='MARA Certificate Status' fontSize='1.4em' color='#2F68FA' iconSize='scale(1.8)' />
			<div className={classes.mara_box}>
				<div className={classes.mara_box__contents}>
					<Certificate className={classes.mara_box__status_icon_big} />
					<div className={classes.mara_box__text_box}>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>MARA Number:</p>
							<p className={classes.mara_box__text_box__container__detail}>{professional?.MARANumber}</p>
						</div>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>Expiry Date:</p>
							<p className={classes.mara_box__text_box__container__detail}>{moment(professional?.MARAexpiryDate).format('DD-MMM-YYYY')}</p>
						</div>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>Certificate:</p>
							<p className={classes.mara_box__text_box__container__detail}>
								{professional?.MARA_documents?.map((document, index) => (
									<Documents
										className={classes.mara_box__text_box__container__detail__document}
										onClick={() => callSigleDownload(document.key)}
										key={index}
									/>
								))}
							</p>
						</div>
						<div className={classes.mara_box__text_box__container}>
							<p className={classes.mara_box__text_box__container__title}>Status:</p>
							<p
								className={
									isExpired ? classes.mara_box__text_box__container__detail_active : classes.mara_box__text_box__container__detail_inactive
								}
							>
								{isExpired ? 'Active' : 'Inactive'}
							</p>
						</div>
						<p className={classes.mara_contact_text}>
							{isExpired && 'Please contact support to upload MARA certificate'}
							<a href='mailto:admin@timeforadvice.com' className={classes.mara_contact_text__address}>
								&nbsp;Send Email
							</a>
						</p>
					</div>
				</div>
				{/* <EditIcon 
                    className={classes.mara_box__edit_icon}
                    onClick={updateMaraModalOpen}
                /> */}
			</div>
		</div>
	)
}

export default MARAStatus
