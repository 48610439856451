import React, { useState } from 'react'
import classes from '../RetrieveProfessionalAccount.module.scss'
import { useNavigate } from 'react-router-dom'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { TFAButton, TFAInputText, TFAText } from '../../../../../components/TFAComponents'
import { useMutation } from '@apollo/client'
import { RESET_PASSWORD_STEP1 } from '../../../../../services/apiMutation'
import { RETRIEVE_PASSWORD_PAGES } from '../../../../../assets/data/SelectOptions'

const AccountEmail = ({ formik, setCurrentPage, setIsEmailSnackbarOpen }) => {
    const navigate = useNavigate()
    const [ isLoading, setIsLoading ] = useState(false)
    const [ checkEmailExistance ] = useMutation(RESET_PASSWORD_STEP1)

    const checkEmailExist = async () => {
        try {
          setIsLoading(true)
          const { ResetPasswordStep1 } = await callCheckEmailExistance(formik.values.email)
          if (ResetPasswordStep1 !== 'success') return
          setCurrentPage(RETRIEVE_PASSWORD_PAGES.VERIFY_CODE)
        } catch (error) {
          setIsEmailSnackbarOpen(true)
        } finally {
          setIsLoading(false)
        }
      }
    
      const callCheckEmailExistance = async (email) => {
        const { data } = await checkEmailExistance({
          variables: { email }
        })
        return data
      }

    return (
        <>
            <div 
                className={classes.backBtn_box} 
                onClick={() => navigate('/p_login')}
            >
                <LeftArrow className={classes.backBtn_box__arrow}/>
                <p className={classes.backBtn_box__txt}>RETURN</p>
            </div>
            <div className={classes.wrapper__main_container__tfa_text}>
                <TFAText 
                    primary_title='Retrieve Account'
                    secondary_title='Let’s get you back in action!'
                    style1={classes}
                />
            </div>
            <div className={classes.text_wrapper}>
                <div className={classes.text_wrapper__title}>
                Account Email
                </div>
                <div className={classes.text_wrapper__description}>
                Enter the email associated with your account. If this email exists on our platform you will receive a 6 digital code to this email address.
                </div>
            </div>
            <TFAInputText
                label='EMAIL'
                type='email'
                name='email'
                placeholder='sample@timeforadvice.com'
                formik={formik}
                error={formik.errors.email ?? null}
            />
            <TFAButton 
                text='NEXT'
                onClick={checkEmailExist}
                isLoading={isLoading}
                disabled={isLoading}
                style1={classes}
            />
        </>
    )
}

export default AccountEmail