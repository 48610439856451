import React, { useEffect, useState } from 'react'
import classes from '../Kits.module.scss'
import KitInfoModal from '../Modals/KitInfoModal'
import PurchaseModal from '../Modals/PurchaseModal'
import useClientContext from '../../../../../states/Client/useClientContext'
import { KitInfo } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { DashboardSubTitle, DashboardTitle, KitCard } from '../../../../../components/Dashboard/DashboardComponents'
import { useQuery } from '@apollo/client'
import { GET_CLIENT, GET_KITS, GET_CARD_DETAIL } from '../../../../../services/apiQuery'
import { TFASkeleton } from '../../../../../components/TFAComponents'
import { useNavigate } from 'react-router-dom'

const AdviceKits = ({ purchasedKitId, kitId, setPurchasedKitId, setKitId }) => {
	const { client } = useClientContext().clientState
	const [purchasedKits, setPurchasedKits] = useState([])
	const [availableKits, setAvailableKits] = useState([])
	const [isKitInfoModalOpen, setIsKitInfoModalOpen] = useState(false)
	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
	const navigate = useNavigate()
	const { loading: cardLoading, data: cardData } = useQuery(GET_CARD_DETAIL)
	const {
		loading: kitsLoading,
		data: kitsData,
		refetch: kitsRefetch,
	} = useQuery(GET_KITS, {
		variables: { title: '' },
	})
	const {
		loading: clientLoading,
		data: clientData,
		refetch: clientRefetch,
	} = useQuery(GET_CLIENT, {
		variables: { getClientId: client?.id },
	})

	useEffect(() => {
		if (kitsData && clientData) {
			const filterPurchased = kitsData.GetKits.objectList.filter((kit) =>
				clientData.GetClient.kits.every((clientKit) => clientKit.id !== kit.id)
			)
			if (filterPurchased) {
				setAvailableKits(filterPurchased)
				setPurchasedKits(clientData.GetClient.kits)
			}
		}
	}, [kitsData, clientData])

	const purchaseNewKit = (selectedId) => {
		if (cardLoading) return

		if (cardData.Stripe_ListAllCards.length === 0) navigate('/payment')
		else {
			setKitId(selectedId)
			setIsPaymentModalOpen(true)
		}
	}

	return (
		<div className={classes.kits_container}>
			<DashboardTitle title='Advice Kits' Icon={KitInfo} iconClick={() => setIsKitInfoModalOpen(true)} scale='scale(1.1)' />
			<div className={classes.kit_list_container}>
				<div className={classes.purchased_box}>
					<DashboardSubTitle title='Purchased' margnTop={true} />
					{clientLoading ? (
						<TFASkeleton type='question_card' count={2} />
					) : purchasedKits.length === 0 ? (
						<div className={classes.empty_kit_box}>No Purchased Kit</div>
					) : (
						purchasedKits.map((kit, index) => (
							<KitCard kit={kit} kitId={purchasedKitId} key={index} clickKit={() => setPurchasedKitId(kit.id)} cardName='purchased' />
						))
					)}
				</div>
				<div className={classes.available_box}>
					<DashboardSubTitle title='Available' />
					{kitsLoading ? (
						<TFASkeleton title='question_card' count={2} />
					) : availableKits.length === 0 ? (
						<div className={classes.empty_kit_box}>No Available Kit</div>
					) : (
						availableKits.map((kit, index) => (
							<KitCard kit={kit} kitId={kitId} key={index} clickKit={() => purchaseNewKit(kit.id)} cardName='available' />
						))
					)}
				</div>
			</div>
			<KitInfoModal isKitInfoModalOpen={isKitInfoModalOpen} setIsKitInfoModalOpen={setIsKitInfoModalOpen} />
			<PurchaseModal
				kitId={kitId}
				isPaymentModalOpen={isPaymentModalOpen}
				setIsPaymentModalOpen={setIsPaymentModalOpen}
				kitsRefetch={kitsRefetch}
				clientRefetch={clientRefetch}
			/>
		</div>
	)
}

export default AdviceKits
