import React, { useEffect, useState } from 'react'
import classes from './CompleteAccount.module.scss'
import { TFALogo } from '../../../../assets/icons/Auth/AuthIcons'
import { COMPLETE_SIGNUP_PAGES } from '../../../../assets/data/SelectOptions'
import AboutYou from './MultiPages/AboutYou'
import Availability from './MultiPages/Availability'
import Address from './MultiPages/Address'
import PaymentMethod from './MultiPages/PaymentMethod'
import CreatePassword from './MultiPages/CreatePassword'
import { useFormik } from 'formik'
import { proCompleteSignupSchema } from '../../../../utils/validation'
import { GET_VISITOR_TOKEN } from '../../../../services/apiQuery'
import { PROFESSIONAL_SIGNUP_STEP2 } from '../../../../services/apiMutation'
import { useQuery, useMutation } from '@apollo/client'
import { dateFormatter } from '../../../../utils/appointmentFormatter'
import useProfessionalState from '../../../../states/Professional/useProfessionalContext'
import { useNavigate } from 'react-router-dom'
import { InitialProCompleteSignupValues } from '../../../../assets/data/FormikInitialValues'
import { TFASnackBar } from '../../../../components/TFAComponents'

const CompleteAccount = () => {
	const paramas = window.location.href
	const professionalId = paramas.split('id=')[1]
	const { professionalDispatch } = useProfessionalState()
	const [currentPage, setCurrentPage] = useState(COMPLETE_SIGNUP_PAGES.ABOUT_YOU)
	const { loading: tokenLoading, data: tokenData } = useQuery(GET_VISITOR_TOKEN)
	const [proSignupStep2Mutation] = useMutation(PROFESSIONAL_SIGNUP_STEP2)
	const [isUnavailable, setIsUnavailable] = useState(false)
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const navigate = useNavigate()
	const formik = useFormik({
		initialValues: InitialProCompleteSignupValues,
		validationSchema: proCompleteSignupSchema,
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const formattedDates = finalFormattedDates(formik.values.availability)
				const { ProfessionalSignupStep2 } = await callProSignupStep2Mutaion(values, formattedDates)
				if (!ProfessionalSignupStep2) return
				localStorage.setItem('TFAToken', ProfessionalSignupStep2.JWT)
				localStorage.setItem('TFATokenType', 'ProfessionalToken')
				professionalDispatch({ type: 'setProfessional', data: ProfessionalSignupStep2 })
				formik.setSubmitting(false)
				navigate('/p_dashboard/working_area')
			} catch (error) {
				setIsSnackbarOpen(true)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (tokenData) {
			localStorage.setItem('TFAToken', tokenData.GetVisitorToken)
			localStorage.setItem('TFATokenType', 'VisitorToken')
		}
	}, [tokenData])

	const renderPage = (currentPage, setCurrentPage, formik) => {
		switch (currentPage) {
			case COMPLETE_SIGNUP_PAGES.ABOUT_YOU:
				return <AboutYou formik={formik} setCurrentPage={setCurrentPage} />
			case COMPLETE_SIGNUP_PAGES.AVAILABILITY:
				return (
					<Availability formik={formik} setCurrentPage={setCurrentPage} isUnavailable={isUnavailable} setIsUnavailable={setIsUnavailable} />
				)
			case COMPLETE_SIGNUP_PAGES.ADDRESS_PAYMENT:
				return (
					<>
						<Address formik={formik} setCurrentPage={setCurrentPage} />
						<PaymentMethod formik={formik} setCurrentPage={setCurrentPage} />
					</>
				)
			case COMPLETE_SIGNUP_PAGES.CREATE_PASSWORD:
				return <CreatePassword formik={formik} setCurrentPage={setCurrentPage} />
			default:
				return <AboutYou formik={formik} setCurrentPage={setCurrentPage} />
		}
	}

	const finalFormattedDates = (availability) => {
		const formattedDates = dateFormatter(availability)
		if (isUnavailable) return []
		const final = formattedDates.filter((day) => day !== 1)
		return final
	}

	const callProSignupStep2Mutaion = async (values, formattedDates) => {
		console.log(professionalId)

		const { data } = await proSignupStep2Mutation({
			variables: {
				professional: {
					id: professionalId,
					avatar: values.avatar,
					bio: values.bio,
					firstLanguage: values.firstLanguage,
					secondLanguage: values.secondLanguage,
					experienceLevel: values.experienceLevel,
					askingPrice: parseInt(values.halfHourRate) * 100,
					questionPrice: parseInt(values.minRate) * 100,
					streetNumber: values.streetNumber,
					streetName: values.streetName,
					suburb: values.suburb,
					postcode: values.postcode,
					state: values.state,
					country: values.country,
					stripe_accountId: values.stripe_id,
					password: values.password,
				},
				stripeAccount: {
					country: 'AU',
					currency: 'aud',
					routing_number: values.bsb,
					account_number: values.accountNumber,
					account_holder_name: values.accountName,
				},
				meetingSchedules: formattedDates,
			},
		})
		return data
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.wrapper}>
			{tokenLoading ? (
				<></>
			) : (
				<>
					<TFALogo className={classes.logo} />
					<div className={classes.main_container}>{renderPage(currentPage, setCurrentPage, formik)}</div>
					<TFASnackBar
						variant='error'
						isSnackbarOpen={isSnackbarOpen}
						handleSnackbarClose={handleSnackbarClose}
						message='Failed to sigup, Please try again later.'
					/>
				</>
			)}
		</div>
	)
}

export default CompleteAccount
