import React, { useEffect, useState } from 'react'
import classes from './MultiPages.module.scss'
import { CREATE_QUESTIONS } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { ProgressState, ProfessionalCard, PaymentMethodCard } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAButton, TFAText } from '../../../../../components/TFAComponents'
import { DeleteImage, EditIcon } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { IconButton } from '@mui/material'
import PaymentMethodModal from '../Modals/PaymentMethodModal'
import UploadImagesFiles from '../../../../../components/Dashboard/UploadImagesFiles/UploadImagesFiles'
import { useLazyQuery } from '@apollo/client'
import { GET_CARD_DETAIL, GET_PRESIGNED_UPLOAD_URL } from '../../../../../services/apiQuery'
import useClientContext from '../../../../../states/Client/useClientContext'

const Confirm = ({ formik, currentPage, setCurrentPage }) => {
	const { client } = useClientContext().clientState
	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
	const paymentModalOpen = () => setIsPaymentModalOpen(true)
	const paymentModalClose = () => setIsPaymentModalOpen(false)
	const [getUploadURL] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)
	const [getCards] = useLazyQuery(GET_CARD_DETAIL)

	useEffect(() => {
		if (client) {
			formik.setFieldValue((formik.values.paymentPreference = client.paymentPreference))
			formik.setFieldValue((formik.values.email = client.email))
		}
	}, [client])

	const callgetUploadURL = async (file) => {
		const { data } = await getUploadURL({
			variables: {
				filename: file.name,
				folder: 'question',
			},
		})
		return data
	}

	const getCardDetails = async () => {
		try {
			const cardData = await getCards()
			if (cardData) {
				if (cardData.Stripe_ListAllCards.length === 0) return
				formik.setFieldValue((formik.values.allCards = cardData.Stripe_ListAllCards))
				formik.setFieldValue((formik.values.originalDefaultCard = cardData.Stripe_ListAllCards[0]))
				formik.setFieldValue((formik.values.defaultCard = cardData.Stripe_ListAllCards[0]))
			}
		} catch (error) {
			console.log('the cards', error)
		}
	}

	const deleteImage = (filename) => {
		const filterUploadedImages = formik.values.uploadedImages.filter((i) => i.filename !== filename)
		const filterImages = formik.values.images.filter((i) => i.fileName !== filename)
		formik.setFieldValue((formik.values.uploadedImages = filterUploadedImages))
		formik.setFieldValue((formik.values.images = filterImages))
	}

	return (
		<>
			<div className={classes.backBtn_box} onClick={() => setCurrentPage(CREATE_QUESTIONS.PRICE)}>
				<LeftArrow className={classes.backBtn_box__arrow} />
				<p className={classes.backBtn_box__txt}>RETURN</p>
			</div>
			<div className={classes.progress_box}>
				<p className={classes.progress_box__title}>Progress:</p>
				<div className={classes.progress_box__state}>
					<ProgressState stateName='create_question' status={currentPage} variant='list' style={classes} />
				</div>
			</div>
			<div className={classes.main_content_container}>
				<TFAText primary_title='Review Your Questions' secondary_title='Ready to post?' style1={classes} />
			</div>
			<div className={classes.confirm_section_box}>
				<div className={classes.editable_title}>
					<p className={classes.section_title}>Questions Details:</p>
					<IconButton className={classes.section_icon} onClick={() => setCurrentPage(CREATE_QUESTIONS.DETAILS)}>
						<EditIcon />
					</IconButton>
				</div>
				<div className={classes.question_detail_container}>
					<div className={classes.question_detail_box}>
						<div className={classes.question_detail_box__title}>Title:</div>
						<div className={classes.question_detail_box__content}>{formik.values.questionTitle}</div>
					</div>
					<div className={classes.question_detail_box}>
						<div className={classes.question_detail_box__title}>Destination:</div>
						<div className={classes.question_detail_box__content}>{formik.values.countryOfDestination}</div>
					</div>
					<div className={classes.question_detail_box}>
						<div className={classes.question_detail_box__title}>Catagory:</div>
						<div className={classes.question_detail_box__content}>{formik.values.category}</div>
					</div>
					<div className={classes.question_detail_box}>
						<div className={classes.question_detail_box__title}>Question Context:</div>
						<div className={classes.question_detail_box__content}>{formik.values.questionContext}</div>
					</div>
				</div>
			</div>
			<div className={classes.confirm_section_box}>
				<div className={classes.editable_title}>
					<p className={classes.section_title}>Images &amp; Videos:</p>
					<IconButton className={classes.section_icon} onClick={() => setCurrentPage(CREATE_QUESTIONS.MEDIA)}>
						<EditIcon />
					</IconButton>
				</div>
				<div className={classes.image_box}>
					{formik.values.uploadedImages.length === 0 ? (
						<div className={classes.image_box__empty}>No images or videos uploaded</div>
					) : (
						<div className={classes.slide_container}>
							{formik.values.uploadedImages.map((image, index) => (
								<div className={classes.slide_container__img_box} key={index}>
									<img src={image.uploadURL} alt='question_document' className={classes.slide_container__img_box__img} />
									<DeleteImage className={classes.slide_container__img_box__delete} onClick={() => deleteImage(image.filename)} />
								</div>
							))}
						</div>
					)}
				</div>
				<div className={classes.title_box}>
					<p className={classes.title_box__primary}>Attach Files</p>
					<p className={classes.title_box__secondary}>Attach files to help your professional understand your situation.</p>
				</div>
				<UploadImagesFiles formik={formik} callgetUploadURL={callgetUploadURL} type='files' />
			</div>
			<div className={classes.confirm_section_box}>
				<div className={classes.editable_title}>
					<p className={classes.section_title}>Price &amp; Professional:</p>
					<IconButton className={classes.section_icon} onClick={() => setCurrentPage(CREATE_QUESTIONS.PRICE)}>
						<EditIcon />
					</IconButton>
				</div>
				<div className={classes.asking_price_box}>
					<p className={classes.asking_price_box__title}>Asking Price:</p>
					<p className={classes.asking_price_box__price}>&#36;{parseFloat(formik.values.askingPrice).toFixed(2)}</p>
				</div>
				<ProfessionalCard professionalDetails={formik.values.professional} type='question' />
			</div>
			<div className={classes.confirm_section_box}>
				<div className={classes.title_box}>
					<p className={classes.title_box__primary}>Payment Method</p>
					<p className={classes.title_box__secondary}>Select how you would like to pay for your question.</p>
				</div>
				<PaymentMethodCard formik={formik} handleClick={paymentModalOpen} isPaymentSelected={true} method={formik.values.paymentPreference} />
			</div>
			<div className={classes.btn_box}>
				<TFAButton
					text='CONFIRM &amp; PAY'
					onClick={formik.handleSubmit}
					isLoading={formik.isSubmitting}
					disabled={formik.isSubmitting}
					style1={classes}
				/>
			</div>
			<PaymentMethodModal
				formik={formik}
				onRefresh={getCardDetails}
				isPaymentModalOpen={isPaymentModalOpen}
				paymentModalClose={paymentModalClose}
			/>
		</>
	)
}

export default Confirm
