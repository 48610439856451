import React, { useState } from 'react'
import classes from './AutoAddressComplete.module.scss'
import { CircularProgress } from '@mui/material'
import parseAddressComponents from '../../../utils/parseAddressComponents'

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { TFAFormControl, TFATextInput } from '../../TFAInputText/MuiStyle'

const AutoAddressComplete = ({ formik }) => {
    const [ searchAddress, setSearchAddress ] = useState('')

    const handleSelect = async (value) => {
        const result = await geocodeByAddress(value)
        const parseAddress = parseAddressComponents(result[0].address_components)
        setSearchAddress(parseAddress.fullAddress)
        formik.setFieldValue(formik.values.streetNumber = parseAddress.streetNumber)
        formik.setFieldValue(formik.values.streetName = parseAddress.streetName)
        formik.setFieldValue(formik.values.suburb = parseAddress.suburb)
        formik.setFieldValue(formik.values.postcode = parseAddress.postCode)
        formik.setFieldValue(formik.values.state = parseAddress.state)
        formik.setFieldValue(formik.values.country = parseAddress.country)
    }

    return(
        <div className={classes.address_complete_container}>
            <PlacesAutocomplete
                value={searchAddress}
                onChange={setSearchAddress}
                onSelect={handleSelect}
            >
                {({ suggestions,
                    loading,
                    getInputProps,
                    getSuggestionItemProps}) => (
                        <div>
                            <TFAFormControl>
                                <TFATextInput 
                                    {...getInputProps({placeholder: 'SEARCH ADDRESS'})}
                                />
                            </TFAFormControl>
                            <div
                                className={(suggestions.length || loading) 
                                    ? classes.suggestions_box
                                    : null
                                }
                            >
                                {loading 
                                ? 
                                    <CircularProgress className={classes.disabled_circular_progress} />
                                :
                                    suggestions.map(suggestion => {
                                        const style = {
                                            padding: '0.3em',
                                            borderRadius: '6px',
                                            backgroundColor: suggestion.active 
                                                ? 'rgba(47, 104, 250, 0.2)' 
                                                : 'white'
                                        }
                                        return (
                                            <div 
                                                {...getSuggestionItemProps(suggestion, { style })}
                                                className={classes.suggestion_cell}
                                            >
                                                {suggestion.description}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </PlacesAutocomplete>
        </div>
    )
}
export default AutoAddressComplete