import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { LeftArrow, TFALogo } from '../../assets/icons/Auth/AuthIcons'
// @ts-ignore
import classes from '../../pages/Client/Auth/Signup/Signup.module.scss'
import { ProgressState } from '../Dashboard/DashboardComponents'

type Props = {
	children: ReactNode
	canGoBack?: boolean
	page: 'Languages' | 'Payment'
}

function SignupWrapper({ children, canGoBack, page }: Props) {
	const navigate = useNavigate()

	return (
		<div className={classes.wrapper}>
			<TFALogo className={classes.logo} />
			<div className={classes.main_container}>
				{canGoBack && (
					<div className={classes.backBtn_box} onClick={() => navigate('/languages')}>
						<LeftArrow className={classes.backBtn_box__arrow} />
						<p className={classes.backBtn_box__txt}>RETURN</p>
					</div>
				)}

				<div className={classes.progress_box}>
					<p className={classes.progress_box__title}>Progress:</p>
					<div className={classes.progress_box__state}>
						<ProgressState cancelled={false} stateName='signup' status={page} variant='list' style={classes} />
					</div>
				</div>
				{children}
			</div>
		</div>
	)
}

export default SignupWrapper
