import React from 'react'
import classes from './DetailsProfile.module.scss'
import { Avatar } from '@mui/material'
import { reverseSpecialtyConverter } from '../../../utils/specialtyConverter'

const DetailsProfile = ({ numberOfAsked, questionDetails, title, avatar, page }) => {
	return (
		<>
			{title && <p className={classes.profile_title}>{title}</p>}
			<div className={classes.container}>
				<Avatar src={avatar} className={classes.container__avatar} sx={{ width: '2.5em', height: '2.5em' }} alt='profile-avatar' />
				<div className={classes.container__profile_info}>
					<p className={classes.container__profile_info__name}>
						{page === 'client' ? (
							<>
								{questionDetails.professional.firstName}&nbsp;
								{questionDetails.professional.lastName}&nbsp;
								<span className={classes.container__profile_info__name__code}>- {questionDetails.professional.professionalCode}</span>
							</>
						) : (
							<>
								{questionDetails.client.firstName}&nbsp;
								{questionDetails.client.lastName}
							</>
						)}
					</p>
					<div className={classes.container__profile_info__detail}>
						{page === 'client' ? (
							reverseSpecialtyConverter(questionDetails.professional.specialty)
						) : (
							<>
								{numberOfAsked}&nbsp;
								{numberOfAsked <= 1 ? 'Question Asked' : 'Questions Asked'}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default DetailsProfile
