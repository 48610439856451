import { InputBase, styled, alpha } from '@mui/material'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '10px',
    width: '100%',
    border: '2px solid #2F68FA',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  }))
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
      width: '100%',
      '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          width: '100%',
          fontFamily: "'Space Grotesk', sans-serif",
      },
      '& .MuiInputBase-input::placeholder': {
          color: '#022873',
          fontFamily: "'Space Grotesk', sans-serif",
          fontWeight: 'bold',
      }
  }))

  export { Search, SearchIconWrapper, StyledInputBase }