import React, { useState } from 'react'
import classes from '../RetrieveProfessionalAccount.module.scss'
import { RETRIEVE_PASSWORD_PAGES } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { TFAButton, TFAText } from '../../../../../components/TFAComponents'
import TwoFactorVerificationForm from '../../../../../components/Register/TwoFactorVerificationForm/TwoFactorVerificationForm'
import { useMutation } from '@apollo/client'
import { RESET_PASSWORD_STEP2 } from '../../../../../services/apiMutation'

const VerifyCode = ({ formik, setCurrentPage, setIsCodeSnackbarOpen }) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ authCode, setAuthCode ] = useState('')
  const [ verifyPasswordCode ] = useMutation(RESET_PASSWORD_STEP2)

  const verifyInputCode = async () => {
    const enteredCode = authCode.slice(0, 6)
    try {
      setIsLoading(true)
      const { ResetPasswordStep2 } = await callVerifyPasswordCode(formik.values.email, enteredCode)
      if (!ResetPasswordStep2) return
      formik.setFieldValue(formik.values.id = ResetPasswordStep2.id)
      localStorage.setItem('TFAToken', ResetPasswordStep2.JWT)
      setCurrentPage(RETRIEVE_PASSWORD_PAGES.RESET_PASSWORD)
    } catch (error) {
      setIsCodeSnackbarOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const callVerifyPasswordCode = async (email, verifyCode) => {
    const { data } = await verifyPasswordCode({
      variables: { 
        email, 
        verifyCode 
      }
    })
    return data
  }

  return (
    <>
      <div 
        className={classes.backBtn_box} 
        onClick={() => setCurrentPage(RETRIEVE_PASSWORD_PAGES.ACCOUNT_EMAIL)}
      >
        <LeftArrow className={classes.backBtn_box__arrow}/>
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.wrapper__main_container__tfa_text}>
        <TFAText 
          primary_title='Retrieve Account'
          secondary_title='Your code'
          style1={classes}
        />
      </div>
      <div className={classes.text_wrapper}>
        <div className={classes.text_wrapper__title}>
          Verifacation Code
        </div>
        <div className={classes.text_wrapper__description}>
          {`We sent an email to ${formik.values.email} enter the 6 digit code below.`}
        </div>
      </div>
      <TwoFactorVerificationForm 
          authCode={authCode} 
          onChangeText={setAuthCode}
          verifyCode={verifyInputCode}
      />
      <TFAButton 
        text='NEXT'
        onClick={verifyInputCode}
        isLoading={isLoading}
        disabled={isLoading}
        style1={classes}
      />
    </>
  )
}

export default VerifyCode