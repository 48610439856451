import React from 'react'
import classes from '../PProfile.module.scss'
import moment from 'moment'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { TransactionHistory } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFASkeleton } from '../../../../../components/TFAComponents'

const History = ({ historyLoading, allHistories }) => {

    const historyDetailGenerator = (value, type) => {
        const client = value.split(':')[1]
        const state = value.split(':')[2]
        const category = state.split(' ')[0]
        switch (type) {
            case 'client': return client
            case 'type': return category
            default: break;
        }
    }

    const createdDateConverter = (createdAt) => {
        const formattedDate = moment.unix(createdAt/1000).format('D MMM YYYY')
        return formattedDate
    }

    const findTransactionTitle = (payment) => {
        if (payment?.question)       return payment.question.title
        if (payment?.appointment)    return payment.appointment.title
        if (payment?.kit)            return payment.kit.title
    }
    
    return (
        <div className={classes.history_container}>
            <DashboardSubTitle 
            Icon={TransactionHistory}
            title='History'
            fontSize='1.4em'
            />
            <div className={classes.history_list}>
            {historyLoading
            ? <TFASkeleton 
                type='transaction_history'
                count={5}
              />
            : allHistories.map(history => (
                <div className={classes.history_list__box} key={history.id}>
                    <p className={classes.history_list__box__date}>
                        {createdDateConverter(history?.payment?.createdAt)}
                    </p>
                    <p className={classes.history_list__box__question}>
                        {findTransactionTitle(history?.payment)}
                    </p>
                    <p className={classes.history_list__box__client}>
                        {history.description
                        ? historyDetailGenerator(history.description, 'client')
                        : '-'
                        }
                    </p>
                    <p className={classes.history_list__box__price}>
                        $ {parseFloat(history.amount / 100).toFixed(2)}
                    </p>
                    <div className={classes.history_list__box__receipt}>
                        {history.description
                        ? historyDetailGenerator(history.description, 'type')
                        : '-'
                        }
                    </div>
                </div>
            ))
            }
            </div>
        </div>
    )
}

export default History