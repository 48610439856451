import React, { useEffect, useState } from 'react'
import classes from '../Questions.module.scss'
import { DashboardTitle, ClientQuestionLog, AnswerInput } from '../../../../../components/Dashboard/DashboardComponents'
import { EditIcon, UploadFileClip } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { Chip } from '@mui/material'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
	GET_CARD_DETAIL,
	GET_QUESTION_FOR_CLIENT,
	GET_QUESTION_OFFERS,
	GET_QUESTION_REPLYS,
	SINGLE_DOWNLOAD,
} from '../../../../../services/apiQuery'
import { TFASkeleton, TFASnackBar } from '../../../../../components/TFAComponents'
import { useFormik } from 'formik'
import { AnswerQuestionValues } from '../../../../../assets/data/FormikInitialValues'
import Modal from './Modal'
import { CREATE_QUESTION_REPLY, QUESTION_UPDATE } from '../../../../../services/apiMutation'
import DetailsProgress from '../../../../../components/Dashboard/DetailsProgress/DetailsProgress'
import DetailsProfile from '../../../../../components/Dashboard/DetailsProfile/DetailsProfile'
import DetailsQuestion from '../../../../../components/Dashboard/DetailsQuestion/DetailsQuestion'
import DetailsImage from '../../../../../components/Dashboard/DetailsImage/DetailsImage'
import DetailsFile from '../../../../../components/Dashboard/DetailsFile/DetailsFile'
import DetailsOffer from '../../../../../components/Dashboard/DetailsOffer/DetailsOffer'
import PaymentMethodModal from '../../CreateQuestion/Modals/PaymentMethodModal'

const Details = ({ questionId, setIsUpdateQuestionOpen }) => {
	const [avatar, setAvatar] = useState('')
	const [questionDetails, setQuestionDetails] = useState({})
	const [questionLogs, setQuestionLogs] = useState([])
	const [allOffers, setAllOffers] = useState([])

	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const [isAcceptOfferOpen, setIsAcceptOfferOpen] = useState(false)
	const [isDeclineOfferOpen, setIsDeclineOfferOpen] = useState(false)
	const [isInputFieldOpen, setIsInputFieldOpen] = useState(false)
	const [isAnswerOpen, setIsAnswerOpen] = useState(false)
	const [isMoreQuestionOpen, setIsMoreQuestionOpen] = useState(false)
	const [isAcceptQuestionOpen, setIsAcceptQuestionOpen] = useState(false)
	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
	const acceptOfferOpen = () => setIsAcceptOfferOpen(true)
	const acceptOfferClose = () => setIsAcceptOfferOpen(false)
	const declineOfferOpen = () => setIsDeclineOfferOpen(true)
	const declineOfferClose = () => setIsDeclineOfferOpen(false)
	const inputFieldOpen = () => setIsInputFieldOpen(true)
	const inputFieldClose = () => setIsInputFieldOpen(false)
	const answerModalOpen = () => setIsAnswerOpen(true)
	const answerModalClose = () => setIsAnswerOpen(false)
	const moreQuestionOpen = () => setIsMoreQuestionOpen(true)
	const moreQuestionClose = () => setIsMoreQuestionOpen(false)
	const acceptQuestionOpen = () => setIsAcceptQuestionOpen(true)
	const acceptQuestionClose = () => setIsAcceptQuestionOpen(false)
	const paymentModalOpen = () => setIsPaymentModalOpen(true)
	const paymentModalClose = () => setIsPaymentModalOpen(false)

	const {
		loading: questionLoading,
		data: questionData,
		refetch: refetchQuestion,
	} = useQuery(GET_QUESTION_FOR_CLIENT, {
		variables: { getQuestionId: questionId },
	})
	const {
		loading: replyLoading,
		data: replyData,
		refetch: refetchReply,
	} = useQuery(GET_QUESTION_REPLYS, {
		variables: { questionId: questionDetails?.id },
	})
	const { loading: avatarLoading, data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
		variables: { filename: questionDetails?.professional?.avatar },
	})
	const { loading: offersLoading, data: offersData } = useQuery(GET_QUESTION_OFFERS, {
		variables: { questionId, status: ['pending'] },
	})
	const [getCards, { data: cardData, refetch }] = useLazyQuery(GET_CARD_DETAIL)

	useEffect(() => {
		getCardDetails()
	}, [])

	const getCardDetails = async () => {
		try {
			await getCards()
		} catch (error) {
			console.log('the cards', error)
		}
	}

	const [createQuestionReply] = useMutation(CREATE_QUESTION_REPLY)
	const [updateQuestion] = useMutation(QUESTION_UPDATE)

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: AnswerQuestionValues,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { QuestionreplyCreate } = await callCreateQuestionReply(values)
				if (!QuestionreplyCreate) {
					setIsSnackbarOpen(true)
					return
				}
				refetchQuestion()
				refetchReply()
				setIsInputFieldOpen(false)
				answerModalClose()
			} catch (error) {
				console.log()
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (cardData) {
			if (cardData.Stripe_ListAllCards.length === 0) return
			formik.setFieldValue((formik.values.allCards = cardData.Stripe_ListAllCards))
			formik.setFieldValue((formik.values.originalDefaultCard = cardData.Stripe_ListAllCards[0]))
			formik.setFieldValue((formik.values.defaultCard = cardData.Stripe_ListAllCards[0]))
		}
	}, [cardData])

	useEffect(() => {
		questionData && setQuestionDetails(questionData.GetQuestion)
		replyData && setQuestionLogs(replyData.GetQuestionreplys.objectList)
		avatarData && setAvatar(avatarData.SingleDownload)
		offersData && setAllOffers(offersData.GetQuestionoffers.objectList)
	}, [questionData, replyData, avatarData, offersData])

	const deleteAttachedFile = (file) => {
		const newFiles = formik.values.attachedFiles.filter((attached) => attached.filename !== file.filename)
		formik.setFieldValue((formik.values.attachedFiles = newFiles))
	}

	const callCreateQuestionReply = async (values) => {
		const fileKeys = values.attachedFiles.map((file) => file.key)
		const { data } = await createQuestionReply({
			variables: {
				questionreply: {
					clientId: questionDetails.client.id,
					questionId: questionDetails.id,
					content: values.inputText,
					stage: 'clientReplyOne',
				},
				questionReplyFileKeys: fileKeys,
			},
		})
		return data
	}

	const callUpdateQuestion = async (question) => {
		const { data } = await updateQuestion({
			variables: {
				question: {
					id: question?.id,
					progress: 'Working',
				},
			},
		})
		return data
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.details_container}>
			<DashboardTitle
				title='Details'
				Icon={questionDetails.progress === 'Pick Up' && allOffers.length === 0 && EditIcon}
				iconClick={() => setIsUpdateQuestionOpen(true)}
			/>
			{Object.entries(questionDetails).length === 0 ? (
				<div className={classes.details_container__detail_box}>
					<div className={classes.details_container__detail_box__empty_txt}>Select a job to see details</div>
				</div>
			) : questionLoading && replyLoading && avatarLoading && offersLoading ? (
				<TFASkeleton type='detail' />
			) : (
				<div className={classes.details}>
					<DetailsProgress questionDetails={questionDetails} page='client' style />
					{questionDetails.progress === 'Pick Up' && allOffers.length !== 0 && (
						<div className={classes.offer_box}>
							<p className={classes.offer_box__title}>Offer:</p>
							{allOffers.map((offer, index) => (
								<DetailsOffer offer={offer} formik={formik} acceptOfferOpen={acceptOfferOpen} declineOfferOpen={declineOfferOpen} key={index} />
							))}
						</div>
					)}
					<div className={classes.your_question_box}>
						<DetailsQuestion questionDetails={questionDetails} title='Your Question:' />
						{questionDetails.questionimages.length !== 0 && <DetailsImage questionDetails={questionDetails} />}
						<DetailsFile questionDetails={questionDetails} />
						<div className={classes.your_question_box__paid_price_box}>
							<p className={classes.your_question_box__paid_price_box__title}>Paid Price:</p>
							<p className={classes.your_question_box__paid_price_box__amount}>&#36;{parseFloat(questionDetails.price / 100).toFixed(2)}</p>
						</div>
					</div>

					{questionDetails?.professional && (
						<div className={classes.professional_box}>
							<DetailsProfile questionDetails={questionDetails} title='Professional:' avatar={avatar} page='client' />
						</div>
					)}
					{questionLogs.length !== 0 && (
						<div className={classes.question_log_box}>
							<p className={classes.detail_section_title}>Question log:</p>
							<div className={classes.log_box}>
								{questionLogs.map((log, index) => (
									<ClientQuestionLog
										log={log}
										length={questionLogs.length}
										progress={questionDetails.progress}
										inputFieldOpen={inputFieldOpen}
										moreQuestionOpen={moreQuestionOpen}
										acceptQuestionOpen={acceptQuestionOpen}
										key={index}
									/>
								))}
							</div>
						</div>
					)}
					{isInputFieldOpen && (
						<div className={classes.answer_input_box}>
							{formik.values.attachedFiles.length !== 0 && (
								<div className={classes.attached_file_box}>
									{formik.values.attachedFiles.map((file, index) => (
										<Chip
											label={file.filename}
											onDelete={() => deleteAttachedFile(file)}
											className={classes.attached_file_box__file_chip}
											key={index}
											icon={<UploadFileClip className={classes.attached_file_box__clip_icon} />}
										/>
									))}
								</div>
							)}
							<AnswerInput formik={formik} answerSubmit={answerModalOpen} />
						</div>
					)}
				</div>
			)}
			<Modal
				questionId={questionId}
				formik={formik}
				questionPrice={questionDetails.price}
				isAcceptOfferOpen={isAcceptOfferOpen}
				isDeclineOfferOpen={isDeclineOfferOpen}
				isAnswerOpen={isAnswerOpen}
				isMoreQuestionOpen={isMoreQuestionOpen}
				isAcceptQuestionOpen={isAcceptQuestionOpen}
				paymentModalOpen={paymentModalOpen}
				acceptOfferClose={acceptOfferClose}
				declineOfferClose={declineOfferClose}
				inputFieldClose={inputFieldClose}
				answerModalClose={answerModalClose}
				moreQuestionClose={moreQuestionClose}
				acceptQuestionClose={acceptQuestionClose}
				refetchQuestion={() => refetchQuestion()}
				refetchReply={() => refetchReply()}
			/>
			<PaymentMethodModal formik={formik} onRefresh={refetch} isPaymentModalOpen={isPaymentModalOpen} paymentModalClose={paymentModalClose} />
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to send your question to the professional, Please try again.'
			/>
		</div>
	)
}

export default Details
