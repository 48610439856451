import React from 'react'
import classes from './MultiPages.module.scss'
import { useNavigate } from 'react-router-dom'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { ProgressState } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAButton, TFASelectBox, TFASimpleInput, TFAText } from '../../../../../components/TFAComponents'
import { CREATE_QUESTIONS, categories } from '../../../../../assets/data/SelectOptions'
import SearchCountryInput from '../../../../../components/Register/SearchCountryInput/SearchCountryInput'
import validatePage from '../../../../../utils/validatePage'

const Details = ({ formik, currentPage, setCurrentPage }) => {
  const { countryOfDestination, category, questionTitle, questionContext} = formik.values
  const navigate = useNavigate()

  const handleSubmit = async () => {
    const isPageValid = await validatePage({countryOfDestination, category, questionTitle, questionContext}, formik)
    if (!isPageValid) return 
    setCurrentPage(CREATE_QUESTIONS.MEDIA)
  }

  return (
    <>
      <div
        className={classes.backBtn_box}
        onClick={() => navigate('/c_dashboard/questions')}
      >
        <LeftArrow className={classes.backBtn_box__arrow}/>
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.progress_box}>
        <p className={classes.progress_box__title}>
            Progress:
        </p>
        <div className={classes.progress_box__state}>
          <ProgressState 
            stateName='create_question'
            status={currentPage}
            variant='list'
            style={classes}
          />
        </div>
      </div>
      <div className={classes.main_content_container}>
        <TFAText 
          primary_title='Question Details'
          secondary_title='What do you need help with?'
          style1={classes}
        />
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Destination
        </p>
        <p className={classes.title_box__secondary}>
          Select the country of destination relating to your question.
        </p>
        {/* <SearchCountryInput 
          name='countryOfDestination'
          placeholder='COUNTRY OF DESTINATION *'
          formik={formik}
          error={formik?.errors?.countryOfDestination ?? null}
        /> */}
        <TFASelectBox 
          selectArray={['Australia']}
          label='COUNTRY OF DESTINATION'
          name='countryOfDestination'
          width='100%'
          required={true}
          formik={formik}
          error={formik?.errors?.countryOfDestination ?? null}
        />
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Category
        </p>
        <p className={classes.title_box__secondary}>
          Select a category for your question.
        </p>
        <TFASelectBox 
          selectArray={categories}
          label='CATEGORY'
          name='category'
          width='100%'
          required={true}
          formik={formik}
          error={formik?.errors?.category ?? null}
        />
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Question Title
        </p>
        <p className={classes.title_box__secondary}>
          Your <span className={classes.title_box__secondary__bold}>Question Title</span> is the headline for your question.
        </p>
        <div className={classes.title_box__simple_input}>
          <TFASimpleInput 
            box_width='100%'
            box_margin='0'
            width='100% !important'
            label='QUESTION TITLE'
            rows={5}
            variant='outlined'
            name='questionTitle'
            text_field='100%'
            formik={formik}
            error={formik.errors.questionTitle ?? null}
          />
        </div>
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Question Context
        </p>
        <p className={classes.title_box__secondary}>
          Provide some context to your question. The more information you can provide, the better professionals can answer your question.
        </p>
        <div className={classes.title_box__simple_input}>
          <TFASimpleInput 
            box_width='100%'
            box_margin='0'
            width='100% !important'
            label='QUESTION CONTEXT'
            rows={5}
            variant='outlined'
            name='questionContext'
            text_field='100%'
            formik={formik}
            error={formik.errors.questionContext ?? null}
          />
        </div>
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='NEXT'
          onClick={handleSubmit}
          style1={classes}
          disabled={
            countryOfDestination === '' ||
            category === '' ||
            questionTitle === '' ||
            questionContext === ''
          }
        />
      </div>
    </>
  )
}

export default Details