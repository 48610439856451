import React, { useEffect } from 'react'
import classes from './AppointmentCard.module.scss'
import { ProgressState } from '../DashboardComponents'
import { CardArrow } from '../../../assets/icons/Dashboard/DashboardIcons'
import { appointmentTimeConverter } from '../../../utils/finishTimeConverter'
import { appointmentStateGenerator } from '../../../utils/appointmentFormatter'

const AppointmentCard = ({ 
  appointment, 
  clickedMeetingId, 
  clickAppointmentCard,
  page,
}) => {
  const {
    id,
    title,
    timeStart,
    timeFinish,
    progress,
    client,
    professional,
  } = appointment

  return (
    <div 
      className={id === clickedMeetingId 
        ? classes.clicked_container 
        : classes.card_container
      }
      onClick={clickAppointmentCard}
    >
      <div className={classes.content_box}>
        <div className={classes.content_box__chat_box}>
          <p className={classes.content_box__chat_box__text}>
            Chat:
            <span className={classes.content_box__chat_box__text__time}>
              {appointmentTimeConverter(timeStart, timeFinish)}
            </span>
          </p>
          <div>
            <ProgressState 
              stateName='appointment'
              status={appointmentStateGenerator(progress, timeStart)}
              variant='card'
            />
          </div>
        </div>
        <div className={classes.content_box__title_box}>
          <p className={classes.content_box__title_box__text}>
            {title}
          </p>
        </div>
        <div className={classes.content_box__client_box}>
          <p className={classes.content_box__client_box__title}>
            {page === 'client'
            ? 'Professional'
            : 'Client'
            }
          </p>
          <p className={classes.content_box__client_box__name}>
            {page === 'client'
            ? `${professional?.firstName} ${professional?.lastName}`
            : `${client?.firstName} ${client?.lastName}`
            }
          </p>
        </div>
      </div>
      <div className={classes.arrow_box}>
        <CardArrow className={classes.arrow_box__icon}/>
      </div>
    </div>
  )
}

export default AppointmentCard