import React from 'react'
import classes from '../PProfile.module.scss'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { BankStatus, EditIcon } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFASkeleton } from '../../../../../components/TFAComponents'

const PayoutStatus = ({ 
    linkLoading, 
    bankDetails,
    accountLink
}) => {
    const updateAccountDetails = () => {
        window.open(accountLink)
    }

    return (
        <div className={classes.bank_status_container}>
            <DashboardSubTitle 
                Icon={BankStatus}
                title='Payout Status'
                fontSize='1.4em'
                color='#2F68FA'
                iconSize='scale(1.8)'
            />
            <div className={classes.account}>
                <div className={classes.account__details}>
                    <BankStatus 
                        className={classes.account__details__status_icon_big}
                    />
                    <div className={classes.account__details__text}>
                        <p className={classes.account__details__text__title}>
                            Payout Status
                        </p>
                        {linkLoading
                        ? <TFASkeleton />
                        : 
                        (bankDetails && 
                        bankDetails.payouts_enabled && 
                        bankDetails.charges_enabled
                        ) 
                            ?   <p 
                                    className={classes.bank_status_active}
                                >
                                    {'Active'}
                                </p>
                            :   <>
                                    <p 
                                        className={classes.bank_status_rejected}
                                    >
                                        {'Update Needed'}
                                    </p>
                                </>
                        }
                    </div>
                </div>
                <EditIcon 
                    className={classes.account__icon_small}
                    onClick={updateAccountDetails}
                />
            </div>
        </div>
    )
}

export default PayoutStatus