import React from 'react'
import classes from './TFAModal.module.scss'
import { Box, Modal} from '@mui/material'

const TFAModal = ({ open, handleClose, children, width, maxWidth, bg }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box 
        className={classes.box} 
        style={{ width: width ?? '25em', maxWidth: maxWidth, background: bg}}
      >
        { children }
      </Box>
    </Modal>
  )
}

export default TFAModal