import React, { useEffect } from 'react'
import classes from './Sections.module.scss'
import { DashboardTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { useFormik } from 'formik'
import { UpdateClientAccountValues } from '../../../../../assets/data/FormikInitialValues'
import useClientContext from '../../../../../states/Client/useClientContext'
import { TFAButton, TFAInputText } from '../../../../../components/TFAComponents'
import { updateClientAccountSchema } from '../../../../../utils/validation'
import { Avatar, IconButton } from '@mui/material'
import { AddImg } from '../../../../../assets/icons/Auth/AuthIcons'
import { UPDATE_CLIENT_PROFILE } from '../../../../../services/apiMutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_PRESIGNED_UPLOAD_URL } from '../../../../../services/apiQuery'
import axios from 'axios'

const AboutYou = ({ setSavedModalOpen }) => {
  const { client } = useClientContext().clientState
  const [ updateProfile ] = useMutation(UPDATE_CLIENT_PROFILE)
  const [ getUploadURL ] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)
  const formik = useFormik({
    initialValues: UpdateClientAccountValues,
    validationSchema: updateClientAccountSchema,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { ClientUpdate } = await callUpdateProfile(values)
        if (ClientUpdate !== 'success') return
        setSavedModalOpen(true)
        window.location.reload(false)
      } catch (error) {
        console.log(error)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    if (client) {
      formik.setFieldValue(formik.values.firstName = client.firstName)
      formik.setFieldValue(formik.values.lastName = client.lastName)
      formik.setFieldValue(formik.values.email = client.email)
    }
  },[client])

  const uploadAvatar = async ({ target }) => {
    const file = target.files[0]
    const uploadImageURL = URL.createObjectURL(file)
    try {
      const { GetPresignedUploadUrl } = await callGetUploadURL(file)
      if (!GetPresignedUploadUrl) return
      const url = GetPresignedUploadUrl.link
      const uploading = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
      })
      if (uploading.status !== 200) return
      formik.setFieldValue(formik.values.avatar = GetPresignedUploadUrl.key)
      formik.setFieldValue(formik.values.image = uploadImageURL)
    } catch (error) {
      console.log(error)
    }
  }

  const callGetUploadURL = async (file) => {
    const { data } = await getUploadURL({
      variables: {
        filename: file.name,
        folder: 'avatar'
      }
    })
    return data
  }

  const callUpdateProfile = async (values) => {
    const { data } = await updateProfile({
      variables: {
        client: {
          id: client?.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          avatar: values.avatar
        }
      }
    })
    return data
  }

  return (
    <div className={classes.section_container}>
      <DashboardTitle title='About You'/>
      <div className={classes.section_box}>
        <p className={classes.section_box__title}>
          Your Details
        </p>
        <p className={classes.section_box__desc}>
          Update your personal account details.
        </p>
      </div>
      <TFAInputText 
        label='FIRST NAME'
        type='text'
        name='firstName'
        formik={formik}
        error={formik.errors.firstName ?? null}
      />
      <TFAInputText 
        label='LAST NAME'
        type='text'
        name='lastName'
        formik={formik}
        error={formik.errors.lastName ?? null}
      />
      <TFAInputText
        label='EMAIL'
        type='email'
        name='email'
        placeholder='sample@timeforadvice.com'
        formik={formik}
        error={formik.errors.email ?? null}
      />
      <div className={classes.second_section_box}>
        <p className={classes.second_section_box__title}>
          Profile Picture
        </p>
        <p className={classes.second_section_box__desc}>
          Personalise your account with a profile picture.
        </p>
        <div className={classes.avatar_box}>
          <Avatar 
            src={formik.values.image 
              ? formik.values.image
              : client?.avatarLink !== 'false'
                ? client?.avatarLink
                : null
            }
            alt='professional avatar' 
            className={classes.avatar_box__avatar}
            sx={{ width: 220, height: 220, bgcolor: '#2F68FA' }}
          />
          <IconButton
            component="span"
            sx={{background: '#2F68FA', padding: '0.5em'}}
            className={classes.avatar_box__camera}
            name='image'
            onChange={uploadAvatar}
          >   
            <input type='file' className={classes.avatar_box__camera__avatar_input} accept={'image/*'}/>
            <AddImg />
          </IconButton>
        </div>
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='SAVE'
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          style1={classes}
        />
      </div>
    </div>
  )
}

export default AboutYou