import React from 'react'
import classes from './PaymentMethodCard.module.scss'
import { DebitCard, EditIcon } from '../../../assets/icons/Dashboard/DashboardIcons'
import { IconButton } from '@mui/material'

const PaymentMethodCard = ({ formik, cardDetails, defaulCard, handleClick, isPaymentSelected, method, iconType, onClick }) => {
	const renderCardWithoutIcon = () => {
		return (
			<>
				<DebitCard className={classes.card_icon} />
				<div className={classes.payment_info}>
					<div className={classes.payment_info__title}>
						Debit Card
						<p className={classes.payment_info__title__active}>{defaulCard?.id === cardDetails.id && 'Active'}</p>
					</div>
					<div className={classes.payment_info__detail}>{`XXXX XXXX XXXX ${!cardDetails?.last4 ? '0000' : cardDetails?.last4}`}</div>
				</div>
			</>
		)
	}

	const renderCardWithIcon = () => {
		return (
			<div className={classes.profile_payment_box}>
				<div className={classes.left_side}>
					<DebitCard className={classes.card_icon} />
					<div className={classes.payment_info}>
						<div className={classes.payment_info__title}>
							Debit Card
							<p className={classes.payment_info__title__active}>{defaulCard?.id === cardDetails.id && 'Active'}</p>
						</div>
						<div className={classes.payment_info__detail}>{`XXXX XXXX XXXX ${!cardDetails?.last4 ? '0000' : cardDetails?.last4}`}</div>
					</div>
				</div>
				<div className={classes.right_side}>
					<IconButton className={classes.edit_icon} onClick={onClick}>
						<EditIcon />
					</IconButton>
				</div>
			</div>
		)
	}

	return (
		<>
			{cardDetails && (
				<div className={isPaymentSelected ? classes.payment_method_selected_container : classes.payment_method_container} onClick={handleClick}>
					{iconType !== 'edit' ? renderCardWithoutIcon() : renderCardWithIcon()}
				</div>
			)}
			{formik && (
				<div className={isPaymentSelected ? classes.payment_method_selected_container : classes.payment_method_container} onClick={handleClick}>
					{method === 'Card' && <DebitCard className={classes.card_icon} />}
					<div className={classes.payment_info}>
						<div className={classes.payment_info__title}>
							{method === 'Card' && 'Debit Card'}
							<p className={classes.payment_info__title__active}>
								{method === 'Card' && formik.values.originalDefaultCard?.id === formik.values.defaultCard.id && 'Active'}
							</p>
						</div>
						<div className={classes.payment_info__detail}>
							{method === 'Card' && `XXXX XXXX XXXX ${formik.values.defaultCard?.last4 === undefined ? '0000' : formik.values.defaultCard?.last4}`}
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default PaymentMethodCard
