import React, { useEffect, useState } from 'react'
import classes from '../PProfile.module.scss'
import { CloseBtn, UpdateCompleted } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAModal, TFAInputText, TFAButton, TFAText } from '../../../../../components/TFAComponents'
import { useFormik } from 'formik'
import { UpdateMeetingAvailabilityValues } from '../../../../../assets/data/FormikInitialValues'
import { updateMeetingAvailabilitySchema } from '../../../../../utils/validation'
import TimePicker from '../../../../../components/Register/TimePicker/TimePicker'
import { FormControl, InputAdornment } from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_AVAILABILITY, UPDATE_PROFILE } from '../../../../../services/apiMutation'
import { dateFormatter, weekAvailabilityFormatter } from '../../../../../utils/appointmentFormatter'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'
import { GET_PROFESSIONAL_SCHEDULES } from '../../../../../services/apiQuery'

const AvailabilityModal = ({ isAvailabilityOpen, availabilityModalClose }) => {
	const { professional } = useProfessionalContext().professionalState
	const [errorDay, setErrorDay] = useState('')
	const [isCompleted, setIsCompleted] = useState(false)
	const { data } = useQuery(GET_PROFESSIONAL_SCHEDULES, {
		variables: {
			professionalId: professional?.id,
		},
	})
	const [updateAvailability] = useMutation(UPDATE_AVAILABILITY)
	const [updateProfile] = useMutation(UPDATE_PROFILE)
	const formik = useFormik({
		initialValues: UpdateMeetingAvailabilityValues,
		validationSchema: updateMeetingAvailabilitySchema,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { MeetingScheduleUpdate } = await callUpdateAvailability(values)
				const { ProfessionalUpdate } = await callUpdateProfile(values)
				if (!MeetingScheduleUpdate || !ProfessionalUpdate) return
				setIsCompleted(true)
			} catch (error) {
				console.log(error)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (!professional) return
		formik.setFieldValue((formik.values.askingPrice = parseFloat(professional?.askingPrice / 100).toFixed(2)))
		formik.setFieldValue((formik.values.questionPrice = parseFloat(professional?.questionPrice / 100).toFixed(2)))
		if (!data?.GetMeetingschedules?.objectList) return
		formik.setFieldValue((formik.values.availability = weekAvailabilityFormatter(data.GetMeetingschedules.objectList)))
	}, [professional, data])

	useEffect(() => {
		setErrorDay('')
		const formattedDates = dateFormatter(formik.values.availability)
		formattedDates.forEach((value, index) => value === 0 && setErrorDay(index))
	}, [formik])

	const callUpdateAvailability = async (values) => {
		const formattedDates = finalFormattedDates(values.availability)
		const { data } = await updateAvailability({
			variables: {
				professionalId: professional.id,
				meetingSchedules: formattedDates,
			},
		})
		return data
	}

	const callUpdateProfile = async (values) => {
		const { data } = await updateProfile({
			variables: {
				professional: {
					id: professional?.id,
					askingPrice: values.askingPrice !== '' ? parseInt(values.askingPrice * 100) : professional?.askingPrice,
					questionPrice: values.questionPrice !== '' ? parseInt(values.questionPrice * 100) : professional?.questionPrice,
				},
			},
		})
		return data
	}

	const finalFormattedDates = (availability) => {
		const formattedDates = dateFormatter(availability)
		const final = formattedDates.filter((day) => day !== 1)
		return final
	}

	const closeCompletedModal = () => {
		availabilityModalClose()
		setIsCompleted(false)
		window.location.reload(false)
	}

	return (
		<>
			{isCompleted ? (
				<TFAModal open={isAvailabilityOpen} handleClose={availabilityModalClose} width='25em'>
					<UpdateCompleted className={classes.modal_title_icon} />
					<div className={classes.modal_text_box}>
						<TFAText secondary_title='Availability Updated' description='Your availability has been updated successfully.' style2={classes} />
					</div>
					<TFAButton text='GREAT!' style2={classes} onClick={closeCompletedModal} />
				</TFAModal>
			) : (
				<TFAModal open={isAvailabilityOpen} handleClose={availabilityModalClose} width='100%' maxWidth='65em'>
					<div className={classes.modal_title_box}>
						<CloseBtn className={classes.modal_title_box__icon} onClick={availabilityModalClose} />
						<p className={classes.modal_title_box__title}>Meeting Availability</p>
					</div>
					<div className={classes.meeting_availability_box}>
						<div className={classes.availability_box}>
							<p className={classes.availability_box__title}>When are you free for meetings?</p>
							<p className={classes.availability_box__desc}>Complete your available times so users can schedule meetings with you.</p>
							<div className={classes.calendar_box}>
								{formik.values.availability.map((week, index) => (
									<TimePicker
										title={week.day}
										key={index}
										clickCheckbox={() => formik.setFieldValue(week, (week.isClicked = !week.isClicked))}
										isClicked={week.isClicked}
										formik={formik}
										week={week}
										error={index === errorDay && true}
									/>
								))}
							</div>
						</div>

						<div className={classes.price_box}>
							<div className={classes.asking_price_box}>
								<p className={classes.asking_price_box__title}>How much are your meetings?</p>
								<p className={classes.asking_price_box__desc}>Select your hourly rate for your meetings.</p>
								<FormControl fullWidth sx={{ mt: 1 }}>
									<TFAInputText
										label='ASKING PRICE'
										type='text'
										name='askingPrice'
										placeholder={parseFloat(professional?.askingPrice / 100).toFixed(2)}
										value={formik.values.askingPrice}
										formik={formik}
										error={formik?.errors?.askingPrice ?? null}
										InputProps={{
											startAdornment: <InputAdornment position='start'>AUD $</InputAdornment>,
										}}
									/>
								</FormControl>
							</div>
							<div className={classes.question_price_box}>
								<p className={classes.question_price_box__title}>What is your minimum question price?</p>
								<p className={classes.question_price_box__desc}>Select your minimum question price for your questions ($25 minimum).</p>
								<FormControl fullWidth sx={{ mt: 1 }}>
									<TFAInputText
										label='QUESTION PRICE'
										type='text'
										name='questionPrice'
										placeholder={parseFloat(professional?.questionPrice / 100).toFixed(2)}
										value={formik.values.questionPrice}
										formik={formik}
										error={formik?.errors?.questionPrice ?? null}
										InputProps={{
											startAdornment: <InputAdornment position='start'>AUD $</InputAdornment>,
										}}
									/>
								</FormControl>
							</div>
							<TFAButton text='SAVE' isLoading={formik.isSubmitting} onClick={formik.handleSubmit} style1={classes} />
						</div>
					</div>
				</TFAModal>
			)}
		</>
	)
}

export default AvailabilityModal
