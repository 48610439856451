import React from 'react'
import classes from './AppointmentCalendar.module.scss'
import moment from 'moment'

const AppointmentCalendar = ({ 
    availabilityList, 
    selectedDay,
    selectAppointmentDay
}) => {

    const getMonthDays = (startOfMonth, numberOfDays, formattedMonth) => {
        const startDay = getStartDay(startOfMonth)
        const monthDays = Array.from({length: numberOfDays}, (_, i) => i + 1)
        const availableDays = availabilityList.map(day => moment(day.startTime).day())
        const today = moment().format('D')
        const thisMonth = moment().format('MMMM yyyy')
        const isThisMonth = moment(formattedMonth).isSame(thisMonth)
        return (
            <>
                {startDay.map((day, index) => (
                    <div className={classes.empty_cell} key={index}></div>
                ))}
                {monthDays.map((day, index) => (
                    <div 
                        className={`${classes.day_cell} 
                        ${checkAvailability(availableDays, index, startDay, monthDays)} 
                        ${(isThisMonth && parseInt(today) >= day) && classes.disabled_day} 
                        ${selectedDay === `${day} ${formattedMonth}` && classes.selected_cell}`
                    } 
                        key={index}
                        onClick={() => 
                            !(isThisMonth && today >= day) &&
                            checkAvailability(availableDays, index, startDay, monthDays) !== classes.available_unavailable &&
                            selectAppointmentDay(day, formattedMonth)
                        }
                    >
                        {day}
                    </div>
                ))}
            </>
        )
    }

    const getStartDay = (startOfMonth) => {
        switch (startOfMonth) {
            case 'Mo': return Array(0).fill(null)
            case 'Tu': return Array(1).fill(null)
            case 'We': return Array(2).fill(null)
            case 'Th': return Array(3).fill(null)
            case 'Fr': return Array(4).fill(null)
            case 'Sa': return Array(5).fill(null)
            case 'Su': return Array(6).fill(null)
            default: break;
        }
    }

    const checkAvailability = (availableDays, index, startDay, monthDays) => {
        let availability
        const emptySpaces = startDay.length

        const calendarIndex = (indexAddOne) => {
            if (indexAddOne < 7) return indexAddOne    
            else if (indexAddOne % 7 === 0) return 0
            else {
                if (indexAddOne > 7 && indexAddOne < 14 )   return indexAddOne - 7
                if (indexAddOne > 14 && indexAddOne < 21)   return indexAddOne - 14
                if (indexAddOne > 21 && indexAddOne < 28)   return indexAddOne - 21
                if (indexAddOne > 28 && indexAddOne < 35)   return indexAddOne - 28
                if (indexAddOne > 35 && indexAddOne < 42)   return indexAddOne - 35
            }
        }

        const today = calendarIndex(index + emptySpaces + 1)
        const isAvailable = availableDays.some(day => day === today)
        const isPrevAvailable = availableDays.some(day => {
            if (today === 0) return day === 6
            if (today === 1) return false
            if (index === 0) return false
            else return day === (today - 1)
        })
        const isNextAvailable = availableDays.some(day => {
            if (today === 6) return day === 0
            if (today === 0) return false
            if (monthDays.length === index + 1) return false 
            else return day === (today + 1)
        })
        
        if (!isAvailable) availability = 0
        else {
            if (!isPrevAvailable && !isNextAvailable) availability = 1
            if (isPrevAvailable && !isNextAvailable) availability = 2
            if (!isPrevAvailable && isNextAvailable) availability = 3
            if (isPrevAvailable && isNextAvailable) availability = 4
        }

        switch (availability) {
            case 0:     return `${classes.available_unavailable}`
            case 1:     return `${classes.available_none}`
            case 2:     return `${classes.available_left}`
            case 3:     return `${classes.available_right}`
            case 4:     return `${classes.available_both}`
            default:    return `${classes.available_unavailable}`
        }
    }

    const getMonth = (index) => {
        const month = moment().add(index, 'month')
        const formattedMonth = month.format('MMMM yyyy')
        const dayOfWeek = [ 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su' ]
        const startOfMonth = month.startOf('month').format('dd')
        const numberOfDays = month.daysInMonth()
        return (
            <div 
                className={classes.month_container}
                key={index}
            >
                <p className={classes.month_text}>
                    {formattedMonth}
                </p>
                <div className={classes.calendar_container}>
                    {dayOfWeek.map((day, index) => (
                        <div 
                            className={classes.dayOfWeek_row} 
                            key={index}
                        >
                            {day}
                        </div>
                    ))}
                    {getMonthDays(startOfMonth, numberOfDays, formattedMonth)}
                </div>
            </div>
        )
    }

    return (
        <div className={classes.availability_box}> 
            {Array(3).fill(null).map((_, index) => {
                return getMonth(index)
            })}
        </div>
    )
}

export default AppointmentCalendar