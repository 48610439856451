import {
    WorkingArea,
    JobSearch,
    Appointments,
    Templates,
    Profile,
    Questions,
    Kits,
} from '../icons/Dashboard/DashboardIcons'

const PRO_SIDEBAR_LIST = [
    {
        listIcon: <WorkingArea />,
        listText: 'Working Area',
        link: '/p_dashboard/working_area',
    },
    {
        listIcon: <JobSearch />,
        listText: 'Job Search',
        link: '/p_dashboard/job_search',
    },
    {
        listIcon: <Appointments />,
        listText: 'Appointments',
        link: '/p_dashboard/p_appointments',
    },
    {
        listIcon: <Templates />,
        listText: 'Templates',
        link: '/p_dashboard/templates',
    },
    {
        listIcon: <Profile />,
        listText: 'Profile',
        link: '/p_dashboard/p_profile',
    },
]

const CLIENT_SIDEBAR_LIST = [
    {
        listIcon: <Questions />,
        listText: 'Questions',
        link: '/c_dashboard/questions',
    },
    {
        listIcon: <Appointments />,
        listText: 'Appointments',
        link: '/c_dashboard/c_appointments',
    },
    {
        listIcon: <Kits />,
        listText: 'Kits',
        link: '/c_dashboard/kits',
    },
    {
        listIcon: <Profile />,
        listText: 'Profile',
        link: '/c_dashboard/c_profile',
    },
]

export { PRO_SIDEBAR_LIST, CLIENT_SIDEBAR_LIST }