import { initialWeekObj } from './SelectOptions'

const InitialProSignupValues = {
	firstName: '',
	lastName: '',
	website: '',
	email: '',
	phoneCountry: {},
	phoneNumber: '',
	day: '',
	month: '',
	year: '',
	specialty: [],
	MARANumber: '',
	expiryDay: '',
	expiryMonth: '',
	expiryYear: '',
	MARAFiles: [],
	MARAUploadedFiles: [],
	LPNNumber: '',
	lpnExpiryDay: '',
	lpnExpiryMonth: '',
	lpnExpiryYear: '',
	LPNFiles: [],
	LPNUploadedFiles: [],
	frontIdName: '',
	frontId: '',
	backIdName: '',
	backId: '',
	files: [],
	uploadedFiles: [],
}

const InitialProCompleteSignupValues = {
	image: '',
	avatar: '',
	bio: '',
	firstLanguage: '',
	secondLanguage: '',
	experienceLevel: '',
	availability: initialWeekObj,
	halfHourRate: '',
	minRate: '',
	searchText: '',
	streetNumber: '',
	streetName: '',
	suburb: '',
	postcode: '',
	state: '',
	country: '',
	accountName: '',
	accountNumber: '',
	bsb: '',
	password: '',
	confirmNewPassword: '',
}

const InitialProLoginValues = {
	email: '',
	password: '',
}

const RetrievePasswordValues = {
	id: '',
	JWT: '',
	email: '',
	phoneNumber: '',
	newPassword: '',
	confirmNewPassword: '',
}

const AnswerQuestionValues = {
	selectedTemplate: {},
	attachedFiles: [],
	inputText: '',
	offerId: '',
	paymentPreference: '',
	allCards: [],
	originalDefaultCard: {},
	defaultCard: {},
	paypalOrderId: '',
	email: '',
}

const UpdateProAccountValues = {
	firstName: '',
	lastName: '',
	email: '',
	phoneCountry: {},
	phoneNumber: '',
	image: '',
	avatar: '',
}

const UpdateMeetingAvailabilityValues = {
	availability: initialWeekObj,
	askingPrice: '',
	questionPrice: '',
}

const UpdatePasswordValues = {
	password: '',
	newPassword: '',
	confirmNewPassword: '',
}

const UpdateBankAccount = {
	accountName: '',
	accountNumber: '',
	bsb: '',
}

const UpdateMARACertificateValues = {
	MARANumber: '',
	expiryDay: '',
	expiryMonth: '',
	expiryYear: '',
	MARAFiles: [],
	MARAUploadedFiles: [],
}

const InitialClientSignupValues = {
	phoneCountry: {},
	phoneNumber: '',
	firstName: '',
	lastName: '',
	email: '',
	countryOfResidence: '',
	image: '',
	avatar: '',
	firstLanguage: '',
	secondLanguage: '',
	paymentMethod: 'Card',
	cardName: '',
	stripeId: '',
	password: '',
	confirmNewPassword: '',
}

const ClientCreateQuestionValues = {
	countryOfDestination: '',
	category: '',
	questionTitle: '',
	questionContext: '',
	images: [],
	uploadedImages: [],
	files: [],
	uploadedFiles: [],
	askingPrice: '',
	professional: {},
	paymentPreference: 'Card',
	allCards: [],
	originalDefaultCard: {},
	defaultCard: {},
	paypalOrderId: '',
	email: '',
}

const ClientCreateAppointmentValues = {
	professional: {},
	appointmentDate: '',
	title: '',
	context: '',
	paymentPreference: 'Card',
	allCards: [],
	originalDefaultCard: {},
	defaultCard: {},
	paypalOrderId: '',
	email: '',
}

const UpdateQuestionValues = {
	questonId: '',
	countryOfDestination: '',
	category: '',
	questionTitle: '',
	questionContext: '',
	initialImages: [],
	images: [],
	uploadedImages: [],
	initialFiles: [],
	files: [],
	uploadedFiles: [],
}

const UpdateAppointmentValues = {
	professional: {},
	timeStart: '',
	timeFinish: '',
	selectedDate: '',
	selectedNewDate: '',
	modifiedDate: '',
	title: '',
	context: '',
}

const KitPurchaseValues = {
	paymentPreference: '',
	allCards: [],
	originalDefaultCard: {},
	defaultCard: {},
	paypalOrderId: '',
	email: '',
}

const UpdateClientAccountValues = {
	firstName: '',
	lastName: '',
	email: '',
	avatar: '',
	image: '',
}

const UpdateClientPaymentValues = {
	allCards: [],
	originalDefaultCard: {},
	defaultCard: {},
	stripeId: '',
	cardName: '',
}

const UpdateClientLanguageValues = {
	firstLanguage: '',
	secondLanguage: '',
}

const CreateReportValues = {
	label: '',
	topic: '',
	content: '',
}

export {
	InitialProSignupValues,
	InitialProCompleteSignupValues,
	InitialProLoginValues,
	RetrievePasswordValues,
	AnswerQuestionValues,
	UpdateProAccountValues,
	UpdateMeetingAvailabilityValues,
	UpdatePasswordValues,
	UpdateBankAccount,
	UpdateMARACertificateValues,
	InitialClientSignupValues,
	ClientCreateQuestionValues,
	ClientCreateAppointmentValues,
	UpdateQuestionValues,
	UpdateAppointmentValues,
	KitPurchaseValues,
	UpdateClientAccountValues,
	UpdateClientPaymentValues,
	UpdateClientLanguageValues,
	CreateReportValues,
}
