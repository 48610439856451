import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import React, { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { languages } from '../../../assets/data/SelectOptions'
import SignupWrapper from '../../../components/Register/SignupWrapper'
import { TFAButton, TFASelectBox, TFASnackBar, TFAText } from '../../../components/TFAComponents'
import { UPDATE_CLIENT_PROFILE } from '../../../services/apiMutation'
import useClientContext from '../../../states/Client/useClientContext'
import { clientLanguagesSchema } from '../../../utils/validation'
// @ts-ignore
import classes from './Signup/MultiPages/MultiPages.module.scss'

function Languages() {
	const { clientState, clientDispatch } = useClientContext()

	const [clientUpdate] = useMutation(UPDATE_CLIENT_PROFILE)
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const navigate = useNavigate()

	const formik = useFormik({
		initialValues: {
			firstLanguage: '',
			secondLanguage: '',
		},
		validationSchema: clientLanguagesSchema,
		onSubmit: () => {
			updateUserLanguages()
		},
	})

	const updateUserLanguages = async () => {
		const { firstLanguage, secondLanguage } = formik.values

		try {
			formik.setSubmitting(true)
			const { data } = await clientUpdate({
				variables: {
					client: {
						firstLanguage,
						secondLanguage,
						id: clientState.client.id,
					},
				},
			})

			if (!data.ClientUpdate) {
				setIsSnackbarOpen(true)
				return
			}
			clientDispatch({ type: 'setClient', data: { ...clientState.client, firstLanguage, secondLanguage } })
			navigate('/payment')
		} catch (error) {
			setIsSnackbarOpen(true)
		} finally {
			formik.setSubmitting(false)
		}
	}

	const handleSnackbarClose = (event: SyntheticEvent<Element, Event>, reason: string) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<SignupWrapper page='Languages'>
			<div className={classes.content_box}>
				<TFAText primary_title='Languages' secondary_title='What is your preferred language?' style1={classes} />
				<div className={classes.title_box}>
					<p className={classes.title_box__primary}>First Language</p>
					<p className={classes.title_box__secondary}>
						Select your first or prefered language. This allows us to connect you to a professional who specializes in your languages.
					</p>
					<TFASelectBox
						selectArray={languages.filter((language) => language !== formik.values.secondLanguage)}
						label='FIRST LANGUAGE'
						name='firstLanguage'
						width='100%'
						required={true}
						formik={formik}
						error={formik?.errors?.firstLanguage}
						reportArray={false}
						multiple={false}
						textAlign={false}
					/>
				</div>
				<div className={classes.title_box}>
					<p className={classes.title_box__primary}>Second Language</p>
					<p className={classes.title_box__secondary}>
						Select your prefered second language. This is a fallback from your first language selection (This is not mandatory).
					</p>
					<TFASelectBox
						selectArray={languages.filter((language) => language !== formik.values.firstLanguage)}
						label='SECOND LANGUAGE'
						name='secondLanguage'
						width='100%'
						formik={formik}
						required={false}
						reportArray={false}
						error={false}
						multiple={false}
						textAlign={false}
					/>
				</div>
				<div className={classes.btn_box}>
					<TFAButton text='NEXT' onClick={formik.handleSubmit} style1={classes} />
				</div>

				<TFASnackBar
					variant='error'
					isSnackbarOpen={isSnackbarOpen}
					handleSnackbarClose={handleSnackbarClose}
					message='Failed to update languages.'
				/>
			</div>
		</SignupWrapper>
	)
}

export default Languages
