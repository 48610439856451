import React from 'react'
import classes from './Modal.module.scss'
import { TFAButton, TFAModal, TFASimpleInput } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'

const UpdateDetailsModal = ({
  formik,
  isDetailModalOpen,
  setIsDetailModalOpen,
}) => {
  return (
    <TFAModal
      open={isDetailModalOpen}
      handleClose={() => setIsDetailModalOpen(false)}
      width='30em'
    >
      <div className={classes.modal_title_box}>
        <CloseBtn 
          className={classes.modal_title_box__icon}
          onClick={() => setIsDetailModalOpen(false)}
        />
        <p className={classes.modal_title_box__title}>
          Edit Details
        </p>
      </div>
      <div className={classes.edit_detail_box}>
        <div className={classes.title_box}>
          <p className={classes.title_box__primary}>
            Appointment Title
          </p>
          <p className={classes.title_box__secondary}>
            Your 
            <span className={classes.title_box__secondary__bold}>
            &nbsp;Appointment Title&nbsp;
            </span>
            gives an outline of what you want to discuss.
          </p>
          Your Appointment Title is the headline for your question.
          <div className={classes.title_box__simple_input}>
            <TFASimpleInput 
              box_width='100%'
              box_margin='0'
              width='100% !important'
              label='APPOINTMENT TITLE'
              rows={5}
              variant='outlined'
              name='title'
              text_field='100%'
              formik={formik}
              error={formik.errors.title ?? null}
            />
          </div>
        </div>
        <div className={classes.title_box}>
          <p className={classes.title_box__primary}>
            Appointment Context
          </p>
          <p className={classes.title_box__secondary}>
            Provide some context to your appointment. The more information you can provide, the better professionals can prepare for you appointment.
          </p>
          <div className={classes.title_box__simple_input}>
            <TFASimpleInput 
              box_width='100%'
              box_margin='0'
              width='100% !important'
              label='APPOINTMENT CONTEXT'
              rows={5}
              variant='outlined'
              name='context'
              text_field='100%'
              formik={formik}
              error={formik.errors.context ?? null}
            />
          </div>
        </div>
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='UPDATE'
          onClick={() => setIsDetailModalOpen(false)}
          style1={classes}
        />
      </div>
    </TFAModal>
  )
}

export default UpdateDetailsModal