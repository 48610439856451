import React, { useEffect, useState } from 'react'
import classes from './PProfile.module.scss'
import { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import Earnings from './Sections/Earnings'
import Settings from './Sections/Settings'
import Account from './Sections/Account'
import History from './Sections/History'
import AccountModal from './Modals/AccountModal'
import AvailabilityModal from './Modals/AvailabilityModal'
import PasswordModal from './Modals/PasswordModal'
import BankAccountModal from './Modals/BankAccountModal'
import useProfessionalContext from '../../../../states/Professional/useProfessionalContext'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_ACCOUNT_LINK, GET_PRO_TRANSACTION_HISTORY, GET_STRIPE_ACCOUNT } from '../../../../services/apiQuery'
import PayoutStatus from './Sections/PayoutStatus'
import Specialities from './Sections/Specialities'
import MARAStatus from './Sections/MARAStatus'
import UpdateMARAModal from './Modals/UpdateMARAModal'
import LPNStatus from './Sections/LPNStatus'

const PProfile = () => {
	const { professionalState, professionalDispatch } = useProfessionalContext()
	const { professional } = professionalState
	const [allHistories, setAllHistories] = useState([])
	const [bankDetails, setBankDetails] = useState({})
	const [accountLink, setAccountLink] = useState('')
	const navigate = useNavigate()

	const { loading: historyLoading, data: historyData } = useQuery(GET_PRO_TRANSACTION_HISTORY, {
		variables: { professionalId: professional?.id },
	})
	const { loading: accountLoading, data: accountData } = useQuery(GET_STRIPE_ACCOUNT, {
		variables: { accountId: professional?.stripe_accountId },
	})
	const { loading: linkLoading, data: linkData } = useQuery(GET_ACCOUNT_LINK, {
		variables: { accountId: professional?.stripe_accountId },
	})

	const [isAccountOpen, setIsAccountOpen] = useState(false)
	const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(false)
	const [isPasswordOpen, setIsPasswordOpen] = useState(false)
	const [isBankAccountOpen, setIsBankAccountOpen] = useState(false)
	const [isUpdateMaraOpen, setIsUpdateMaraOpen] = useState(false)
	const accountModalOpen = () => setIsAccountOpen(true)
	const accountModalClose = () => setIsAccountOpen(false)
	const availabilityModalOpen = () => setIsAvailabilityOpen(true)
	const availabilityModalClose = () => setIsAvailabilityOpen(false)
	const passwordModalOpen = () => setIsPasswordOpen(true)
	const passwordModalClose = () => setIsPasswordOpen(false)
	const bankAccountModalOpen = () => setIsBankAccountOpen(true)
	const bankAccountModalClose = () => setIsBankAccountOpen(false)
	const updateMaraModalOpen = () => setIsUpdateMaraOpen(true)
	const updateMaraModalClose = () => setIsUpdateMaraOpen(false)

	useEffect(() => {
		historyData && setAllHistories(historyData.ListAllTransfers)
		accountData && setBankDetails(accountData.Stripe_RetrieveAccount)
		linkData && setAccountLink(linkData.GetAccounLink)
	}, [historyData, accountData, linkData])

	const professionalLogout = () => {
		localStorage.removeItem('TFAToken')
		localStorage.removeItem('TFATokenType')
		professionalDispatch({ type: 'setProfessional', data: null })
		navigate('/top')
	}

	const renderProfessionalDocument = () => {
		if (professional?.MARANumber) return <MARAStatus professional={professional} updateMaraModalOpen={updateMaraModalOpen} />
		else if (professional?.LPNNumber) return <LPNStatus professional={professional} />
	}

	return (
		<div className={classes.wrapper}>
			<Sidebar pageSide='professional' tabName='Profile' />
			<div className={classes.main_container}>
				<div className={classes.setting_container}>
					<Earnings allHistories={allHistories} />
					<Settings
						professionalLogout={professionalLogout}
						accountModalOpen={accountModalOpen}
						availabilityModalOpen={availabilityModalOpen}
						passwordModalOpen={passwordModalOpen}
					/>
				</div>
				<div className={classes.account_detail_container}>
					<Account accountLoading={accountLoading} bankDetails={bankDetails} bankAccountModalOpen={bankAccountModalOpen} />
					<PayoutStatus linkLoading={linkLoading} bankDetails={bankDetails} accountLink={accountLink} />
					<Specialities specialities={professional?.professionalspecialties} />
					{renderProfessionalDocument()}
				</div>
				<History historyLoading={historyLoading} allHistories={allHistories} />
			</div>

			<AccountModal isAccountOpen={isAccountOpen} accountModalClose={accountModalClose} />
			<AvailabilityModal isAvailabilityOpen={isAvailabilityOpen} availabilityModalClose={availabilityModalClose} />
			<PasswordModal isPasswordOpen={isPasswordOpen} passwordModalClose={passwordModalClose} />
			<BankAccountModal
				professionalId={professional?.id}
				isBankAccountOpen={isBankAccountOpen}
				bankAccountModalClose={bankAccountModalClose}
			/>
			<UpdateMARAModal isUpdateMaraOpen={isUpdateMaraOpen} updateMaraModalClose={updateMaraModalClose} />
		</div>
	)
}

export default PProfile
