import { Tabs, styled, Tab } from '@mui/material'

const TFAMuiTabs = styled(Tabs)(({ theme }) => ({
    marginTop: '2em'
}))

const TFAMuiTab = styled(Tab)(({ theme }) => ({
    fontFamily: '"Space Grotesk", sans-serif',
    color: '#022873',
    fontWeight: 'bold',
    fontSize: '1.2em',
    '& .Mui-disabled': {
        color: '#C7C7C7 !important',
    },
    '&.Mui-selected': {
        color: '#022873 !important',
    },
}))

export { TFAMuiTabs, TFAMuiTab }