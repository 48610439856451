import React, { SyntheticEvent, useState } from 'react'
// @ts-ignore
import classes from './MultiPages.module.scss'
import { TFAButton, TFASnackBar, TFASubText, TFAText, TFAInputText } from '../../../../../components/TFAComponents'
import { SIGNUP_PAGES } from '../../../../../assets/data/SelectOptions'
import TwoFactorVerificationForm from '../../../../../components/Register/TwoFactorVerificationForm/TwoFactorVerificationForm'
import { useMutation } from '@apollo/client'
import { CLIENT_SIGNUP_STEP1, CLIENT_VERIFY_CODE } from '../../../../../services/apiMutation'
import { useFormik } from 'formik'
import { verifyEmailSchema } from '../../../../../utils/validation'

type Props = {
	setCurrentPage: (page: string) => void
	isAuthCode: boolean
	setIsAuthCode: (isAuthCode: boolean) => void
	setEmail: (email: string) => void
	setCode: (code: string) => void
}

function Verify({ setCurrentPage, isAuthCode, setIsAuthCode, setEmail, setCode }: Props) {
	const [authCode, setAuthCode] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const [clientSignupStep1] = useMutation(CLIENT_SIGNUP_STEP1)
	const [clientVerifyCode] = useMutation(CLIENT_VERIFY_CODE)

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: verifyEmailSchema,
		onSubmit: () => {
			if (!isAuthCode) sendAuthCode()
			else verifyCode()
		},
	})

	const sendCodeAgain = () => {
		sendAuthCode()
	}

	const sendAuthCode = async () => {
		try {
			const email = formik.values.email

			setIsLoading(true)

			const sendCodeResult = await clientSignupStep1({
				variables: { email },
			})

			if (sendCodeResult.data.ClientSignupStep1 !== 'success') {
				setIsSnackbarOpen(true)
				return
			}

			setIsAuthCode(true)
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const verifyCode = async () => {
		const enteredCode = authCode.slice(0, 6)
		try {
			setIsLoading(true)
			const email = formik.values?.email

			const verifyCodeResult = await clientVerifyCode({
				variables: { email, verifyCode: enteredCode },
			})

			if (verifyCodeResult.data.ClientVerifyCode.result !== 'valid code') {
				setIsSnackbarOpen(true)
				return
			}
			setEmail(email)
			setCode(enteredCode)
			setCurrentPage(SIGNUP_PAGES.ABOUT_YOU)
		} catch (error) {
			setIsSnackbarOpen(true)
		} finally {
			setIsLoading(false)
		}
	}

	const handleSnackbarClose = (event: SyntheticEvent<Element, Event>, reason: string) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.content_box}>
			{!isAuthCode ? (
				<>
					<TFAText primary_title='Authentication' secondary_title='Authenticate Your Device' style1={classes} />
					<TFASubText
						primary_title='About Authentication'
						description='To ensure Time For Advice is a secure platform, we require all users to authenticate their details. This ensures a safe user experience.'
						style1={classes}
					/>
					<div className={classes.phone_input_box}>
						<TFAInputText
							label='EMAIL'
							type='email'
							name='email'
							placeholder='sample@timeforadvice.com'
							formik={formik}
							error={formik.errors.email ?? null}
						/>
					</div>
				</>
			) : (
				<>
					<TFAText primary_title='Authentication' secondary_title='Authentication Code' style1={classes} />
					<TFASubText
						primary_title='Verification Code'
						description={<p>We sent a code to your email address, please enter the 6 digit code below.</p>}
						style1={classes}
					/>
					<TwoFactorVerificationForm authCode={authCode} onChangeText={setAuthCode} verifyCode={verifyCode} />
					<div className={classes.send_code_box}>
						<p>Didn't receive your code?</p>
						<p className={classes.send_code_box__text} onClick={sendCodeAgain}>
							Send Code Again
						</p>
					</div>
				</>
			)}

			<div className={classes.btn_box}>
				<TFAButton
					text='NEXT'
					onClick={!isAuthCode ? sendAuthCode : verifyCode}
					style1={classes}
					disabled={formik.errors.email || !formik.values.email}
					isLoading={isLoading}
				/>
			</div>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message={
					!isAuthCode
						? 'This email is either already registered or invalid.'
						: 'Failed to verify authentication code, Please try again with new code.'
				}
			/>
		</div>
	)
}

export default Verify
