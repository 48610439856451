import React, { useEffect, useState } from 'react'
import classes from '../EditQuestion.module.scss'
import QuestionDetailsModal from '../Modals/QuestionDetailsModal'
import RemoveModal from '../Modals/RemoveModal'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { AddNewImage, DeleteImage, EditIcon, VisaForm } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAButton, TFAText } from '../../../../../components/TFAComponents'
import { CircularProgress, IconButton } from '@mui/material'
import UploadImagesFiles from '../../../../../components/Dashboard/UploadImagesFiles/UploadImagesFiles'
import { useLazyQuery } from '@apollo/client'
import { GET_PRESIGNED_UPLOAD_URL } from '../../../../../services/apiQuery'
import { reverseCategoryConverter } from '../../../../../utils/specialtyConverter'
import axios from 'axios'

const UpdateQuestion = ({ formik, setIsUpdateQuestionOpen }) => {
    const [ isUploading, setIsUploading ] = useState(false)
    const [ loaded, setLoaded ] = useState(0)
    const [ isDetailModalOpen, setIsDetailModalOpen ] = useState(false)
    const [ isRemoveModalOpen, setIsRemoveModalOpen ] = useState(false)
    const [ removeType, setRemoveType ] = useState('')
    const [ getUploadURL ] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)

    const addNewImage = async ({ target }) => {
        const file = target.files[0]
        const uploadImageURL = URL.createObjectURL(target.files[0])
        try {
            setIsUploading(true)
            const { GetPresignedUploadUrl } = await callgetUploadURL(file)
            if (!GetPresignedUploadUrl) return
            const url = GetPresignedUploadUrl.link
            const uploading = await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: e => {
                    setLoaded(parseInt(((e.loaded * 100) / e.total).toFixed()))
                    if (e.loaded === e.total) setIsUploading(false)
                }
            })
            if (uploading.status !== 200) return
            formik.setFieldValue(formik.values.uploadedImages = [
                ...formik.values.uploadedImages, {
                    filename: file.name, 
                    uploadURL: uploadImageURL
                }
            ])
            formik.setFieldValue(formik.values.images = [
                ...formik.values.images, {
                fileName: GetPresignedUploadUrl.filename,
                key: GetPresignedUploadUrl.key,
            }])
        } catch (error) {
            console.log(error)
        } finally {
            setIsUploading(false)
        }
    }

    const callgetUploadURL = async (file) => {
        const { data } = await getUploadURL({
            variables: {
                filename: file.name,
                folder: 'question'
            }
        })
        return data
    }

    const deleteAddedImages = (filename) => {
        const filterUploadedImages = formik.values.uploadedImages.filter(i => i.filename !== filename)
        const filterImages = formik.values.images.filter(i => i.fileName !== filename)
        formik.setFieldValue(formik.values.uploadedImages = filterUploadedImages)
        formik.setFieldValue(formik.values.images = filterImages)
    }

    const deleteInitImages = (id) => {
        const filterImages = formik.values.initialImages.filter(i => i.id !== id)
        formik.setFieldValue(formik.values.initialImages = filterImages)
    }

    const deleteInitFile = (file) => {
        const filterUploadedFiles = formik.values.initialFiles.filter(f => f.id !== file.id)
        formik.setFieldValue(formik.values.initialFiles = filterUploadedFiles)
    }

    const removeQuestion = () => {
        setRemoveType('question')
        setIsRemoveModalOpen(true)
    }

    const formatFileDetails = (key, type) => {
        const fileName = key.split(' - ')[1].split('.')[0]
        const fileType = key.split(' - ')[1].split('.')[1]
        if (type === 'name') return fileName
        if (type === 'type') return fileType
    }
    
    return (
        <>
            <div
                className={classes.backBtn_box}
                onClick={() => setIsUpdateQuestionOpen(false)}
            >
                <LeftArrow className={classes.backBtn_box__arrow}/>
                <p className={classes.backBtn_box__txt}>RETURN</p>                
            </div>
            <div className={classes.main_content_container}>
                <TFAText 
                    primary_title='Edit Question'
                    secondary_title='Update your question'
                    style1={classes}
                />
            </div>
            <div className={classes.confirm_section_box}>
                <div className={classes.editable_title}>
                    <p className={classes.section_title}>
                    Questions Details:
                    </p>
                    <IconButton 
                        className={classes.section_icon}
                        onClick={() => setIsDetailModalOpen(true)}
                    >
                        <EditIcon />
                    </IconButton>
                </div>
                <div className={classes.question_detail_container}>
                    <div className={classes.question_detail_box}>
                        <div className={classes.question_detail_box__title}>
                            Title:
                        </div>
                        <div className={classes.question_detail_box__content}>
                            {formik.values.questionTitle}
                        </div>
                    </div>
                    <div className={classes.question_detail_box}>
                        <div className={classes.question_detail_box__title}>
                            Destination:
                        </div>
                        <div className={classes.question_detail_box__content}>
                            {formik.values.countryOfDestination}
                        </div>
                    </div>
                    <div className={classes.question_detail_box}>
                        <div className={classes.question_detail_box__title}>
                            Catagory:
                        </div>
                        <div className={classes.question_detail_box__content}>
                            {reverseCategoryConverter(formik.values.category)}
                        </div>
                    </div>
                    <div className={classes.question_detail_box}>
                        <div className={classes.question_detail_box__title}>
                            Question Context:
                        </div>
                        <div className={classes.question_detail_box__content}>
                            {formik.values.questionContext}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.confirm_section_box}>
                <div className={classes.editable_title}>
                    <p className={classes.section_title}>
                        Images &amp; Videos:
                    </p>
                    <IconButton className={classes.section_icon}>
                        <input 
                            type='file' 
                            className={classes.section_icon__input}
                            onChange={addNewImage}
                            accept='image/*, video/*'
                        />
                        <AddNewImage />
                    </IconButton>
                </div>
                <div className={classes.image_box}>
                    {(formik.values.initialImages.length === 0 &&
                    formik.values.uploadedImages.length === 0)
                    ? 
                    <div className={classes.image_box__empty}>
                        No images or videos uploaded
                    </div>
                    : 
                    <div className={classes.slide_container}>
                    {isUploading &&
                        <div className={classes.slide_container__uploading_box}>
                            <CircularProgress 
                                variant='determinate' 
                                value={loaded} 
                                className={classes.slide_container__uploading_box__circular}
                            />
                            <div className={classes.slide_container__uploading_box__progress}>
                                <p className={classes.slide_container__uploading_box__progress__label}>
                                    {loaded}%
                                </p>
                            </div>
                        </div>
                    }
                    {formik.values.uploadedImages &&
                        formik.values.uploadedImages.map((image, index) => (
                        <div 
                            className={classes.slide_container__img_box} 
                            key={index}
                        >
                            <img
                                src={image.uploadURL}
                                alt='question_document'
                                className={classes.slide_container__img_box__img}
                            />
                            <DeleteImage 
                                className={classes.slide_container__img_box__delete}
                                onClick={() => deleteAddedImages(image.filename)}
                            />
                        </div>
                        ))
                    }
                    {formik.values.initialImages.map((image, index) => (
                        <div
                            className={classes.slide_container__img_box}
                            key={index}
                        >
                        <img 
                            src={image.link}
                            alt='question_document'
                            className={classes.slide_container__img_box__img}
                            accept='image/*'
                        />
                        <DeleteImage 
                            className={classes.slide_container__img_box__delete}
                            onClick={() => deleteInitImages(image.id)}
                        />
                        </div>
                    ))}
                    </div>
                    }
                </div>
                <div className={classes.title_box}>
                    <p className={classes.title_box__primary}>
                    Attach Files
                    </p>
                    <p className={classes.title_box__secondary}>
                    Attach files to help your professional understand your situation.
                    </p>
                </div>
                {formik.values.initialFiles.length !== 0 &&
                formik.values.initialFiles.map((file, index) => (
                    <div 
                        className={classes.file_box} 
                        key={index}
                    >
                        <div className={classes.file_box__title_box}>
                            <VisaForm className={classes.file_box__title_box__icon}/>
                            <div className={classes.file_box__title_box__text_box}>
                                <p className={classes.file_box__title_box__text_box__top}>
                                    {formatFileDetails(file.key, 'name')}
                                </p>
                                <p className={classes.file_box__title_box__text_box__bottom}>
                                    {formatFileDetails(file.key, 'type')}
                                </p>
                            </div>
                        </div>
                        <DeleteImage 
                            className={classes.file_box__delete}
                            onClick={() => deleteInitFile(file)}
                        />
                    </div>
                ))}
                <UploadImagesFiles 
                    formik={formik}
                    callgetUploadURL={callgetUploadURL}
                    type='files'
                />
            </div>
            <div className={classes.confirm_section_box}>
                <p className={classes.remove_title}>Remove Question</p>
                <div className={classes.remove_container}>
                    <p>
                        Your question will be removed from the Time For Advice question pool and you will not receive your full amount paid.
                    </p>
                    <TFAButton 
                        text='DELETE QUESTION'
                        onClick={removeQuestion}
                        style1={classes}
                    />
                </div>
            </div>
            <div className={classes.btn_box}>
                <TFAButton 
                    text='SAVE'
                    onClick={formik.handleSubmit}
                    isLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    style1={classes}
                />
            </div>
            <QuestionDetailsModal 
                formik={formik}
                isDetailModalOpen={isDetailModalOpen}
                setIsDetailModalOpen={setIsDetailModalOpen}
            />
            <RemoveModal 
                formik={formik}
                type={removeType}
                setIsUpdateQuestionOpen={setIsUpdateQuestionOpen}
                isRemoveModalOpen={isRemoveModalOpen}
                setIsRemoveModalOpen={setIsRemoveModalOpen}
            />
        </>
    )
}

export default UpdateQuestion