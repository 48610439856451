import React, { useEffect, useState } from 'react'
import classes from '../CAppointments.module.scss'
import { EditIcon } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { DashboardTitle, ProgressState } from '../../../../../components/Dashboard/DashboardComponents'
import { GET_APPOINTMENT, SINGLE_DOWNLOAD } from '../../../../../services/apiQuery'
import { TFAButton, TFASkeleton } from '../../../../../components/TFAComponents'
import { appointmentDateFormatter, appointmentTimeConverter } from '../../../../../utils/finishTimeConverter'
import { reverseSpecialtyConverter } from '../../../../../utils/specialtyConverter'
import { Avatar } from '@mui/material'
import { useQuery } from '@apollo/client'
import moment from 'moment'

const Details = ({ appointmentId, setIsUpdateAppointmentOpen }) => {
  const [ avatar, setAvatar ] = useState('')
  const [ appointmentDetails, setAppointmentDetails ] = useState({})
  const [ createdTime, setCreatedTime ] = useState('')
  const now = moment().format()
  const openTime = moment(appointmentDetails?.timeStart).subtract(15, "minutes").format()
  const isJoinChatAvailable = (now < openTime)

  const { 
    loading: appointmentLoading,
    data: appointmentData,
  } = useQuery(GET_APPOINTMENT, { 
    variables: { getAppointmentId: appointmentId }
  })
  const { loading: avatarLoading, data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
    variables: { filename: appointmentDetails?.professional?.avatar }
  })

  useEffect(() => {
    appointmentData && setAppointmentDetails(appointmentData.GetAppointment)
    avatarData && setAvatar(avatarData.SingleDownload)
  },[appointmentData, avatarData])

  useEffect(() => {
    if (appointmentDetails) {
      const updatedAt = parseInt(appointmentDetails.updatedAt)
      const result = moment(updatedAt).format('k:mm D/MMM/YYYY')
      setCreatedTime(result)
    }
  },[appointmentDetails])

  const formatCompleteDate = (date) => {
    const formattedDate = moment.utc(date).local().format('D MMMM YYYY')
    return formattedDate
  }

  const beginMeeting = () => {
    if (!appointmentDetails.link) return
    window.open(
      `${appointmentDetails.link}`,
      '_blank'
    )
  }

  return (
    <div className={classes.details_container}>
      <DashboardTitle 
        title='Details' 
        marginBottom='0.5em'
        Icon={Object.entries(appointmentDetails).length !== 0 &&
          appointmentDetails.progress === 'Meeting' &&
          isJoinChatAvailable &&
           EditIcon
        }
        iconClick={() => setIsUpdateAppointmentOpen(true)}
      />
      {Object.entries(appointmentDetails).length === 0
      ? <div className={classes.details_container__detail_box}>
          <div className={classes.details_container__detail_box__empty_txt}>
            Select an appointment to see details
          </div>
        </div>
      : 
        <div className={classes.details_box}>
          {(appointmentLoading && avatarLoading)
          ? <TFASkeleton type='detail'/>
          : <>
              <p className={classes.details_box__progress_title}>Progress</p>
              <div className={classes.progress}>
                <div className={classes.progress__progress_state}>
                  <ProgressState 
                    stateName='meeting'
                    status={appointmentDetails.progress !== 'Cancelled'
                    ? appointmentDetails.progress
                    : 'Meeting'
                  }
                  cancelled={appointmentDetails.progress === 'Cancelled'}
                  variant='list'
                  style={classes}
                  />
                </div>
              </div>

              {appointmentDetails.progress === 'Cancelled' &&
              <div className={classes.cancel_box}>
                <p className={classes.cancel_box__title}>
                  Appointment Cancelled
                </p>
                <p className={classes.cancel_box__content}>
                  This appointment has been cancelled. Sorry for the inconvenience. You can always create another.
                </p>
              </div>
              }

              <p className={classes.details_box__detail_title}>Details</p>
              <div className={classes.question_box}>
                <p className={classes.question_box__title}>
                  {appointmentDetails.title}
                </p>
                <p className={classes.question_box__content}>
                  {appointmentDetails.context}
                </p>
                <p className={classes.question_box__complete_time}>
                  {`Complete・ ${formatCompleteDate(appointmentDetails.timeFinish)}`}
                </p>
              </div>
              <div className={classes.time_slot_box}>
                <p className={classes.time_slot_box__title}>
                  Time Slot:
                </p>
                <p className={classes.time_slot_box__time}>
                  {appointmentTimeConverter(appointmentDetails.timeStart, appointmentDetails.timeFinish)}
                </p>
                <p className={classes.time_slot_box__date}>
                  {appointmentDateFormatter(appointmentDetails.timeFinish)}
                </p>
              </div>

              <div className={classes.chat_btn_box}>
              {(appointmentDetails.progress === 'Sent' ||
              appointmentDetails.progress === 'Meeting') &&
                <TFAButton 
                  text='JOIN CHAT'
                  style2={!isJoinChatAvailable ? classes : null}
                  style3={isJoinChatAvailable ? classes : null}
                  onClick={!isJoinChatAvailable ? beginMeeting : null}
                />
              }
              {appointmentDetails.progress === 'Complete' &&
                <TFAButton
                  variant='outlined'
                  text='APPOINTMENT COMPLETE'
                  style1={classes}
                />
              }
              {appointmentDetails.progress === 'Cancelled' &&
                <TFAButton
                  variant='outlined'
                  text='APPOINTMENT CANCELLED'
                  style1={classes}
                />
              }
              </div>

              {
                <div className={classes.button_text_box}>
                  <p className={classes.button_text_box__text}>
                    {appointmentDetails.progress !== 'Cancelled' &&
                    appointmentDetails.progress !== 'Complete' &&
                      `Your link will be ready 15 minutes before the allocated time.`
                    }
                  </p>
                </div>
              }

              <p className={classes.details_box__detail_title}>Professional</p>
              <div className={classes.professional_box}>
                <Avatar 
                  alt='professional-avatar' 
                  src={avatar} 
                  className={classes.professional_box__avator} 
                  sx={{width: '2.5em', height: '2.5em'}}
                  />
                <div className={classes.professional_box__professional_info}>
                  <p className={classes.professional_box__professional_info__professional_name}>
                    {appointmentDetails.professional.firstName}&nbsp;
                    {appointmentDetails.professional.lastName}&nbsp;
                    <span className={classes.professional_box__professional_info__professional_name__code}>
                      - {appointmentDetails.professional.professionalCode}
                    </span>
                  </p>
                  <div className={classes.professional_box__professional_info__specialty}>
                    {reverseSpecialtyConverter(appointmentDetails.professional.specialty)}
                  </div>
                </div>
              </div>

              {appointmentDetails.cancelDetail &&
              <div className={classes.cancel_detail_box}>
                <div className={classes.cancel_detail_box__name_box}>
                  <p className={classes.cancel_detail_box__name_box__user}>
                    {appointmentDetails.professional.firstName}&nbsp;
                    {appointmentDetails.professional.lastName}&nbsp;
                    <span> ・ </span>
                    Professional
                  </p>
                  <p className={classes.cancel_detail_box__name_box__time}>
                    {createdTime}
                  </p>
                </div>
                <div>
                  <p>
                    {appointmentDetails.cancelDetail}
                  </p>
                </div>
              </div>
              }
            </>
          }
        </div>
      }
    </div>
  )
}

export default Details