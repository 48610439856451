import React, { useState } from 'react'
import classes from './UploadFileInput.module.scss'
import { 
    UploadFileClip,
    FileDocument,
    CompleteUpload,
    FileDelete,
} from '../../../assets/icons/Auth/AuthIcons'
import axios from "axios"
import { Alert } from '@mui/material'

const UploadFileInput = ({ 
    formik, 
    callUploadFileQuery,
    files,
    uploadedFiles,
    type,
    error,
}) => {
    const [ uploadingFile, setUploadingFile ] = useState('')
    const [ loaded, setLoaded ] = useState(0)
    const [ isUploading, setIsUploading ] = useState(false)

    const handleChange = async({ target }) => {
        const file = target.files[0]
        const { data } = await callUploadFileQuery(file, type)
        setUploadingFile(file)
        setIsUploading(true)
        try {
            if (data.GetPresignedUploadUrl) {
                const url = data.GetPresignedUploadUrl.link
                const uploading = await axios.put(url, file, {
                    headers: {
                        'Content-Type': file.type
                    },
                    onUploadProgress: e => {
                        setLoaded(((e.loaded * 100) / e.total).toFixed())
                        if (e.loaded === e.total) setIsUploading(false)
                    }
                })
                if (uploading.status === 200) {
                    formik.setFieldValue(formik.values[uploadedFiles] = [...formik.values[uploadedFiles], file])
                    formik.setFieldValue(formik.values[files] = [...formik.values[files], {
                        fileName: data.GetPresignedUploadUrl?.filename,
                        key: data.GetPresignedUploadUrl?.key,
                    }])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const shortenFilename = (filename) => {
        if (filename.length >= 12) {
            let splitName = filename.split('.')
            filename = splitName[0].substring(0,12) + '... .' + splitName[1]
            return filename
        }
        return filename
    }

    const fileSizeConverter = (size) => {
        if (size < 1000000) return `${(size / 1024).toFixed(2)} KB`
        else return `${(size / (1024 * 1024)).toFixed(2)} MB`
    }

    const deleteFile = (file) => {
        const filterFiles = formik.values[files].filter(f => f.fileName !== file.name)
        const filterUploadedFiles = formik.values[uploadedFiles].filter(f => f.name !== file.name)
        formik.setFieldValue(formik.values[files] = filterFiles)
        formik.setFieldValue(formik.values[uploadedFiles] = filterUploadedFiles)
    }
    
    return (
        <div className={classes.wrapper}>
            <div className={classes.form_box}>
                <input 
                    type='file' 
                    className={classes.file_input} 
                    onChange={handleChange} 
                    disabled={isUploading}
                />
                <UploadFileClip className={classes.form_box__upload_icon}/>
                <p className={classes.form_box__upload_text}>
                    DOCUMENTS
                </p>
            </div>
            {isUploading &&
            <div className={classes.progress_box}>
                <li className={classes.row}>
                    <FileDocument className={classes.document_icon}/>
                    <div className={classes.row__content}>
                        <div className={classes.row__content__details}>
                            <span className={classes.row__content__details__name}>{shortenFilename(uploadingFile.name)} ・ Uploading</span>
                            <span className={classes.row__content__details__percent}>{loaded}%</span>
                        </div>
                        <div className={classes.row__content__progress_bar}>
                            <div className={classes.row__content__progress_bar__progress} style={{width: `${loaded}%`}}></div>
                        </div>
                    </div>
                </li>
            </div>
            }
            <div className={classes.uploaded_box} >
            {formik.values[uploadedFiles] &&
                formik.values[uploadedFiles].map((file, index) => (
                    <li className={classes.row} key={index}>
                        <div className={classes.row__progress_content}>
                            <FileDocument className={classes.document_icon}/>
                            <div className={classes.row__progress_content__details}>
                                <span className={classes.row__progress_content__details__name}>{shortenFilename(file.name)} ・ Uploaded</span>
                                <span className={classes.row__progress_content__details__size}>{fileSizeConverter(file.size)}</span>
                            </div>
                        </div>
                        <CompleteUpload className={classes.complete_icon}/>
                        <FileDelete 
                            className={classes.delete_file} 
                            onClick={() => deleteFile(file)}
                        />
                    </li>
                ))
            }
            </div>
            {formik.touched[files] && error && 
                <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {error}
                </Alert>
            }
        </div>
    )
}

export default UploadFileInput