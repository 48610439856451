import React, { useEffect, useState } from 'react'
import classes from './ProfessionalCard.module.scss'
import { Avatar } from '@mui/material'
import { useQuery } from '@apollo/client'
import { SINGLE_DOWNLOAD } from '../../../services/apiQuery'
import { reverseSpecialtyConverter } from '../../../utils/specialtyConverter'

const ProfessionalCard = ({ professionalDetails, clickProfessional, clickedId, bio, type }) => {
    const [ avatar, setAvatar ] = useState('')
    const { data: avatarData } = useQuery(SINGLE_DOWNLOAD, {
        variables: { filename: professionalDetails.avatar }
    })

    useEffect(() => {
        avatarData && setAvatar(avatarData.SingleDownload)
    },[avatarData, professionalDetails])

    const formatMinPrice = () => {
        let minPrice
        if (type === 'question') 
            minPrice = professionalDetails.questionPrice
        else 
            minPrice = professionalDetails.askingPrice
        
        const formattedPrice = parseFloat(minPrice / 100).toFixed(2)
        return formattedPrice
    }

    return (
        <div 
            className={professionalDetails.id === clickedId
                ? classes.professional_card_container_clicked
                : classes.professional_card_container
            }
            onClick={clickProfessional}
        >
            {Object.entries(professionalDetails).length === 0
            ? 
            <div className={classes.professional_card_container__empty_box}>
                <p className={classes.professional_card_container__empty_box__title}>
                    No professional selected
                </p>
                {bio
                ? <></>
                :
                <p>
                    If you do not select any professional, this question will be waited untill it is picked up
                </p>
                }
            </div>
            :
            <>
                <div className={classes.professional_box}>
                    <Avatar 
                        src={avatar}
                        className={classes.professional_box__icon}
                        sx={{width:'2.5em', height:'2.5em'}}
                        alt='professional-avatar'
                    />
                    <div className={classes.professional_box__info}>
                        <div className={classes.professional_box__info__top}>
                            <p className={classes.professional_box__info__top__name}>
                                {professionalDetails.firstName}&nbsp;
                                {professionalDetails.lastName}
                            </p>
                            <p className={classes.professional_box__info__top__number}>
                                <span className={classes.professional_box__info__top__devider}> - </span>
                                {professionalDetails.professionalCode}
                            </p>
                        </div>
                        <div className={classes.professional_box__info__bottom}>
                            <p className={classes.professional_box__info__bottom__experience}>
                                {professionalDetails.experienceLevel ?? 'No'} Experience
                            </p>
                            <p className={classes.professional_box__info__bottom__category}>
                                {reverseSpecialtyConverter(professionalDetails.specialty)}
                            </p>
                        </div>
                    </div>
                </div>
                {bio 
                ?
                <div className={classes.bio_box}>
                    <p className={classes.bio_box__content}>
                        {professionalDetails.bio}
                    </p>
                </div>
                :
                <div className={classes.price_box}>
                    <p className={classes.price_box__title}>
                        Minimum Price:
                    </p>
                    <p className={classes.price_box__price}>
                        &#36;{formatMinPrice()}
                    </p>
                </div>
                }
            </>
            }
        </div>
    )
}

export default ProfessionalCard