import React, { useState, useEffect } from 'react'
import classes from './CreateAppointment.module.scss'
import Professional from './MultiPages/Professional'
import Time from './MultiPages/Time'
import Details from './MultiPages/Details'
import Confirm from './MultiPages/Confirm'
import useClientContext from '../../../../states/Client/useClientContext'
import { CREATE_APPOINTMENT } from '../../../../assets/data/SelectOptions'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { ClientCreateAppointmentValues } from '../../../../assets/data/FormikInitialValues'
import { CreateAppointmentSchema } from '../../../../utils/validation'
import { TFALogo } from '../../../../assets/icons/Auth/AuthIcons'
import { TFASnackBar } from '../../../../components/TFAComponents'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_CLIENT_APPOINTMENT } from '../../../../services/apiMutation'
import { GET_CARD_DETAIL } from '../../../../services/apiQuery'
import moment from 'moment'

const CreateAppointment = () => {
	const { client } = useClientContext().clientState
	const [currentPage, setCurrentPage] = useState(CREATE_APPOINTMENT.PROFESSIONAL)
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const navigate = useNavigate()
	const { data: cardData } = useQuery(GET_CARD_DETAIL)
	const [createAppointment] = useMutation(CREATE_CLIENT_APPOINTMENT)

	const formik = useFormik({
		initialValues: ClientCreateAppointmentValues,
		validationSchema: CreateAppointmentSchema,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { AppointmentCreate } = await callCreateAppointment(values)
				if (!AppointmentCreate) return
				navigate('/c_dashboard/c_appointments')
				window.location.reload(false)
			} catch (error) {
				setIsSnackbarOpen(true)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (cardData) {
			if (cardData.Stripe_ListAllCards.length === 0) return
			formik.setFieldValue((formik.values.allCards = cardData.Stripe_ListAllCards))
			formik.setFieldValue((formik.values.originalDefaultCard = cardData.Stripe_ListAllCards[0]))
			formik.setFieldValue((formik.values.defaultCard = cardData.Stripe_ListAllCards[0]))
		}
	}, [cardData])

	const callCreateAppointment = async (values) => {
		const finishTime = moment.utc(values.appointmentDate).add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
		const { data } = await createAppointment({
			variables: {
				appointment: {
					clientId: client.id,
					professionalId: values.professional.id,
					title: values.title,
					context: values.context,
					timeStart: values.appointmentDate,
					timeFinish: finishTime,
					progress: 'Sent',
					defaultCard: values.paymentPreference === 'Card' ? values.defaultCard.id : '',
					paypalOfferId: null,
				},
			},
		})
		return data
	}

	const renderPage = (currentPage, setCurrentPage, formik) => {
		switch (currentPage) {
			case CREATE_APPOINTMENT.PROFESSIONAL:
				return <Professional formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			case CREATE_APPOINTMENT.TIME:
				return <Time formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			case CREATE_APPOINTMENT.DETAILS:
				return <Details formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			case CREATE_APPOINTMENT.CONFIRM:
				return <Confirm formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			default:
				break
		}
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.wrapper}>
			<TFALogo className={classes.logo} />
			<div className={classes.main_container}>{renderPage(currentPage, setCurrentPage, formik)}</div>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to create a new appointment. Please try again.'
			/>
		</div>
	)
}

export default CreateAppointment
