import React, { SyntheticEvent, useState } from 'react'
// @ts-ignore
import classes from './Signup.module.scss'
import { TFALogo, LeftArrow } from '../../../../assets/icons/Auth/AuthIcons'
import { useNavigate } from 'react-router-dom'
import { ProgressState } from '../../../../components/Dashboard/DashboardComponents'
import { TFASnackBar } from '../../../../components/TFAComponents'
// @ts-ignore
import AboutYou from './MultiPages/AboutYou.tsx'
// @ts-ignore
import Verify from './MultiPages/Verify.tsx'

type SignupPages = 'Verify' | 'About You'

function Signup() {
	const [currentPage, setCurrentPage] = useState<SignupPages>('Verify')
	const [isAuthCode, setIsAuthCode] = useState(false)
	const [email, setEmail] = useState('')
	const [code, setCode] = useState('')
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const navigate = useNavigate()

	const renderPage = (currentPage: SignupPages) => {
		switch (currentPage) {
			case 'Verify':
				return (
					<Verify setCurrentPage={setCurrentPage} isAuthCode={isAuthCode} setIsAuthCode={setIsAuthCode} setEmail={setEmail} setCode={setCode} />
				)
			case 'About You':
				return <AboutYou email={email} code={code} />
			default:
				break
		}
	}

	const renderBackBtn = (currentPage: SignupPages) => {
		switch (currentPage) {
			case 'Verify':
				if (isAuthCode) return setIsAuthCode(false)
				return navigate('/auth')
			case 'About You':
				return setCurrentPage('Verify')
			default:
				break
		}
	}

	const handleSnackbarClose = (event: SyntheticEvent<Element, Event>, reason: string) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.wrapper}>
			<TFALogo className={classes.logo} />
			<div className={classes.main_container}>
				<div className={classes.backBtn_box} onClick={() => renderBackBtn(currentPage)}>
					<LeftArrow className={classes.backBtn_box__arrow} />
					<p className={classes.backBtn_box__txt}>RETURN</p>
				</div>
				<div className={classes.progress_box}>
					<p className={classes.progress_box__title}>Progress:</p>
					<div className={classes.progress_box__state}>
						<ProgressState cancelled={false} stateName='signup' status={currentPage} variant='list' style={classes} />
					</div>
				</div>
				{renderPage(currentPage)}
			</div>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to signup, Please try again later.'
			/>
		</div>
	)
}

export default Signup
