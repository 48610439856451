import React, { useEffect, useState } from 'react'
import classes from '../PProfile.module.scss'
import { CloseBtn, UpdateCompleted } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import {
  TFAModal,
  TFAInputText,
  TFAButton,
  TFAText,
} from '../../../../../components/TFAComponents'
import { useFormik } from 'formik'
import { updateProAccountSchema } from '../../../../../utils/validation'
import { Avatar, IconButton } from '@mui/material'
import { AddImg } from '../../../../../assets/icons/Auth/AuthIcons'
import TFAPhoneInput from '../../../../../components/Register/TFAPhoneInput/TFAPhoneInput'
import { UpdateProAccountValues } from '../../../../../assets/data/FormikInitialValues'
import { useLazyQuery, useMutation } from '@apollo/client'
import { UPDATE_PROFILE } from '../../../../../services/apiMutation'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'
import { GET_PRESIGNED_UPLOAD_URL } from '../../../../../services/apiQuery'
import axios from 'axios'

const AccountModal = ({ isAccountOpen, accountModalClose }) => {
  const { professional } = useProfessionalContext().professionalState
  const [ getUploadURL ] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)
  const [ updateProfile ] = useMutation(UPDATE_PROFILE)
  const [ isCompleted, setIsCompleted ] = useState(false)

  const formik = useFormik({
    initialValues: UpdateProAccountValues,
    validationSchema: updateProAccountSchema,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { ProfessionalUpdate } = await callUpdateProfile(values)
        if (!ProfessionalUpdate) return
        setIsCompleted(true)
      } catch (error) {
        console.log(error)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    if (professional) {
      formik.setFieldValue(formik.values.firstName = professional.firstName)
      formik.setFieldValue(formik.values.lastName = professional.lastName)
      formik.setFieldValue(formik.values.email = professional.email)
      formik.setFieldValue(formik.values.image = professional.avatarLink)
      formik.setFieldValue(formik.values.phoneNumber = professional.mobile)
    }
  },[professional])

  const uploadAvatar = async ({ target }) => {
    const file = target.files[0]
    const uploadImageURL = URL.createObjectURL(file)
    try {
      const { GetPresignedUploadUrl } = await callGetUploadURL(file)
      if (!GetPresignedUploadUrl) return
      const url = GetPresignedUploadUrl.link
      const uploading = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
      })
      if (uploading.status !== 200) return
      formik.setFieldValue(formik.values.avatar = GetPresignedUploadUrl.key)
      formik.setFieldValue(formik.values.image = uploadImageURL)
    } catch (error) {
      console.log(error)
    }
  }

  const callGetUploadURL = async (file) => {
    const { data } = await getUploadURL({
      variables: {
        filename: file.name,
        folder: 'avatar'
      }
    })
    return data
  }

  const callUpdateProfile = async (values) => {
    const { data } = await updateProfile({
      variables: {
        professional: {
          id: professional?.id,
          firstName: values.firstName !== '' ? values.firstName : professional?.firstName,
          lastName: values.lastName !== '' ? values.lastName : professional?.lastName,
          email: values.email !== '' ? values.email : professional?.email,
          mobile: values.phoneNumber ?? professional?.mobile,
          avatar: values.avatar !== '' ? values.avatar : professional?.avatar,
        },
      }
    })
    return data
  }

  const closeCompletedModal = () => {
    accountModalClose()
    setIsCompleted(false)
    window.location.reload(false)
  }

  return (
    <>
      {isCompleted
      ?
      <TFAModal
        open={isAccountOpen}
        handleClose={accountModalClose}
        width='25em'
      >
        <UpdateCompleted className={classes.modal_title_icon}/>
        <div className={classes.modal_text_box}>
          <TFAText
            secondary_title='Profile Updated'
            description='Your Profile has been updated successfully.'
            style2={classes}
          />
        </div>
        <TFAButton
          text='GREAT!'
          style2={classes}
          onClick={closeCompletedModal}
        />
      </TFAModal>
      :
      <TFAModal 
        open={isAccountOpen}
        handleClose={accountModalClose}
        width='50em'
      >
        <div className={classes.modal_title_box}>
          <CloseBtn 
            className={classes.modal_title_box__icon}
            onClick={accountModalClose}
          />
          <p className={classes.modal_title_box__title}>
            Account Details
          </p>
        </div>
        <div className={classes.account_detail_box}>
          <div className={classes.detail_box}>
            <p className={classes.detail_box__title}>
              Your Details
            </p>
            <p className={classes.detail_box__desc}>
              Update you personal account details.
            </p>
            <TFAInputText 
              label='FIRST NAME'
              type='text'
              name='firstName'
              placeholder={professional?.firstName}
              required={false}
              formik={formik}
              error={formik.errors.firstName ?? null}
            />
            <TFAInputText 
              label='LAST NAME'
              type='text'
              name='lastName'
              placeholder={professional?.lastName}
              required={false}
              formik={formik}
              error={formik.errors.lastName ?? null}
            />
            <TFAInputText
              label='EMAIL'
              type='email'
              name='email'
              required={false}
              placeholder={professional?.email}
              formik={formik}
              error={formik.errors.email ?? null}
            />
            <TFAPhoneInput 
              name='phoneNumber'
              formik={formik}
              error={formik.errors.phoneNumber ?? null}
              bg='#F2F6F7'
              prefix={''}
            />
          </div>
          <div className={classes.profile_box}>
            <p className={classes.profile_box__title}>
              Profile Picture
            </p>
            <p className={classes.profile_box__desc}>
              Personalise your account with a profile picture.
            </p>
            <div className={classes.avatar_box}>
              <Avatar 
                src={formik && formik.values.image !== 'false' 
                  ? formik.values.image
                  : null
                }
                alt='professional avatar' 
                className={classes.avatar_box__avatar}
                sx={{ width: 220, height: 220, bgcolor: '#2F68FA' }}
              />
              <IconButton
                component="span"
                sx={{background: '#2F68FA', padding: '0.5em'}}
                className={classes.avatar_box__camera}
                name='image'
                onChange={uploadAvatar}
              >   
                <input type='file' className={classes.avatar_box__camera__avatar_input} accept={'image/*'}/>
                <AddImg />
              </IconButton>
            </div>
          </div>
        </div>
        <TFAButton 
          text='SAVE'
          isLoading={formik.isSubmitting}
          onClick={formik.handleSubmit}
          style1={classes}
        />
      </TFAModal>
      }
    </>
  )
}

export default AccountModal