import React from 'react'
import classes from './MultiPageStyle.module.scss'
import { TFAButton } from '../../../../../components/TFAComponents'
import { COMPLETE_SIGNUP_PAGES } from '../../../../../assets/data/SelectOptions'
import validatePage from '../../../../../utils/validatePage'
import TFADirectDebitInput from '../../../../../components/Register/TFADirectDebitInput/TFADirectDebitInput'

const PaymentMethod = ({ formik, setCurrentPage }) => {

  const handleSubmit = async () => {
    const { streetNumber, streetName, suburb, postcode, state, country, accountName, accountNumber, bsb} = formik.values
    const isPageValid = await validatePage({ streetNumber, streetName, suburb, postcode, state, country, accountName, accountNumber, bsb }, formik)
    if(!isPageValid) return
    setCurrentPage(COMPLETE_SIGNUP_PAGES.CREATE_PASSWORD) 
  }

  return (
    <div className={classes.payment_wrapper}>
      <div className={classes.section_title_box}>
        <p className={classes.section_title_box__main}>
          Where would you like to be paid?
        </p>
        <p className={classes.section_title_box__desc}>
          Enter your below Bank Details to start earning money on Time For Advice.
        </p>
      </div>
      <TFADirectDebitInput formik={formik} />
      <div className={classes.btn_box}>
        <TFAButton
          text='SAVE &amp; CONTINUE'
          onClick={handleSubmit}
          disabled={(!formik.values.accountNumber || !formik.values.bsb)}
          style1={classes}
        />
      </div>
    </div>
  )
}

export default PaymentMethod