import React, { useMemo } from 'react'
import classes from './Searchbar.module.scss'
import SearchIcon from '@mui/icons-material/Search'
import { Box } from '@mui/material'
import { Search, SearchIconWrapper, StyledInputBase } from './Muistyle'
import { debounce } from 'debounce'

const Searchbar = ({ setSearchText, ...props }) => {
  const searchTextChanged = useMemo(() => debounce(setSearchText, 500),[setSearchText])

  const handleSearchText = ({ target }) => {
    const newText = target.value
    searchTextChanged(newText)
  }
  
  return (
    <div className={props.bg ? classes.searchbar_bg : classes.searchbar_box}>
        <Box 
          sx={{ 
            borderRadius: '10px', 
            width: props.width ?? '100%', 
            padding: props.bg ? '0.5vw 0' : '1vw'
          }}
        >
          <Search>
            <SearchIconWrapper>
            <SearchIcon sx={{color: '#375FC5'}}/>
            </SearchIconWrapper>
            <StyledInputBase 
              placeholder='SEARCH' 
              onChange={handleSearchText}
            />
          </Search>
        </Box>
    </div>
  )
}

export default Searchbar