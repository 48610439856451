import React, { useState } from 'react'
import classes from './Sections.module.scss'
import { useFormik } from 'formik'
import { UpdatePasswordValues } from '../../../../../assets/data/FormikInitialValues'
import { updatePasswordSchema } from '../../../../../utils/validation'
import { LeftArrow, TFALogo } from '../../../../../assets/icons/Auth/AuthIcons'
import { PROFILE_SETTTING_PAGE } from '../../../../../assets/data/SelectOptions'
import { TFAButton, TFAInputText, TFAText } from '../../../../../components/TFAComponents'
import { useMutation } from '@apollo/client'
import { CHECK_CLIENT_PASSOWORD, UPDATE_CLIENT_PROFILE } from '../../../../../services/apiMutation'
import useClientContext from '../../../../../states/Client/useClientContext'

const Security = ({ setClickedPage, setPasswordModalOpen }) => {
  const { client } = useClientContext().clientState
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isShowPassword, setIsShowPassword ] = useState(false)
  const [ currentPage, setCurrentPage ] = useState('current_password')
  const [ checkClientPassword ] = useMutation(CHECK_CLIENT_PASSOWORD)
  const [ updateProfile ] = useMutation(UPDATE_CLIENT_PROFILE)
  const formik = useFormik({
    initialValues: UpdatePasswordValues,
    validationSchema: updatePasswordSchema,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { ClientUpdate } = await callUpdateProfile(values.newPassword)
        if (ClientUpdate !== 'success') return
        setPasswordModalOpen(true)
        setClickedPage(PROFILE_SETTTING_PAGE.ABOUT_YOU)
      } catch (error) {
        console.log(error)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const checkCurrentPassword = async () => {
    try {
      setIsLoading(true)
      const { ClientLogin } = await callCheckCurrentPassword(formik.values.password)
      if (!ClientLogin) return
      setCurrentPage('update_password')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const callCheckCurrentPassword = async (password) => {
    const { data } = await checkClientPassword({
      variables: {
        email: client?.email,
        password
      }
    })
    return data
  }

  const callUpdateProfile = async (password) => {
    const { data } = await updateProfile({
      variables: {
        client: {
          id: client?.id,
          password,
        }
      }
    })
    return data
  }

  return (
    <div className={classes.wrapper}>
      <TFALogo className={classes.logo}/>
      <div className={classes.main_container}>
        {currentPage === 'current_password' &&
        <>
          <div 
            className={classes.backBtn_box}
            onClick={() => setClickedPage(PROFILE_SETTTING_PAGE.ABOUT_YOU)}
          >
            <LeftArrow className={classes.backBtn_box__arrow}/>
            <p className={classes.backBtn_box__txt}>RETURN</p>
          </div>
          <div className={classes.main_content_container}>
            <TFAText 
              primary_title='Security'
              secondary_title='Update Password'
              style1={classes}
            />
          </div>
          <div className={classes.second_section_box}>
            <p className={classes.second_section_box__title}>
              Current Password
            </p>
            <p className={classes.second_section_box__desc}>
              Enter your current password to verify yourself.
            </p>
            <TFAInputText
              label='CURRENT PASSWORD'
              type='password'
              name='password'
              palceholder='Enter Current Password'
              form_width='100%'
              showPassword={isShowPassword}
              visiblePassword={setIsShowPassword}
              formik={formik}
              error={formik.errors.password ?? null}
            />
          </div>
          <div className={classes.btn_box}>
            <TFAButton 
              text='NEXT'
              onClick={checkCurrentPassword}
              isLoading={isLoading}
              disabled={isLoading}
              style2={classes}
            />
          </div>
        </>
        }
        {currentPage === 'update_password' &&
        <>
          <div 
            className={classes.backBtn_box}
            onClick={() => setCurrentPage('current_password')}
          >
            <LeftArrow className={classes.backBtn_box__arrow}/>
            <p className={classes.backBtn_box__txt}>RETURN</p>
          </div>
          <div className={classes.main_content_container}>
            <TFAText 
              primary_title='Security'
              secondary_title='Update Password'
              style1={classes}
            />
          </div>
          <div className={classes.second_section_box}>
            <p className={classes.second_section_box__title}>
              New Password
            </p>
            <p className={classes.second_section_box__desc}>
              Create a strong password that will protect your Time For Advice account.
            </p>
            <TFAInputText 
              label='NEW PASSWORD'
              type='password'
              name='newPassword'
              palceholder='Enter New Password'
              form_width='95%'
              showPassword={isShowPassword}
              visiblePassword={setIsShowPassword}
              formik={formik}
              error={formik.errors.newPassword ?? null}
            />
            <TFAInputText 
              label='CONFIRM NEW PASSWORD'
              type='password'
              name='confirmNewPassword'
              palceholder='Enter Confirm New Password'
              form_width='95%'
              showPassword={isShowPassword}
              visiblePassword={setIsShowPassword}
              formik={formik}
              error={formik.errors.confirmNewPassword ?? null}
            />
          </div>
          <div className={classes.btn_box}>
            <TFAButton 
              text='NEXT'
              onClick={formik.handleSubmit}
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
              style2={classes}
            />
          </div>
        </>
        }
      </div>
    </div>
  )
}

export default Security