import React, { useEffect, useState } from 'react'
import classes from './CreateQuestion.module.scss'
import Details from './MultiPages/Details'
import Media from './MultiPages/Media'
import Price from './MultiPages/Price'
import Confirm from './MultiPages/Confirm'
import { TFALogo } from '../../../../assets/icons/Auth/AuthIcons'
import { CREATE_QUESTIONS } from '../../../../assets/data/SelectOptions'
import { useFormik } from 'formik'
import { ClientCreateQuestionValues } from '../../../../assets/data/FormikInitialValues'
import { createQuestionsSchema } from '../../../../utils/validation'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_QUESTION } from '../../../../services/apiMutation'
import useClientContext from '../../../../states/Client/useClientContext'
import { categoryConverter } from '../../../../utils/specialtyConverter'
import { useNavigate } from 'react-router-dom'
import { TFASnackBar } from '../../../../components/TFAComponents'
import { GET_CARD_DETAIL } from '../../../../services/apiQuery'

const CreateQuestion = () => {
	const { client } = useClientContext().clientState
	const [currentPage, setCurrentPage] = useState(CREATE_QUESTIONS.DETAILS)
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const navigate = useNavigate()
	const { data: cardData } = useQuery(GET_CARD_DETAIL)
	const [createQuestion] = useMutation(CREATE_QUESTION)
	const formik = useFormik({
		initialValues: ClientCreateQuestionValues,
		validationSchema: createQuestionsSchema,
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { QuestionCreate } = await callCreateQuestion(values)
				if (!QuestionCreate) return
				navigate('/c_dashboard/questions')
				window.location.reload(false)
			} catch (error) {
				setIsSnackbarOpen(true)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (cardData) {
			if (cardData.Stripe_ListAllCards.length === 0) return
			formik.setFieldValue((formik.values.allCards = cardData.Stripe_ListAllCards))
			formik.setFieldValue((formik.values.originalDefaultCard = cardData.Stripe_ListAllCards[0]))
			formik.setFieldValue((formik.values.defaultCard = cardData.Stripe_ListAllCards[0]))
		}
	}, [cardData])

	const callCreateQuestion = async (values) => {
		const category = categoryConverter(values.category)
		const imageKeys = values.images.map((image) => image.key)
		const fileKeys = values.files.map((file) => file.key)
		const isProSelected = Object.entries(values.professional).length === 0 ? 'Pick_Up' : 'Posted'
		const { data } = await createQuestion({
			variables: {
				question: {
					clientId: client.id,
					destinationCountry: values.countryOfDestination,
					category: category,
					title: values.questionTitle,
					content: values.questionContext,
					price: parseInt(values.askingPrice * 100),
					progress: isProSelected,
					professionalId: values.professional.id ?? null,
					defaultCard: values.paymentPreference === 'Card' ? values.defaultCard.id : '',
				},
				paypalOfferId: null,
				imageKeys: imageKeys,
				fileKeys: fileKeys,
				isPool: values.professional.id ? false : true,
			},
		})
		return data
	}

	const renderPage = (currentPage, setCurrentPage, formik) => {
		switch (currentPage) {
			case CREATE_QUESTIONS.DETAILS:
				return <Details formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			case CREATE_QUESTIONS.MEDIA:
				return <Media formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			case CREATE_QUESTIONS.PRICE:
				return <Price formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			case CREATE_QUESTIONS.CONFIRM:
				return <Confirm formik={formik} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			default:
				break
		}
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<div className={classes.wrapper}>
			<TFALogo className={classes.logo} />
			<div className={classes.main_container}>{renderPage(currentPage, setCurrentPage, formik)}</div>
			<TFASnackBar
				variant='error'
				isSnackbarOpen={isSnackbarOpen}
				handleSnackbarClose={handleSnackbarClose}
				message='Failed to create a new question. Please try again.'
			/>
		</div>
	)
}

export default CreateQuestion
