const SignupProgressState = [
	{
		title: 'Verify',
		status: 0,
	},
	{
		title: 'About You',
		status: 0,
	},
	{
		title: 'Languages',
		status: 0,
	},
	{
		title: 'Payment',
		status: 0,
	},
]

const QuestionProgressState = [
	{
		title: 'Posted',
		status: 0,
	},
	{
		title: 'Pick Up',
		status: 0,
	},
	{
		title: 'Working',
		status: 0,
	},
	{
		title: 'Answered',
		status: 0,
	},
	{
		title: 'Complete',
		status: 0,
	},
]

const ReplyLogState = [
	{
		title: 'Pending',
		status: 0,
	},
	{
		title: 'Info Required',
		status: 0,
	},
	{
		title: 'Accepted',
		status: 0,
	},
]

const AppointmentProgressState = [
	{
		title: 'Today',
		status: 1,
	},
	{
		title: 'Cancelled',
		status: 3,
	},
	{
		title: 'Complete',
		status: 0,
	},
]

const MeetingProgressState = [
	{
		title: 'Sent',
		status: 0,
	},
	{
		title: 'Meeting',
		status: 0,
	},
	{
		title: 'Complete',
		status: 0,
	},
]

const CreateQuestionsProgressState = [
	{
		title: 'Details',
		status: 0,
	},
	{
		title: 'Media',
		status: 0,
	},
	{
		title: 'Price',
		status: 0,
	},
	{
		title: 'Confirm',
		status: 0,
	},
]

const CreateAppointmentProgressState = [
	{
		title: 'Professional',
		status: 0,
	},
	{
		title: 'Time',
		status: 0,
	},
	{
		title: 'Details',
		status: 0,
	},
	{
		title: 'Confirm',
		status: 0,
	},
]

export {
	SignupProgressState,
	QuestionProgressState,
	ReplyLogState,
	AppointmentProgressState,
	MeetingProgressState,
	CreateQuestionsProgressState,
	CreateAppointmentProgressState,
}
