import React from 'react'
import classes from './Auth.module.scss'
import { AuthImg } from '../../../assets/images/Auth/AuthImages'
import { AuthNetWork } from '../../../assets/icons/Auth/AuthIcons'
import { TFAText, TFAButton } from '../../../components/TFAComponents'
import { useNavigate } from 'react-router-dom'
import TFALinkExternal from '../../../components/TFALinkExternal/TFALinkExternal'

const Auth = () => {
	const navigate = useNavigate()
	return (
		<div className={classes.auth_wrapper}>
			<div className={classes.left_container}>
				<img src={AuthImg} className={classes.left_container__img} alt='auth' />
			</div>
			<div className={classes.right_container}>
				<div className={classes.center_box}>
					<TFALinkExternal text='RETURN TO WEBSITE' backward={true} to='https://timeforadvice.com' style1={classes} />
					<div className={classes.right_container__title}>
						<TFAText
							primary_title='Let’s Begin!'
							secondary_title='Start your advice journey'
							description={
								<>
									Welcome to Time For Advice. New around here? Create a free account!
									<br />
									<br />
									Returning users can sign back in.
								</>
							}
							style1={classes}
						/>
						<AuthNetWork className={classes.user_icon} />
					</div>
					<div className={classes.auth_btn_wrapper}>
						<TFAButton
							text='CREATE ACCOUNT'
							onClick={() => {
								navigate('/signup')
							}}
							style1={classes}
						/>
						<TFAButton
							text='SIGN IN'
							onClick={() => {
								navigate('/login')
							}}
							style2={classes}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auth
