import React, { useEffect, useState } from 'react'
import classes from './Modal.module.scss'
import moment from 'moment'
import { TFAButton, TFAModal, TFASkeleton } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useQuery } from '@apollo/client'
import { GET_MEETING_SCHEDULES } from '../../../../../services/apiQuery'
import { AppointmentCalendar } from '../../../../../components/Dashboard/DashboardComponents'

const UpdateDateModal = ({ 
  formik, 
  isDateModalOpen,
  setIsDateModalOpen,
  setIsTimeModalOpen,
}) => {
  const formattedSelectedDay = moment(formik.values.selectedNewDate 
    ? formik.values.selectedNewDate
    : formik.values.timeStart
  ).format('DD/MM/YYYY')
  const [ availabilityList, setAvailabilityList ] = useState([])
  const [ selectedDay, setSelectedDay ] = useState('')
  const { loading: availabilityLoading, data: availabilityData } = useQuery(GET_MEETING_SCHEDULES, {
    variables: { professionalId: formik.values.professional.id}
  })

  useEffect(() => {
    availabilityData && setAvailabilityList(availabilityData.GetMeetingschedules.objectList)
  },[availabilityData])

  const selectAppointmentDay = (day, month) => {
    setSelectedDay(`${day} ${month}`)
  }

  const confirmAppointmentDate = () => {
    const selectedDate = moment(selectedDay).format()
    formik.setFieldValue(formik.values.selectedNewDate = selectedDate)
    setIsDateModalOpen(false)
    setIsTimeModalOpen(true)
  }

  return (
    <TFAModal
      open={isDateModalOpen}
      handleClose={() => setIsDateModalOpen(false)}
      width='30em'
    >
      <div className={classes.modal_title_box}>
        <CloseBtn
          className={classes.modal_title_box__icon}
          onClick={() => setIsDateModalOpen(false)}
        />
        <p className={classes.modal_title_box__title}>
          Select New Date
        </p>
      </div>
      <p className={classes.modal_desc_box}>
        Select a time that works for your appointment from the options below.
      </p>
      <div className={classes.selected_date_box}>
        <p className={classes.selected_date_box__title}>
            Date Selected:
        </p>
        <p className={classes.selected_date_box__date}>
            {formattedSelectedDay}
        </p>
      </div>
      <div className={classes.calendar_box}>
      {availabilityLoading
      ? <TFASkeleton type='calendar'/>
      : <AppointmentCalendar 
          availabilityList={availabilityList}
          selectedDay={selectedDay}
          selectAppointmentDay={selectAppointmentDay}
        />
      }
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='CONFIRM'
          onClick={confirmAppointmentDate}
          style1={classes}
        />
      </div>
    </TFAModal>
  )
}

export default UpdateDateModal