import React from 'react'
import classes from './Modals.module.scss'
import { ReportClaimed } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAButton, TFAModal, TFAText } from '../../../../../components/TFAComponents'

const ReportModal = ({ reportModalOpen, setReportModalOpen }) => {
    return (
        <TFAModal
            open={reportModalOpen}
            handleClose={() => setReportModalOpen(false)}
            width='25em'
        >
            <ReportClaimed className={classes.modal_icon}/>
            <div className={classes.modal_text_box}>
                <TFAText 
                    secondary_title='Report Sent To The Team'
                    description={
                        <p>
                            Your report has been successfully sent to the Time For Advice team.<br/><br/> 
                            We will be in contact with you to resolve this issue.
                        </p>
                    }
                    style1={classes}
                />
            </div>
            <div className={classes.confirm_btn_box}>
                <TFAButton 
                    text='GREAT!'
                    onClick={() => setReportModalOpen(false)}
                    style1={classes}
                />
            </div>
        </TFAModal>
    )
}

export default ReportModal