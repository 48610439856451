const ClientReducer = (state, action) => {
    switch(action.type) {
        case 'setClient': {
            return {
                ...state,
                client: action.data
            }
        }
        default:
            return state
    }
}

export default ClientReducer