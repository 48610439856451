import React, { useState, useRef, useEffect } from 'react'
import classes from './TwoFactorVerificationForm.module.scss'

const TwoFactorVerificationForm = ({ authCode, onChangeText, verifyCode }) => {
	const [isFocused, setIsFocused] = useState(false)
	const textInputRef = useRef()

	useEffect(() => {
		console.log('code is: ', authCode)
		if (authCode.length === 6) verifyCode()
		return null
	}, [authCode])

	useEffect(() => {
		setIsFocused(true)
		if (!textInputRef.current) return
		textInputRef.current.focus()
	}, [])

	const handleClick = () => {
		setIsFocused(true)
		if (!textInputRef.current) return
		textInputRef.current.focus()
	}

	const renderDigits = () => {
		const digits = new Array(6).fill(0)
		return digits.map((digit, index) => {
			const value = authCode[index] || ' '
			return (
				<div className={isFocused && index === authCode.length ? classes.digit_container_highlighted : classes.digit_container} key={index}>
					<div className={classes.digit_input}>{value}</div>
				</div>
			)
		})
	}

	const handleOnChange = (e) => {
		if (e.target.value.length > 6) return
		onChangeText(e.target.value)
	}

	return (
		<div className={classes.wrapper}>
			<div onClick={handleClick} className={classes.container} name='token'>
				{renderDigits()}
			</div>
			<input
				ref={textInputRef}
				onChange={(e) => handleOnChange(e)}
				value={authCode}
				className={classes.hidden_code_input}
				type='number'
				maxLength='6'
			/>
		</div>
	)
}

export default TwoFactorVerificationForm
