import React, { useEffect } from 'react'
import classes from './AnswerInput.module.scss'
import { AnswerTextField } from './MuiStyle'
import { InputAdornment, IconButton  } from '@mui/material'
import { UploadFile, UploadTemplate, SubmitAnswer } from '../../../assets/icons/Dashboard/DashboardIcons'
import { useLazyQuery } from '@apollo/client'
import { GET_PRESIGNED_UPLOAD_URL } from '../../../services/apiQuery'
import axios from 'axios'

const AnswerInput = ({ 
  formik,
  templateSubmit,
  answerSubmit, 
}) => {
  const [ getUploadURL ] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)

  useEffect(() => {
    if (Object.entries(formik.values.selectedTemplate).length !== 0) {
      formik.setFieldValue(
        formik.values.inputText = formik.values.inputText + formik.values.selectedTemplate.content
      )
    }
  },[formik.values.selectedTemplate])

  const attachNewFile = async({ target }) => {
    const uploadImageURL = target?.files[0]
    const { GetPresignedUploadUrl } = await callUploadFileQuery(uploadImageURL)
    if (!GetPresignedUploadUrl) return
    const url = GetPresignedUploadUrl.link
    const uploading = await axios.put(url, uploadImageURL, {
      headers: {'Content-Type': uploadImageURL.type}
    })
    if (uploading.status === 200) {
      formik.setFieldValue(formik.values.attachedFiles = [
        ...formik.values.attachedFiles,
        GetPresignedUploadUrl
      ])
    }
  }

  const callUploadFileQuery = async(file) => {
    const { data } = await getUploadURL({
        variables: {
          filename: file?.name,
          folder: 'question'
        }
    })
    return data
  }

  return (
    <div className={classes.answer_input_box}>
      <div className={classes.answer_input_field}>
        <AnswerTextField
          label="YOUR ANSWER"
          id="answer-input"
          variant="filled"
          multiline={true}
          fullWidth={true}
          value={formik.values.inputText}
          onChange={(e) => formik.setFieldValue(formik.values.inputText = e.target.value)}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                {templateSubmit &&
                <IconButton 
                  edge="end"
                  onClick={templateSubmit}
                >
                  <UploadTemplate className={classes.uploadIcons}/>
                </IconButton>
                }
                <IconButton 
                  edge="end"
                  className={classes.upload_file_box}
                  onChange={attachNewFile}
                >
                  <input 
                    type='file'
                    className={classes.upload_file_box__input}
                    title=''
                    accept="image/*"
                  />
                  <UploadFile className={classes.uploadIcons}/>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.submitBtn_box}>
        <SubmitAnswer className={classes.submitBtn_box__icon} onClick={answerSubmit}/>
      </div>
    </div>
  )
}

export default AnswerInput