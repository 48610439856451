import React, { useState } from 'react'
import classes from './UploadImagesFiles.module.scss'
import { CircularProgress, IconButton } from '@mui/material'
import { DeleteImage, AddImage, VisaForm, AttachFiles } from '../../../assets/icons/Dashboard/DashboardIcons'
import axios from 'axios'
import { TFAAttachFilesButton } from '../../TFAButton/MuiStyle'

const UploadImagesFiles = ({ 
    formik, 
    callgetUploadURL,
    type,
}) => {
    const [ isUploading, setIsUploading ] = useState(false)
    const [ loaded, setLoaded ] = useState(0)
    const [ uploadingFile, setUploadingFile ] = useState('')

    const handleChange = async ({ target }) => {
        const file = target.files[0]
        setUploadingFile(file)
        const uploadImageURL = URL.createObjectURL(target.files[0])
        try {
            setIsUploading(true)
            const { GetPresignedUploadUrl } = await callgetUploadURL(file)
            if (!GetPresignedUploadUrl) return
            const url = GetPresignedUploadUrl.link
            const uploading = await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: e => {
                    setLoaded(parseInt(((e.loaded * 100) / e.total).toFixed()))
                    if (e.loaded === e.total) setIsUploading(false)
                }
            })
            if (uploading.status !== 200) return
            if (type === 'images') {
                formik.setFieldValue(formik.values.uploadedImages = [
                    ...formik.values.uploadedImages, {
                        filename: file.name, 
                        uploadURL: uploadImageURL
                    }
                ])
                formik.setFieldValue(formik.values.images = [
                    ...formik.values.images, {
                    fileName: GetPresignedUploadUrl.filename,
                    key: GetPresignedUploadUrl.key,
                }])
            }
            if (type === 'files') {
                formik.setFieldValue(formik.values.uploadedFiles = [
                    ...formik.values.uploadedFiles, 
                    file,
                ])
                formik.setFieldValue(formik.values.files = [
                    ...formik.values.files, {
                    fileName: GetPresignedUploadUrl.filename,
                    key: GetPresignedUploadUrl.key,
                }])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsUploading(false)
        }
    }

    const deleteImage = (filename) => {
        const filterUploadedImages = formik.values.uploadedImages.filter(i => i.filename !== filename)
        const filterImages = formik.values.images.filter(i => i.fileName !== filename)
        formik.setFieldValue(formik.values.uploadedImages = filterUploadedImages)
        formik.setFieldValue(formik.values.images = filterImages)
    }

    const deleteFile = (file) => {
        const filterUploadedFiles = formik.values.uploadedFiles.filter(f => f.name !== file.name)
        const filterFiles = formik.values.files.filter(f => f.fileName !== file.name)
        formik.setFieldValue(formik.values.uploadedFiles = filterUploadedFiles)
        formik.setFieldValue(formik.values.files = filterFiles)
    }

    const shortenFilename = (filename) => {
        if (filename?.length >= 12) {
            let splitName = filename.split('.')
            filename = splitName[0].substring(0,12) + '... .' + splitName[1]
            return filename
        }
        return filename
    }

    const formatFileType = (type) => {
        const fileType = type.split('/')[1]
        const formattedType = fileType.toUpperCase()
        return formattedType
    }

    return (
        <>
            {type === 'images' &&
            <div className={classes.document_list}>
            {isUploading &&
                <div className={classes.document_list__uploading_box}>
                    <CircularProgress 
                        variant='determinate' 
                        value={loaded} 
                        className={classes.document_list__uploading_box__circular}
                    />
                    <div className={classes.document_list__uploading_box__progress}>
                        <p className={classes.document_list__uploading_box__progress__label}>
                            {loaded}%
                        </p>
                    </div>
                </div>
            }
            {formik.values.uploadedImages &&
                formik.values.uploadedImages.map((image, index) => (
                <div 
                    className={classes.document_list__image_box} 
                    key={index}
                >
                    <img
                        src={image.uploadURL}
                        alt='question_document'
                        className={classes.document_list__image_box__file}
                    />
                    <DeleteImage 
                        className={classes.document_list__image_box__icon}
                        onClick={() => deleteImage(image.filename)}
                    />
                </div>
                ))
            }
                <div className={classes.document_list__add_box}>
                    <IconButton className={classes.document_list__add_box__icon_btn}>
                        <input 
                            type='file'
                            className={classes.document_list__add_box__icon_btn__file_input}
                            onChange={handleChange}
                            disabled={isUploading}
                            accept="image/*, video/*"
                        />
                        <AddImage 
                            className={classes.document_list__add_box__icon_btn__image}
                        />
                    </IconButton>
                </div>
            </div>
            }

            {type === 'files' &&
            <div className={classes.file_list}>
            {isUploading &&
                <div className={classes.progress_box}>
                    <div className={classes.progress_box__row}>
                        <VisaForm className={classes.progress_box__row__icon}/>
                        <div className={classes.progress_box__row__content}>
                            <div className={classes.progress_box__row__content__top}>
                                <span className={classes.progress_box__row__content__detail}>
                                    {shortenFilename(uploadingFile.name)} ・ Uploading
                                </span>
                                <span className={classes.progress_box__row__content__detail}>
                                    {loaded}%
                                </span>
                            </div>
                            <div className={classes.progress_box__row__content__progress}>
                                <div 
                                    className={classes.progress_box__row__content__progress__bar}
                                    style={{width: `${loaded}%`}}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {formik.values.uploadedFiles &&
                formik.values.uploadedFiles.map((file, index) => (
                <div 
                    className={classes.file_box} 
                    key={index}
                >
                    <div className={classes.file_box__title_box}>
                        <VisaForm className={classes.file_box__title_box__icon}/>
                        <div className={classes.file_box__title_box__text_box}>
                            <p className={classes.file_box__title_box__text_box__top}>
                                {file.name}
                            </p>
                            <p className={classes.file_box__title_box__text_box__bottom}>
                                {formatFileType(file.type)}
                            </p>
                        </div>
                    </div>
                    <DeleteImage 
                        className={classes.file_box__delete}
                        onClick={() => deleteFile(file)}
                    />
                </div>
            ))}
                <TFAAttachFilesButton 
                    variant='outlined' 
                    endIcon={<AttachFiles />}
                >
                    <input 
                        type='file'
                        className={classes.attach_file_input}
                        onChange={handleChange}
                        disabled={isUploading}
                    />
                    Attach Files
                </TFAAttachFilesButton>
            </div>
            }
        </>
    )
}

export default UploadImagesFiles