const validatePage = async (values, formik) => {
    Object.keys(values).forEach(value => formik.touched[value] = true)
    
    const errors = await formik.validateForm(values)
    const isPageValid = Object.keys(values).every(key => {
        return !errors.hasOwnProperty(key)
    })
    if(isPageValid) formik.setErrors({})
    return isPageValid
}

export default validatePage