import React, { useEffect, useState } from 'react'
import classes from './JobSearch.module.scss'
import Details from './Sections/Details'
import Modal from './Sections/Modal'
import { QuestionCard, Searchbar, Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import { findClickedQuestion, removeClickedQuestion } from '../../../../utils/sortCards'
import { TFASkeleton, TFASnackBar } from '../../../../components/TFAComponents'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_QUESTION, GET_QUESTION_POOLS } from '../../../../services/apiQuery'

const JobSearch = () => {
  const [ searchText, setSearchText ] = useState('')
  const [ allQuestions, setAllQuestions ] = useState([])
  const [ clickedQuestion, setClickedQuestion ] = useState({})
  const [ clickedPoolId, setClickedPoolId ] = useState('')
  const [ sortedJobs, setSortedJobs ] = useState([])

  const [ isAdviceOpen, setIsAdviceOpen ] = useState(false)
  const [ isOfferOpen, setIsOfferOpen ] = useState(false)
  const handleAdviceOpen = () => setIsAdviceOpen(true)
  const handleAdviceClose = () => setIsAdviceOpen(false)
  const handleOfferOpen = () => setIsOfferOpen(true)
  const handleOfferClose = () => setIsOfferOpen(false)
  const [ isGiveAdviceErrorOpen, setIsGiveAdviceErrorOpen ] = useState(false)
  const [ isErrorbarOpen, setIsErrorOpen ] = useState(false)
  const [ isTakeOneError, setIsTakeOneError ] = useState(false)
  const [ isSuccessbarOpen, setIsSuccessbarOpen ] = useState(false)

  const { loading: questionsLoading, data: questionsData } = useQuery(GET_QUESTION_POOLS, {
    variables: { title: searchText }
  })
  const [ getQuestion ] = useLazyQuery(GET_QUESTION)

  useEffect(() => {
    setSortedJobs([])
    setClickedQuestion({})
  },[searchText])

  useEffect(() => {
    if (questionsData) {
      const allQuestions = questionsData.GetQuestionpools.objectList
      const validQuestions = allQuestions.filter(q => q?.question?.valid === true)
      const sortedValieQuestions = validQuestions.slice().sort((a, b) => b.createdAt - a.createdAt)
      setAllQuestions(sortedValieQuestions)
    }
  },[questionsData])

  const clickJob = async(question) => {
    const { GetQuestion } = await callGetQuestion(question.id)
    if (!GetQuestion) return
    setClickedQuestion(GetQuestion)
    const clickedJob = await findClickedQuestion(GetQuestion, allQuestions)
    const restOfJobs = await removeClickedQuestion(GetQuestion, allQuestions)
    setClickedPoolId(clickedJob.id)
    setSortedJobs([clickedJob, ...restOfJobs])
  }

  const callGetQuestion = async(questionId) => {
    const { data } = await getQuestion({
      variables: {
        getQuestionId: questionId
      }
    })
    return data
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return
    setIsGiveAdviceErrorOpen(false)
    setIsErrorOpen(false)
    setIsTakeOneError(false)
    setIsSuccessbarOpen(false)
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar pageSide='professional' tabName='Job Search' />
      <div className={classes.job_search_box}>
        <div className={classes.searchbar_box}>
          <Searchbar 
            width='51%'
            setSearchText={setSearchText}
          />
        </div>
        <div className={classes.job_scroll_table}>
          {Object.entries(clickedQuestion).length === 0
          ? 
            <div className={classes.card_scroll_table}>
              <div className={classes.job_cards_box}>
                {questionsLoading
                ? <div className={classes.skeleton_container}>
                    <TFASkeleton
                      type='question_card'
                      count={8}
                    />
                  </div> 
                : allQuestions.length === 0
                  ? <div className={classes.empty_job_search_box}>
                    No Jobs to search
                    </div>
                  : allQuestions.map(q => (
                    <QuestionCard 
                      question={q.question}
                      key={q.id}
                      clickQuestionCard={() => clickJob(q.question)}
                      tabName='Job Search'
                    />
                  ))
                }
              </div>
            </div>
          :
            <div className={classes.job_selected_box}>
              <div className={classes.job_selected_box__job_list}>
                {sortedJobs
                ? sortedJobs.map(q => (
                    <QuestionCard 
                      question={q.question}
                      key={q.id}
                      clickQuestionCard={() => clickJob(q.question)}
                      tabName='Job Search'
                      clicked={true}
                    />
                  ))
                : <TFASkeleton 
                    type='question_card'
                    count={8}
                  />
                }
              </div>
              {clickedQuestion
              ? <Details
                clickedQuestion={clickedQuestion}
                setClickedQuestion={setClickedQuestion}
                handleAdviceOpen={handleAdviceOpen}
                handleOfferOpen={handleOfferOpen}
                />
              : <div className={classes.skeleton_detail_container}>
                  <TFASkeleton type='detail' />
                </div>
              
              }
            </div>
          }
        </div>
      </div>
      {clickedQuestion &&
      <Modal 
        poolId={clickedPoolId}
        clickedQuestion={clickedQuestion}
        isAdviceOpen={isAdviceOpen}
        isOfferOpen={isOfferOpen}
        handleAdviceClose={handleAdviceClose}
        handleOfferClose={handleOfferClose}
        setIsGiveAdviceErrorOpen={setIsGiveAdviceErrorOpen}
        setIsErrorOpen={setIsErrorOpen}
        setIsTakeOneError={setIsTakeOneError}
        setIsSuccessbarOpen={setIsSuccessbarOpen}
      />
      }
      <TFASnackBar 
        variant='error'
        isSnackbarOpen={isGiveAdviceErrorOpen}
        handleSnackbarClose={handleSnackbarClose}
        message='Failed to take this question.'
      />
      <TFASnackBar 
        variant='error'
        isSnackbarOpen={isErrorbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message='Failed to send your new offer to the client, Please try again.'
      />
      <TFASnackBar 
        variant='error'
        isSnackbarOpen={isTakeOneError}
        handleSnackbarClose={handleSnackbarClose}
        message='Please answer your existing jobs before picking up another question.'
      />
      <TFASnackBar 
        variant='success'
        isSnackbarOpen={isSuccessbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message='Successfully sent your new offer to the client.'
      />
    </div>
  )
}

export default JobSearch