import { FormControl, styled, TextField } from '@mui/material'

const TFAFormControl = styled(FormControl)(({ them, form_width }) => ({
  width: form_width ?? '100%',
  position: 'relative'
}))

const TFATextInput = styled(TextField)(({ theme, text_field }) => ({
    '& label': {
        color: '#022873',
        fontWeight: 'bold',
    },
    '& label.Mui-focused': {
        color: '#022873',
    },
    '& .MuiInputBase-input': {
        fontFamily: "'Space Grotesk', sans-serif",
        width: text_field ?? '100%', 
        height: '1.1em',
        cursor: 'pointer',
        '&[type=number]': {
          'MozAppearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          'WebkitAppearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          'WebkitAppearance': 'none',
          margin: 0,
        },
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#2F68FA',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '6px',
        borderColor: '#2F68FA',
        borderWidth: '2px'
      },
      '&:hover fieldset': {
        borderColor: '#2F68FA',
        borderWidth: '2px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2F68FA',
        borderWidth: '2px',
      },
    },
}))

const TemplateField = styled(TextField)(({ them }) => ({
  '& .MuiInputBase-input': {
    fontFamily: "'Space Grotesk', sans-serif",
    width: '100%',
    minHeight: '20em',
    fontWeight: '500',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}))

export { TFAFormControl, TFATextInput, TemplateField }