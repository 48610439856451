import React, { useState } from 'react'
import classes from './Modal.module.scss'
import { TFAButton, TFAModal } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useMutation } from '@apollo/client'
import { UPDATE_APPOINTMENT } from '../../../../../services/apiMutation'

const CancelAppointmentModal = ({
  appointmentId,
  isCancelModalOpen,
  setIsCancelModalOpen,
  setIsUpdateAppointmentOpen,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ cancelAppointment ] = useMutation(UPDATE_APPOINTMENT)

  const handleCancelAppointment = async () => {
    try {
      setIsLoading(true)
      const { AppointmentUpdate } = await callCancelAppointment()
      if (!AppointmentUpdate) return
      setIsCancelModalOpen(false)
      setIsUpdateAppointmentOpen(false)
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const callCancelAppointment = async () => {
    const { data } = await cancelAppointment({
      variables: {
        appointment: {
          id: appointmentId,
          progress: "Cancelled",
        }
      }
    })
    return data
  }

  return (
    <TFAModal
      open={isCancelModalOpen}
      handleClose={() => setIsCancelModalOpen(false)}
      width='30em'
    >
      <div className={classes.modal_title_box}>
        <CloseBtn 
          className={classes.modal_title_box__icon}
          onClick={() => setIsCancelModalOpen(false)}
        />
        <p className={classes.modal_title_box__title}>
          Cancel Appointment
        </p>
      </div>
      <p className={classes.modal_desc_box}>
        Are you sure you want to cancel this appointment? You will forfeit your timeslot and not be refunded your full amount.
      </p>
      <div className={classes.cancel_box}>
        <TFAButton 
          text='CANCEL APPOINTMENT'
          onClick={handleCancelAppointment}
          isLoading={isLoading}
          disabled={isLoading}
          style1={classes}
        />
      </div>
    </TFAModal>
  )
}

export default CancelAppointmentModal