import React, { useEffect, useState } from 'react'
import classes from './MultiPages.module.scss'
import { CREATE_APPOINTMENT } from '../../../../../assets/data/SelectOptions'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { PaymentMethodCard, ProfessionalCard, ProgressState } from '../../../../../components/Dashboard/DashboardComponents'
import { TFAButton, TFAText } from '../../../../../components/TFAComponents'
import { IconButton } from '@mui/material'
import { EditIcon } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import PaymentMethodModal from '../Modals/PaymentMethodModal'
import { timeSlotConverter } from '../../../../../utils/appointmentFormatter'
import useClientContext from '../../../../../states/Client/useClientContext'
import { GET_CARD_DETAIL } from '../../../../../services/apiQuery'
import { useLazyQuery } from '@apollo/client'

const Confirm = ({ formik, currentPage, setCurrentPage }) => {
	const { client } = useClientContext().clientState
	const { professional, appointmentDate, title, context } = formik.values
	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
	const paymentModalOpen = () => setIsPaymentModalOpen(true)
	const paymentModalClose = () => setIsPaymentModalOpen(false)
	const [getCards] = useLazyQuery(GET_CARD_DETAIL)

	const getCardDetails = async () => {
		try {
			const cardData = await getCards()

			if (!cardData || cardData.Stripe_ListAllCards.length === 0) return

			formik.setFieldValue((formik.values.allCards = cardData.Stripe_ListAllCards))
			formik.setFieldValue((formik.values.originalDefaultCard = cardData.Stripe_ListAllCards[0]))
			formik.setFieldValue((formik.values.defaultCard = cardData.Stripe_ListAllCards[0]))
		} catch (error) {
			console.log('the cards', error)
		}
	}

	useEffect(() => {
		if (client) {
			formik.setFieldValue((formik.values.paymentPreference = client.paymentPreference))
			formik.setFieldValue((formik.values.email = client.email))
		}
	}, [client])

	return (
		<>
			<div className={classes.backBtn_box} onClick={() => setCurrentPage(CREATE_APPOINTMENT.DETAILS)}>
				<LeftArrow className={classes.backBtn_box__arrow} />
				<p className={classes.backBtn_box__txt}>RETURN</p>
			</div>
			<div className={classes.progress_box}>
				<p className={classes.progress_box__title}>Progress:</p>
				<div className={classes.progress_box__state}>
					<ProgressState stateName='create_question' status={currentPage} variant='list' style={classes} />
				</div>
			</div>
			<div className={classes.main_content_container}>
				<TFAText primary_title='Review Your Appointment' secondary_title='Good to go?' style1={classes} />
			</div>
			<div className={classes.confirm_section_box}>
				<div className={classes.editable_title}>
					<p className={classes.section_title}>Professional</p>
					<IconButton className={classes.section_icon} onClick={() => setCurrentPage(CREATE_APPOINTMENT.PROFESSIONAL)}>
						<EditIcon />
					</IconButton>
				</div>
				<div className={classes.professional_box}>
					<ProfessionalCard professionalDetails={professional} bio={true} />
				</div>
			</div>
			<div className={classes.confirm_section_box}>
				<div className={classes.editable_title}>
					<p className={classes.section_title}>Appointment time</p>
					<IconButton className={classes.section_icon} onClick={() => setCurrentPage(CREATE_APPOINTMENT.TIME)}>
						<EditIcon />
					</IconButton>
				</div>
				<div className={classes.appointment_time_slot_box}>
					<div className={classes.time_slot_box}>
						<p className={classes.time_slot_box__title}>Time Slot:</p>
						<p className={classes.time_slot_box__time}>{timeSlotConverter(appointmentDate, 'time')}</p>
						<p className={classes.time_slot_box__date}>{timeSlotConverter(appointmentDate, 'date')}</p>
					</div>
				</div>
			</div>
			<div className={classes.confirm_section_box}>
				<div className={classes.editable_title}>
					<p className={classes.section_title}>Appointment Details</p>
					<IconButton className={classes.section_icon} onClick={() => setCurrentPage(CREATE_APPOINTMENT.DETAILS)}>
						<EditIcon />
					</IconButton>
				</div>
				<div className={classes.appointment_detail_container}>
					<div className={classes.appointment_detail_box}>
						<div className={classes.appointment_detail_box__title}>Appointment Title:</div>
						<div className={classes.appointment_detail_box__content}>{title}</div>
					</div>
					<div className={classes.appointment_detail_box}>
						<div className={classes.appointment_detail_box__title}>Appointment Context:</div>
						<div className={classes.appointment_detail_box__content}>{context}</div>
					</div>
				</div>
				<div className={classes.title_box}>
					<p className={classes.title_box__primary}>Appointment Price</p>
					<p className={classes.title_box__secondary}>
						We charge a flat appointment rate for our appointments. Select how you would like to pay for your appointment.
					</p>
					<div className={classes.appointment_price_box}>
						<p className={classes.appointment_price_box__title}>Price:</p>
						<p className={classes.appointment_price_box__price}>&#36;{parseFloat(professional.askingPrice / 100).toFixed(2)}</p>
					</div>
					<div className={classes.confirm_section_box}>
						<PaymentMethodCard formik={formik} handleClick={paymentModalOpen} isPaymentSelected={true} method={formik.values.paymentPreference} />
					</div>
				</div>
				<div className={classes.btn_box}>
					<TFAButton
						text='CONFIRM &amp; PAY'
						onClick={formik.handleSubmit}
						isLoading={formik.isSubmitting}
						disabled={formik.isSubmitting}
						style1={classes}
					/>
				</div>
				<PaymentMethodModal
					formik={formik}
					onRefresh={getCardDetails}
					isPaymentModalOpen={isPaymentModalOpen}
					paymentModalClose={paymentModalClose}
				/>
			</div>
		</>
	)
}

export default Confirm
