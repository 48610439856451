import React, { useState } from 'react'
import classes from './MultiPageStyle.module.scss'
import { LeftArrow, TnCInfo } from '../../../../../assets/icons/Auth/AuthIcons'
import { TFAInputText, TFAAccordion, TFAButton } from '../../../../../components/TFAComponents'
import validatePage from '../../../../../utils/validatePage'
import { TFAFormControlLabel, TFACheckbox } from '../../../../../components/Register/TimePicker/MuiStyle'
import { COMPLETE_SIGNUP_PAGES } from '../../../../../assets/data/SelectOptions'

const CreatePassword = ({ formik, setCurrentPage }) => {
  const [ showPassword, setShowPassword ] = useState(false)
  const [ isClicked, setIsClicked ] = useState(false)
  
  const handleSubmit = async () => {
    const { password, confirmNewPassword } = formik.values
    const isPageValid = await validatePage({ password, confirmNewPassword }, formik)
    if(!isPageValid) return
    formik.handleSubmit()
  }

  const openTnC = () => {
    window.open(
      `https://www.timeforadvice.com/terms/`,
      '_blank'
    )
  }

  return (
    <div>
      <div 
          className={classes.backBtn_box} 
          onClick={() => setCurrentPage(COMPLETE_SIGNUP_PAGES.ADDRESS_PAYMENT)}
      >
          <LeftArrow className={classes.backBtn_box__arrow}/>
          <p className={classes.backBtn_box__txt}>RETURN TO ADDRESS AND PAYMENT</p>
      </div>
      <div className={classes.section_title_box}>
        <p className={classes.section_title_box__main}>
          Password
        </p>
        <p className={classes.section_title_box__desc}>
          Create a strong password that will protect your Time For Advice account.
        </p>
      </div>
      <div>
      <TFAInputText
        label='PASSWORD'
        type='password'
        name='password'
        form_width='100%'
        showPassword={showPassword}
        visiblePassword={setShowPassword}
        formik={formik}
        error={formik?.errors?.password ?? null}
      />
      <TFAInputText
        label='CONFIRM PASSWORD'
        type='password'
        name='confirmNewPassword'
        form_width='100%'
        showPassword={showPassword}
        visiblePassword={setShowPassword}
        formik={formik}
        error={formik?.errors?.confirmNewPassword ?? null}
      />
      </div>
      <div className={classes.passward_suggestion_box}>
        <TFAAccordion title='Our Password Advice'>
          <ul className={classes.bullet_points}>
              <li>Minimum length of 8 characters.</li>
              <li>At least 1 number.</li>
              <li>At least 1 uppercase and lowercase letter.</li>
          </ul>
        </TFAAccordion>
      </div>
      <div className={classes.tnc_box}>
        <TFAFormControlLabel
          label="I AGREE TO THE T&amp;C'S"
          className={classes.title_checkbox}
          control={
            <TFACheckbox
              onChange={() => setIsClicked(!isClicked)}
            />
          }
        />
        <TnCInfo 
          className={classes.tnc_icon}
          onClick={openTnC}
        />
      </div>
      <div className={classes.btn_box}>
        <TFAButton
          text='NEXT'
          onClick={handleSubmit}
          isLoading={formik.isSubmitting}
          disabled={!isClicked ?? formik.isSubmitting}
          style1={classes}
        />
      </div>
    </div>
  )
}

export default CreatePassword