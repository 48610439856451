import React, { useState } from 'react'
import classes from './UploadIdentityInput.module.scss'
import { CompleteUpload, FileDelete, FileDocument, UploadFileClip } from '../../../assets/icons/Auth/AuthIcons'
import axios from 'axios'
import { Alert } from '@mui/material'

const UploadIdentityInput = ({ formik, frontError, backError}) => {
    const [ isUploading, setIsUploading ] = useState(false)

    const uploadIdentityImages = async (e, type) => {
        const file = e.target.files[0]
        if (e.target.files.length === 0) return
        try {
            setIsUploading(true)
            const { id } = await uploadIdToStripe(file)
            if (!id) return
            if (type === 'front') {
                formik.setFieldValue(formik.values.frontId = id)
                formik.setFieldValue(formik.values.frontIdName = file.name)
            } else {
                formik.setFieldValue(formik.values.backId = id)
                formik.setFieldValue(formik.values.backIdName = file.name)
            }
        } catch (error) {
            console.log(error.response)
        } finally {
            setIsUploading(false)
        }
    }

    const uploadIdToStripe = async (file) => {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("purpose", 'identity_document')
        const { data } =  await axios.post('https://files.stripe.com/v1/files', formData, {
            headers: {
                Authorization: 'Bearer ' + 'pk_test_a2cvNAKecW53nc2tGV1yJD2d006q7CEjHR'
            }
        })
        return data
    }

    const deleteFile = (type) => {
        if (type === 'front') {
            formik.setFieldValue(formik.values.frontIdName = '')
            formik.setFieldValue(formik.values.frontId = '')
        } else {
            formik.setFieldValue(formik.values.backIdName = '')
            formik.setFieldValue(formik.values.backId = '')
        }
    }

    const shortenFilename = (filename) => {
        if (filename.length >= 12) {
            let splitName = filename.split('.')
            filename = splitName[0].substring(0,12) + '... .' + splitName[1]
            return filename
        }
        return filename
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.form_box}>
                <input 
                    type='file' 
                    className={classes.file_input} 
                    onChange={(e) => uploadIdentityImages(e, 'front')} 
                    disabled={isUploading}
                    accept='application/pdf, image/jpeg, image/png'
                />
                <UploadFileClip className={classes.form_box__upload_icon}/>
                <p className={classes.form_box__upload_text}>
                    FRONT IDENTITY IMAGE
                </p>
            </div>
            {formik.values.frontIdName &&
            <div className={classes.uploaded_box}>
                <div className={classes.uploaded_box__progress_content}>
                    <FileDocument className={classes.document_icon}/>
                    <div className={classes.uploaded_box__progress_content__details}>
                        <span className={classes.uploaded_box__progress_content__details__name}>
                            {shortenFilename(formik.values.frontIdName)} ・ Uploaded
                        </span>
                    </div>
                </div>
                <CompleteUpload className={classes.complete_icon}/>
                <FileDelete 
                    className={classes.delete_file} 
                    onClick={() => deleteFile('front')}
                />
            </div>
            }
            {formik.touched['frontId'] && frontError && 
                <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {frontError}
                </Alert>
            }

            <div className={classes.form_box}>
                <input 
                    type='file' 
                    className={classes.file_input} 
                    onChange={(e) => uploadIdentityImages(e, 'back')} 
                    disabled={isUploading}
                    accept='application/pdf, image/jpeg, image/png'
                />
                <UploadFileClip className={classes.form_box__upload_icon}/>
                <p className={classes.form_box__upload_text}>
                    BACK IDENTITY IMAGE
                </p>
            </div>
            {formik.values.backIdName &&
            <div className={classes.uploaded_box}>
                <div className={classes.uploaded_box__progress_content}>
                    <FileDocument className={classes.document_icon}/>
                    <div className={classes.uploaded_box__progress_content__details}>
                        <span className={classes.uploaded_box__progress_content__details__name}>
                            {shortenFilename(formik.values.backIdName)} ・ Uploaded
                        </span>
                    </div>
                </div>
                <CompleteUpload className={classes.complete_icon}/>
                <FileDelete 
                    className={classes.delete_file} 
                    onClick={() => deleteFile('back')}
                />
            </div>
            }
            {formik.touched['backId'] && backError && 
                <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {backError}
                </Alert>
            }
        </div>
    )
}

export default UploadIdentityInput