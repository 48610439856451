import React, { useState } from 'react'
import classes from './CProfile.module.scss'
import Account from './Sections/Account'
import AboutYou from './Sections/AboutYou'
import Payment from './Sections/Payment'
import Languages from './Sections/Languages'
import Security from './Sections/Security'
import Report from './Sections/Report'
import TransactionHistory from './Sections/TransactionHistory'
import SavedModal from './Modals/SavedModal'
import PasswordModal from './Modals/PasswordModal'
import ReportModal from './Modals/ReportModal'
import { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import { PROFILE_SETTTING_PAGE } from '../../../../assets/data/SelectOptions'

const CProfile = () => {
  const [ clickedPage, setClickedPage ] = useState(PROFILE_SETTTING_PAGE.ABOUT_YOU)
  const [ savedModalOpen, setSavedModalOpen ] = useState(false)
  const [ passwordModalOpen, setPasswordModalOpen ] = useState(false)
  const [ reportModalOpen, setReportModalOpen ] = useState(false)

  const switchPages = (clickedPage) => {
    switch (clickedPage) {
      case PROFILE_SETTTING_PAGE.ABOUT_YOU:   return <AboutYou setSavedModalOpen={setSavedModalOpen}/>
      case PROFILE_SETTTING_PAGE.PAYMENT:     return <Payment setSavedModalOpen={setSavedModalOpen}/>
      case PROFILE_SETTTING_PAGE.LANGUAGES:   return <Languages setSavedModalOpen={setSavedModalOpen}/>
      case PROFILE_SETTTING_PAGE.HISTORY:     return <TransactionHistory />
      default: break;
    }
  }
  
  return (
    <div className={classes.wrapper}>
      {clickedPage === PROFILE_SETTTING_PAGE.SECURITY &&
        <Security 
          setClickedPage={setClickedPage}
          setPasswordModalOpen={setPasswordModalOpen}
        />
      }
      {clickedPage === PROFILE_SETTTING_PAGE.REPORT &&
        <Report 
          setClickedPage={setClickedPage}
          setReportModalOpen={setReportModalOpen}
        />
      }
      {(clickedPage !== PROFILE_SETTTING_PAGE.SECURITY &&
      clickedPage !== PROFILE_SETTTING_PAGE.REPORT) &&
      <>
        <Sidebar pageSide='client' tabName='Profile' />
        <div className={classes.main_content_box}>
          <Account 
            clickedPage={clickedPage}
            setClickedPage={setClickedPage}
          />
          {switchPages(clickedPage)}
        </div>
      </>
      }
      <SavedModal
        savedModalOpen={savedModalOpen}
        setSavedModalOpen={setSavedModalOpen}
      />
      <PasswordModal 
        passwordModalOpen={passwordModalOpen}
        setPasswordModalOpen={setPasswordModalOpen}
      />
      <ReportModal 
        reportModalOpen={reportModalOpen}
        setReportModalOpen={setReportModalOpen}
      />
    </div>
  )
}

export default CProfile