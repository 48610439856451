import React from 'react'
import { TFAFormControl, TFATextInput } from '../TFAInputText/MuiStyle'
import Alert from '@mui/material/Alert'

const TFADateInputField = ({ type, formik, required }) => {
    const day = () => {
        switch (type) {
            case 'dob':         return 'day'
            case 'marn_expiry': return 'expiryDay'
            case 'lpn_expiry':  return 'lpnExpiryDay'
            default:            return ''
        }
    }
    const month = () => {
        switch (type) {
            case 'dob':         return 'month'
            case 'marn_expiry': return 'expiryMonth'
            case 'lpn_expiry':  return 'lpnExpiryMonth'
            default:            return ''
        }
    }
    const year = () => {
        switch (type) {
            case 'dob':         return 'year'
            case 'marn_expiry': return 'expiryYear'
            case 'lpn_expiry':  return 'lpnExpiryYear'
            default:            return ''
        }
    }

    return (
        <>
            <TFAFormControl 
                sx={{margin: '0.5em 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
            >
                <TFATextInput
                    sx={{ width: '32%' }}
                    label='DAY'
                    type='number'
                    placeholder='01'
                    required={required}
                    name={day()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e) => e.target.blur()}
                    value={formik.values[day()] ?? ''}
                    InputProps={{inputProps: {max: 31}}}
                />
                <TFATextInput 
                    sx={{ width: '32%' }}
                    label='MONTH'
                    type='number'
                    placeholder='01'
                    required={required}
                    name={month()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e) => e.target.blur()}
                    value={formik.values[month()] ?? ''}
                />
                <TFATextInput 
                    sx={{ width: '32%' }}
                    label='YEAR'
                    type='number'
                    placeholder={type === 'dob' ? '1980' : '2025'}
                    required={required}
                    name={year()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e) => e.target.blur()}
                    value={formik.values[year()] ?? ''}
                />
            </TFAFormControl>
            {formik.touched[day()] && formik.errors[day()] &&
            <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {formik.errors[day()]}
            </Alert>
            }
            {formik.touched[month()] && formik.errors[month()] &&
            <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {formik.errors[month()]}
            </Alert>
            }
            {formik.touched[year()] && formik.errors[year()] &&
            <Alert severity='error' sx={{padding: '0 1em', marginTop: '0.2em'}}>
                {formik.errors[year()]}
            </Alert>
            }
        </>
    )
}

export default TFADateInputField