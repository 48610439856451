import React from 'react'
import classes from './TFAMinScreenSize.module.scss'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'

const TFAMinScreenSize = () => {
    return (
        <div className={classes.container}>
            <div className={classes.alert_container}>
                <div className={classes.center_content}>
                    <ZoomOutMapIcon className={classes.zoom_out_icon}/>
                    <div className={classes.zoom_out_title}>
                        Use Time For Advice on Desktop, Laptop and Tablets!
                    </div>
                    <div className={classes.zoom_out_desc}>
                        To use on this phone please turn your phone sideways to landscape view. For the best user experience please use on a device with a larger screen
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TFAMinScreenSize