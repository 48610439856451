import React from 'react'
import classes from './TFAPhoneInput.module.scss'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Alert } from '@mui/material'

const TFAPhoneInput = ({ formik, name, error, bg, prefix }) => {
	return (
		<>
			<PhoneInput
				country={'au'}
				preferredCountries={['au']}
				placeholder='PHONE NUMBER'
				value={formik.values.phoneNumber}
				enableSearch={true}
				autoFormat={false}
				onChange={(value, country) => {
					formik.setFieldValue((formik.values.phoneNumber = value))
					formik.setFieldValue((formik.values.phoneCountry = country))
				}}
				containerClass={classes.container}
				inputClass={classes.input_container}
				buttonClass={bg ? classes.bg_btn_container : classes.btn_container}
				dropdownClass={classes.dropdown_container}
				searchClass={classes.search_container}
				inputStyle={{ background: bg }}
				buttonStyle={{ background: bg }}
				prefix={prefix}
			/>
			{formik.touched[name] && error && (
				<Alert severity='error' sx={{ padding: '0 1em', marginTop: '0.2em' }}>
					{error}
				</Alert>
			)}
		</>
	)
}

export default TFAPhoneInput
