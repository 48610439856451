import React, { useEffect, useState } from 'react'
import classes from '../CAppointments.module.scss'
import useClientContext from '../../../../../states/Client/useClientContext'
import { AppointmentCard, DashboardSubTitle, DashboardTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { useMutation, useQuery } from '@apollo/client'
import { GET_APPOINTMENTS, GET_CARD_DETAIL } from '../../../../../services/apiQuery'
import { AddAppointment, QuestionBox } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useNavigate } from 'react-router-dom'
import { TFASkeleton } from '../../../../../components/TFAComponents'
import { UPDATE_APPOINTMENT } from '../../../../../services/apiMutation'
import moment from 'moment'

const Appointment = ({ appointmentId, setAppointmentId }) => {
	const { client } = useClientContext().clientState
	const [activeMeetings, setActiveMeetings] = useState([])
	const [completedMeetings, setCompletedMeetings] = useState([])
	const navigate = useNavigate()
	const { loading: cardLoading, data: cardData } = useQuery(GET_CARD_DETAIL)
	const { loading: activeMeetingLoading, data: activeMeetingData } = useQuery(GET_APPOINTMENTS, {
		variables: {
			clientId: client?.id,
			progress: ['Sent', 'Meeting'],
		},
	})
	const { loading: completedMeetingLoading, data: completedMeetingData } = useQuery(GET_APPOINTMENTS, {
		variables: {
			clientId: client?.id,
			progress: ['Cancelled', 'Complete'],
		},
	})
	const [updateAppointment] = useMutation(UPDATE_APPOINTMENT)

	useEffect(() => {
		if (activeMeetingData) {
			const allUpcomingMeetings = activeMeetingData.GetAppointments.objectList
			const sortedUpcomingMeetings = allUpcomingMeetings.slice().sort((a, b) => moment(a.timeStart) - moment(b.timeStart))
			setActiveMeetings(sortedUpcomingMeetings)
		}
		completedMeetingData && setCompletedMeetings(completedMeetingData.GetAppointments.objectList)
	}, [activeMeetingData, completedMeetingData])

	const clickActiveAppointment = async (appointment) => {
		try {
			if (appointment.progress === 'Sent') {
				const { AppointmentUpdate } = await callUpdateAppointment(appointment.id)
				AppointmentUpdate === 'success' && setAppointmentId(appointment.id)
			} else {
				setAppointmentId(appointment.id)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const callUpdateAppointment = async (id) => {
		const { data } = await updateAppointment({
			variables: {
				appointment: {
					id,
					progress: 'Meeting',
				},
			},
		})
		return data
	}

	const navigateToCreateAppointment = () => {
		if (cardLoading) return

		if (cardData.Stripe_ListAllCards.length === 0) navigate('/payment')
		else navigate('/create_appointment')
	}

	return (
		<div className={classes.appointment_container}>
			<DashboardTitle title='Appointments' Icon={AddAppointment} iconClick={() => navigateToCreateAppointment()} scale='scale(1.6)' />
			{!activeMeetingLoading && !completedMeetingLoading && activeMeetings.length === 0 && completedMeetings.length === 0 && (
				<div className={classes.no_appointment_box}>
					<div className={classes.no_appointment_box__text}>
						<p className={classes.no_appointment_box__text__title}>No Appointments Scheduled Yet.</p>
						<p className={classes.no_appointment_box__text__desc}>
							Start your advice-seeking journey on Time For Advice by setting up your first appointment.
							<br />
							<br />
							Create an appointment by tapping the Plus icon in the top right corner.
						</p>
					</div>
					<QuestionBox className={classes.no_appointment_box__icon} />
				</div>
			)}
			{(activeMeetings.length > 0 || completedMeetings.length > 0) && (
				<div className={classes.appointment_box}>
					<div className={classes.appointment_box__active}>
						<DashboardSubTitle title='Active:' margnTop={true} />
						{activeMeetingLoading ? (
							<TFASkeleton type='question_card' count={2} />
						) : activeMeetings.length === 0 ? (
							<div className={classes.empty_appointment_box}>No Active Meetings</div>
						) : (
							activeMeetings.map((appointment, index) => (
								<AppointmentCard
									appointment={appointment}
									key={index}
									clickedMeetingId={appointmentId}
									clickAppointmentCard={() => clickActiveAppointment(appointment)}
									page='client'
								/>
							))
						)}
					</div>
					<div className={classes.appointment_box__completed}>
						<DashboardSubTitle title='Complete:' />
						{completedMeetingLoading ? (
							<TFASkeleton type='question_card' count={2} />
						) : completedMeetings.length === 0 ? (
							<div className={classes.empty_appointment_box}>No Active Meetings</div>
						) : (
							completedMeetings.map((appointment, index) => (
								<AppointmentCard
									appointment={appointment}
									key={index}
									clickedMeetingId={appointmentId}
									clickAppointmentCard={() => setAppointmentId(appointment.id)}
									page='client'
								/>
							))
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default Appointment
