const specialties = [
	'Visiting Visas',
	'Family Visas',
	'Skilled Visas',
	'Employer Visas',
	'Business Visas',
	'Tourist Visas',
	'Other Personal Visas',
	'Humanitarian Visas',
	'Bridging Visas',
	'Student Visas',
]

const categories = [
	'Visiting Visas',
	'Family Visas',
	'Skilled Visas',
	'Employer Visas',
	'Business Visas',
	'Tourist Visas',
	'Other Personal Visas',
	'Humanitarian Visas',
	'Bridging Visas',
	'Student Visas',
]

const languages = ['English', 'Mandarin', 'Hindi', 'Spanish', 'French']

const country = ['Australia', 'France', 'New Zealeand', 'United Kingdom', 'United States of America']

const reportTopics = ['Poor Response', 'Not The Expected Answer', 'Un-cooperative Professional', 'Abuse', 'Other']

const timeTable = [
	'00:00',
	'00:30',
	'01:00',
	'01:30',
	'02:00',
	'02:30',
	'03:00',
	'03:30',
	'04:00',
	'04:30',
	'05:00',
	'05:30',
	'06:00',
	'06:30',
	'07:00',
	'07:30',
	'08:00',
	'08:30',
	'09:00',
	'09:30',
	'10:00',
	'10:30',
	'11:00',
	'11:30',
]

const meridiemTable = ['am', 'pm']

const initialWeekObj = [
	{
		day: 'Monday',
		isClicked: false,
		startTime: '00:00',
		startMeridiem: 'am',
		endTime: '00:00',
		endMeridiem: 'pm',
	},
	{
		day: 'Tuesday',
		isClicked: false,
		startTime: '00:00',
		startMeridiem: 'am',
		endTime: '00:00',
		endMeridiem: 'pm',
	},
	{
		day: 'Wednesday',
		isClicked: false,
		startTime: '00:00',
		startMeridiem: 'am',
		endTime: '00:00',
		endMeridiem: 'pm',
	},
	{
		day: 'Thursday',
		isClicked: false,
		startTime: '00:00',
		startMeridiem: 'am',
		endTime: '00:00',
		endMeridiem: 'pm',
	},
	{
		day: 'Friday',
		isClicked: false,
		startTime: '00:00',
		startMeridiem: 'am',
		endTime: '00:00',
		endMeridiem: 'pm',
	},
	{
		day: 'Saturday',
		isClicked: false,
		startTime: '00:00',
		startMeridiem: 'am',
		endTime: '00:00',
		endMeridiem: 'pm',
	},
	{
		day: 'Sunday',
		isClicked: false,
		startTime: '00:00',
		startMeridiem: 'am',
		endTime: '00:00',
		endMeridiem: 'pm',
	},
]

const SELECT_NAMES = {
	START_TIME: 'startTime',
	START_MERIDIEM: 'startMeridiem',
	END_TIME: 'endTime',
	END_MERIDIEM: 'endMeridiem',
}

const RETRIEVE_PASSWORD_PAGES = {
	ACCOUNT_EMAIL: 'account_email',
	VERIFY_CODE: 'verify_code',
	RESET_PASSWORD: 'reset_password',
}

const COMPLETE_SIGNUP_PAGES = {
	ABOUT_YOU: 'about_you',
	AVAILABILITY: 'meeting_availability',
	ADDRESS_PAYMENT: 'address_payment',
	CREATE_PASSWORD: 'create_passward',
}

const SIGNUP_PAGES = {
	VERIFY: 'Verify',
	ABOUT_YOU: 'About You',
	LANGUAGES: 'Languages',
	PAYMENT: 'Payment',
	SECURITY: 'Security',
}

const CREATE_QUESTIONS = {
	DETAILS: 'Details',
	MEDIA: 'Media',
	PRICE: 'Price',
	CONFIRM: 'Confirm',
}

const CREATE_APPOINTMENT = {
	PROFESSIONAL: 'Professional',
	TIME: 'Time',
	DETAILS: 'Details',
	CONFIRM: 'Confirm',
}

const QUESTION_LOG_STATE = {
	YOUR_ANSWER: {
		title: 'Your Answer',
		body: 'Are you happy with the answer you received? Appect the answer to close this question or ask for more information.',
		options: ['Accept Answer', 'Reply'],
		state: '',
	},
	ANSWER_ACCEPTED1: {
		title: 'Answer Accepted',
		body: 'Hurray! Great to hear your question was answered successfully.',
		options: [],
		state: 'Answer Accepted',
	},
	CLARIFICATION_NEEDED: {
		title: 'Clarification Needed',
		body: 'You have asked your professional for some clarifacation. They will. get back to you within 24hrs.',
		options: [],
		state: 'Clarification Needed',
	},
	REPLY_RECIEVED: {
		title: 'Reply Recieved',
		body: 'Are you happy with the reply you received? Accept the reply to close this question.',
		options: ['Accept Answer', 'More Questions?'],
		state: '',
	},
	ANSWER_ACCEPTED2: {
		title: 'Answer Accepted',
		body: 'Hurray! Great to hear your questions were answered successfully.',
		options: [],
		state: 'Answer Accepted',
	},
}

const PROFILE_SETTTING_PAGE = {
	ABOUT_YOU: 'About You',
	PAYMENT: 'Payment',
	LANGUAGES: 'Languages',
	SECURITY: 'Security',
	REPORT: 'Report',
	HISTORY: 'Transaction History',
}

const profileSettingTabs = ['About You', 'Payment', 'Languages', 'Security', 'Report', 'Transaction History']

export {
	specialties,
	categories,
	languages,
	country,
	reportTopics,
	timeTable,
	meridiemTable,
	initialWeekObj,
	SELECT_NAMES,
	RETRIEVE_PASSWORD_PAGES,
	COMPLETE_SIGNUP_PAGES,
	SIGNUP_PAGES,
	CREATE_QUESTIONS,
	CREATE_APPOINTMENT,
	QUESTION_LOG_STATE,
	PROFILE_SETTTING_PAGE,
	profileSettingTabs,
}
