import React from 'react'
import classes from './QuestionCard.module.scss'
import { ProgressState, CategoryState } from '../DashboardComponents'
import { CardArrow } from '../../../assets/icons/Dashboard/DashboardIcons'
import { completeQuestionTimeConverter, expiryDateConverter, remainingTimeConverter } from '../../../utils/finishTimeConverter'

const QuestionCard = ({ question, questionId, clickQuestionCard, tabName, clicked }) => {
	const getStatus = (progress) => {
		switch (progress) {
			case 'Posted':
				return 'New!'
			case 'Pick Up':
				return 'Waiting'
			case 'Working':
				return 'Awaiting Reply'
			case 'Answered':
				return 'Answered'
			default:
				break
		}
	}

	const getStatusStyle = (progress) => {
		switch (progress) {
			case 'Posted':
				return `${classes.status_green}`
			case 'Pick Up':
				return `${classes.status_yellow}`
			case 'Working':
				return `${classes.status_red}`
			case 'Answered':
				return `${classes.status_green}`
			default:
				break
		}
	}

	return (
		<>
			{tabName === 'Working Area' && question && (
				<div className={questionId === question.id ? classes.clicked_container : classes.card_container} onClick={clickQuestionCard}>
					<div>
						<p className={classes.question_txt}>
							Question:
							<span className={classes.price_txt}>&#36;{parseFloat(question.price / 100).toFixed(2)}</span>
						</p>
						<p className={classes.question_title}>{question.title}</p>
						<div className={classes.expiry_box}>
							<ProgressState stateName='question' status={question.progress === 'Pick_Up' ? 'Pick Up' : question.progress} variant='card' />
							<div className={classes.expiry_box__expiry_txt}>Expiry・{expiryDateConverter(question.finishTime)}</div>
						</div>
					</div>
					<div className={classes.remaining_time_arrrow_box}>
						{question.progress === 'Complete' ? (
							<></>
						) : (
							<div className={classes.remaing_time_box}>{remainingTimeConverter(question.finishTime)}</div>
						)}
						<div className={classes.arrow_box}>
							<CardArrow className={classes.arrow_icon} />
						</div>
					</div>
				</div>
			)}
			{tabName === 'Job Search' && (
				<div className={clicked ? `${classes.clicked_job_container}` : `${classes.search_job_container} `} onClick={clickQuestionCard}>
					<div className={classes.content_box}>
						<p className={classes.question_txt}>
							Question:
							<span className={classes.price_txt}>&#36;{parseFloat(question.price / 100).toFixed(2)}</span>
						</p>
						<p className={classes.question_title}>{question.title}</p>
						<div className={classes.category_box}>
							<CategoryState destinationCountry={question.destinationCountry} category={question.category} />
						</div>
					</div>
					<div className={classes.arrow_box}>
						<CardArrow />
					</div>
				</div>
			)}

			{tabName === 'My Questions' && (
				<div className={questionId === question.id ? classes.clicked_container : classes.card_container} onClick={clickQuestionCard}>
					<div className={classes.card_main_box}>
						<div className={classes.question_price_box}>
							<p className={classes.question_price_box__title_box}>
								Question:
								<span className={question.progress === 'Complete' ? classes.price_txt_complete : classes.price_txt}>
									&#36;{parseFloat(question.price / 100).toFixed(2)}
								</span>
							</p>
							<div className={getStatusStyle(question.progress)}>{getStatus(question.progress)}</div>
						</div>
						<p className={classes.question_title}>{question.title}</p>
						<div className={classes.expiry_box}>
							<ProgressState stateName='question' status={question.progress} variant='card' />
							<div className={classes.expiry_box__expiry_txt}>
								{question.progress === 'Complete'
									? `Complete・${completeQuestionTimeConverter()}`
									: `Expiry・${expiryDateConverter(question.finishTime)}`}
							</div>
						</div>
					</div>
					<div className={classes.arrow_box}>
						<CardArrow />
					</div>
				</div>
			)}
		</>
	)
}

export default QuestionCard
