import React, { useEffect, useState } from 'react'
import classes from '../JobSearch.module.scss'
import {
    TFAButton,
    TFAInputText,
    TFAModal,
    TFASimpleInput,
    TFAText,
} from '../../../../../components/TFAComponents'
import { JobSearch, ProposeNewOffer } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useNavigate } from 'react-router-dom'
import { FormControl, InputAdornment } from '@mui/material'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/client'
import { CREATE_QUESTION_OFFER, GIVE_QUESTION_ADVICE } from '../../../../../services/apiMutation'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'

const Modal = ({
    poolId,
    clickedQuestion,
    isAdviceOpen,
    isOfferOpen,
    handleAdviceClose,
    handleOfferClose,
    setIsGiveAdviceErrorOpen,
    setIsErrorOpen,
    setIsTakeOneError,
    setIsSuccessbarOpen,
}) => {
    const { professional } = useProfessionalContext().professionalState
    const [ isLoading, setIsLoading ] = useState(false)
    const navigate = useNavigate()
    const [ giveQuestionAdvice ] = useMutation(GIVE_QUESTION_ADVICE)
    const [ createQuestionOffer ] = useMutation(CREATE_QUESTION_OFFER)

    const formik = useFormik({
        initialValues: { message: '', price: 0 },
        onSubmit: async(values) => {
            try {
                formik.setSubmitting(true)
                if ((parseInt(values.price * 100) <= clickedQuestion.price) ||
                    ((parseInt(values.price * 100) - clickedQuestion.price) < 100)) {
                    formik.setFieldError('price', `Asking price must be greater than $${parseFloat(clickedQuestion.price / 100).toFixed(2)}` )
                    return
                }
                const { QuestionofferCreate } = await callCreateQuestionOffer(values)
                if (QuestionofferCreate) {
                    setIsSuccessbarOpen(true)
                    handleOfferClose()
                }
            } catch (error) {
                const serviceMessage = error?.message?.split(':')[0]
                if (serviceMessage === 'service') {
                    setIsTakeOneError(true)
                } else {
                    setIsErrorOpen(true)
                }
            } finally {
                formik.setSubmitting(false)
            }
        }
    })

    const takeQuestion = async() => {
        try {
            setIsLoading(true)
            const { QuestionofferGiveAdvice } = await callUpdateQuestion(poolId)
            if (!QuestionofferGiveAdvice) return
            navigate('/p_dashboard/working_area')
            window.location.reload(true)
        } catch (error) {
            const serviceMessage = error?.message?.split(':')[0]
            if (serviceMessage === 'service') {
                setIsTakeOneError(true)
            } else {
                setIsGiveAdviceErrorOpen(true)
            }
        } finally {
            setIsLoading(false)
            handleAdviceClose()
        }
    }

    const callUpdateQuestion = async(poolId) => {
        const { data } = await giveQuestionAdvice({
            variables: {
                questionpoolId: poolId,
            }
        })
        return data
    }

    const callCreateQuestionOffer = async(values) => {
        const { data } = await createQuestionOffer({
            variables: {
                questionoffer: {
                    professionalId: professional?.id,
                    questionId: clickedQuestion?.id,
                    price: parseInt(values.price * 100),
                    message: values.message,
                    status: 'pending',
                }
            }
        })
        return data
    }

    const calculateReceivingPrice = (price) => {
        const float = parseFloat(price)
        if (isNaN(float)) return '--'
        if (typeof float === 'number') {
            const receivePrice = float * 0.75
            return parseFloat(receivePrice).toFixed(2)
        } else return '--'
    }

    useEffect(() => {
        formik.setFieldValue(formik.values.price = parseFloat(clickedQuestion.price / 100).toFixed(2))
    },[clickedQuestion])

    return (
        <>
            <TFAModal open={isAdviceOpen} handleClose={handleAdviceClose} width={'25em'}>
                <JobSearch className={classes.modal_icon}/>
                <div className={classes.modal_text_box}>
                    <TFAText 
                        secondary_title='Give Advice?'
                        description='Your 24-hour timer will start once you confirm your work on this question.'
                        style1={classes}
                    />
                </div>
                <div className={classes.confirm_btn_box}>
                    <TFAButton 
                        text='CONFIRM'
                        isLoading={isLoading}
                        onClick={takeQuestion}
                        style1={classes}
                    />
                </div>
            </TFAModal>
            <TFAModal open={isOfferOpen} handleClose={handleOfferClose} width={'25em'}>
                <ProposeNewOffer className={classes.modal_icon}/>
                <div className={classes.modal_text_box}>
                    <TFAText 
                        secondary_title='Propose New Price'
                        description='If you would like to propose a higher price, enter your proposal below.'
                        style1={classes}
                    />
                </div>
                <FormControl>
                    <TFASimpleInput 
                        box_width='100%'
                        box_margin='0.5em 0'
                        width='100% !important'
                        label='OFFER MESSAGE'
                        maxRows={5}
                        variant='outlined'
                        text_field='100%'
                        name='message'
                        formik={formik}
                        value={formik.values.message}
                    />
                    <TFAInputText
                        label='ASKING PRICE'
                        type='text'
                        name='price'
                        value={formik.values.price}
                        formik={formik}
                        error={formik.errors.price ?? null}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                AUD $
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
                <div className={classes.price_container}>
                    <div className={classes.price_box}>
                        <p className={classes.price_box__receive_text}>
                            Receiving Price:
                        </p>
                        <p className={classes.price_box__receive_price}>
                            &#36;{calculateReceivingPrice(formik.values.price)}
                        </p>
                    </div>
                    <div className={classes.price_box}>
                        <p className={classes.price_box__asking_text}>
                            Asking Price:
                        </p>
                        <p className={classes.price_box__asking_price}>
                            &#36;{formik.values.price}
                        </p>
                    </div>
                    <p className={classes.fee_text}>Transaction fees apply</p>
                </div>
                <div className={classes.confirm_btn_box}>
                    <TFAButton 
                        text='SEND PROPOSAL'
                        isLoading={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                        style1={classes}
                    />
                </div>
            </TFAModal>
        </>
    )
}

export default Modal