import React from 'react'
import classes from '../Kits.module.scss'
import { TFAModal } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'

const KitInfoModal = ({
    isKitInfoModalOpen,
    setIsKitInfoModalOpen,
}) => {
    return (
        <TFAModal
            open={isKitInfoModalOpen}
            handleClose={() => setIsKitInfoModalOpen(false)}
            width='35em'
        >
            <div className={classes.modal_title_box}>
                <CloseBtn 
                    className={classes.modal_title_box__icon}
                    onClick={() => setIsKitInfoModalOpen(false)}
                />
                <p className={classes.modal_title_box__title}>
                    About Kits
                </p>
            </div>
            <div className={classes.about_kit_box}>
                <p className={classes.about_kit_box__title}>
                    About Migration Kits
                </p>
                <p className={classes.about_kit_box__desc}>
                    Experts supply a comprehensive selection of migration kits across many visa categories to assist you with your migration goals. <br /><br /> 
                    Used in conjunction with our question and answer service you should easily be able to prepare and file your own application. <br /><br />
                    All migration kits supplied by experts are examined by our expert panel before being made available for sale by the expert in our Store.
                </p>
            </div>
            <div className={classes.include_kit_box}>
                <p className={classes.include_kit_box__title}>
                    About Migration Kits
                </p>
                <ul className={classes.include_kit_box__list_box}>
                    <li className={classes.include_kit_box__list_box__list}>
                        A general overview of the visa of your choice and key points to consider.
                    </li>
                    <li className={classes.include_kit_box__list_box__list}>
                        Evidence Requirements and Document Check Lists.
                    </li>
                    <li className={classes.include_kit_box__list_box__list}>
                        Step by Step guidelines. Where and how to do things.
                    </li>
                    <li className={classes.include_kit_box__list_box__list}>
                        Step by Step guidelines. Where and how to do things.
                    </li>
                </ul>
            </div>
        </TFAModal>
    )
}

export default KitInfoModal