import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useFormik } from 'formik'
import React, { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SignupWrapper from '../../../components/Register/SignupWrapper'
import TFAStripeInput from '../../../components/Register/TFAStripeInput/TFAStripeInput'
import { TFAButton, TFASnackBar, TFASubText, TFAText } from '../../../components/TFAComponents'
import { clientPaymentSchema } from '../../../utils/validation'
// @ts-ignore
import classes from './Signup/MultiPages/MultiPages.module.scss'
import { useMutation } from '@apollo/client'
import { CREATE_STRIPE_CARD } from '../../../services/apiMutation'
import useClientContext from '../../../states/Client/useClientContext'

function Payment() {
	const { clientState } = useClientContext()
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
	const [createStripeCard] = useMutation(CREATE_STRIPE_CARD)
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)
	const stripe = useStripe()
	const elements = useElements()

	const formik = useFormik({
		initialValues: {
			cardName: '',
		},
		validationSchema: clientPaymentSchema,
		onSubmit: () => {
			addUserPaymentMethod()
		},
	})

	const addUserPaymentMethod = async () => {
		setIsLoading(true)
		try {
			const stripeToken = await createPaymentMethod()

			const { data } = await createStripeCard({
				variables: {
					clientId: clientState.client.id,
					sourceId: stripeToken,
				},
			})
			console.log(data)
			if (!data.Stripe_CreateCard) return
			navigate('/c_dashboard/questions')
		} catch (error) {
			setIsSnackbarOpen(true)
		} finally {
			setIsLoading(false)
		}
	}

	const createPaymentMethod = async (): Promise<string | undefined> => {
		if (!stripe || !elements || !formik.values.cardName) return

		const cardElement = elements.getElement(CardElement)

		if (!cardElement) return undefined

		try {
			const result = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: formik.values.cardName,
				},
			})
			const stripeToken = await stripe.createToken(cardElement)

			console.log(result)
			console.log('token, ', stripeToken)

			return stripeToken.token?.id ?? undefined
		} catch (error) {
			setIsSnackbarOpen(true)
		}
	}

	const handleSnackbarClose = (event: SyntheticEvent<Element, Event>, reason: string) => {
		if (reason === 'clickaway') return
		setIsSnackbarOpen(false)
	}

	return (
		<SignupWrapper page='Payment'>
			<div className={classes.content_box}>
				<TFAText primary_title='Payment' secondary_title='Let’s Create Your Payment Method' style1={classes} />
				<div>
					<TFASubText primary_title='Debit/Credit Card Setup' description='Enter valid debit or credit card details below.' style1={classes} />
					<TFAStripeInput formik={formik} errorMessage={''} setCardDetail={() => null} />
				</div>
				<div className={classes.btn_box}>
					<TFAButton text='COMPLETE' onClick={formik.handleSubmit} style1={classes} isLoading={isLoading} />
				</div>
				<TFASnackBar
					variant='error'
					isSnackbarOpen={isSnackbarOpen}
					handleSnackbarClose={handleSnackbarClose}
					message='Failed to add your payment method, please double check your details.'
				/>
			</div>
		</SignupWrapper>
	)
}

export default Payment
