import React, { useReducer, createContext, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Auth from './pages/Client/Auth/Auth'
import Login from './pages/Client/Auth/Login/Login'
import Signup from './pages/Client/Auth/Signup/Signup.tsx'
import RetrieveClientAccount from './pages/Client/Auth/RetrieveClientAccount/RetrieveClientAccount'
import Questions from './pages/Client/Dashboard/Questions/Questions'
import CAppointments from './pages/Client/Dashboard/Appointments/CAppointments'
import Kits from './pages/Client/Dashboard/Kits/Kits'
import CProfile from './pages/Client/Dashboard/Profile/CProfile'
import CreateQuestion from './pages/Client/Dashboard/CreateQuestion/CreateQuestion'
import CreateAppointment from './pages/Client/Dashboard/CreateAppointment/CreateAppointment'
import ProLogin from './pages/Professional/Auth/Login/ProLogin'
import ProSignup from './pages/Professional/Auth/Signup/ProSignup'
import WorkingArea from './pages/Professional/Dashboard/WorkingArea/WorkingArea'
import JobSearch from './pages/Professional/Dashboard/JobSearch/JobSearch'
import PAppointments from './pages/Professional/Dashboard/Appointments/PAppointments'
import Templates from './pages/Professional/Dashboard/Templates/Templates'
import PProfile from './pages/Professional/Dashboard/Profile/PProfile'
import CompleteAccount from './pages/Professional/Auth/CompleteAccount/CompleteAccount'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ProfessionalReducer from './states/Professional/ProfessionalReducer'
import ClientReducer from './states/Client/ClientReducer'
import { useLazyQuery } from '@apollo/client'
import { GET_PROFESSIONAL, GET_CLIENT } from './services/apiQuery'
import RetrieveProfessionalAccount from './pages/Professional/Auth/RetrieveProfessionalAccount/RetrieveProfessionalAccount'
import { TFAMinScreenSize } from './components/TFAComponents'
import Languages from './pages/Client/Auth/Languages.tsx'
import Payment from './pages/Client/Auth/Payment'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

export const ProfessionalContext = createContext()
export const ClientContext = createContext()
const initialValue = {}

function App() {
	const [professionalState, professionalDispatch] = useReducer(ProfessionalReducer, initialValue)
	const [clientState, clientDispatch] = useReducer(ClientReducer, initialValue)
	const [getProfessional, { loading: professionalLoading }] = useLazyQuery(GET_PROFESSIONAL)
	const [getClient, { loading: clientLoading }] = useLazyQuery(GET_CLIENT)
	const token = localStorage.getItem('TFAToken')
	const tokenType = localStorage.getItem('TFATokenType')

	const getProfessionalData = async () => {
		try {
			const { data } = await getProfessional()
			if (data.GetProfessional) professionalDispatch({ type: 'setProfessional', data: data.GetProfessional })
		} catch (error) {
			console.log(error)
		}
	}

	const getClientData = async () => {
		try {
			const { data } = await getClient()
			if (data.GetClient) clientDispatch({ type: 'setClient', data: data.GetClient })
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (!token) {
			professionalDispatch({ type: 'setProfessional', data: null })
			clientDispatch({ type: 'setClient', data: null })
		}
		if (token && tokenType === 'ProfessionalToken') getProfessionalData()
		if (token && tokenType === 'ClientToken') getClientData()
	}, [])

	const ProfessionalPrivateRoute = ({ children }) => {
		return token && tokenType === 'ProfessionalToken' ? children : <Navigate to='/auth' />
	}

	const ProfessionalRedirectRoute = ({ children }) => {
		return token && tokenType === 'ProfessionalToken' ? <Navigate to='/p_dashboard/working_area' /> : children
	}

	const ClientPrivateRoute = ({ children }) => {
		return token && tokenType === 'ClientToken' ? children : <Navigate to='/auth' />
	}

	const ClientRedirectRoute = ({ children }) => {
		return token && tokenType === 'ClientToken' ? <Navigate to='/c_dashboard/questions' /> : children
	}

	return (
		<Elements stripe={stripePromise}>
			<ProfessionalContext.Provider value={{ professionalState, professionalDispatch }}>
				<ClientContext.Provider value={{ clientState, clientDispatch }}>
					<div className='router_container'>
						{professionalLoading || clientLoading ? (
							''
						) : (
							<Router>
								<Routes>
									<Route path='*' element={<Navigate to='/auth' replace />} />

									<Route
										path='/auth'
										element={
											<ClientRedirectRoute>
												<Auth />
											</ClientRedirectRoute>
										}
									/>
									<Route
										path='/login'
										element={
											<ClientRedirectRoute>
												<Login />
											</ClientRedirectRoute>
										}
									/>
									<Route
										path='/signup'
										element={
											<ClientRedirectRoute>
												<Signup />
											</ClientRedirectRoute>
										}
									/>
									<Route
										path='/retrieve_client_account'
										element={
											<ClientRedirectRoute>
												<RetrieveClientAccount />
											</ClientRedirectRoute>
										}
									/>
									<Route
										path='/languages'
										element={
											<ClientPrivateRoute>
												<Languages />
											</ClientPrivateRoute>
										}
									/>
									<Route
										path='/payment'
										element={
											<ClientPrivateRoute>
												<Payment />
											</ClientPrivateRoute>
										}
									/>
									<Route
										path='/c_dashboard/questions'
										element={
											<ClientPrivateRoute>
												<Questions />
											</ClientPrivateRoute>
										}
									/>
									<Route
										path='/c_dashboard/c_appointments'
										element={
											<ClientPrivateRoute>
												<CAppointments />
											</ClientPrivateRoute>
										}
									/>
									<Route
										path='/c_dashboard/kits'
										element={
											<ClientPrivateRoute>
												<Kits />
											</ClientPrivateRoute>
										}
									/>
									<Route
										path='/c_dashboard/c_profile'
										element={
											<ClientPrivateRoute>
												<CProfile />
											</ClientPrivateRoute>
										}
									/>
									<Route
										path='/create_question'
										element={
											<ClientPrivateRoute>
												<CreateQuestion />
											</ClientPrivateRoute>
										}
									/>
									<Route
										path='/create_appointment'
										element={
											<ClientPrivateRoute>
												<CreateAppointment />
											</ClientPrivateRoute>
										}
									/>

									<Route
										path='/p_login'
										element={
											<ProfessionalRedirectRoute>
												<ProLogin />
											</ProfessionalRedirectRoute>
										}
									/>
									<Route
										path='/p_signup'
										element={
											<ProfessionalRedirectRoute>
												<ProSignup />
											</ProfessionalRedirectRoute>
										}
									/>
									<Route
										path='/create_pro_account'
										element={
											<ProfessionalRedirectRoute>
												<CompleteAccount />
											</ProfessionalRedirectRoute>
										}
									/>
									<Route
										path='/retrieve_professional_account'
										element={
											<ProfessionalRedirectRoute>
												<RetrieveProfessionalAccount />
											</ProfessionalRedirectRoute>
										}
									/>
									<Route
										path='/p_dashboard/working_area'
										element={
											<ProfessionalPrivateRoute>
												<WorkingArea />
											</ProfessionalPrivateRoute>
										}
									/>
									<Route
										path='/p_dashboard/job_search'
										element={
											<ProfessionalPrivateRoute>
												<JobSearch />
											</ProfessionalPrivateRoute>
										}
									/>
									<Route
										path='/p_dashboard/p_appointments'
										element={
											<ProfessionalPrivateRoute>
												<PAppointments />
											</ProfessionalPrivateRoute>
										}
									/>
									<Route
										path='/p_dashboard/templates'
										element={
											<ProfessionalPrivateRoute>
												<Templates />
											</ProfessionalPrivateRoute>
										}
									/>
									<Route
										path='/p_dashboard/p_profile'
										element={
											<ProfessionalPrivateRoute>
												<PProfile />
											</ProfessionalPrivateRoute>
										}
									/>
								</Routes>
							</Router>
						)}
						<TFAMinScreenSize />
					</div>
				</ClientContext.Provider>
			</ProfessionalContext.Provider>
		</Elements>
	)
}

export default App
