import React, { useState} from 'react'
import classes from './Modals.module.scss'
import { TFAButton, TFAModal } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useMutation } from '@apollo/client'
import { DELETE_STRIPE_CARD } from '../../../../../services/apiMutation'

const RemovePaymentMethod = ({
  formik,
  clientId,
  removePaymentModalOpen,
  setRemovePaymentModalOpen,
}) => {

  const [ isLoading, setIsLoading ] = useState(false)
  const [ deleteCard ] = useMutation(DELETE_STRIPE_CARD)

  const handleDeleteCard = async () => {
    try {
      setIsLoading(true)
      const { Stripe_DeleteCard } = await callDeleteCard()
      if (!Stripe_DeleteCard) return
      setRemovePaymentModalOpen(false)
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

const callDeleteCard = async () => {
  const { data } = await deleteCard({
    variables: {
      clientId,
      sourceId: formik.values.defaultCard.id,
    }
  })
  return data
}

  return (
    <TFAModal
      open={removePaymentModalOpen}
      handleClose={() => setRemovePaymentModalOpen(false)}
      width='25em'
    >
      <div className={classes.modal_title_box}>
        <CloseBtn 
          className={classes.modal_title_box__icon}
          onClick={() => setRemovePaymentModalOpen(false)}
        />
        <p className={classes.modal_title_box__title}>
          Payment Method Settings
        </p>
      </div>
      <div className={classes.title_box}>
        <p className={classes.title_box__primary}>
          Remove Payment Method
        </p>
        <p className={classes.title_box__secondary}>
          By removing this method from your account, you will no longer be able to use it to make purchase on Time For Advice.
        </p>
      </div>
      <div className={classes.cancel_box}>
        <TFAButton 
        text='REMOVE METHOD'
        onClick={handleDeleteCard}
        isLoading={isLoading}
        disabled={isLoading}
        style1={classes}
        />
      </div>
    </TFAModal>
  )
}

export default RemovePaymentMethod