import React from 'react'
import classes from '../CProfile.module.scss'
import { CardArrow, LogoutProfile } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { DashboardTitle } from '../../../../../components/Dashboard/DashboardComponents'
import useClientContext from '../../../../../states/Client/useClientContext'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { profileSettingTabs } from '../../../../../assets/data/SelectOptions'

const Account = ({ clickedPage, setClickedPage }) => {
  const { clientState, clientDispatch } = useClientContext()
  const { client } = clientState
  const navigate = useNavigate()

  const clientLogout = () => {
    localStorage.removeItem('TFAToken')
    localStorage.removeItem('TFATokenType')
    clientDispatch({type: 'setClient', data: null})
    navigate('/top')
  }

  return (
    <div className={classes.account_container}>
      <DashboardTitle 
        title='Account'
        Icon={LogoutProfile}
        iconClick={clientLogout}
      />
      <div className={classes.client_box}>
        <Avatar 
          alt='client-avatar'
          src={client?.avatarLink}
          className={classes.avatar}
          sx={{width: '4em', height: '4em'}}
        />
        <div className={classes.client_box__detail}>
          <p className={classes.client_box__detail__name}>
            {client?.firstName}&nbsp;
            {client?.lastName}
          </p>
          <p className={classes.client_box__detail__email}>
            {client?.email}
          </p>
        </div>
      </div>
      <div className={classes.setting_box}>
        <p className={classes.setting_box__title}>
          Settings
        </p>
        {profileSettingTabs.map((tab, index) => (
          <div 
            className={clickedPage === tab
              ? classes.setting_box__card_selected
              : classes.setting_box__card
            } 
            key={index}
            onClick={() => setClickedPage(tab)}
          >
            <p className={classes.setting_box__card__title}>
            {tab}
            </p>
            <div className={classes.setting_box__card__arrow}>
              <CardArrow />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Account