import React from 'react'
import classes from './DetailsProgress.module.scss'
import { ProgressState } from '../DashboardComponents'

const DetailsProgress = ({ questionDetails, page, style }) => {
    const getProgressMessage = (progress) => {
        switch (progress) {
            case 'Pick Up':  return 'Your question is currently waiting to be picked up.'
            case 'Working':  return 'Your professional has 24hrs to answer your question.'
            case 'Answered': return 'Your question has been answered.'
            case 'Complete': return 'This question is completed and now closed.'
            default:         return ''
        }
    }

    return (
        <div className={classes.progress_box}>
            <p className={classes.progress_box__title}>
                Progress:
            </p>
            {page === 'client' &&
            <p className={classes.progress_box__progress_msg}>
                {getProgressMessage(questionDetails.progress)}
            </p>
            }
            <div className={classes.progress_box__progress}>
                <ProgressState 
                    stateName='question'
                    status={questionDetails.progress}
                    variant='list'
                    style={style && classes}
                />
            </div>
        </div>
    )
}

export default DetailsProgress