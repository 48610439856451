import React, { useState } from 'react'
import classes from './Kits.module.scss'
import { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import AdviceKits from './Sections/AdviceKits'
import KitDetails from './Sections/KitDetails'

const Kits = () => {
  const [ purchasedKitId, setPurchasedKitId ] = useState('')
  const [ kitId, setKitId ] = useState('')
  
  return (
    <div className={classes.wrapper}>
      <Sidebar pageSide='client' tabName='Kits' />
      <div className={classes.main_content_box}>
        <AdviceKits 
          purchasedKitId={purchasedKitId}
          kitId={kitId}
          setPurchasedKitId={setPurchasedKitId}
          setKitId={setKitId}
        />
        <KitDetails 
          purchasedKitId={purchasedKitId}
          setPurchasedKitId={setPurchasedKitId}
        />
      </div>
    </div>
  )
}

export default Kits