import moment from 'moment'

const weekAvailabilityFormatter = (availability) => {
    const days = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]
    const newAvailabilityObj = availability.map(day => {
        const availableDay = moment(day.startTime).format('dddd')
        const startTime = moment(day.startTime).format('LT').split(' ')[0]
        const startMeridiem = moment(day.startTime).format("LT").split(' ')[1].toLowerCase()
        const endTime = moment(day.endTime).format("LT").split(' ')[0]
        const endMeridiem = moment(day.endTime).format("LT").split(' ')[1].toLowerCase()
        const midTimeConverter = (time) => {
            const newTime = time.split(':')[0]
            if (newTime === '12') {
                return `00:${time.split(':')[1]}`
            } else if (newTime < 10) {
                return `0${newTime}:${time.split(':')[1]}`
            } else {
                return time
            }
        }
        return {
            day:       availableDay,
            isClicked: false,
            startTime: midTimeConverter(startTime),
            startMeridiem,
            endTime:   midTimeConverter(endTime),
            endMeridiem,
        }
    })

    let weekAvailability = []
    days.map(day => {
        const availableDays = newAvailabilityObj.map(available => available.day)
        if (availableDays.includes(day)) {
            const existingDay = newAvailabilityObj.find( available => available.day === day)
            weekAvailability.push(existingDay)
        } else {
            const newDay = {
                day,
                isClicked: true,
                startTime: '00:00',
                startMeridiem: 'am',
                endTime: '00:00',
                endMeridiem: 'pm',
            }
            weekAvailability.push(newDay)
        }
    })
    return weekAvailability
}

const dateFormatter = (availability) => {
    const days = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]
    const IndexOfToday = days.indexOf(moment().format('dddd'))
    const formattedDates = availability.map((week, index) => {
        const startHour = week.startMeridiem === 'am' ? week.startTime + ':00' : timeConverter(week.startTime)
        const endHour = week.endMeridiem === 'am' ? week.endTime + ':00' : timeConverter(week.endTime)
        let targetDay
        if (!compareTimes(startHour, endHour)) return 0
        if (week.isClicked === true) return 1
        if (index === IndexOfToday)
            targetDay = moment().format()
        if (index < IndexOfToday)
            targetDay = moment().add(index - IndexOfToday, 'day').format()
        if (index > IndexOfToday)
            targetDay = moment().subtract(IndexOfToday - index, 'day').format()
        const startTime = replaceTime(targetDay, startHour)
        const endTime = replaceTime(targetDay, endHour)
        return {startTime, endTime}
    })
    return formattedDates
}

const timeConverter = (time) => {
    const first = time.slice(0, 2)
    const second = time.slice(2)
    const addition = parseInt(first) + 12
    return addition + second + ':00'
}

const compareTimes = (start, end) => {
    const startTime = start.replace(':', '')
    const endTime = end.replace(':', '')
    const startInt = parseInt(startTime)
    const endInt = parseInt(endTime)
    return startInt < endInt
}

const replaceTime = (formatDate, time) => {
    const date = formatDate.slice(0, 11)
    const timezone = formatDate.slice(19)
    const result = date + time + timezone
    return result
}

const appointmentStateGenerator = (progress, startTime) => {
    const now = moment().local().format()
    const isSameDay = moment(now).isSame(moment(startTime), 'day')
    if (progress === 'Cancelled') return 'Cancelled'
    if (progress === 'Complete') return 'Complete'
    if (isSameDay) return 'Today'
    else return ''
}

const timeSlotConverter = (date, type) => {
    const startTime = moment(date).format('hh:mm')
    const endTime = moment(date).add(30, 'minutes').format('hh:mma')
    const startDay = moment(date).format('YYYY-MM-DD')
    switch (type) {
      case 'time': return `${startTime}-${endTime}`
      case 'date': return startDay
      default: break;
    }
}  

export { 
    weekAvailabilityFormatter,
    dateFormatter, 
    appointmentStateGenerator,
    timeSlotConverter,
}