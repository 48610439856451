import React, { useEffect, useState } from 'react'
import classes from '../WorkingArea.module.scss'
import {
    CardArrow,
    CloseBtn,
    ConfirmAnswer,
} from '../../../../../assets/icons/Dashboard/DashboardIcons'
import {
    TFAModal,
    TFAText,
    TFAButton,
    TFASkeleton,
} from '../../../../../components/TFAComponents'
import { useQuery } from '@apollo/client'
import { GET_TEMPLATES } from '../../../../../services/apiQuery'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'

const Modal = ({
    formik,
    isTemplateOpen,
    isAnswerOpen,
    templateModalClose,
    answerModalClose,
}) => {
    const { professional } = useProfessionalContext().professionalState
    const [ allTemplates, setAllTemplates ] = useState([])
    const [ selectedTemplate, setSelectedTemplate ] = useState({})
    const { loading: templatesLoading, data: templatesData } = useQuery(GET_TEMPLATES, {
        variables: { professionalId: professional?.id}
    })

    useEffect(() => {
        templatesData && setAllTemplates(templatesData.GetTemplates.objectList)
    },[templatesData])
    
    const handleTemaplate = async() => {
        if (Object.entries(selectedTemplate).length === 0) return
        formik.setFieldValue(
            formik.values.selectedTemplate = selectedTemplate
        )
        templateModalClose()
    }

    return (
        <>
            <TFAModal 
                open={isTemplateOpen} 
                handleClose={templateModalClose} 
                width={'25em'}
            >
                <div className={classes.modal_title_box}>
                    <CloseBtn 
                        className={classes.modal_title_box__icon} 
                        onClick={templateModalClose}
                    />
                    <p className={classes.modal_title_box__title}>Answer From Template</p>
                </div>
                <p className={classes.modal_description}>
                    Select the template you wish to answer with.
                </p>
                <div className={classes.modal_template_list}>
                {templatesLoading
                ? <TFASkeleton type='template' />
                : allTemplates?.map((template, index) => (
                    <div 
                        className={selectedTemplate.id === template.id
                            ? `${classes.modal_template_list__box} ${classes.modal_template_list__selected}` 
                            : `${classes.modal_template_list__box}`
                        }
                        onClick={() => setSelectedTemplate(template)}
                        key={index}
                    >
                        <p className={classes.modal_template_list__box__title}>
                            {template.title}
                        </p>
                        <CardArrow 
                            className={classes.modal_template_list__box__arrow}
                        />
                    </div>
                ))
                }
                </div>
                
                <div className={classes.confirm_btn_box}>
                    <TFAButton 
                        text='USE TEMPLATE'
                        onClick={handleTemaplate}
                        style1={classes}
                    />
                </div>
            </TFAModal>
            <TFAModal 
                open={isAnswerOpen} 
                handleClose={answerModalClose} 
                width={'25em'}
            >
                <ConfirmAnswer className={classes.modal_icon}/>
                <div className={classes.modal_text_box}>
                    <TFAText 
                        secondary_title='Confirm Your Answer'
                        description='Confirm that you want to send this answer to the client. Once you send this message it can not be undone.'
                        style2={classes}
                    />
                </div>
                <div className={classes.confirm_btn_box}>
                    <TFAButton 
                        text='CONFIRM'
                        isLoading={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                        style2={classes}
                    />
                    <div onClick={answerModalClose} className={classes.confirm_btn_box__go_back_box}>
                        <p className={classes.confirm_btn_box__go_back_box__txt}>
                            GO BACK
                        </p>
                    </div>
                </div>
            </TFAModal>
        </>
    )
}

export default Modal