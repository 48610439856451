import React, { useState } from 'react'
import classes from '../PProfile.module.scss'
import { CloseBtn, UpdatePassword } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import {
  TFAModal,
  TFAInputText,
  TFAButton,
  TFAText,
} from '../../../../../components/TFAComponents'
import { useFormik } from 'formik'
import { updatePasswordSchema } from '../../../../../utils/validation'
import { UpdatePasswordValues } from '../../../../../assets/data/FormikInitialValues'
import { useMutation } from '@apollo/client'
import { CHECK_CURRENT_PASSWORD, UPDATE_PROFILE } from '../../../../../services/apiMutation'
import useProfessionalContext from '../../../../../states/Professional/useProfessionalContext'

const PasswordModal = ({ isPasswordOpen, passwordModalClose }) => {
  const { professional } = useProfessionalContext().professionalState
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isShowPassword, setIsShowPassword ] = useState(false)
  const [ passwordPage, setPasswordPage ] = useState('current_password')
  const [ checkCurrentPassowrd ] = useMutation(CHECK_CURRENT_PASSWORD)
  const [ updateProfile ] = useMutation(UPDATE_PROFILE)
  const formik = useFormik({
    initialValues: UpdatePasswordValues,
    validationSchema: updatePasswordSchema,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const { ProfessionalUpdate } = await callUpdateProfile(values.newPassword)
        if (ProfessionalUpdate !== 'success') return
        setPasswordPage('password_updated')
      } catch (error) {
        console.log(error)
      } finally {
        formik.setSubmitting(false)
      }
    }
  })

  const checkPasswordValidation = async () => {
    try {
      setIsLoading(true)
      const { ProfessionalLogin } = await callCheckCurrentPassword(formik.values.password)
      if (!ProfessionalLogin) return
      setPasswordPage('update_password')
    } catch (error) {
      setPasswordPage('current_password')
    } finally {
      setIsLoading(false)
    }
  }

  const callCheckCurrentPassword = async (password) => {
    const { data } = await checkCurrentPassowrd({
      variables: {
        email: professional?.email,
        password,
      }
    })
    return data
  }

  const callUpdateProfile = async (password) => {
    const { data } = await updateProfile({
      variables: {
        professional: {
          id: professional?.id,
          password,
        }
      }
    })
    return data
  }

  const closeCompletedModal = () => {
    passwordModalClose()
    setPasswordPage('current_password')
  }

  return (
    <>
      <TFAModal
        open={isPasswordOpen}
        handleClose={passwordModalClose}
        width={'30em'}
      >
        {((passwordPage === 'current_password') ||
         (passwordPage === 'update_password')) &&
        <>
          <div className={classes.modal_title_box}>
            <CloseBtn 
              className={classes.modal_title_box__icon}
              onClick={passwordModalClose}
            />
            <p className={classes.modal_title_box__title}>
              Password
            </p>
          </div>
          <TFAText
            primary_title='Security'
            secondary_title='Update Password'
            style1={classes}
          />
          {passwordPage === 'current_password' &&
          <>
            <div className={classes.password_box}>
              <p className={classes.password_box__title}>
                Current Password
              </p>
              <p className={classes.password_box__desc}>
                Enter your current password to verify yourself.
              </p>
              <TFAInputText 
                label='CURRENT PASSWORD'
                type='password'
                name='password'
                palceholder='Enter Current Password'
                form_width='95%'
                showPassword={isShowPassword}
                visiblePassword={setIsShowPassword}
                formik={formik}
                error={formik.errors.password ?? null}
              />
            </div>
          </>
          }
          {passwordPage === 'update_password' &&
          <>
            <div className={classes.password_box}>
              <p className={classes.password_box__title}>
                New Password
              </p>
              <p className={classes.password_box__desc}>
              Create a strong password that will protect your Time For Advice account.
              </p>
              <TFAInputText 
                label='NEW PASSWORD'
                type='password'
                name='newPassword'
                palceholder='Enter New Password'
                form_width='95%'
                showPassword={isShowPassword}
                visiblePassword={setIsShowPassword}
                formik={formik}
                error={formik.errors.newPassword ?? null}
              />
              <TFAInputText 
                label='CONFIRM NEW PASSWORD'
                type='password'
                name='confirmNewPassword'
                palceholder='Enter Confirm New Password'
                form_width='95%'
                showPassword={isShowPassword}
                visiblePassword={setIsShowPassword}
                formik={formik}
                error={formik.errors.confirmNewPassword ?? null}
              />
            </div>
          </>
          }
          <TFAButton 
            text='NEXT'
            style2={classes}
            isLoading={isLoading ?? formik.isSubmitting}
            onClick={passwordPage === 'current_password' 
            ? checkPasswordValidation 
            : formik.handleSubmit
            }
          />
        </>
        }
        {passwordPage === 'password_updated' &&
        <>
          <UpdatePassword className={classes.modal_title_icon}/>
          <div className={classes.modal_text_box}>
            <TFAText 
              secondary_title='Password Updated'
              description='Your password has been updated successfully.'
              style2={classes}
            />
          </div>
          <TFAButton 
            text='GREAT!'
            style2={classes}
            onClick={closeCompletedModal}
          />
        </>
        }
      </TFAModal>
    </>
  )
}

export default PasswordModal