import React, { useState } from 'react'
import classes from '../PProfile.module.scss'
import { TFAButton, TFAInputText, TFAModal, TFASubText, TFAText } from '../../../../../components/TFAComponents'
import { CloseBtn, UpdateCompleted } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useFormik } from 'formik'
import { updateMARACertificateSchema } from '../../../../../utils/validation'
import { UpdateMARACertificateValues } from '../../../../../assets/data/FormikInitialValues'
import TFADateInputField from '../../../../../components/TFADateInputField/TFADateInputField'
import UploadFileInput from '../../../../../components/Register/UploadFileInput/UploadFileInput'
import { GET_PRESIGNED_UPLOAD_URL } from '../../../../../services/apiQuery'
import { useLazyQuery, useMutation } from '@apollo/client'
import { UPDATE_PROFILE } from '../../../../../services/apiMutation'
import dateParser from '../../../../../utils/dateParser'

const UpdateMARAModal = ({ 
    isUpdateMaraOpen, 
    updateMaraModalClose 
}) => {
    const [ isCompleted, setIsCompleted ] = useState(false)
    const [ getUploadURL ] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL)
    const [ updateProfile ] = useMutation(UPDATE_PROFILE)
    const formik = useFormik({
        initialValues: UpdateMARACertificateValues,
        validationSchema: updateMARACertificateSchema,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                const { ProfessionalUpdate } = await callUpdateProfile(values)
                if (!ProfessionalUpdate) return
                setIsCompleted(true)
            } catch (error) {
                console.log({error})
            } finally {
                formik.setSubmitting(false)
            }
        }
    })

    const callUploadFileQuery = async(file, type) => {
        const result = await getUploadURL({
          variables: {
            filename: file.name,
            folder: type === 'MARA' ? 'MARA_document': 'prof_supportingdocument'
          }
        })
        return result
    }

    const callUpdateProfile = async (values) => {
        const { data } = await updateProfile({
            variables: {
                professional: {
                    MARANumber: values.MARANumber,
                    MARAexpiryDate: dateParser(values.expiryYear, values.expiryMonth, values.expiryDay),
                }
            }
        })
        return data
    }

    const closeCompletedModal = () => {
        updateMaraModalClose()
        setIsCompleted(false)
    }

    return (
        <>
        {isCompleted
        ?    
            <TFAModal
                open={isUpdateMaraOpen}
                handleClose={updateMaraModalClose}
                width='25em'
            >
                <UpdateCompleted className={classes.modal_title_icon}/>
                <div className={classes.modal_text_box}>
                <TFAText
                    secondary_title='MARA Certificate Updated'
                    description='Your MARA detail has been updated successfully.'
                    style2={classes}
                />
                </div>
                <TFAButton
                text='GREAT!'
                style2={classes}
                onClick={closeCompletedModal}
                />
            </TFAModal>
        :
            <TFAModal
                open={isUpdateMaraOpen}
                handleClose={updateMaraModalClose}
                width='30em'
            >
                <div className={classes.mara_modal_title_box}>
                    <CloseBtn 
                        className={classes.mara_modal_title_box__icon}
                        onClick={updateMaraModalClose}
                    />
                    <p className={classes.mara_modal_title_box__title}>
                        Update MARA Certificate
                    </p>
                </div>
                <TFAText 
                    primary_title='Certificate'
                    secondary_title='Update MARA Certificate'
                    style1={classes}
                />
                <div style={{textAlign: 'start', padding: '0 1em'}}>
                    <TFASubText 
                        primary_title='MARA Number'
                        description={<p>Enter your <b>MARA Number</b> below.</p>}
                        style1={classes}
                    />
                    <TFAInputText 
                        label='MARA NUMBER'
                        type='text'
                        name='MARANumber'
                        placeholder='1234567'
                        required={false}
                        formik={formik}
                        error={formik.errors.MARANumber ?? null}
                    />
                    <p className={classes.mara_title_vontainer}>
                        Enter your <b>MARA Expiry</b> date or <b>Practising Certificate Expiry</b> date.
                    </p>
                    <TFADateInputField 
                        type='marn_expiry'
                        formik={formik}
                        required={false}
                    />
                    <p className={classes.mara_title_vontainer}>
                        Attach your <b>Practising Certificate</b> or <b>MARA Registration and PI Insurance Certificate</b>
                    </p>
                    <UploadFileInput 
                        formik={formik}
                        callUploadFileQuery={callUploadFileQuery}
                        files={'MARAFiles'}
                        uploadedFiles={'MARAUploadedFiles'}
                        type={'MARA'}
                        error={formik.errors.MARAFiles ?? null}
                    />
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <TFAButton 
                            text='UPDATE'
                            onClick={formik.handleSubmit}
                            isLoading={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                            style1={classes}
                        />
                    </div>
                </div>
            </TFAModal>
        }
        </>
    )
}

export default UpdateMARAModal