import React from 'react'
import classes from '../EditAppointment.module.scss'
import { IconButton } from '@mui/material'
import { LeftArrow } from '../../../../../assets/icons/Auth/AuthIcons'
import { EditIcon } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAText, TFAButton } from '../../../../../components/TFAComponents'
import { ProfessionalCard } from '../../../../../components/Dashboard/DashboardComponents'
import { timeSlotConverter } from '../../../../../utils/appointmentFormatter'

const UpdateAppointment = ({
  formik,
  setIsUpdateAppointmentOpen,
  setIsTimeModalOpen,
  setIsDetailModalOpen,
  setIsCancelModalOpen,
}) => {
  const { professional, timeStart, modifiedDate, title, context } = formik.values
  return (
    <>
      <div 
        className={classes.backBtn_box}
        onClick={() => setIsUpdateAppointmentOpen(false)}
      >
        <LeftArrow className={classes.backBtn_box__arrow}/>
        <p className={classes.backBtn_box__txt}>RETURN</p>
      </div>
      <div className={classes.main_content_container}>
        <TFAText 
          primary_title='Adjust Appointment'
          secondary_title='Update your appointment'
          style1={classes}
        />
      </div>
      <div className={classes.adjust_appointment_box}>
        <div className={classes.editable_title}>
          <p className={classes.section_title}>
            Professional
          </p>
        </div>
        <div className={classes.professional_box}>
          <ProfessionalCard 
            professionalDetails={professional}
            bio={true}
          />
        </div>
      </div>
      <div className={classes.adjust_appointment_box}>
        <div className={classes.editable_title}>
          <p className={classes.section_title}>
            Appointment time
          </p>
          <IconButton 
            className={classes.section_icon}
            onClick={() => setIsTimeModalOpen(true)}
          >
            <EditIcon />
          </IconButton>
        </div>
        <div className={classes.appointment_time_slot_box}>
          <div className={classes.time_slot_box}>
            <p className={classes.time_slot_box__title}>
              Time Slot:
            </p>
            <p className={classes.time_slot_box__time}>
              {timeSlotConverter(modifiedDate !== '' ? modifiedDate : timeStart, 'time')}
            </p>
            <p className={classes.time_slot_box__date}>
              {timeSlotConverter(modifiedDate !== '' ? modifiedDate : timeStart, 'date')}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.adjust_appointment_box}>
        <div className={classes.editable_title}>
          <p className={classes.section_title}>
            Appointment Details
          </p>
          <IconButton 
            className={classes.section_icon}
            onClick={() => setIsDetailModalOpen(true)}
          >
            <EditIcon />
          </IconButton>
        </div>
        <div className={classes.appointment_detail_container}>
          <div className={classes.appointment_detail_box}>
            <div className={classes.appointment_detail_box__title}>
              Appointment Title:
            </div>
            <div className={classes.appointment_detail_box__content}>
              {title}
            </div>
          </div>
          <div className={classes.appointment_detail_box}>
            <div className={classes.appointment_detail_box__title}>
              Appointment Context:
            </div>
            <div className={classes.appointment_detail_box__content}>
              {context}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.adjust_appointment_box}>
        <div className={classes.editable_title}>
          <p className={classes.section_title}>
            Cancel Appointment
          </p>
        </div>
        <div className={classes.cancel_box}>
          <p className={classes.cancel_box__text}>
            By cancelling this appointment you will forfeit your allocated time slot and not be refunded your full amount paid.
          </p>
          <div className={classes.cancel_box__btn}>
            <TFAButton 
              text='CANCEL APPOINTMENT'
              onClick={() => setIsCancelModalOpen(true)}
              style1={classes}
            />
          </div>
        </div>
      </div>
      <div className={classes.btn_box}>
        <TFAButton 
          text='SAVE'
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          style2={classes}
        />
      </div>
    </>
  )
}

export default UpdateAppointment