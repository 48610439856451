import { gql } from '@apollo/client'

// check if a user exists already
const GET_PROFESSIONAL = gql`
	query GetProfessional($getProfessionalId: String) {
		GetProfessional(id: $getProfessionalId) {
			id
			professionalCode
			firstName
			lastName
			email
			mobile
			professionalspecialties {
				id
				specialty
			}
			dayOfBirth
			monthOfBirth
			yearOfBirth
			MARANumber
			MARAexpiryDate
			MARA_documents {
				id
				fileName
				key
			}
			LPNNumber
			bio
			firstLanguage
			secondLanguage
			experienceLevel
			stripe_accountId
			streetNumber
			streetName
			suburb
			postcode
			state
			country
			askingPrice
			questionPrice
			JWT
			avatarLink
			lpn_documents {
				id
				fileName
				key
			}
			lpn_expiry_date
		}
	}
`

const GET_CLIENT = gql`
	query GetClient($getClientId: String) {
		GetClient(id: $getClientId) {
			id
			firstName
			lastName
			email
			mobile
			countryOfOrigin
			firstLanguage
			secondLanguage
			paymentPreference
			stripe_customId
			status
			questionsAsked
			JWT
			avatarLink
			kits {
				id
				title
				date
				price
				subclass
			}
		}
	}
`

// Professional Signup
const GET_VISITOR_TOKEN = gql`
	query Root_query_type_v1 {
		GetVisitorToken
	}
`

const GET_PRESIGNED_UPLOAD_URL = gql`
	query GetPresignedUploadUrl($filename: String, $folder: S3FoldersEnum!) {
		GetPresignedUploadUrl(filename: $filename, folder: $folder) {
			filename
			key
			link
		}
	}
`

const DECRYPT_USER_ID = gql`
	query Root_query_type_v1($ciphertext: String) {
		RSA_decrypt_test(ciphertext: $ciphertext)
	}
`

// Questions
const GET_QUESTIONS = gql`
	query GetQuestions($progress: [QuestionProgressEnumType], $professionalId: String, $clientId: String, $title: String) {
		GetQuestions(progress: $progress, professionalId: $professionalId, clientId: $clientId, title: $title) {
			count
			objectList {
				id
				title
				content
				finishTime
				destinationCountry
				category
				price
				progress
				createdAt
				client {
					firstName
					lastName
					avatar
					questionsAsked
				}
				questionimages {
					id
					key
					sequence
					link
				}
				questionfiles {
					id
					key
					sequence
					link
				}
				professional {
					id
				}
			}
		}
	}
`

const GET_QUESTION = gql`
	query GetQuestion($getQuestionId: String) {
		GetQuestion(id: $getQuestionId) {
			id
			title
			content
			finishTime
			destinationCountry
			category
			price
			clientExperience
			progress
			client {
				id
				avatar
				firstName
				lastName
				questionsAsked
			}
			questionimages {
				id
				key
				link
			}
			questionfiles {
				id
				key
				link
			}
			professional {
				id
			}
		}
	}
`

const GET_QUESTION_FOR_CLIENT = gql`
	query GetQuestion($getQuestionId: String) {
		GetQuestion(id: $getQuestionId) {
			id
			title
			content
			finishTime
			destinationCountry
			category
			price
			clientExperience
			progress
			client {
				id
			}
			professional {
				id
				professionalCode
				avatar
				firstName
				lastName
				professionalspecialties {
					id
					specialty
				}
			}
			questionimages {
				id
				key
				link
			}
			questionfiles {
				id
				key
				link
			}
		}
	}
`

const SINGLE_DOWNLOAD = gql`
	query Root_query_type_v1($filename: String) {
		SingleDownload(filename: $filename)
	}
`

const GET_QUESTION_REPLYS = gql`
	query GetQuestionreplys($questionId: String) {
		GetQuestionreplys(questionId: $questionId) {
			count
			objectList {
				id
				content
				stage
				progress
				createdAt
				professional {
					id
					firstName
					lastName
				}
				client {
					id
					firstName
					lastName
				}
				questionreplyfiles {
					id
					key
					sequence
					link
				}
			}
		}
	}
`

const GET_QUESTION_POOLS = gql`
	query GetQuestionpools($title: String) {
		GetQuestionpools(title: $title) {
			count
			objectList {
				id
				createdAt
				question {
					id
					title
					price
					destinationCountry
					category
					valid
				}
			}
		}
	}
`

const GET_QUESTION_OFFERS = gql`
	query GetQuestionoffers($questionId: String, $status: [QuestionofferStatusEnum]) {
		GetQuestionoffers(questionId: $questionId, status: $status) {
			count
			objectList {
				id
				time
				price
				message
				status
				professional {
					id
					professionalCode
					firstName
					lastName
					avatar
					professionalspecialties {
						id
						specialty
					}
				}
			}
		}
	}
`

const GET_QUESTION_OFFER = gql`
	query GetQuestionoffer($getQuestionofferId: String) {
		GetQuestionoffer(id: $getQuestionofferId) {
			id
			time
			price
			message
			status
			professional {
				id
				professionalCode
				firstName
				lastName
				professionalspecialties {
					id
					specialty
				}
				avatar
			}
		}
	}
`
const GET_QUESTION_COUNT = gql`
	query Root_query_type_v1($clientId: String) {
		CountQuestions(clientId: $clientId)
	}
`

// Appointment
const GET_APPOINTMENTS = gql`
	query GetAppointments($professionalId: String, $progress: [AppointmentProgressEnum], $title: String) {
		GetAppointments(professionalId: $professionalId, progress: $progress, title: $title) {
			count
			objectList {
				id
				title
				timeStart
				timeFinish
				progress
				client {
					firstName
					lastName
				}
				professional {
					firstName
					lastName
				}
			}
		}
	}
`

const GET_APPOINTMENT = gql`
	query GetAppointment($getAppointmentId: String) {
		GetAppointment(id: $getAppointmentId) {
			id
			title
			context
			link
			timeStart
			timeFinish
			progress
			cancelDetail
			updatedAt
			client {
				id
				firstName
				lastName
				avatar
				questionsAsked
			}
			professional {
				id
				professionalCode
				avatar
				firstName
				lastName
				professionalspecialties {
					id
					specialty
				}
			}
		}
	}
`

const GET_MEETING_SCHEDULES = gql`
	query GetMeetingschedules($professionalId: String) {
		GetMeetingschedules(professionalId: $professionalId) {
			count
			objectList {
				id
				startTime
				endTime
			}
		}
	}
`

const GET_AVAILABLE_TIME = gql`
	query Root_query_type_v1($professionalId: String, $dayStart: String, $dayEnd: String) {
		GetAvailableTime(professionalId: $professionalId, dayStart: $dayStart, dayEnd: $dayEnd)
	}
`

const GET_APPOINTMENT_COUNT = gql`
	query Root_query_type_v1($clientId: String) {
		CountAppointments(clientId: $clientId)
	}
`

// Professional Template
const GET_TEMPLATES = gql`
	query GetTemplates($professionalId: String) {
		GetTemplates(professionalId: $professionalId) {
			count
			objectList {
				id
				title
				content
			}
		}
	}
`

const GET_TEMPLATE = gql`
	query GetTemplate($getTemplateId: String) {
		GetTemplate(id: $getTemplateId) {
			id
			title
			content
		}
	}
`

// Create Question
const GET_PROFESSIONALS = gql`
	query GetProfessionals($professionalCode: String) {
		GetProfessionals(professionalCode: $professionalCode) {
			count
			objectList {
				id
				professionalCode
				firstName
				lastName
				MARANumber
				MARAexpiryDate
				LPNNumber
				experienceLevel
				professionalspecialties {
					id
					specialty
				}
				askingPrice
				questionPrice
				avatar
				bio
			}
		}
	}
`

// Payment
const GET_CARD_DETAIL = gql`
	query Stripe_ListAllCards($clientId: String) {
		Stripe_ListAllCards(clientId: $clientId) {
			id
			brand
			country
			cvc_check
			exp_month
			exp_year
			last4
			funding
		}
	}
`

const GET_STRIPE_ACCOUNT = gql`
	query Stripe_RetrieveAccount($accountId: String) {
		Stripe_RetrieveAccount(accountId: $accountId) {
			payouts_enabled
			charges_enabled
			externalAccount {
				id
				country
				currency
				last4
				routing_number
				bank_name
			}
		}
	}
`

const GET_ACCOUNT_LINK = gql`
	query Root_query_type_v1($accountId: String) {
		GetAccounLink(accountId: $accountId)
	}
`

// Kits
const GET_KITS = gql`
	query GetKits($title: String) {
		GetKits(title: $title) {
			count
			objectList {
				id
				title
				date
				price
				subclass
			}
		}
	}
`

const GET_KIT = gql`
	query GetKit($getKitId: String) {
		GetKit(id: $getKitId) {
			id
			title
			price
			subclass
			content
			date
			key
			link
		}
	}
`

const GET_ALL_CLIENT_QUESTION = gql`
	query Root_query_type_v1($clientId: String, $progress: [QuestionProgressEnumType]) {
		GetQuestions(clientId: $clientId, progress: $progress) {
			objectList {
				id
				title
			}
		}
	}
`

const GET_ALL_CLIENT_APPOINTMENT = gql`
	query GetAppointments($clientId: String, $progress: [AppointmentProgressEnum]) {
		GetAppointments(clientId: $clientId, progress: $progress) {
			objectList {
				id
				title
			}
		}
	}
`

// Transaction History
const GET_PRO_TRANSACTION_HISTORY = gql`
	query ListAllTransfers($professionalId: String) {
		ListAllTransfers(professionalId: $professionalId) {
			id
			object
			amount
			created
			currency
			description
			transfer_group
			payment {
				id
				amount
				message
				question {
					id
					title
					content
				}
				appointment {
					id
					title
					context
				}
				kit {
					id
					title
				}
				professional {
					id
					firstName
					lastName
				}
				client {
					id
					firstName
					lastName
				}
				createdAt
			}
		}
	}
`

const GET_PAYMENTS = gql`
	query GetPayments($clientId: String) {
		GetPayments(clientId: $clientId) {
			count
			objectList {
				id
				amount
				message
				stripe_id
				paypal_id
				type
				isSucceed
				createdAt
				question {
					id
					title
					professional {
						firstName
						lastName
					}
				}
				appointment {
					id
					title
					professional {
						firstName
						lastName
					}
				}
				kit {
					id
					title
				}
			}
		}
	}
`

// Professional Schedules
const GET_PROFESSIONAL_SCHEDULES = gql`
	query GetMeetingschedules($professionalId: String) {
		GetMeetingschedules(professionalId: $professionalId) {
			objectList {
				id
				startTime
				endTime
				valid
				reserve
				updatedAt
				createdAt
			}
		}
	}
`

// Notification
const GET_NOTIFICATIONS = gql`
	query GetNotifications($clientId: String, $professionalId: String, $isRead: Boolean) {
		GetNotifications(clientId: $clientId, professionalId: $professionalId, isRead: $isRead) {
			count
			objectList {
				id
				isRead
				title
				content
				question {
					id
				}
				appointment {
					id
				}
			}
		}
	}
`

export {
	GET_PROFESSIONAL,
	GET_CLIENT,
	GET_VISITOR_TOKEN,
	GET_PRESIGNED_UPLOAD_URL,
	DECRYPT_USER_ID,
	GET_QUESTIONS,
	GET_QUESTION,
	GET_QUESTION_FOR_CLIENT,
	SINGLE_DOWNLOAD,
	GET_QUESTION_REPLYS,
	GET_QUESTION_POOLS,
	GET_QUESTION_OFFERS,
	GET_QUESTION_OFFER,
	GET_QUESTION_COUNT,
	GET_APPOINTMENTS,
	GET_APPOINTMENT,
	GET_MEETING_SCHEDULES,
	GET_AVAILABLE_TIME,
	GET_APPOINTMENT_COUNT,
	GET_TEMPLATES,
	GET_TEMPLATE,
	GET_PROFESSIONALS,
	GET_CARD_DETAIL,
	GET_STRIPE_ACCOUNT,
	GET_ACCOUNT_LINK,
	GET_KITS,
	GET_KIT,
	GET_ALL_CLIENT_QUESTION,
	GET_ALL_CLIENT_APPOINTMENT,
	GET_PRO_TRANSACTION_HISTORY,
	GET_PAYMENTS,
	GET_PROFESSIONAL_SCHEDULES,
	GET_NOTIFICATIONS,
}
