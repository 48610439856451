import { styled, ListItem, ListItemIcon, ListItemText } from '@mui/material'

const SidebarItem = styled(ListItem)(({ theme }) => ({
    width: '95%',
    margin: '0.1em auto',
    borderRadius: '10px',
    '&.MuiListItem-root.Mui-selected': {
        '& div.MuiListItemIcon-root': {
            fill: '#022873'
        },
        '& div.MuiListItemText-root': {
            color: '#022873'
        }
    },
    '&:hover': {
        '& div.MuiListItemIcon-root': {
            fill: '#022873'
        },
        '& div.MuiListItemText-root': {
            color: '#022873'
        }
    }
}))

const SidebarItemIcon = styled(ListItemIcon)(({ theme }) => ({
    justifyContent: 'center',
    transform: 'scale(1.1)',
    fill: '#C7C7C7',
}))

const SidebarItemText = styled(ListItemText)(({ theme }) => ({
    color: '#C7C7C7',
    '.MuiListItemText-primary': {
        fontFamily: "'Space Grotesk', sans-serif",
        fontWeight: 'bold',
    },
}))

const MinSidebarItem = styled(ListItem)(({ theme }) => ({
    width: '100%',
    margin: '0 auto',
    padding: '1em 0',
    borderRadius: '6px',
    '&.MuiListItem-root.Mui-selected': {
        '& div.MuiListItemIcon-root': {
            fill: '#022873'
        },
        '& div.MuiListItemText-root': {
            color: '#022873'
        }
    },
    '&:hover': {
        '& div.MuiListItemIcon-root': {
            fill: '#022873'
        },
        '& div.MuiListItemText-root': {
            color: '#022873'
        }
    }
}))

const MinSidebarItemIcon = styled(ListItemIcon)(({ theme }) => ({
    justifyContent: 'center',
    transform: 'scale(1.2)',
    fill: '#C7C7C7',
}))


export { 
    SidebarItem, 
    SidebarItemIcon, 
    SidebarItemText,
    MinSidebarItem,
    MinSidebarItemIcon
}