import React, { useState } from 'react'
import classes from './Modals.module.scss'
import { TFAButton, TFAModal } from '../../../../../components/TFAComponents'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { useMutation } from '@apollo/client'
import { DELETE_QUESTION } from '../../../../../services/apiMutation'

const RemoveModal = ({
    formik, 
    type, 
    setIsUpdateQuestionOpen,
    isRemoveModalOpen, 
    setIsRemoveModalOpen, 
}) => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ deleteQueston ] = useMutation(DELETE_QUESTION)

    const handleCancelQuestion = async () => {
        try {
            setIsLoading(true)
            const { QuestionDelete } = await callDeleteQueston(formik.values.questonId)
            if (!QuestionDelete) return
            setIsRemoveModalOpen(false)
            setIsUpdateQuestionOpen(false)
            window.location.reload(false)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const callDeleteQueston = async (questonId) => {
        const { data } = await deleteQueston({
            variables: {
                questionDeleteId: questonId
            }
        })
        return data
    }

    return (
        <TFAModal
            open={isRemoveModalOpen}
            handleClose={setIsRemoveModalOpen}
            width='25em'
        >
            {type === 'question' &&
            <>
                <div className={classes.modal_title_box}>
                    <CloseBtn 
                        className={classes.modal_title_box__icon}
                        onClick={() => setIsRemoveModalOpen(false)}
                    />
                    <p className={classes.modal_title_box__title}>
                        Remove Question
                    </p>
                </div>
                <p className={classes.modal_desc_box}>
                    Are you sure you want to remove your question? Your question will be removed from the Time For Advice question pool and you will not receive your full amount paid.
                </p>
                <div className={classes.cancel_box}>
                    <TFAButton 
                    text='REMOVE QUESTION'
                    onClick={handleCancelQuestion}
                    isLoading={isLoading}
                    disabled={isLoading}
                    style1={classes}
                    />
                </div>
            </>
            }
            {type === 'image' &&
            <div className={classes.modal_title_box}>
                imge modal
            </div>
            }
        </TFAModal>
    )
}

export default RemoveModal