const ProfessionalReducer = (state, action) => {
    switch(action.type) {
        case 'setProfessional': {
            return {
                ...state,
                professional: action.data
            }
        }
        default:
            return state
    }
}

export default ProfessionalReducer