import { alpha, styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

const AnswerTextField = styled(TextField)(({ theme }) => ({
    '& label': {
        color: '#022873',
        fontFamily: "'Space Grotesk', sans-serif",
        fontWeight: '600'
    },
    '& label.Mui-focused': {
        color: '#022873',
        fontFamily: "'Space Grotesk', sans-serif",
    },
    '& .MuiFilledInput-root': {
        width: '100%',
        height: '4em',
        transition: 'height 500ms',
        overflowY: 'auto',
        border: '2px solid #2F68FA',
        borderRadius: 6,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        '&#answer-input': {
            height: '100%',
        },
        '&:hover': {
            transition: 'height 500ms',
            height: '13em',
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            borderColor: '#2F68FA',
        },
    },
    '& .MuiFilledInput-input': {
        height: '100% !important',
        overflowY: 'auto !important',
    },
}))

export { AnswerTextField }