// https://react-select.com/styles
const CustomStyles = {
    control: () => ({
        display: 'flex',
        width: '100%',
        height: '3.4em',
        borderRadius: '6px',
        border: '2px solid #2F68FA',
    }),
    valueContainer: () => ({
        width: '95%',
        gridArea: '1',
        alignItems: 'center',
    }),
    singleValue: () => ({
        width: '100%',
        height: '100%',
        color: '#022873',
        fontSize: '1.1em',
        display: 'flex',
        alignItems: 'center',
        padding: '1em 0.7em'
    }),
    input: () => ({
        width: '100%',
        height: '100%',
        color: '#022873',
        fontSize: '1.1em',
        display: 'flex',
        alignItems: 'center',
        padding: '1em 0.7em',
    }),
    placeholder: () => ({
        width: '100%',
        height: '100%',
        color: '#022873',
        fontWeight: 'bold',
        fontSize: '1.1em',
        display: 'flex',
        alignItems: 'center',
        padding: '1em 0.7em'
    }),
    menu: () => ({
        border: '2px solid #2F68FA',
        borderRadius: '6px',
    }),
}

export default CustomStyles