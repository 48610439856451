import React, { useEffect, useState } from 'react'
import classes from '../Kits.module.scss'
import { CloseBtn } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFAButton, TFAModal } from '../../../../../components/TFAComponents'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_CARD_DETAIL, GET_KIT } from '../../../../../services/apiQuery'
import { useFormik } from 'formik'
import { KitPurchaseValues } from '../../../../../assets/data/FormikInitialValues'
import { PaymentMethodCard } from '../../../../../components/Dashboard/DashboardComponents'
import PaymentMethodModal from './PaymentMethodModal'
import { KIT_PURCHASE } from '../../../../../services/apiMutation'
import useClientContext from '../../../../../states/Client/useClientContext'
import PayPalButton from '../../../../../components/Dashboard/PayPalButton/PayPalButton'

const PurchaseModal = ({ kitId, isPaymentModalOpen, setIsPaymentModalOpen, kitsRefetch, clientRefetch }) => {
	const { client } = useClientContext().clientState
	const [kitDetails, setKitDetails] = useState({})
	const [isSelectPaymentModalOpen, setIsSelectPaymentModalOpen] = useState(false)
	const [getCards, { data: cardData, refetch }] = useLazyQuery(GET_CARD_DETAIL)

	useEffect(() => {
		getCardDetails()
	}, [])

	const getCardDetails = async () => {
		try {
			await getCards()
		} catch (error) {
			console.log('the cards', error)
		}
	}

	const { loading: kitLoading, data: kitData } = useQuery(GET_KIT, {
		variables: { getKitId: kitId },
	})

	const [purchaseKit] = useMutation(KIT_PURCHASE)

	const formik = useFormik({
		initialValues: KitPurchaseValues,
		onSubmit: async (values) => {
			try {
				formik.setSubmitting(true)
				const { KitPurchase } = await callPurchaseKit(values)
				if (!KitPurchase) return
				setIsPaymentModalOpen(false)
				kitsRefetch()
				clientRefetch()
			} catch (error) {
				console.log(error)
			} finally {
				formik.setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (client) {
			formik.setFieldValue((formik.values.paymentPreference = client.paymentPreference))
			formik.setFieldValue((formik.values.email = client.email))
		}
	}, [client])

	useEffect(() => {
		kitData && setKitDetails(kitData.GetKit)
	}, [kitData])

	useEffect(() => {
		if (cardData) {
			if (cardData.Stripe_ListAllCards.length === 0) return
			formik.setFieldValue((formik.values.allCards = cardData.Stripe_ListAllCards))
			formik.setFieldValue((formik.values.originalDefaultCard = cardData.Stripe_ListAllCards[0]))
			formik.setFieldValue((formik.values.defaultCard = cardData.Stripe_ListAllCards[0]))
		}
	}, [cardData])

	const callPurchaseKit = async (values) => {
		if (kitDetails.price === 0) {
			const { data } = await purchaseKit({
				variables: {
					kitId,
					sourceId: '',
					paypalOfferId: 'no-transaction-required',
				},
			})
			return data
		} else {
			const { data } = await purchaseKit({
				variables: {
					kitId,
					sourceId: values.paymentPreference === 'Card' ? values.defaultCard.id : '',
					paypalOfferId: values.paymentPreference === 'PayPal' ? values.paypalOrderId : null,
				},
			})
			return data
		}
	}

	return (
		<TFAModal open={isPaymentModalOpen} handleClose={() => setIsPaymentModalOpen(false)} width='30em'>
			<div className={classes.modal_title_box}>
				<CloseBtn className={classes.modal_title_box__icon} onClick={() => setIsPaymentModalOpen(false)} />
				<p className={classes.modal_title_box__title}>Purchase Kit</p>
			</div>
			<div className={classes.your_kit_box}>
				<p className={classes.your_kit_box__title}>Your Kit</p>
				<p className={classes.your_kit_box__desc}>Please ensure the selected migration kit below is the one you desire to purchase.</p>
			</div>
			{kitLoading && Object.entries(kitDetails).length !== 0 ? null : (
				<div className={classes.kit_content_box}>
					<div className={classes.kit_content_box__title_box}>
						{kitDetails.subclass !== 'N/A' ? (
							<>
								<p className={classes.kit_content_box__title_box__title}>Subclass:</p>
								<p className={classes.kit_content_box__title_box__content}>{kitDetails?.subclass?.split(' ')[1]}</p>
							</>
						) : null}
					</div>
					<div className={classes.kit_content_box__context_box}>
						<p className={classes.kit_content_box__context_box__title}>{kitDetails.title}</p>
					</div>
					<div className={classes.kit_content_box__price_box}>
						<p className={classes.kit_content_box__price_box__title}>Kit Price:</p>
						<p className={classes.kit_content_box__price_box__content}>&#36;{parseFloat(kitDetails.price / 100).toFixed(2)}</p>
					</div>
				</div>
			)}
			<div className={classes.your_kit_box}>
				<p className={classes.your_kit_box__title}>Payment Method</p>
				<p className={classes.your_kit_box__desc}>Select how you would like to pay for your migration kit.</p>
			</div>
			<div className={classes.payment_method_box}>
				<PaymentMethodCard
					formik={formik}
					handleClick={() => setIsSelectPaymentModalOpen(true)}
					isPaymentSelected={true}
					method={formik.values.paymentPreference}
				/>
			</div>
			<div className={classes.btn_box}>
				<TFAButton
					text='PURCHASE KIT'
					onClick={formik.handleSubmit}
					isLoading={formik.isSubmitting}
					disabled={formik.isSubmitting}
					style1={classes}
				/>
			</div>
			<PaymentMethodModal
				formik={formik}
				onAddNewCard={() => refetch()}
				isPaymentModalOpen={isSelectPaymentModalOpen}
				paymentModalClose={() => setIsSelectPaymentModalOpen(false)}
			/>
		</TFAModal>
	)
}

export default PurchaseModal
