import React from 'react'
import classes from '../PProfile.module.scss'
import { DashboardSubTitle } from '../../../../../components/Dashboard/DashboardComponents'
import { BankAccount, EditIcon } from '../../../../../assets/icons/Dashboard/DashboardIcons'
import { TFASkeleton } from '../../../../../components/TFAComponents'

const Account = ({ 
    accountLoading,
    bankDetails,
    bankAccountModalOpen, 
}) => {
    const bsbFormatter = (bsb) => {
        if (!bsb) return
        const sixDigits = bsb.split(' ').join('')
        const formatted = sixDigits.slice(0, 3) + '-' + sixDigits.slice(3)
        return formatted
    }

    const renderBankDetails = () => {
        if (accountLoading) return <TFASkeleton />
        if (!bankDetails?.externalAccount) return 'No Bank Details'
        return (
          <>
            <p>Account • xxxxx{bankDetails?.externalAccount[0]?.last4}</p>
            <p>
              {`BSB  •  ${bsbFormatter(
                bankDetails?.externalAccount[0]?.routing_number
              )}`}
            </p>
          </>
        )
    }

    return (
        <div className={classes.bank_container}>
            <DashboardSubTitle 
            Icon={BankAccount}
            title='Account'
            fontSize='1.4em'
            color='#2F68FA'
            />
            <div className={classes.account}>
                <div className={classes.account__details}>
                    <BankAccount 
                    className={classes.account__details__icon_big}
                    />
                    <div className={classes.account__details__text}>
                        <p className={classes.account__details__text__title}>
                            Bank Account
                        </p>
                        {renderBankDetails()}
                    </div>
                </div>
                <EditIcon 
                    className={classes.account__icon_small}
                    onClick={bankAccountModalOpen}
                />
            </div>
        </div>
    )
}

export default Account