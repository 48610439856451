import React, { useState } from 'react'
import classes from './Templates.module.scss'
import { Sidebar } from '../../../../components/Dashboard/DashboardComponents'
import MyTemplates from './Sections/MyTemplates'
import EditTemplates from './Sections/EditTemplates'

const Templates = () => {
  const [ clickedTemplateId, setClickedTemplateId ] = useState('')
  const [ isNewClicked, setIsNewClicked ] = useState(false)

  return (
    <div className={classes.wrapper}>
      <Sidebar pageSide='professional' tabName='Templates' />
      <div className={classes.main_content_box}>
        <MyTemplates 
          clickedTemplateId={clickedTemplateId}
          setClickedTemplateId={setClickedTemplateId}
          isNewClicked={isNewClicked}
          setIsNewClicked={setIsNewClicked}
        />
        <EditTemplates 
          clickedTemplateId={clickedTemplateId}
          setClickedTemplateId={setClickedTemplateId}
          isNewClicked={isNewClicked}
          setIsNewClicked={setIsNewClicked}
        />
      </div> 
    </div>
  )
}

export default Templates