// Sort questions for professional working area
const activeQuestions = (questions) => {
    const active = questions.filter(question => question.Progress !== 'Complete')
    return active
}

const completedQuestions = (questions) => {
    const completed = questions.filter(question => question.Progress === 'Complete')
    return completed
}

const findClickedQuestion = (clicked, questions) => {
    const clickedQuestion = questions.find(question => question.question.id === clicked.id)
    return clickedQuestion
}

const removeClickedQuestion = (clicked, questions) => {
    const clickedQuestion = questions.filter(question => question.question.id !== clicked.id)
    return clickedQuestion
}

export {
    activeQuestions,
    completedQuestions,
    findClickedQuestion,
    removeClickedQuestion,
}